'use strict';

/* global angular */

angular.module('medtasker.service').factory('config', ['$http', '$q', '$window', '$rootScope', '_', function ($http, $q, $window, $rootScope, _) {
	var preservedFields = ['restoreMobileSession', 'mobileMinimumSessionLengthMinutes', 'preferredTelecoms', 'defaultCampusLoc', 'cellularServerConnectionAllowed', 'bugsnagApiKeyMobile', 'bugsnagApiKeyWeb', 'bugsnagNotify'];
	var o = {
		initialised: false,
		verboseLog: false,
		logMode: 'save',
		allowedLogVerbs: ['info', 'warn', 'error', 'log', 'debug'],
		breakOnException: false,
		restoreMobileSession: false,
		mobileMinimumSessionLengthMinutes: 0,
		baseUrl: '',
		defaultCampusLoc: null,

		preinit: function preinit() {
			if ($window.localStorage.mtconfig) {
				angular.merge(o, angular.fromJson($window.localStorage.mtconfig));
			}
			if ($window.localStorage['medtasker.baseUrl']) {
				o.baseUrl = $window.localStorage.getItem('medtasker.baseUrl');
			}
		},

		setBaseUrl: function setBaseUrl(url) {
			o.baseUrl = url;
			$window.localStorage.setItem('medtasker.baseUrl', url);
		},

		init: function init(force) {
			var url = o.baseUrl + '/api/v1/script/app-config';
			var deferred = $q.defer();
			if (o.initialised && !force) {
				deferred.resolve();
			} else {
				var req = {
					method: 'GET',
					url: url
				};
				req.headers = { 'Accept': 'application/json' };
				$http(req).then(function (res) {
					angular.merge(o, res.data);
					o.initialised = true;
					var cfg = {};
					_.forEach(preservedFields, function (f) {
						cfg[f] = o[f];
					});
					$window.localStorage.setItem('mtconfig', angular.toJson(cfg));
					var url = o.baseUrl + '/api/v1/oauth/config';
					$http.get(url).then(function (resoauth) {
						o.setItem('oauthconfig', resoauth.data);
						$rootScope.$broadcast('ConfigInitComplete');
						deferred.resolve();
					}, function (err) {
						deferred.reject(err);
					});
				}, function (err) {
					deferred.reject(err);
				});
			}
			return deferred.promise;
		},

		setItem: function setItem(item, value) {
			var cfg = angular.fromJson($window.localStorage.getItem('mtconfig'));
			cfg[item] = o[item] = value;
			$window.localStorage.setItem('mtconfig', angular.toJson(cfg));
		}
	};
	return o;
}]);