'use strict';

angular.module('medtasker').controller('ReasonModalCtrl', ['modalArgs', '$modalInstance', function (modalArgs, $modalInstance) {

	var _this = this;
	_this.reasons = modalArgs.reasons;
	_this.title = modalArgs.title;
	_this.message = modalArgs.message;

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.ok = function () {
		if (_this.reason === 'Other') {
			$modalInstance.close(_this.otherReason);
		} else {
			$modalInstance.close(_this.reason);
		}
	};
}]);