'use strict';

// Values may be overwritten at deploy time when starting the docker container
// See ops/inject.sh

angular.module('medtasker').value('baseUrl', 'https://rc-server.medtasker.com:3001'); // INJECT: This will be modified by inject.sh

angular.module('medtasker').value('hospitalCode', 'NIMBLOC'); // INJECT: This will be modified by inject.sh

angular.module('medtasker').value('appVersion', 'MEDTASKER_VERSION'); // INJECT: This will be modified by inject.sh

angular.module('medtasker').value('defaultState', 'app.compose'); // INJECT: This will be modified by inject.sh

angular.module('medtasker').constant('idleTimeoutMins', '20'); // INJECT: This will be modified by inject.sh