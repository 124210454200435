'use strict';

/***
 Services for Nimblic Medtasker
*/
/* global angular */

(function (window, angular) {
	'use strict';

	angular.module('medtasker.service', ['ngCookies', 'ngSanitize', 'angularMoment', 'ngResource', 'uuid', 'lodash', 'angular-jwt', 'stopwatch', 'ngIdle']).constant('AUTH_EVENTS', {
		loginSuccess: 'auth-login-success',
		loginFailed: 'auth-login-failed',
		logoutSuccess: 'auth-logout-success',
		loggedOutUser: 'auth-user-logout',
		loggedOutWard: 'auth-ward-logout',
		sessionTimeout: 'auth-session-timeout',
		notAuthenticated: 'auth-not-authenticated',
		notAuthorized: 'auth-not-authorized',
		userSessionRestored: 'auth-userSession-restored',
		wardSessionRestored: 'auth-wardSession-restored'
	}).constant('USER_ROLES', {
		public: 'public',
		admin: 'admin',
		roster: 'roster',
		ward: 'ward',
		clinician: 'clinician',
		codes: 'codes',
		switch: 'switch'
	});
})(window, window.angular);