/*eslint angular/controller-as:0 */
'use strict';

angular.module('medtasker').controller('DatePickerCtrl', ['$scope', function ($scope) {

	var _this = this;
	// this.practitioners = practitioners;
	//this.patients = patients;

	_this.today = function () {
		_this.dt = new Date();
	};
	_this.today();

	_this.clear = function () {
		$scope.dt = null;
	};

	_this.open = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		_this.opened = true;
	};
}]);