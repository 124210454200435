
'use strict';

angular.module('medtasker').controller('RoleDialogCtrl', ['RoleService', 'AppService', 'config', '$log', '_', 'moment', '$modalInstance', 'modalArgs', function (RoleService, AppService, config, $log, _, moment, $modalInstance, modalArgs) {

	var _this = this;

	_this.config = config;

	var defaultRole = {
		type: _.find(config.mtRoleTypes, function (t) {
			return t.code === 'doctor';
		}),
		level: null,
		responsibility: null,
		core: false,
		active: true,
		organization: modalArgs.team,
		telecom: []
	};

	_this.wards = [];

	AppService.search('Location', 'physicalType=wi').then(function (wards) {
		_this.wards = _.transform(wards, function (accum, w) {
			if (w.identifier[0].value !== 'TEMP_WARD' && w.identifier[0].value !== 'NO_WARD') {
				w.display = config.wardDisplayField === 'name' ? w.name : w.identifier[0].value;
				accum.push(w);
			}
		});
	});

	_this.campusCode = AppService.campusDisplayCode;

	if (modalArgs.role) {
		//Copy the role to prevent the object in context being modified by edits before being saved.
		_this.role = angular.copy(modalArgs.role);
		var t = _this.role.start.hours() + ':' + _this.role.start.minutes();
		_this.startTime = moment.tz(moment(t, 'hh:mm').format('YYYY-MM-DDTHH:mm:ss'), moment.tz.guess()).toDate(); //strip out tz info so the date object is not converted to browser timezone (in case there's a difference)
		if (config.hospitalType === 'private') {
			_this.responsibilityCode = _this.role.responsibility.code;
			_this.responsibilityDisplay = _this.role.responsibility.display;
		}
		_this.role.associatedLocation = _this.role.associatedLocation || [];
	} else {
		_this.role = angular.copy(defaultRole);
		_this.role.associatedLocation = _this.role.associatedLocation || [];
		_this.startTime = moment(config.defaultShiftStartTime, 'hh:mm').toDate();
	}
	//_this.startTime = moment().set()
	function populateTelecoms() {
		var phone = _.find(_this.role.telecom, { 'system': 'phone' });
		_this.phone = phone ? phone.value : null;
		var pager = _.find(_this.role.telecom, { 'system': 'pager' });
		_this.pager = pager ? pager.value : null;
	}

	function codify(text) {
		return text.toLowerCase().replace(/\s/g, '');
	}

	var otherOption = {
		code: 'other',
		htmlDisplay: '<span class="italic">Other</span>',
		display: 'Other',
		order: 1
	};

	function setOptions(t) {
		if (!t) {
			_this.roleType = _this.role.type;
		}

		_this.responsibilities = _.transform(_.filter(config.mtRoleResponsibilities, function (r) {
			return r.roleType === _this.roleType.code;
		}), function (accum, r) {
			r.htmlDisplay = r.display;
			r.order = 0;
			accum.push(r);
		});

		_this.responsibilities.push(otherOption);
		_this.responsibility = _this.role.responsibility;

		_this.levels = _.transform(_.filter(config.mtRoleLevels, function (r) {
			return r.roleType === _this.roleType.code;
		}), function (accum, r) {
			r.htmlDisplay = r.display;
			r.order = 0;
			accum.push(r);
		});
		_this.levels.push(otherOption);
		_this.level = _this.role.level;

		_this.roleTypes = _.transform(config.mtRoleTypes, function (accum, r) {
			r.htmlDisplay = r.display;
			r.order = 0;
			accum.push(r);
		});
		_this.roleTypes.push(otherOption);
	}
	setOptions();
	populateTelecoms();

	function validateNoDuplicate() {
		if (!config.validateNoDuplicateRoles) {
			return true;
		}
		var dupe = _.find(modalArgs.roles, function (r) {
			return r.type.code === _this.role.type.code && r.level.code === _this.role.level.code && r.responsibility.code === _this.role.responsibility.code && ( //no other roles with same level and responsibility
			_this.role.id ? r.id !== _this.role.id : true); //except the role we're editing of course
		});
		return !dupe;
	}

	_this.setResponsibility = function (r) {
		if (!r) {
			_this.role.responsibility = null;
		} else if (r.code !== 'other') {
			_this.role.responsibility = r;
			_this.otherResponsibility = null;
		}
	};

	_this.setLevel = function (l) {
		if (!l) {
			_this.role.level = null;
		} else if (l.code !== 'other') {
			_this.role.level = l;
			_this.otherLevel = null;
		}
	};

	_this.addWard = function (w) {
		if (!_.some(_this.role.associatedLocation, function (loc) {
			return loc.id === w.id;
		})) {
			_this.role.associatedLocation = _this.role.associatedLocation || [];
			_this.role.associatedLocation.push(w);
			_this.ward = null;
		}
	};

	_this.wardNotSelected = function (w) {
		return !_.some(_this.role.associatedLocation, function (loc) {
			return loc.id === w.id;
		});
	};

	_this.removeWard = function (w) {
		_.remove(_this.role.associatedLocation, w);
	};

	_this.displayWardShort = function (w) {
		return config.wardDisplayField === 'name' ? w.name : w.identifier[0].value;
	};

	_this.displayWardFull = function (w) {
		return w.identifier[0].value + ' - ' + w.name;
	};
	_this.setType = function (t) {
		if (t) {
			setOptions(t);
			_this.role.type = t;
			if (_this.levels.length > 2) {
				_this.role.level = _this.level = null;
			} else {
				_this.role.level = _this.level = _this.levels[0];
			}
			if (_this.responsibilities.length > 2) {
				_this.role.responsibility = _this.responsibility = null;
			} else {
				_this.role.responsibility = _this.responsibility = _this.responsibilities[0];
			}
		} else {
			_this.role.type = _this.roleType = null;
			_this.role.level = _this.level = null;
			_this.role.responsibility = _this.responsibility = null;
			_this.responsibilities = [];
			_this.levels = [];
		}

		_this.otherResponsibility = null;
		_this.otherLevel = null;
	};

	_this.setOther = function (item, o) {
		_this.role[item] = {
			code: codify(o),
			display: o
		};
	};

	_this.setResponsibilityCode = function (c) {
		_this.role.responsibility = _this.role.responsibility || {};
		_this.role.responsibility.code = c;
	};

	_this.setResponsibilityDisplay = function (d) {
		_this.role.responsibility = _this.role.responsibility || {};
		_this.role.responsibility.display = d;
	};

	_this.save = function () {
		// TODO: Validation should be done by restricting the choices in the form, not on submit
		if (validateNoDuplicate()) {
			_this.duplicates = false;

			if (!_this.role.telecom) {
				_this.role.telecom = [];
			}

			if (_this.phone) {
				var phone = _.find(_this.role.telecom, { 'system': 'phone' });
				if (phone) {
					phone.value = _this.phone;
				} else {
					_this.role.telecom.push({
						system: 'phone',
						value: _this.phone
					});
				}
			} else {
				_.remove(_this.role.telecom, function (t) {
					return t.system === 'phone';
				});
			}

			if (_this.pager) {
				var pager = _.find(_this.role.telecom, { 'system': 'pager' });
				if (pager) {
					pager.value = _this.pager;
				} else {
					_this.role.telecom.push({
						system: 'pager',
						value: _this.pager
					});
				}
			} else {
				_.remove(_this.role.telecom, function (t) {
					return t.system === 'pager';
				});
			}

			var startTime = moment.tz(_this.startTime, moment.tz.guess());
			var clockTime = moment.duration({ hours: startTime.hours(), minutes: startTime.minutes() });
			if (!_this.role.id) {
				RoleService.createRole(_this.role.type, _this.role.level, _this.role.responsibility, _this.role.organization, clockTime, _this.role.telecom, _this.role.associatedLocation, null, _this.role.associatedBeds, _this.role.core, _this.role.active).then(function () {
					$modalInstance.close();
				});
			} else {
				_this.role.start = clockTime;
				RoleService.updateRole(_this.role).then(function () {
					// We need to replace the role in the context, since we copied it.
					_.remove(AppService.ctx.roles, function (r) {
						return r.id === _this.role.id;
					});
					AppService.ctx.roles.push(_this.role);
					$modalInstance.close();
				});
			}
		} else {
			_this.duplicates = true;
		}
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.byRoleType = function (x) {
		if (_this.roleType) {
			return x.roleType === _this.role.type.code || x.code === 'other';
		}
		return false;
	};
}]);