'use strict';

angular.module('medtasker').directive('mtTimeMarker', [function () {

	return {
		restrict: 'E',
		scope: {
			timeCell: '=',
			viewPeriod: '='
		},
		transclude: true,
		template: '<div class="time-scale" ng-transclude></div>"',
		replace: true,
		link: function link(scope, element) {
			var totalSpan = scope.viewPeriod.end.unix() - scope.viewPeriod.start.unix();
			var left = (scope.timeCell.time.unix() - scope.viewPeriod.start.unix()) * 100 / totalSpan;
			element.css({ 'float': 'left', 'position': 'absolute', 'left': left + '%', 'margin-left': '-15px' });
		}
	};
}]);

angular.module('medtasker').directive('mtTimeScale', ['moment', function (moment) {

	return {
		restrict: 'E',
		scope: {
			days: '=?', //number of days to generate scale for
			hours: '=?',
			hoursPerTick: '=', //number of hours between ticks on scale
			startTime: '=', //start time in 'HH:mm' format
			markers: '=' },
		templateUrl: 'app/common/time-scale.html',
		link: function link(scope) {
			scope.hours = scope.hours || 0;
			scope.days = scope.days || 0;
			scope.hoursPerTick = scope.hoursPerTick || 2 * scope.days || 2;
			scope.startTime = scope.startTime || '00:00';

			var totalHours = scope.hours + scope.days * 24 || 24;
			scope.times = [];
			scope.viewPeriod = {};

			scope.viewPeriod.start = moment(scope.startTime, 'HH:mm');
			for (var i = 0; i <= totalHours; i++) {
				scope.times.push({ time: moment(scope.viewPeriod.start).add(i, 'h'), showTime: i % scope.hoursPerTick === 0 });
			}
			scope.viewPeriod.end = scope.times[scope.times.length - 1].time;
		}
	};
}]);