'use strict';

angular.module('medtasker').controller('RoleGroupNewCtrl', ['RoleService', '$log', '$timeout', '$modalInstance', '$scope', function (RoleService, $log, $timeout, $modalInstance, $scope) {
	// eslint-disable-line

	var _this = this;
	_this.roleGroupName = null;

	_this.save = function () {
		_this.error = null;
		_this.triedSave = true;
		RoleService.createRoleGroup(_this.roleGroupName).then(function (res) {
			$modalInstance.close(res);
		}, function (err) {
			_this.error = err;
		});
	};

	$timeout(function () {
		_this.focussed = true;
	}, 300);

	_this.cancel = function () {
		$modalInstance.dismiss();
	};
}]);