'use strict';

angular.module('medtasker').directive('scheduleSelect', ['config', '_', '$log', function (config, _, $log) {
	$log.info('inside schdule select');
	return {
		scope: {
			onChanged: '=',
			schedule: '=',
			defaultDay: '@'
		},
		templateUrl: 'app/shift/schedule-directive-template.html', //eslint-disable-line
		link: function link(scope) {
			scope.sched = scope.defaultDay || 'mon';

			scope.changeSchedule = function () {
				scope.schedule = _.find(config.shiftSchedules, { 'code': scope.sched });
				scope.onChanged(scope.schedule);
			};
			scope.changeSchedule();
		}
	};
}]);