'use strict';

//
// Convert js libraries into Angular modules
//

angular.module('lodash', []).factory('_', ['$window', function ($window) {
	return $window._;
}]);

angular.module('uuid', []).factory('uuid', ['$window', function ($window) {
	return $window.uuid;
}]);

angular.module('download', []).factory('download', ['$window', function ($window) {
	return $window.download;
}]);