'use strict';

angular.module('medtasker').factory('RoleService', ['AppService', 'ResourceService', 'config', '$http', '$q', '_', '$log', function (AppService, ResourceService, config, $http, $q, _, $log) {

	var _ctx = void 0;

	function _updateRoles(team) {
		if (!team) {
			return [];
		}
		var roles = _.filter(_ctx.roles, function (role) {
			return role.organization && role.organization.id === team.id;
		});
		return _.sortBy(roles, 'level');
	}

	return {

		// Return the context object
		init: function init() {
			$log.debug('init RoleService');
			var deferred = $q.defer();
			AppService.ready.promise.then(function () {
				_ctx = AppService.ctx;
				deferred.resolve(_ctx);
			});
			return deferred.promise;
		},

		selectTeam: function selectTeam(team) {
			_ctx.selectedTeam = team;
			_ctx.selectedRole = null;

			// Set the next and previous teams from an alphabetised list of organizations
			//   (based on team identifier)
			var teams = _.filter(_ctx.organizations, function (org) {
				return org.type === 'team' || org.type === 'subteam';
			});
			var alphaSortedTeams = _.sortBy(teams, ['partOf.location[0].identifier[0].value', 'name']);
			var selectedTeamIndex = _.findIndex(alphaSortedTeams, team);
			if (selectedTeamIndex !== -1) {
				_ctx.nextTeam = alphaSortedTeams[selectedTeamIndex + 1];
				_ctx.prevTeam = alphaSortedTeams[selectedTeamIndex - 1];
			}

			_ctx.teamRoles = _updateRoles(team);
		},

		selectRole: function selectRole(role) {
			_ctx.selectedRole = role;
		},

		selectRoleGroup: function selectRoleGroup(roleGroup) {
			_ctx.selectedRoleGroup = roleGroup;
		},

		createRoleGroup: function createRoleGroup(name) {
			if (_.find(_ctx.roleGroups, function (g) {
				return g.name === name;
			})) {
				return AppService.reject('Name must be unique');
			}
			var grp = ResourceService.newRoleGroup(name);
			return AppService.create(grp);
		},

		deleteRoleGroup: function deleteRoleGroup(roleGroup) {
			return ResourceService.delete(_ctx, roleGroup);
		},

		createRole: function createRole(type, level, responsibility, team, start, telecom, associatedLocation, associatedOrganization, associatedBeds, core, active) {
			var role = ResourceService.newRole(type, level, responsibility, team, start, telecom, associatedLocation, associatedOrganization, associatedBeds, core, active);
			return AppService.create(role).then(function () {
				_ctx.teamRoles = _updateRoles(_ctx.selectedTeam);
			});
		},

		updateRole: function updateRole(role) {
			return AppService.update(role).then(function () {
				_ctx.teamRoles = _updateRoles(_ctx.selectedTeam);
			});
		},

		deleteRole: function deleteRole(role) {
			return ResourceService.delete(_ctx, role);
		},

		removeRoleFromGroup: function removeRoleFromGroup(roleGroup, role) {
			_.remove(roleGroup.role, function (r) {
				return r.id === role.id;
			});
			return AppService.update(roleGroup);
		},

		promoteRoleInGroup: function promoteRoleInGroup(roleGroup, role) {
			var tmp = roleGroup.role[0];
			var i = roleGroup.role.indexOf(role);
			roleGroup.role[0] = role;
			roleGroup.role[i] = tmp;
			return AppService.update(roleGroup);
		},

		addRoleToGroup: function addRoleToGroup(roleGroup, role) {
			roleGroup.role = roleGroup.role || [];
			roleGroup.role.push(role);
			return AppService.update(roleGroup);
		},

		// Filter
		// Excludes health services and hospitals
		teamsOnly: function teamsOnly(org) {
			return org.type === 'team' || org.type === 'subteam' || org.type === 'ward';
		}
	};
}]);