
'use strict';

angular.module('medtasker').controller('ShiftDialogCtrl', ['AppService', 'ShiftService', 'RosterService', 'ResourceService', 'modalArgs', '$modalInstance', 'moment', 'LogService', 'DialogService', '$rootScope', 'config', '_', function (AppService, ShiftService, RosterService, ResourceService, modalArgs, $modalInstance, moment, LogService, DialogService, $rootScope, config, _) {

	var _this = this;
	var minsInDay = 60 * 24;
	_this.mode = modalArgs.mode;
	_this.shift = modalArgs.shift; // only for edit
	_this.role = modalArgs.role;
	_this.roles = modalArgs.roles;
	_this.shifts = modalArgs.shifts;
	_this.schedule = modalArgs.schedule;
	_this.applyDate = moment(modalArgs.applyDate);
	_this.shiftBaseStart = _this.role.start;
	_this.readyToSave = false;
	_this.schedules = config.shiftSchedules;
	_this.selCodes = {};
	_this.allCodes = false;
	_this.selRoles = {};
	_this.allRoles = false;

	_this.overlap = config.maxShiftOverlapMinutes;

	var base = moment('2015-07-01').startOf('day'); //arbitrary date - but avoid any leap year issues from using current day
	_this.startTime = moment(base).startOf('day').add(modalArgs.shift.start.hours(), 'hours').add(modalArgs.shift.start.minutes(), 'minutes');
	_this.duration = moment('00:00', 'HH:mm').add(modalArgs.duration).toDate();
	_this.endTime = moment(_this.startTime).add(modalArgs.duration);
	//save original
	_this.lowerBound = moment(_this.startTime).subtract(_this.overlap, 'minutes');
	_this.upperBound = moment(_this.endTime).add(_this.overlap, 'minutes');
	_this.times = _this.startTime.format('HH:mm') + '-' + _this.endTime.format('HH:mm');
	_this.invalid = false;

	// _this.duration  = modalArgs.duration.asHours() * 100;
	_this.title = _this.mode === 'edit' ? 'Edit Shift' : 'Add Shift';
	_this.nextDay = false;

	function setTimes() {
		if (_this.startTime.format() === _this.endTime.format()) {
			_this.sliderInvalid = true;
			_this.sliderErrorMessage = 'Duration cannot be zero';
			return;
		}
		_this.sliderErrorMessage = '';
		_this.sliderInvalid = false;
		_this.times = _this.startTime.format('HH:mm') + '-' + _this.endTime.format('HH:mm');
	}

	function wrap(b, t, preferStart) {
		var diff = moment.duration(t - b).asDays();
		if (diff > 1) {
			return moment(t).subtract(1, 'days');
		} else if (diff < 0) {
			return moment(t).add(1, 'days');
		} else if (diff === 0) {
			//time to use is ambiguous (could be b, or b + 1 day). We use the start of period if we 'preferStart'
			return preferStart ? t : t.add(1, 'days');
		}
		return t;
	}

	function init() {
		if (_this.mode === 'edit') {
			_this.raPromise = AppService.roleAssignmentsForShift(_this.shift.id).then(function (ras) {
				_this.roleAssignments = ras;
				_this.futureRoleAssignments = _.filter(ras, function (ra) {
					return +ra.period.start >= +moment(_this.applyDate);
				});
				_this.readyToSave = true;
			});
		} else {
			_this.readyToSave = true;
		}
		//configure the slider
		var ft = void 0,
		    tt = void 0,
		    from = void 0,
		    to = void 0; //eslint-disable-line one-var

		// in edit mode, the boundaries of the start and end times are the end of the last previous defined shift
		// and the start of the next defined shift (plus/minus the allowed overlap), or if there is no previous or next shift,
		// the boundaries of the role (plus/minus overlap).
		if (_this.mode === 'edit') {
			var times = [];
			// rs = role start on our base day. We will wrap times to this 24 hour period in order to compare them
			var rs = moment(base).add(_this.role.start);
			// times will be an array of periods (start & end times) for the other shifts defined on the role - these define the boundaries
			// within which I can edit the current shift
			_.forEach(_this.shifts, function (s) {
				if (+s.start !== +_this.shift.start) {
					times.push({ start: wrap(rs, moment(base).add(s.start), true), end: wrap(rs, moment(base).add(s.start).add(s.duration), false) });
				}
			});

			//find the last shift that ends before the end of the start-of-shift overlap period
			var sos = wrap(rs, moment(base).add(_this.shift.start).add(_this.overlap, 'minutes'), true);
			times = _.sortBy(times, function (t) {
				return -+t.end;
			});
			var before = _.find(times, function (t) {
				return t.end <= sos;
			});
			//ft is the lower bound on the shift time.
			if (before) {
				ft = moment(before.end).subtract(_this.overlap, 'minutes');
			} else {
				ft = moment(base).add(_this.role.start).subtract(_this.overlap, 'minutes');
			}

			//find first shift that starts after the beginning of the end-of-shift overlap period
			var eos = wrap(rs, moment(base).add(_this.shift.start).add(_this.shift.duration).subtract(_this.overlap, 'minutes'), false);
			times = _.sortBy(times, function (t) {
				return +t.start;
			});
			var after = _.find(times, function (t) {
				return t.start >= eos;
			});
			//tt is the upper bound on the shift time
			if (after) {
				tt = moment(after.start).add(_this.overlap, 'minutes');
			} else {
				tt = moment(base).add(_this.role.start).add(1, 'days').add(_this.overlap, 'minutes');
			}

			//calculate the minute values of the start and end boundaries
			from = ft.diff(base, 'minutes');
			to = tt.diff(base, 'minutes');

			//calculate the minute values of the shift start and end times
			_this.low = moment(base).add(_this.shift.start).diff(base, 'minutes');
			_this.high = moment(base).add(_this.shift.start).add(_this.shift.duration).diff(base, 'minutes');
			if (_this.low < from) {
				_this.low += minsInDay;
				_this.high += minsInDay;
			}
		} else {
			//create mode
			//these are the moment values of the boundaries of the shift period
			ft = moment(base).add(_this.shift.start).subtract(_this.overlap, 'minutes');
			tt = moment(base).add(_this.shift.start).add(_this.shift.duration).add(_this.overlap, 'minutes');

			//calculate the minute values of the start and end boundaries
			from = ft.diff(base, 'minutes');
			to = tt.diff(base, 'minutes');

			//calculate the minute values of the shift start and end times
			_this.low = from + _this.overlap;
			_this.high = to - _this.overlap;
		}

		_this.sliderModel = _this.low + ';' + _this.high;

		//generate the scale
		var scale = [];
		var t = moment(ft);
		var duration = moment.duration(tt.diff(ft));
		var hours = duration.asHours();
		var nrw = false;
		if (hours > 17) {
			nrw = true;
		}
		do {
			if (t.minutes() === 0) {
				scale.push(nrw ? '&nbsp;' + t.format('HH') : t.format('HH:mm'));
			} else {
				scale.push('');
			}
			t.add(15, 'minutes');
		} while (+t <= +tt);

		_this.calculate = function (value) {
			if (value || value === 0) {
				return moment(base).add(value, 'minutes').format('HH:mm');
			}
			return void 0;
		};

		_this.onsliderstatechange = function (value) {
			var vals = value.split(';');
			_this.startTime = moment(base).add(vals[0], 'minutes');
			_this.endTime = moment(base).add(vals[1], 'minutes');
			//_this.validateTimes(value);
		};

		_this.sliderOptions = {
			from: from,
			to: to,
			smooth: false,
			step: 15,
			scale: scale,
			limits: false,
			calculate: _this.calculate,
			onstatechange: _this.onsliderstatechange
		};
	}
	init();

	_this.save = function () {
		if (_this.mode === 'edit') {
			var start = AppService.wrapDuration(moment.duration(_this.startTime - base));
			var duration = moment.duration(_this.endTime - _this.startTime);
			var schedules = _.filter(_.keys(_this.selCodes), function (k) {
				return _this.selCodes[k];
			});
			schedules.unshift(_this.schedule.code);
			ShiftService.changeShift(_this.shift.id, start, duration, _this.applyDate, schedules).then(function () {
				$modalInstance.close();
			}, function (err) {
				DialogService.errorMessage('A problem occurred editing the shifts', err);
				LogService.error('Error editing shift: ' + err.data);
			});
		} else {
			var _schedules = _.filter(_.keys(_this.selCodes), function (k) {
				return _this.selCodes[k];
			});
			_schedules.unshift(_this.schedule.code);
			var roles = _.filter(_.keys(_this.selRoles), function (k) {
				return _this.selRoles[k];
			});
			roles.unshift(_this.role.id);

			if (_this.multishift) {
				var times = [];
				_.forEach(_this.newShifts, function (s) {
					times.push({
						start: AppService.wrapDuration(moment.duration(s.start - moment(s.start).startOf('day'))),
						duration: moment.duration(s.end - s.start)
					});
				});
				$rootScope.$broadcast('appLoadingPush', 'shift-save');
				ShiftService.createShifts(roles, _schedules, times, _this.applyDate, false).then(function () {
					$rootScope.$broadcast('appLoadingPop', 'shift-save');
					$modalInstance.close();
				}, function (err) {
					$rootScope.$broadcast('appLoadingPop', 'shift-save');
					DialogService.errorMessage('A problem occurred creating shifts', err);
					LogService.error('Error creating shift(s): ' + err.data);
				});
			} else {
				var time = {
					start: AppService.wrapDuration(moment.duration(_this.startTime - moment(_this.startTime).startOf('day'))),
					duration: moment.duration(_this.endTime - _this.startTime)
				};
				$rootScope.$broadcast('appLoadingPush', 'shift-save');
				ShiftService.createShifts(roles, _schedules, [time], _this.applyDate, false).then(function () {
					$rootScope.$broadcast('appLoadingPop', 'shift-save');
					$modalInstance.close();
				}, function (err) {
					$rootScope.$broadcast('appLoadingPop', 'shift-save');
					DialogService.errorMessage('A problem occurred creating shifts', err);
					LogService.error('Error creating shift(s): ' + err.data);
				});
			}
		}
	};

	_this.delete = function () {
		ShiftService.deleteShift(_this.shift.id, _this.applyDate).then(function () {
			$modalInstance.close();
		}, function (err) {
			DialogService.errorMessage('A problem occurred deleting shifts', err);
			LogService.error('Error deleting shift: ' + err.data);
		});
	};

	function parseTime(t) {
		var hours = 0;
		var minutes = 0;
		var ts = t.split(':');
		if (!ts) {
			return { error: 'Invalid time' };
		}
		if (ts.length === 1) {
			if (ts[0].length < 3) {
				hours = parseInt(ts[0]);
				minutes = 0;
			} else if (ts[0].length === 4) {
				hours = parseInt(ts[0].substring(0, 2));
				minutes = parseInt(ts[0].substring(2, 4));
			} else {
				return { error: 'Invalid time' };
			}
		} else if (ts.length === 2) {
			hours = parseInt(ts[0]);
			minutes = parseInt(ts[1]);
		} else {
			return { error: 'Invalid time' };
		}
		if (isNaN(minutes) || isNaN(hours)) {
			return { error: 'Invalid time' };
		}
		if (minutes % 15 !== 0) {
			return { error: 'Invalid time: minutes must be in intervals of 15' };
		}
		if (hours > 23) {
			return { error: 'Invalid time: hours out of range' };
		}
		if (minutes > 60) {
			return { error: 'Invalid time: minutes out of range' };
		}
		return wrap(base, moment(base).add(hours, 'hours').add(minutes, 'minutes'));
	}

	_this.scheduleChanged = function (sch) {
		_this.selCodes[sch.code] = !_this.selCodes[sch.code];
		if (_this.allCodes && !_this.selCodes[sch.code]) {
			_this.allCodes = false;
		}
	};

	_this.rolesChanged = function (r) {
		_this.selRoles[r.id] = !_this.selRoles[r.id];
		if (_this.allRoles && !_this.selRoles[r.id]) {
			_this.allRoles = false;
		}
	};

	_this.toggleAllCodes = function () {
		_this.allCodes = !_this.allCodes;
		_.forEach(_this.schedules, function (sch) {
			if (_this.schedule.code !== sch.code) {
				_this.selCodes[sch.code] = _this.allCodes;
			}
		});
	};

	_this.toggleAllRoles = function () {
		_this.allRoles = !_this.allRoles;
		_.forEach(_this.roles, function (r) {
			if (_this.role.id !== r.id) {
				_this.selRoles[r.id] = _this.allRoles;
			}
		});
	};

	//parse a string in format HH:mm-HH:mm or HHmm-HHmm into two moments (start and end)
	function parseTimes(t) {
		t = t.trim();
		var times = t.split('-');
		if (times.length !== 2) {
			return { error: 'Please provide a start and end time' };
		}
		var start = parseTime(times[0]);
		if (start.error) {
			return start;
		}
		var end = parseTime(times[1]);
		if (end.error) {
			return end;
		}
		if (start < _this.lowerBound) {
			start.add(1, 'days');
		}
		if (start.format() === end.format()) {
			return { error: 'Duration must be > zero' };
		}
		if (end <= start) {
			end.add(1, 'days');
		}
		return { start: start, end: end };
	}

	_this.otherDaySelected = function () {
		return _.some(_this.schedules, function (s) {
			return _this.selCodes[s.code] && _this.schedule.code !== s.code;
		});
	};

	_this.validateTimes = function () {
		_this.invalid = false;
		_this.errorMessage = '';
		if (_this.times.length === 0) {
			return;
		}
		var periods = _this.times.split(',');
		var shifts = _.transform(periods, function (sh, period) {
			var shift = parseTimes(period);
			if (shift.error) {
				_this.invalid = true;
				_this.errorMessage = shift.error;
			} else {
				sh.push(shift);
			}
		});
		_.forEach(shifts, function (s) {
			if (s.start - _this.lowerBound < 0 || s.end - _this.upperBound > 0) {
				_this.invalid = true;
				_this.errorMessage = 'Invalid time: outside of shift period';
			}
		});
		if (shifts.length > 1) {
			_this.multishift = true;
			shifts = _.sortBy(shifts, 'end');
			//validate that none of the shifts overlap
			for (var i = 0; i < shifts.length - 1; i++) {
				var s = shifts[i];
				for (var j = i + 1; j < shifts.length; j++) {
					var s2 = shifts[j];
					if (moment.duration(s.end - s2.start).asMinutes() > _this.overlap) {
						_this.invalid = true;
						_this.errorMessage = 'Shifts overlap by more than ' + _this.overlap + ' minutes';
						return;
					}
				}
			}
		} else {
			_this.multishift = false;
			if (shifts[0]) {
				var m1 = shifts[0].start.hours() * 60 + shifts[0].start.minutes();
				var m2 = moment.duration(shifts[0].end - shifts[0].start).asMinutes() + m1;
				if (m1 < _this.low) {
					m1 += minsInDay;
					m2 += minsInDay;
				}
				_this.sliderModel = m1 + ';' + m2;
			}
		}
		_this.newShifts = shifts;
	};

	_this.sliderChange = function () {
		setTimes();
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.dateDialog = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		_this.showDateDialog = true;
	};

	_this.formatDate = function (d) {
		return moment(d).format('ddd D MMM YYYY');
	};
}]);