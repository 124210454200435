'use strict';

angular.module('medtasker').controller('HomeCtrl', ['AuthService', 'DialogService', 'oauth', 'LogService', '$window', '$stateParams', function (AuthService, DialogService, oauth, LogService, $window, $stateParams) {

	var _this = this;

	_this.wardLoggedIn = function () {

		return AuthService.isAuthenticated('ward');
	};

	_this.getErrorString = function () {
		var allowedErrorStrings = ['Your session has ended. Please log in to continue using Medtasker.', 'You do not have permission to use Medtasker. For help, contact your IT service desk.', 'Please log in to view this page.'];
		if (angular.isUndefined($stateParams.msg)) {
			return '';
		}
		//LogService.debug('getErrorString ' + $stateParams.msg );
		// TODO implement better sanitisation of  the query parameter than this brute force error 
		return allowedErrorStrings.includes($stateParams.msg) ? $stateParams.msg : 'Something went wrong during login. Please try again or contact your IT service desk for assistance.';
	};

	_this.openLoginDialog = function () {
		LogService.debug('open login dialog ' + $stateParams.guest);
		oauth.getOauthLoginURL().then(function (res) {
			LogService.debug('openLoginDialog ' + angular.toJson(res));
			if (res !== '' && angular.isUndefined($stateParams.guest)) {
				$window.location.href = res;
			} else {
				DialogService.openLoginDialog();
			}
		}, function (err) {
			_this.error = 'Error getting oauth config: ' + angular.toJson(err);
			LogService.error(err, 1, 'HttpGetError');
		});
	};
}]);