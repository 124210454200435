'use strict';

angular.module('medtasker').factory('DialogService', ['AppService', 'AudioService', '$q', '$log', '$state', 'config', '$modal', function (AppService, AudioService, $q, $log, $state, config, $modal) {
	var modalOpen = false;
	var errorOpen = false;
	return {
		openLoginDialog: function openLoginDialog(message) {
			var deferred = $q.defer();
			if (modalOpen) {
				//prevent login modal being opened twice
				deferred.reject('Login dialog was already open - attempt to load locations may have failed. Check server config.');
			} else {
				modalOpen = true;

				var options = {
					templateUrl: 'components/login/login-dialog.html',
					controller: 'LoginCtrl',
					controllerAs: 'login',
					resolve: {
						initModalMsg: function initModalMsg() {
							return { value: message };
						},
						locations: function locations() {
							return AppService.loadLoginLocations(); //load locations only as we aren't logged in yet. We need the locations to get the wards list
						}
					},
					backdrop: true
				};
				var modal = $modal.open(options);
				modal.result.then(function () {
					modalOpen = false;
					modal.close();
					$state.go(config.defaultState || 'app.compose');
					deferred.resolve();
				}, function () {
					modalOpen = false;
					deferred.reject('cancelled login');
					//$state.go('home');
				});
			}
			return deferred.promise;
		},
		openCampusSelectorDialog: function openCampusSelectorDialog(message) {
			var deferred = $q.defer();
			if (modalOpen) {
				//prevent login modal being opened twice
				deferred.reject('Login dialog was already open - attempt to load locations may have failed. Check server config.');
			} else {
				modalOpen = true;

				var options = {
					templateUrl: 'components/login/campus-selector-dialog.html',
					controller: 'CampusSelectorCtrl',
					controllerAs: 'login',
					resolve: {
						initModalMsg: function initModalMsg() {
							return { value: message };
						},
						locations: function locations() {
							return AppService.loadLoginLocations(); //load locations only as we aren't logged in yet. We need the locations to get the wards list
						}
					},
					backdrop: 'static',
					keyboard: false
				};
				var modal = $modal.open(options);
				modal.result.then(function () {
					modalOpen = false;
					modal.close();
					$state.go(config.defaultState || 'app.compose');
					deferred.resolve();
				}, function () {
					modalOpen = false;
					deferred.reject('cancelled login');
					//$state.go('home');
				});
			}
			return deferred.promise;
		},

		//options= {
		//	duration: 		show-for-ms
		//	hideFooter: 	bool
		//	modal-class:	class-to-apply-to-modal-div
		//	size:			sm,md,lg
		//}
		openMessageDialog: function openMessageDialog(title, htmlMessage, msgType, options, more) {
			var msg = {
				templateUrl: 'app/common/message.modal.html',
				controller: 'MessageModalCtrl as ctrl',
				backdrop: true,
				resolve: {
					modalArgs: function modalArgs() {
						return {
							message: htmlMessage,
							title: title,
							more: more,
							type: msgType,
							options: options
						};
					}
				}
			};
			return $modal.open(msg);
		},

		errorMessage: function errorMessage(msg, err) {
			if (errorOpen) {
				return;
			}
			errorOpen = true;
			AudioService.playErrorSound();
			if (err && err.status === 409) {
				msg = msg + '<br /> A version conflict occurred. It may be another user has just updated the same resource. Please try again. If this recurs, try again after refreshing the page.';
			}
			var more = '';
			if (err && err.data) {
				more = err.data;
			}
			this.openMessageDialog('Error', msg, 'error', null, more).result.then(function () {
				errorOpen = false;
			}, function () {
				errorOpen = false;
			});
		}
	};
}]);