'use strict';

/* global angular */
angular.module('medtasker.service').factory('InboxService', ['AppService', 'TaskService', 'AudioService', '$q', 'LogService', '_', 'config', function (AppService, TaskService, AudioService, $q, log, _, config) {
	'use strict';

	var _ctx = {}; // Set during init()

	var o = {
		ctx: _ctx,
		viewConfig: {}, //container to persist information about sorting and filtering of different inbox views
		selectedTask: null,

		nextStatus: function nextStatus(task) {
			switch (task.status) {
				case 'sent':
					return 'accepted';
				case 'accepted':
					return o.startRequired(task) ? 'started' : 'completed';
				case 'started':
					return 'completed';
				case 'completed':
					return 'accepted';
				default:
					return null;
			}
		},

		startRequired: function startRequired(task) {
			if (!task.serviceRequested) {
				return false;
			}
			var scats = _.flatten(_.map(config.categories, function (c) {
				return c.subCategories;
			}));
			var sc = _.find(scats, function (c) {
				return c.code === task.serviceRequested[0].coding[0].code;
			});
			return sc && sc.requireStart;
		},

		taskType: function taskType(task) {
			if (!task.serviceRequested) {
				return null;
			}
			var scats = _.flatten(_.map(config.categories, function (c) {
				return c.subCategories;
			}));
			var sc = _.find(scats, function (c) {
				return c.code === task.serviceRequested[0].coding[0].code;
			});
			return sc;
		},

		availableAnnotations: function availableAnnotations(task) {
			var tt = o.taskType(task);
			if (tt && tt.annotations) {
				return _.transform(tt.annotations, function (results, an) {
					if (_.some(an.statuses, function (s) {
						return s === task.status;
					})) {
						var action = _.find(config.taskActions, function (a) {
							return a.code === an.code;
						});
						if (action) {
							results.push(action);
						}
					}
				});
			}
			return [];
		},

		transitionTask: function transitionTask(task, roleId, withHandover, note) {
			var ann = null;
			if (withHandover) {
				ann = {
					code: 'handed_over',
					note: note
				};
			}
			var next = o.nextStatus(task);
			switch (next) {
				case 'accepted':
					return TaskService.accept(task, ann, roleId); // Pass roleId to accept method
				case 'started':
					return TaskService.start(task, ann);
				case 'completed':
					return TaskService.complete(task, ann);
				default:
					return AppService.resolve();
			}
		},

		cancelTask: function cancelTask(task, reason) {
			return TaskService.cancel(task, null, reason);
		},

		taskAlerts: function taskAlerts(task) {
			//save the alerts on the task for efficiency's sake
			task.alerts = _.filter(AppService.ctx.communications, function (c) {
				return c.payload[0].contentReference && c.payload[0].contentReference.id === task.id && c.medium[0].text === 'appAlert';
			});
			return task.alerts;
		},

		unviewedAlert: function unviewedAlert(task) {
			if (!task.alerts) {
				task.alerts = o.taskAlerts(task);
			}
			return _.some(task.alerts, function (alert) {
				return alert.status === 'in-progress' && alert.reason[0].coding[0].code === 'taskReply';
			});
		},

		setTaskAsViewed: function setTaskAsViewed(task) {
			if (!task.alerts) {
				task.alerts = o.taskAlerts(task);
			}
			_.forEach(task.alerts, function (alert) {
				if (alert.status === 'in-progress') {
					alert.status = 'completed';
					AppService.update(alert);
				}
			});
		},

		myCcStatus: function myCcStatus(task, statuses) {
			if (!angular.isArray(statuses)) {
				statuses = [statuses];
			}
			if (!task.cc || task.cc.length === 0 || !statuses || statuses.length === 0) {
				return false;
			}
			var mycc = AppService.user && _.filter(task.cc, function (cc) {
				return cc.practitioner.id === AppService.user.id || _.some(AppService.userRAs, function (ra) {
					return ra.role.id === cc.role.id;
				});
			});
			return _.some(mycc, function (cc) {
				return _.some(statuses, function (s) {
					return cc.status === s;
				});
			});
		},

		myCc: function myCc(task) {
			return task && AppService.user && _.some(task.cc, function (cc) {
				return cc.practitioner.id === AppService.user.id || _.some(AppService.userRAs, function (ra) {
					return ra.role.id === cc.role.id;
				});
			});
		},

		ccOnly: function ccOnly(task) {
			return !AppService.isTaskRecipient(task) && o.myCc(task);
		},

		updateCc: function updateCc(task, acknowledge) {
			if (acknowledge) {
				TaskService.acknowledgeCc(task);
			} else {
				TaskService.declineCc(task);
			}
		},

		acceptAndCompleteTask: function acceptAndCompleteTask(task, withHandover, note) {
			var ann = null;
			if (withHandover) {
				ann = {
					code: 'handed_over',
					note: note
				};
			}
			return TaskService.complete(task, ann);
		},

		annotateTask: function annotateTask(task, code, note) {
			var ann = {
				code: code,
				note: note
			};
			return TaskService.annotate(task, ann);
		},

		getNPRTLabel: function getNPRTLabel() {
			if (angular.isDefined(config.notPatientTaskLabel)) {
				return config.notPatientTaskLabel;
			}
			return 'Non Patient Related Task';
		},

		completeTaskForMe: function completeTaskForMe(task, roleId) {
			return TaskService.complete(task, null, roleId);
		}

	};

	return o;
}]);