'use strict';

angular.module('medtasker').factory('AudioService', [function () {
	//jshint ignore:start
	var errorSound = new Audio('assets/sounds/ui-error.mp3'); // eslint-disable-line
	var sendTaskSound = new Audio('assets/sounds/ui-task-accept.mp3'); // eslint-disable-line
	var completeTaskSound = new Audio('assets/sounds/ui-task-complete.mp3'); // eslint-disable-line
	var receivedTaskSound = new Audio('assets/sounds/ui-newtask.mp3'); // eslint-disable-line
	var escalationSound = new Audio('assets/sounds/ui-escalation.mp3'); // eslint-disable-line
	var receivedUrgentTaskSound = new Audio('assets/sounds/ui-urgenttask.mp3'); // eslint-disable-line
	var receivedReplyTaskSound = new Audio('assets/sounds/ui-newtask.mp3'); // eslint-disable-line
	return {
		playErrorSound: function playErrorSound() {
			errorSound.play();
		},
		playSendSound: function playSendSound() {
			sendTaskSound.play();
		},
		playCompleteSound: function playCompleteSound() {
			completeTaskSound.play();
		},
		playReceivedTaskSound: function playReceivedTaskSound() {
			receivedTaskSound.play();
		},
		playEscalationSound: function playEscalationSound() {
			escalationSound.play();
		},
		playUrgentTaskSound: function playUrgentTaskSound() {
			receivedUrgentTaskSound.play();
		},
		playReplyReceivedSound: function playReplyReceivedSound() {
			receivedReplyTaskSound.play();
		}
	};
	//jshint ignore:end
}]);