
'use strict';

angular.module('medtasker').controller('UnassignedRolesCtrl', ['RosterService', 'AppService', 'unassignedRoles', 'moment', '_', 'config', '$rootScope', '$log', function (RosterService, AppService, unassignedRoles, moment, _, config, $rootScope, $log) {
	// eslint-disable-line

	var _this = this;

	_this.unassignedRoles = unassignedRoles;
	_this.campuses = AppService.campuses();
	_this.config = config;
	_this.filteredRoles = unassignedRoles;
	_this.roleTypes = config.mtRoleTypes;
	if (config.defaultCampusLoc) {
		_this.campus = _.find(_this.campuses, { 'id': config.defaultCampusLoc.id });
		if (_this.campus && _this.campus.identifier) {
			_this.filteredRoles = _.filter(_this.unassignedRoles, function (r) {
				return r.campus === _this.campus.identifier[0].value;
			});
		}
	}

	_this.refresh = function () {
		$rootScope.$broadcast('appLoadingPush', { source: 'UnassignedRoles' });
		RosterService.unassignedRoles().then(function (r) {
			_this.unassignedRoles = r;
			_this.filteredRoles = r;
			$rootScope.$broadcast('appLoadingPop', { source: 'UnassignedRoles' });
		});
	};

	_this.filterRoles = function (campusId, roleTypeCode) {
		_this.filteredRoles = _.filter(_this.unassignedRoles, function (r) {
			if (campusId && roleTypeCode) {
				return r.campus === campusId && r.roleType === roleTypeCode;
			} else if (campusId) {
				return r.campus === campusId;
			} else if (roleTypeCode) {
				return r.roleType === roleTypeCode;
			}
			return true;
		});
	};

	_this.showStart = function (t) {
		return moment(t).format('HH:mm');
	};

	// return a specific contact number, where type is either 'pager' or 'phone'
	_this.showTelecom = function (telecoms, type) {
		return _.find(telecoms, function (t) {
			return t.system === type;
		});
	};

	_this.formatCampus = function (c) {
		if (!c) {
			return '';
		}
		if (!c.id) {
			return '--' + c.name + '--';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};
}]);