'use strict';
/* global angular */

angular.module('medtasker.service').factory('LogService', ['$log', 'moment', 'config', '$window', '_', function ($log, moment, config, $window, _) {
	function _redactForPrivacy(obj) {
		var r = '<redacted>';
		if (_.get(obj, 'config.headers.Authorization')) {
			obj.config.headers.Authorization = r;
		}
		if (obj && obj.config && obj.config.data) {
			//we need to redact private information from tasks that might leak out to Bugsnag.
			var data = obj.config.data;

			if (data.resourceType) {
				if (data.resourceType === 'MtTask') {
					data.description = r;
					data.pagerMessage = r;
					if (data.response) {
						_.forEach(data.response, function (resp) {
							resp.message = r;
						});
					}
				} else if (data.resourceType === 'EpisodeOfCare') {
					data.note = r;
				}
			} else if (data.password) {
				data.password = r;
			}
		}
		return obj;
	}
	var _log = [];

	var o = {
		get: function get() {
			return _log;
		},

		warn: function warn(text, forceLog, highlight) {
			o.log(text, 'warn', forceLog, highlight);
		},

		info: function info(text, forceLog, highlight) {
			o.log(text, 'info', forceLog, highlight);
		},

		//Error severities:
		//0 - very low - user experience unaffected, or error has already been logged lower down in call stack
		//1 - low - errors caused by lack of connectivity for example, not indicating program error (Default)
		//2 - medium - errors indicating unexpected program conditions
		//3 - high - errors indicating serious issues such as data inconsistency
		error: function error(_error, severity, bugsnagErrorName) {
			if (angular.isUndefined(_error) || _error === null) {
				o.log('Unknown error - no error was provided to log service', 'error');
				return;
			}
			var text = void 0;
			if (angular.isString(_error)) {
				text = _error;
			} else if (_error.message) {
				text = _error.message;
			} else {
				text = angular.toJson(_redactForPrivacy(_error));
			}

			if (angular.isUndefined(severity)) {
				severity = 1;
			}
			o.log(text, 'error');
			if (config.logToBugsnag && severity >= config.bugsnagLogThreshold) {
				$window.bugsnagClient.notify(new Error(text), bugsnagErrorName);
			}
			if (config.breakOnException) {
				debugger; //eslint-disable-line
			}
		},
		//using forceLog allows you to push a debug message through regardless of whether 'debug' verb is enabled.
		//By turing off debug and forcing only relevant debug messages, you can more easily see relevant debug information
		debug: function debug(text, forceLog, highlight, verboseOnly) {
			o.log(text, 'debug', forceLog, highlight, verboseOnly);
		},

		log: function log(text, logType, forceLog, highlight, verboseOnly) {
			logType = logType || 'log';
			if (!forceLog) {
				if (config.logMode === 'off') {
					return;
				}
				if (config.allowedLogVerbs.indexOf(logType) === -1) {
					return;
				}
				if (verboseOnly && !config.verboseLog) {
					return;
				}
			}

			if (highlight) {
				text = '******** ' + text + ' ********';
			}

			var entry = {
				time: new Date(),
				event: text
			};

			if (config.logMode === 'save') {
				_log.push(entry);
			}
			var msg = moment().format('HH:mm:ss.SS') + ': ' + text;
			switch (logType) {
				case 'info':
					$log.info(msg);
					break;
				case 'debug':
					$log.debug(msg);
					break;
				case 'warn':
					$log.warn(msg);
					break;
				case 'error':
					$log.error(msg);
					break;
				case 'log':
					$log.log(msg);
					break;
				default:
					$log.log(msg);
			}
			//log to $window.console if on mobile - this outputs to XCode console
			if ($window.console && $window.cordova) {
				$window.console.log(msg);
			}
		}
	};

	return o;
}]);