'use strict';

angular.module('medtasker').directive('aggregateIcon', ['$log', function () {
	// eslint-disable-line
	return {
		scope: {
			iconSize: '@',
			iconColor: '@',
			inline: '@'
		},
		templateUrl: 'app/shift/aggregate-icon.html',
		replace: true,
		link: function link(scope, element) {

			if (scope.iconSize) {
				element.css({
					height: scope.iconSize,
					width: scope.iconSize
				});
			}

			if (scope.inline && scope.inline.toLowerCase() === 'true') {
				element.css({
					display: 'inline-block'
				});
			}
			if (scope.iconColor) {
				var svgpath = element.find('sgv > path');
				svgpath.css({
					fill: scope.iconColor
				});
			}
		}
	};
}]);