'use strict';

angular.module('medtasker').factory('MonitorService', ['AppService', 'ResourceService', '$log', '$timeout', '$rootScope', '$q', '$interval', 'config', '_', 'moment', function (AppService, ResourceService, $log, $timeout, $rootScope, $q, $interval, config, _, moment) {

	var _ctx = {};

	function _findDeterioratingPatients(fromTime, minUrgency) {
		var tasks = _.filter(_ctx.tasks, function (task) {
			return ((minUrgency === 'premet' ? task.priority.coding[0].code === 'premet' : false) || task.priority.coding[0].code === 'met' || task.priority.coding[0].code === 'codeblue') && task.dateSent.isAfter(fromTime) && task.status !== 'cancelled';
		});
		var tasksByPatientId = _.groupBy(tasks, function (t) {
			return t.patient.id;
		});
		var patients = _.transform(_.keys(tasksByPatientId), function (accum, patientId) {
			var patient = _.find(_ctx.patients, { 'id': patientId });
			var tasksByPid = tasksByPatientId[patientId];
			if (tasksByPid.length > 1 || _.some(tasksByPid, function (t) {
				return t.priority.coding[0].code === 'codeblue' || t.priority.coding[0].code === 'met';
			})) {
				patient.tasks = _.sortBy(tasksByPid, 'dateSent');
				patient.lastEncounter = patient.tasks[patient.tasks.length - 1].encounter;
				accum.push(patient);
			}
		});
		return _.sortBy(patients, function (p) {
			return AppService.displayName(p, 'surnameFirst');
		});
	}

	var o = {
		init: function init() {
			$log.debug('init MonitorService');
			var deferred = $q.defer();
			AppService.init().then(function () {
				AppService.loadAllPreMetOrHigherTasksSince(moment().subtract(48, 'hours'), 'premet').then(function () {
					_ctx = AppService.ctx;
					deferred.resolve(AppService.ctx);
				});
			});
			return deferred.promise;
		},

		findDeterioratingPatients: _findDeterioratingPatients

	};
	return o;
}]);