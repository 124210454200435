'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* global angular */

angular.module('medtasker.service').factory('ComposeService', ['AppService', 'ResourceService', 'config', 'LogService', 'TaskService', '$q', '$window', 'moment', '_', '$sanitize', function (AppService, ResourceService, config, log, TaskService, $q, $window, moment, _, $sanitize) {
	'use strict';

	log.debug('ComposeService loading');

	//generate the observations data for a task
	function createObservationsForTask(task, encounter) {
		var promises = [];
		var observationArray = [];
		var deferred = $q.defer();

		// Create all observations
		var floats = ['rr', 'spo2', 'hr', 'sbp', 'dbp', 'temp', 'bsl', 'uo']; //these are the properties with a float value
		_.forEach(_.keys(task.observation), function (key) {
			if (key === 'observationTime') {
				return;
			}
			var val = task.observation[key];
			if (val) {
				if (floats.indexOf(key) > -1) {
					val = parseFloat(task.observation[key]);
				} else if (val.code === null) {
					return;
				}
				var ot = '';
				if (task.observation.observationTime) {
					ot = task.observation.observationTime.code;
				}
				var obs = ResourceService.newObservation(key, val, ot, encounter.patient, encounter);
				var def = $q.defer();
				AppService.create(obs).then(function (data) {
					observationArray.push(data);
					def.resolve(data);
				});
				promises.push(def.promise);
			}
		});

		$q.all(promises).then(function () {
			deferred.resolve(observationArray);
		});
		return deferred.promise;
	}

	// Determine override team to send in messages
	function _team(task, encounter) {
		return task.overrideTeam ? AppService.campusDisplayCode(task.overrideTeam.partOf.location[0].id) + task.overrideTeam.name : AppService.campusDisplayCode(encounter.serviceProvider.partOf.location[0].id) + encounter.serviceProvider.name;
	}

	function _location(task, encounter) {
		var bedLoc = encounter.location[encounter.location.length - 1].location;
		var pasWard = AppService.displayLocation('ward', bedLoc.partOf);
		var pasBed = AppService.displayLocation('bed', bedLoc);
		return (task.overrideWard ? AppService.displayLocation('ward', task.overrideWard) : pasWard) + '-' + (task.overrideBed || pasBed);
	}

	function _pagerMessage(task, encounter) {
		// Determine sender contact details to send in messages
		function truncateToFirstSpace(t) {
			var p = t.indexOf(' ');
			if (p === -1) {
				return t;
			}
			return t.substring(0, p);
		}
		var contact = '';
		var pager = '';
		var phone = task.senderPhone || '';
		if (phone.length === 5) {
			phone = 'x' + phone;
		}
		if (task.senderPager) {
			pager = 'p' + task.senderPager;
		} else {
			pager = '';
		}
		contact = phone + (task.senderPager && task.senderPhone ? '/' : '') + pager;

		// Set pager message
		var msg = void 0;
		if (!encounter) {
			//non patient-related
			if (!task.category) {
				msg = task.urgency.display + '|' + task.from + (task.senderRole ? ' (' + task.senderRole + ')' : '') + '-' + contact + '|' + (task.description || '');
			} else {
				// for non patient tasks there won't be encounter but there would be category and sub category
				var catg = task.category ? task.category.display === 'Other' ? 'Other' : task.subCategory ? task.subCategory.code : '' : '';
				msg = task.urgency.display + '|' + task.from + (task.senderRole ? ' (' + task.senderRole + ')' : '') + '-' + contact + '|' + catg + '|' + (task.description || '');
			}
		} else {
			var team = _team(task, encounter);
			var location = _location(task, encounter);
			var _catg = task.category ? task.category.display === 'Other' ? 'Other' : task.subCategory ? task.subCategory.code : '' : '';
			msg = AppService.displayName(encounter.patient) + '|' + encounter.patient.birthDate.format('DD/MM/YYYY') + '|' + encounter.patient.identifier[0].value + '|' + team + '|' + location + '|' + task.urgency.display + '|' + truncateToFirstSpace(task.from) + (task.senderRole ? ' (' + task.senderRole + ')' : '') + '-' + contact + '|' + _catg + (task.description ? '|' + task.description : '');
		}
		return msg;
	}

	function _pushMessage(task, encounter) {
		var msg = void 0;
		if (!encounter || config.redactedPush) {
			//non patient-related, or hospital won't let us show locations
			msg = 'You\'ve got a new task! Urgency: ' + task.urgency.display + '.';
		} else {
			var location = _location(task, encounter);
			msg = 'You\'ve got a new task! Urgency: ' + task.urgency.display + '. Pt location: ' + location + '.';
		}
		return msg;
	}

	function matchFields(tokens, terms) {
		return _.every(terms, function (srch) {
			return _.some(tokens, function (t) {
				var match = t.slice(0, srch.length) === srch;
				return match;
			});
		});
	}

	var o = {

		pagerMessage: _pagerMessage,
		pushMessage: _pushMessage,

		filterEncounters: function filterEncounters(filter, encounters) {
			var _ctx = AppService.ctx;
			var filteredEncounters = null;
			var encountersSupplied = !!encounters;

			if (filter.searchText) {
				filter.searchText = filter.searchText.replace(/'/g, "\\'"); // eslint-disable-line
			}

			// Display only encounters that are 'in-progress', other resources in ctx may have returned
			//   encounters that are not 'in-progress'
			encounters = _.filter(encounters || _ctx.encounters, function (e) {
				return e.status === 'in-progress';
			});

			// if no search criteria, return no results
			if (!encountersSupplied && (!filter.searchText || filter.searchText.length === 0) && !filter.team && !filter.ward && !filter.bed) {
				return [];
			}

			// split search string into array of terms
			if (filter.searchText && filter.searchText.length > 0) {
				var terms = filter.searchText.toLowerCase().split(' ');

				filteredEncounters = _.filter(encounters, function (e) {
					return matchFields(e.tokens, terms);
				});
			}

			if (filter.campusLocId) {
				filteredEncounters = _.filter(filteredEncounters || encounters, function (e) {
					return e.serviceProvider.partOf.location[0].id === filter.campusLocId;
				});
			}

			if (filter.team) {
				filteredEncounters = _.filter(filteredEncounters || encounters, function (e) {
					return e.serviceProvider.identifier[0].value === filter.team.identifier[0].value;
				});
			}

			if (filter.ward) {
				filteredEncounters = _.filter(filteredEncounters || encounters, function (e) {
					// jshint ignore:line
					return e.location[e.location.length - 1].location.partOf.identifier[0].value === filter.ward.identifier[0].value;
				});
			}

			if (filter.bed) {
				var srch = filter.bed.toLowerCase();
				filteredEncounters = _.filter(filteredEncounters || encounters, function (e) {
					// jshint ignore:line
					return e.location[e.location.length - 1].location.name.toLowerCase().indexOf(srch) > -1;
				});
			}

			filteredEncounters = _.sortBy(filteredEncounters || encounters, function (e) {
				return e.location[e.location.length - 1].location.partOf.identifier[0].value;
			});

			return filteredEncounters;
		},

		filterRoleAssignments: function filterRoleAssignments(filter, ras) {
			var _ctx = AppService.ctx;
			var rasSupplied = !!ras;

			if (filter.searchText) {
				filter.searchText = filter.searchText.replace(/'/g, "\\'"); // eslint-disable-line
			}

			// restrict to current
			var now = moment();
			var filteredRAs = _.filter(ras || _ctx.roleAssignments, function (ra) {
				return moment(ra.period.start).isBefore(now) && moment(ra.period.end).isAfter(now);
			});

			//if no search criteria and no pre-supplied filter list, return no results
			if (!rasSupplied && (!filter.searchText || filter.searchText.length === 0) && !filter.team && !filter.ward && !filter.bed) {
				return [];
			}

			// split search string into array of terms
			if (filter.searchText && filter.searchText.length > 0) {
				var terms = filter.searchText.toLowerCase().split(' ');

				filteredRAs = _.filter(o.uniqueRas(filteredRAs), function (ra) {
					return matchFields(ra.tokens, terms);
				});
			}

			if (filter.campusLocId) {
				filteredRAs = _.filter(filteredRAs || _ctx.roleAssignments, function (ra) {
					return ra.role.organization.partOf.location[0].id === filter.campusLocId;
				});
			}

			if (filter.team) {
				filteredRAs = _.filter(filteredRAs || _ctx.roleAssignments, function (ra) {
					return ra.role.organization.identifier[0].value === filter.team.identifier[0].value;
				});
			}

			if (filter.pager) {
				filteredRAs = _.filter(filteredRAs || _ctx.roleAssignments, function (ra) {
					// jshint ignore:line
					var pager = _.find(ra.practitioner.telecom, function (t) {
						return t.system === 'pager';
					});

					if (pager) {
						return pager.value.startsWith(filter.pager);
					}
					return false;
				});
			}

			if (filter.level) {
				filteredRAs = _.filter(filteredRAs || _ctx.roleAssignments, function (ra) {
					return ra.role.level.code.toLowerCase() === filter.level.toLowerCase();
				});
			}

			if (filter.responsibility) {
				filteredRAs = _.filter(filteredRAs || _ctx.roleAssignments, function (ra) {
					return ra.role.responsibility.code.toLowerCase() === filter.responsibility.toLowerCase();
				});
			}

			return _.uniq(filteredRAs);
		},

		uniqueRas: function uniqueRas(ras) {
			var uniqueRas = {};
			_.forEach(ras, function (ra) {
				if (uniqueRas[ra.role.id]) {
					if (ra.type === 'override') {
						// Replace the ra stored
						uniqueRas[ra.role.id] = ra;
					}
				} else {
					uniqueRas[ra.role.id] = ra;
				}
			});
			return _.values(uniqueRas);
		},

		createTask: function createTask(task, encounter, recipientRA) {
			task.description = $sanitize(task.description);
			var deferred = $q.defer();
			createObservationsForTask(task, encounter).then(function (observations) {
				try {
					var pagerMsg = _pagerMessage(task, encounter);
					var pushMsg = _pushMessage(task, encounter);

					var senderContact = [];
					if (task.senderPhone) {
						senderContact.push({
							system: 'phone',
							value: task.senderPhone });
					}

					if (task.senderPager) {
						senderContact.push({
							system: 'pager',
							value: task.senderPager });
					}
					if (task.subCategory && task.subCategory.descriptionPostScript) {
						task.description = task.description + '\n' + '[i]' + task.subCategory.descriptionPostScript + '[/i]';
					}
					var t = void 0;
					if (!encounter) {
						//non patient-related
						t = ResourceService.newTask(AppService.user, // updatedBy
						task.urgency, // urgency
						null, // patientId
						null, // encounter
						null, // overrideTeam
						null, // ward
						null, // bed
						AppService.user, // requester
						recipientRA.practitioner, // recipient
						task.cc, // cc
						task.description, // freetextDescription
						task.subCategory ? task.subCategory.code : 'non-patient-related', // selectedTaskCode
						task.subCategory ? task.subCategory.display : 'Non Patient Related', // selectedTaskDisplay
						recipientRA.role, // recipientMtRole
						null, // obsArray
						null, // overrideRecPager
						senderContact, // overrideSendContact(array)
						task.from, // sentByName
						task.senderRole, // sentByRole
						pagerMsg, // pagerMsg
						pushMsg, // pushMsg
						task.fulfillmentTime, // fulfillmentTime
						task.photo, // photos
						null, // action
						task.customData // custom data
						);
					} else {
						t = ResourceService.newTask(AppService.user, // updatedBy
						task.urgency, // urgency
						encounter.patient, // patient
						encounter, // encounter
						task.overrideTeam, // overrideTeam
						task.overrideWard, // ward
						task.overrideBed, // bed
						AppService.user, // requester
						recipientRA.practitioner, // recipient
						task.cc, // cc
						task.description, // freetextDescription
						task.subCategory ? task.subCategory.code : 'other', // selectedTaskCode
						task.subCategory ? task.subCategory.display : 'Other', // selectedTaskDisplay
						recipientRA.role, // recipientMtRole
						observations, // obsArray
						null, // overrideRecPager
						senderContact, // overrideSendContact(array)
						task.from, // sentByName
						task.senderRole, // sentByRole
						pagerMsg, // pagerMsg
						pushMsg, // pushMsg
						task.fulfillmentTime, // fulfillmentTime
						task.photo, // photos
						null, // action
						task.customData // custom data
						);
					}
					AppService.addTaskAction(t, 'sent', t.recipient[0], t.recipientRole[0]);
					if (t.recipient[0].id === t.requester.id) {
						t.status = 'accepted';
						AppService.addTaskAction(t, 'accepted');
					}
					AppService.create(t).then(function () {
						deferred.resolve(t);
					}, function (err) {
						deferred.reject(err);
					});
				} catch (ex) {
					log.error(ex, 3, 'TaskInitializationError');
				}
			});
			return deferred.promise;
		},

		forwardTask: function forwardTask(task, roleAssignment, reason, fromRoleId) {
			// Logservice.error('compose task Forwarding task to ' + roleAssignment.practitioner + ' with reason: ' + reason+' fromRoleId: '+fromRoleId);
			return TaskService.redirect(task, roleAssignment, reason, fromRoleId);
		},

		cancelTask: function cancelTask(task, reason) {
			return TaskService.cancel(task, null, reason);
		},

		newObservation: function newObservation() {
			return {
				observationTime: null, // ? codeable concept
				airway: null, // ? codeable concept
				rr: null,
				spo2: null,
				spo2qualifier: null, // ? codeable concept
				hr: null,
				sbp: null,
				disability: null, // ? codeable concept
				temp: null,
				bsl: null,
				uo: null,
				trend: null };
		},

		newTask: function newTask() {
			return {
				to: '',
				recipientPager: '',

				from: '',
				senderRole: null,
				senderPhone: null,
				senderPager: null,

				patientRelated: true,
				patient: null,

				overrideTeam: null,
				overrideWard: null,
				overridBed: null,

				category: null,
				subCategory: null,
				description: '',
				urgency: _.find(config.urgencies, { 'code': 'routine' }),
				observation: {
					observationTime: {
						code: null,
						display: null
					},
					airway: {
						code: null,
						display: null
					},
					rr: null,
					spo2: null,
					spo2qualifier: null,
					hr: null,
					sbp: null,
					disability: {
						code: null,
						display: null
					},
					temp: null,
					bsl: null,
					uo: null,
					trend: {
						code: null,
						display: null
					}
				},
				cc: [],
				photo: [],
				action: []
			};
		},

		contactNumberFromTelecoms: function contactNumberFromTelecoms(telecoms) {
			var p = _.find(telecoms, function (t) {
				return t.system === 'pager';
			});
			if (p) {
				return AppService.formatTelecom(p);
			} else if (telecoms && telecoms.length > 0) {
				return AppService.formatTelecom(telecoms[0]); //TODO: verify this logic.
			}
			return '(via switch)';
		},

		setSenderContactsForRoleAssignment: function setSenderContactsForRoleAssignment(task, ra) {
			task.senderRole = null;
			task.senderPager = null;
			task.senderPhone = null;
			var tcs = void 0;
			if (ra && ra.role) {
				if (ra.aggregate && ra.aggregate.telecom) {
					tcs = ra.aggregate.telecom;
				} else {
					tcs = ra.role.telecom;
				}
				if (tcs) {
					_.forEach(tcs, function (tel) {
						if (tel.system === 'pager') {
							task.senderPager = tel.value;
						}
						if (tel.system === 'phone') {
							task.senderPhone = tel.value;
						}
					});
				}
				task.senderRole = AppService.roleShortName(ra.role);
			}
		},

		setTaskSenderDetails: function setTaskSenderDetails(task) {
			task.from = AppService.displayName(AppService.user);

			// Display the role associated with MtRoleAssignment if loggedInUser has only one current MtRoleAssignment
			if (AppService.userRAs.length === 1) {
				task.sender = AppService.userRAs[0];
				task.senderRole = AppService.roleFullName(AppService.userRAs[0].role);
				o.setSenderContactsForRoleAssignment(task, AppService.userRAs[0]);
			} else {
				task.sender = null;
				if (AppService.user.defaultRole) {
					task.senderRole = AppService.user.defaultRole;
				}
				task.senderPhone = null;
				task.senderPager = null;
			}
		},

		storeUserContactPref: function storeUserContactPref(raId, role, phone, pager) {
			var pref = {
				raId: raId,
				senderRole: role,
				senderPhone: phone,
				senderPager: pager
			};

			$window.sessionStorage.setItem('userContactPref', angular.toJson(pref));
		},

		clearUserContactPref: function clearUserContactPref() {
			$window.sessionStorage.removeItem('userContactPref');
		},

		getUserContactPref: function getUserContactPref() {
			return $window.sessionStorage.userContactPref;
		},

		escalate: function escalate(obs, esc, field) {
			field = field || 'display';
			if (config.noObsEscalations || obs !== 0 && (!obs || !esc)) {
				return 'none';
			}

			var _loop = function _loop(i) {
				var e = esc.escalations[i];
				if (obs >= e.min && obs <= e.max) {
					var escl = _.find(config.escalationNames, function (n) {
						return n.code === e.escalationType;
					});
					if (escl) {
						return {
							v: escl[field]
						};
					}
					return {
						v: 'none'
					};
				}
			};

			for (var i = 0; i < esc.escalations.length; i++) {
				var _ret = _loop(i);

				if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
			}
			return 'none';
		},

		escalateByOption: function escalateByOption(opt, esc, field) {
			if (!opt || config.noObsEscalations) {
				return 'none';
			}
			var obs = _.findIndex(esc.options, function (e) {
				return e.code === opt.code;
			});
			return o.escalate(obs, esc, field);
		},

		//Return an array of all observations which are in escalation range
		escalationSummary: function escalationSummary(obs) {
			if (config.noObsEscalations) {
				return [];
			}
			var summary = [];
			_.forEach(_.keys(obs), function (key) {
				if (config[key]) {
					//will ignore 'observationTime'
					var esc = null;
					if (config[key].options) {
						esc = o.escalateByOption(obs[key], config[key], 'code');
					} else {
						esc = o.escalate(obs[key], config[key], 'code');
					}
					if (esc && esc !== 'none') {
						summary.push({
							observationName: config[key].display,
							observation: obs[key],
							escalation: esc,
							display: _.find(config.escalationNames, { 'code': esc }).display
						});
					}
				}
			});
			summary = _.sortBy(summary, 'escalation').reverse(); //order from highest to lowest level of escalation
			return summary;
		},

		customFieldsByTaskType: function customFieldsByTaskType(type) {
			if (!type.customFields) {
				return [];
			}
			return _.orderBy(_.filter(config.customTaskFields, function (f) {
				return _.some(type.customFields, function (cf) {
					return cf === f.name;
				});
			}), function (fl) {
				return _.indexOf(type.customFields, fl.name);
			}); //return the fields in the order they are listed under the task type
		},

		highlight: function highlight(text, terms, spanClass) {
			var t = terms.toLowerCase().split(' ');
			var searchText = text.toLowerCase();

			function getMatchingSegments(s, term, matches) {
				var rx = new RegExp('\\b' + term, 'ig');
				var match = void 0;
				while ((match = rx.exec(s)) !== null) {
					var p = match.index;
					var _e = p + term.length;
					matches.push({ start: p, end: _e });
				}
			}

			//recursive function to find end of contiguous block of matches
			function endOfBlock(matches, index) {
				var end = { end: matches[index].end, nextIndex: index + 1 };
				if (matches.length > index + 1) {
					if (matches[index + 1].start <= matches[index].end) {
						return endOfBlock(matches, index + 1);
					}
				}
				return end;
			}

			//take an array of start and end indices and consolidate into an array which
			//merges contiguous blocks
			function consolidateContiguousSegments(matches) {
				var i = 0;
				var consolidated = [];
				while (matches.length > i) {
					var r = endOfBlock(matches, i);
					consolidated.push({ start: matches[i].start, end: r.end });
					i = r.nextIndex;
				}
				return consolidated;
			}
			//posOffset is an offset determined by the number of subsitutions we have already made into the string - the inserted tags affect the total length, so must be compensated for
			function wrapSegmentInTag(str, pos, tagStart, tagEnd, posOffset) {
				var start = pos.start + posOffset;
				var end = pos.end + posOffset;
				return str.substring(0, start) + tagStart + str.substring(start, end) + tagEnd + str.substring(end);
			}

			var segments = []; //this will be an array of segments (starting and ending indexes) that need to be highlighted in text
			for (var i = 0; i < t.length; i++) {
				getMatchingSegments(searchText, t[i], segments);
			}

			if (segments.length > 0) {
				segments = consolidateContiguousSegments(_.sortBy(segments, 'start'));
				var s1 = '<span class= "' + spanClass + '">';
				var s2 = '</span>';
				var l = s1.length + s2.length;
				for (var _i = 0; _i < segments.length; _i++) {
					//jshint ignore:line
					text = wrapSegmentInTag(text, segments[_i], s1, s2, _i * l);
				}
			}
			return text;
		},

		getLocById: function getLocById(id) {
			return _.find(AppService.ctx.locations, { 'id': id });
		}
	};
	return o;
}]);