'use strict';

/* global angular */

angular.module('medtasker.service').factory('UrlService', ['config', '_', function (config, _) {
	'use strict';

	var data = {
		apiVersion: 'v1'
	};

	function apiPath() {
		return '/api/' + data.apiVersion + '/';
	}

	var o = {
		baseUrl: function baseUrl() {
			return config.baseUrl;
		},
		pingUrl: function pingUrl() {
			return config.baseUrl + apiPath() + 'ping';
		},
		fhirUrl: function fhirUrl() {
			return o.baseUrl() + apiPath() + 'fhir';
		},
		taskUrl: function taskUrl() {
			return o.baseUrl() + apiPath() + 'task';
		},
		scriptUrl: function scriptUrl() {
			return o.baseUrl() + apiPath() + 'script';
		},
		sseUrl: function sseUrl() {
			return o.baseUrl() + apiPath() + 'events';
		},
		ldapUrl: function ldapUrl() {
			return o.baseUrl() + apiPath() + 'auth';
		},
		deviceUrl: function deviceUrl() {
			return o.baseUrl() + apiPath() + 'device';
		},
		medtaskerUrl: function medtaskerUrl() {
			return o.baseUrl() + apiPath() + 'medtasker';
		},
		imageUrl: function imageUrl() {
			return o.baseUrl() + apiPath() + 'images';
		},
		userUrl: function userUrl() {
			return o.baseUrl() + apiPath() + 'user';
		},
		shiftsUrl: function shiftsUrl() {
			return o.baseUrl() + apiPath() + 'shifts';
		},
		reportsUrl: function reportsUrl() {
			return o.baseUrl() + apiPath() + 'reports';
		},
		rosterUrl: function rosterUrl() {
			return o.baseUrl() + apiPath() + 'roster';
		},
		//strip the baseUrl from a url string to return the server-relative URI
		uriFromUrl: function uriFromUrl(url) {
			return _.startsWith(url, o.baseUrl()) ? url.substring(o.baseUrl().length) : url;
		}
	};
	return o;
}]);