'use strict';

/*
	This module is responsible for calling the Medtasker server and correctly
	de-serialize the responses.
		It is a pure service layer with no data held locally. A 'context' object
	is passed to some of the functions when access to the cache of resources is needed.
*/
/* global angular */
angular.module('medtasker.service').factory('ResourceService', ['$http', '$interval', 'uuid', 'moment', 'config', '$q', '_', 'LogService', '$rootScope', 'AuthService', 'DecoratorService', 'UrlService', function ($http, $interval, uuid, moment, config, $q, _, log, $rootScope, AuthService, DecoratorService, UrlService) {
	'use strict';

	//Keep track of the ids of missing resources we are searching for, to prevent searching for the same ids multiple times

	var _resourcePromises = {};
	var _searchCache = {};

	// id and/or version are optional
	// eg: _uri('Practitioner')
	function _uri(resName, id, version) {
		var uri = resName;

		if (id) {
			uri = uri + '/' + id;
		}
		if (version) {
			uri = uri + '/_history/' + version;
		}
		return uri;
	}

	function _url(resName, id, version) {
		return UrlService.fhirUrl() + '/' + _uri(resName, id, version);
	}

	function _searchUrl(resName, criteria, noIndex) {
		var url = _url(resName);
		if (criteria) {
			url += '?' + criteria.replace(/\+/g, '%2B').replace(/(\r\n|\n|\r|\t)/gm, '');
		}
		if (noIndex) {
			url += (criteria ? '&' : '?') + '_medtasker_mode=without_index';
		}
		return url;
	}

	function _uriFromRes(res, ignoreVersion) {
		if (!res) {
			return void 0;
		}

		if (!res.resourceType) {
			throw new Error('Attempt to serialize invalid resource (missing resourceType field): ' + angular.toJson(res));
		}

		var version = ignoreVersion ? null : res.meta.versionId;
		return { reference: _uri(res.resourceType, res.id, version) };
	}

	function _uriFromResArray(array, ignoreVersion) {
		if (!array) {
			return [];
		}
		var a = [];
		var i = void 0;
		for (i = 0; i < array.length; i++) {
			a[i] = _uriFromRes(array[i], ignoreVersion);
		}
		return a;
	}
	// convert a resourceType name to its 'friendly' equivalent
	// e.g. MtOverrideRequest -> overrideRequest
	// MtShift -> shift
	// Organization -> organization
	function _toFriendlyName(s) {
		if (s.startsWith('Mt')) {
			s = s.slice(2);
		}
		return s.charAt(0).toLowerCase() + s.slice(1);
	}

	// Return the name of the array containing the resType resources
	function _pluralize(resType) {
		/* eslint-disable key-spacing */
		var pluralizations = {
			'MtRole': 'roles',
			'MtRoleAssignment': 'roleAssignments',
			'MtTask': 'tasks',
			'MtShift': 'shifts',
			'MtTeamAssignment': 'teamAssignments',
			'MtRotation': 'rotations',
			'MtAggregate': 'aggregates',
			'MtRoleGroup': 'roleGroups',
			'EpisodeOfCare': 'episodesOfCare',
			'MtOverrideRequest': 'overrideRequests',
			'MtClinicalPhotograph': 'clinicalPhotographs'
		};
		/* eslint-enable key-spacing */
		return pluralizations[resType] || resType.toLowerCase() + 's'; // simplistic pluralisation
	}

	function _decorate(res, ctx, force) {
		if (force || !res.$metadata.decorated) {
			switch (res.resourceType) {
				case 'MtTask':
					DecoratorService.decorateTask(res, ctx);
					break;
			}
			res.$metadata.decorated = true;
		}
	}

	// Used to determine whether a RefReq should contain version no for its encounter and patient refs
	function _taskIsActive(status) {
		return status === 'accepted' || status === 'sent' || status === 'started';
	}

	function _updateResRef(ctx, array, fieldName, res, condition) {
		_.forEach(array, function (parent) {
			if (parent.$deserialized && (!condition || condition(parent))) {
				if (parent[fieldName] && parent[fieldName].id === res.id) {
					parent[fieldName] = res;
					_decorate(parent, ctx, true);
					// HACK: Review this. It causes unexpected results when a resource is deserialized as part of
					// a bundle. SSE events will trigger many behaviours in the clients that aren't really
					// anticipated to arise from a bundle being received.
					$rootScope.$broadcast('sse.' + _toFriendlyName(parent.resourceType), parent);
				}
			}
		});
	}

	// Updates all resources in the context ctx which reference res.
	// Eg: If res = Encounter(id:2222, version:2), then a
	//     MtTask.encounter = Encounter(id:2222, version:1) will be updated to
	//     MtTask.encounter = Encounter(id:2222, version:2)
	function _updateParentRefs(ctx, res) {
		switch (res.resourceType) {
			case 'Encounter':
				_updateResRef(ctx, ctx.tasks, 'encounter', res, function (t) {
					return t.status === 'sent' || t.status === 'accepted' || t.status === 'started';
				});
				_updateResRef(ctx, ctx.tasks, 'patient', res.patient, function (t) {
					return t.status === 'sent' || t.status === 'accepted' || t.status === 'started';
				});
				break;
			case 'Patient':
				_updateResRef(ctx, ctx.tasks, 'patient', res, function (t) {
					return t.status === 'sent' || t.status === 'accepted' || t.status === 'started';
				});
				//TODO: should we update encounters here?
				break;
			case 'MtClinicalPhotograph':
				_.forEach(ctx.tasks, function (t) {
					if (t.$deserialized) {
						if (t.photo && t.photo.length > 0) {
							_.forEach(t.photo, function (ph) {
								if (ph.metaData && ph.metaData.id === res.id) {
									ph.metaData = res;
									$rootScope.$broadcast('sse.task', t);
									return false;
								}
								return true;
							});
						}
						if (t.response && t.response.length > 0) {
							_.forEach(t.response, function (r) {
								_.forEach(r.photo, function (ph) {
									if (ph.metaData && ph.metaData.id === res.id) {
										ph.metaData = res;
										$rootScope.$broadcast('sse.task', t);
										return false;
									}
									return true;
								});
							});
						}
					}
				});
				break;
			case 'MtTask':
				_.forEach(ctx.communications, function (c) {
					if (c.payload && c.payload.length && c.payload[0].contentReference && c.payload[0].contentReference.id === res.id) {
						c.payload[0].contentReference = res;
						$rootScope.$broadcast('sse.communication', c);
					}
				});
				break;
			default:
		}
	}
	// We register only the most recent (versionId) copy of a resource into the ctx.
	// If we're in the context of a search operation (ie: ctx['archives'] is used), we also
	// temporarily keep track of previous versions of a resource that are being registered.
	function _registerRes(ctx, res) {
		if (!res) {
			return;
		}
		var arrayName = _pluralize(res.resourceType);
		// Add the resource to the ctx
		ctx[arrayName] = ctx[arrayName] || []; // Create it if missing
		var index = _.findIndex(ctx[arrayName], { id: res.id });
		if (index >= 0) {
			var currentRes = ctx[arrayName][index];
			if (currentRes.meta.versionId < res.meta.versionId || currentRes.meta.versionId === res.meta.versionId && currentRes.meta.lastUpdated !== res.meta.lastUpdated) {
				//due to patch (update without version increment), we replace if the version is the same but updated value has changed
				if (ctx.archives) {
					ctx.archives[currentRes.id + '.' + currentRes.meta.versionId] = currentRes;
				}
				// Replace the resource in the ctx with the newer version
				ctx[arrayName][index] = res;
				_updateParentRefs(ctx, res);
			} else if (currentRes.meta.versionId > res.meta.versionId) {
				if (ctx.archives) {
					// Older version received. Keep it in the archives
					ctx.archives[res.id + '.' + res.meta.versionId] = res;
				} else {
					log.warn('Older version of resource received: ' + ' resType: ' + res.resourceType + '  id: ' + res.id + ' version: ' + res.meta.versionId);
				}
			}
		} else {
			// Adding the new res
			ctx[arrayName].push(res);
		}
	}

	function _retrieveResource(ctx, uri) {
		var deferred = $q.defer();
		var url = UrlService.fhirUrl() + '/' + uri;
		log.debug('Getting: ' + url, false, false, true);
		var p = $http.get(url);
		p.then(function (res) {
			log.debug('Got: ' + url, false, false, true);
			_resourcePromises[uri] = void 0;
			var obj = res.data;
			_registerRes(ctx, obj);
			_deserialize(ctx, obj).then(function () {
				//eslint-disable-line
				deferred.resolve(obj);
			}, function (err) {
				log.error(err, 3, 'FatalContextInconsistencyError'); //heaven forbid we ever get here
				deferred.reject(err);
			});
		}, function (err) {
			log.error(err, 1, 'HttpGetError');
			_resourcePromises[uri] = void 0;
			deferred.reject(err);
		});
		_resourcePromises[uri] = deferred.promise;
		return deferred.promise;
	}

	function _resFromUri(ctx, resource, field, array, index) {
		var deferred = $q.defer();
		var uri = resource[field];

		function setObj(o) {
			if (o) {
				o.$reference = uri;
			}
			if (array) {
				array[index] = o;
			} else {
				resource[field] = o;
			}
		}
		if (angular.isUndefined(uri) || uri === null) {
			setObj(void 0);
			deferred.resolve();
			return deferred.promise;
		}

		// eg: res.reference = '/ResourceName/id{/_history/version}'
		if (_.has(uri, 'reference')) {
			uri = uri.reference;
		}

		// uri has the form /{ResourceName}/{uuid}/_history/{version}
		var tokens = uri.split('/');
		var resType = tokens[0];
		var id = tokens[1];
		var history = !!tokens[2];
		var version = history ? parseInt(tokens[3]) : null;

		var res = _.find(ctx[_pluralize(resType)], { id: id });
		if (res && version && res.meta.versionId !== version) {
			// See if the res with the version is in temporary archives
			var key = id + '.' + version;
			if (ctx.archives && ctx.archives[key]) {
				var r = ctx.archives[key];
				setObj(r);
				deferred.resolve(r);
			} else {
				//We don't have the correct resource version in the context. Try to find it on the server...
				var p = _resourcePromises[uri];
				if (!p) {
					log.debug('Version not in context: attempting retrieval from server. Resource uri =' + uri);
					p = _retrieveResource(ctx, uri);
				} else {
					log.info('Version not in context, but already looking on server.');
				}
				p.then(function (r) {
					setObj(r);
					deferred.resolve(r);
				});
			}
		} else if (!res) {
			//Object missing from the context. Try to find it on the server...
			var _p = _resourcePromises[uri];
			if (!_p) {
				log.debug('Source res=' + angular.toJson(resource) + '\nField=' + field, false, false, true);
				_p = _retrieveResource(ctx, uri);
			} else {
				log.debug('Resource not in context, but already looking on server.', false, false, true);
			}
			_p.then(function (r) {
				setObj(r);
				deferred.resolve(r);
			});
		} else {
			res.$reference = uri;
			setObj(res);
			deferred.resolve(res);
		}
		return deferred.promise;
	}

	function _resFromUriArray(ctx, array, promises) {
		if (array) {
			for (var i = 0; i < array.length; i++) {
				promises.push(_resFromUri(ctx, array[i], 'reference', array, i));
			}
		}
	}

	function _toMomentPeriod(period) {
		return {
			start: period.start ? moment(period.start) : void 0,
			end: period.end ? moment(period.end) : void 0
		};
	}

	function _fromMomentPeriod(period) {
		return {
			start: period.start ? period.start.format() : void 0, // keep the tz info. Do not convert to utc
			end: period.end ? period.end.format() : void 0 };
	}

	function _fromMomentDuration(duration) {
		var hours = duration.hours() + duration.days() * 24;
		return sprintf('%02d:%02d', hours, duration.minutes()); // eslint-disable-line no-undef
	}

	/*
 	Deserialize from FHIR json.
 	Converts uuid's to references to in memory objects.
 */
	function _deserialize(ctx, res) {
		var promises = [];
		var deferred = $q.defer();
		res.$metadata = res.$metadata || {};
		if (!res.$deserialized) {
			//lets us add objects to context that we created ourselves, though we need to set this deserialized flag ourselves beforehand
			switch (res.resourceType) {
				case 'Communication':
					promises.push(_resFromUri(ctx, res, 'sender'));
					_resFromUriArray(ctx, res.recipient, promises);
					promises.push(_resFromUri(ctx, res.payload[0], 'contentReference'));
					res.sent = moment(res.sent);
					promises.push(_resFromUri(ctx, res, 'encounter'));
					break;
				case 'MtOverrideRequest':
					promises.push(_resFromUri(ctx, res, 'recipient'));
					promises.push(_resFromUri(ctx, res, 'requester'));
					_resFromUriArray(ctx, res.roleAssignment, promises);
					res.period = _toMomentPeriod(res.period);
					res.sent = moment(res.sent);
					break;
				case 'Encounter':
					promises.push(_resFromUri(ctx, res, 'patient'));
					promises.push(_resFromUri(ctx, res, 'serviceProvider'));
					promises.push(_resFromUri(ctx, res, 'episodeOfCare'));
					for (var i = 0; i < res.location.length; i++) {
						promises.push(_resFromUri(ctx, res.location[i], 'location'));
						res.location[i].period = _toMomentPeriod(res.location[i].period);
					}
					promises.push(_resFromUri(ctx, res, 'partOf'));
					res.period = _toMomentPeriod(res.period);
					break;
				case 'Practitioner':
					break;
				case 'Patient':
					res.birthDate = moment(res.birthDate);
					// Used in compose searches
					res.index = function () {
						var i = void 0;
						var j = void 0;
						var index = '';
						for (i = 0; i < res.name.length; i++) {
							for (j = 0; j < res.name[i].given.length; j++) {
								index += res.name[i].given[j] + ' ';
							}
							for (j = 0; j < res.name[i].family.length; j++) {
								index += res.name[i].family[j] + ' ';
							}
						}
						return index;
					}();
					break;
				case 'Organization':
					promises.push(_resFromUri(ctx, res, 'partOf'));
					if (res.location) {
						_resFromUriArray(ctx, res.location, promises);
					}
					break;
				case 'Location':
					promises.push(_resFromUri(ctx, res, 'partOf'));
					break;
				case 'MtRole':
					promises.push(_resFromUri(ctx, res, 'organization'));
					_resFromUriArray(ctx, res.associatedLocation, promises);
					_resFromUriArray(ctx, res.associatedOrganization, promises);
					res.start = moment.duration(res.start);
					break;
				case 'MtAggregate':
					res.start = moment.duration(res.start);
					break;
				case 'MtRoleAssignment':
					res.period = _toMomentPeriod(res.period);
					promises.push(_resFromUri(ctx, res, 'practitioner'));
					promises.push(_resFromUri(ctx, res, 'role'));
					promises.push(_resFromUri(ctx, res, 'partOf'));
					promises.push(_resFromUri(ctx, res, 'aggregate'));
					promises.push(_resFromUri(ctx, res, 'override'));
					promises.push(_resFromUri(ctx, res, 'shift'));
					break;
				case 'Observation':
					promises.push(_resFromUri(ctx, res, 'subject'));
					promises.push(_resFromUri(ctx, res, 'encounter'));
					break;
				case 'EpisodeOfCare':
					promises.push(_resFromUri(ctx, res, 'patient'));
					promises.push(_resFromUri(ctx, res, 'managingOrganization'));
					res.period = _toMomentPeriod(res.period);
					break;
				case 'MtShift':
					res.duration = moment.duration(res.duration); // res.duration is tz independent
					res.validPeriod = _toMomentPeriod(res.validPeriod);
					res.start = moment.duration(res.start); // res.start is tz independent
					promises.push(_resFromUri(ctx, res, 'aggregate'));
					promises.push(_resFromUri(ctx, res, 'role'));
					break;
				case 'MtRotation':
					res.period = _toMomentPeriod(res.period);
					promises.push(_resFromUri(ctx, res, 'organization'));
					break;
				case 'MtTeamAssignment':
					promises.push(_resFromUri(ctx, res, 'practitioner'));
					promises.push(_resFromUri(ctx, res, 'organization'));
					res.period = _toMomentPeriod(res.period);
					break;
				case 'MtTask':
					res.dateSent = moment(res.dateSent);
					if (res.patient) {
						promises.push(_resFromUri(ctx, res, 'patient'));
						promises.push(_resFromUri(ctx, res, 'encounter'));
						if (res.supportingInformation) {
							_resFromUriArray(ctx, res.supportingInformation, promises);
						}
					}
					if (res.fulfillmentTime) {
						res.fulfillmentTime = _toMomentPeriod(res.fulfillmentTime);
					}
					_resFromUriArray(ctx, res.recipient, promises);
					_resFromUriArray(ctx, res.recipientRole, promises);
					promises.push(_resFromUri(ctx, res, 'requester'));
					promises.push(_resFromUri(ctx, res, 'overrideTeam'));
					promises.push(_resFromUri(ctx, res, 'overrideWard'));
					_.forEach(res.response, function (r) {
						promises.push(_resFromUri(ctx, r, 'sender'));
						r.dateSent = moment(r.dateSent);
						if (r.photo) {
							_.forEach(r.photo, function (p) {
								if (p.metaData) {
									promises.push(_resFromUri(ctx, p, 'metaData'));
								}
								if (p.attachment && p.attachment.timestamp) {
									p.attachment.timestamp = moment(p.attachment.timestamp);
								}
							});
						}
					});
					_.forEach(res.action, function (a) {
						promises.push(_resFromUri(ctx, a, 'practitioner'));
						promises.push(_resFromUri(ctx, a, 'targetPractitioner'));
						promises.push(_resFromUri(ctx, a, 'targetRole'));
						a.actionedAt = moment(a.actionedAt);
					});
					_.forEach(res.cc, function (cc) {
						promises.push(_resFromUri(ctx, cc, 'practitioner'));
						promises.push(_resFromUri(ctx, cc, 'role'));
					});
					_.forEach(res.photo, function (p) {
						if (p.metaData) {
							promises.push(_resFromUri(ctx, p, 'metaData'));
						}
						if (p.attachment && p.attachment.timestamp) {
							p.attachment.timestamp = moment(p.attachment.timestamp);
						}
					});
					break;
				case 'Claim':
					res.created = moment(res.created);
					promises.push(_resFromUri(ctx, res, 'target'));
					promises.push(_resFromUri(ctx, res, 'provider'));
					promises.push(_resFromUri(ctx, res, 'organization'));
					promises.push(_resFromUri(ctx, res, 'enterer'));
					promises.push(_resFromUri(ctx, res, 'patient'));
					_.forEach(res.item, function (i) {
						promises.push(_resFromUri(ctx, i, 'provider'));
						i.serviceDate = moment(i.serviceDate);
					});
					break;
				case 'MtRoleGroup':
					_resFromUriArray(ctx, res.role, promises);
					break;
				case 'MtClinicalPhotograph':
					res.takenAt = moment(res.takenAt);
					promises.push(_resFromUri(ctx, res, 'uploader'));
					promises.push(_resFromUri(ctx, res, 'takenBy'));
					promises.push(_resFromUri(ctx, res, 'encounter'));
					break;
				case 'Item':
					promises.push(_resFromUri(ctx, res, 'provider'));
					break;
				case 'MBS':
					break;
				case 'undefined':
					break;
				default:
					var err = 'Incoming resources of type ' + res.resourceType + ' are not implemented yet';
					log.error(err, 2, 'ResourceNotImplementedError');
					throw new Error(err);
			}
		}
		$q.all(promises).then(function () {
			//run any decorators
			res.$deserialized = true;
			_decorate(res, ctx);
			deferred.resolve(res);
		});
		return deferred.promise;
	}

	function _addToContext(ctx, res) {
		var deferred = $q.defer();
		_deserialize(ctx, res).then(function () {
			_registerRes(ctx, res);
			deferred.resolve(res);
		}, function (e) {
			deferred.reject(e);
		});
		return deferred.promise;
	}

	function _unregisterRes(ctx, res) {
		// Currently only needed for delete() calls
		if (!res) {
			return;
		}
		var arrayName = _pluralize(res.resourceType);
		if (ctx[arrayName]) {
			_.remove(ctx[arrayName], { 'id': res.id });
		}
	}

	//	Return a copy of res with any resource object reference converted to a uri.
	//	The original res remains untouched.
	function _serialize(res) {
		var res2 = angular.copy(res);
		switch (res2.resourceType) {
			case 'Communication':
				res2.recipient = _uriFromResArray(res2.recipient);
				_.forEach(res2.payload, function (payload) {
					payload.contentReference = _uriFromRes(payload.contentReference);
				});
				res2.sender = _uriFromRes(res2.sender);
				res2.sent = res.sent.format();
				res2.encounter = _uriFromRes(res2.encounter);
				break;
			case 'MtOverrideRequest':
				res2.recipient = _uriFromRes(res2.recipient);
				res2.roleAssignment = _uriFromResArray(res2.roleAssignment);
				res2.requester = _uriFromRes(res2.requester);
				res2.sent = res.sent.format();
				res2.period = _fromMomentPeriod(res2.period);
				break;
			case 'Encounter':
				res2.patient = _uriFromRes(res2.patient);
				res2.serviceProvider = _uriFromRes(res2.serviceProvider);
				_.forEach(res2.location, function (loc) {
					loc.location = _uriFromRes(loc.location);
					loc.period = _fromMomentPeriod(loc.period);
				});
				res2.partOf = _uriFromRes(res2.partOf);
				res2.period = _fromMomentPeriod(res2.period);
				break;
			case 'Practitioner':
				break;
			case 'Patient':
				res2.index = void 0;
				res2.birthdate = res2.birtdate.toDate();
				break;
			case 'Organization':
				res2.partOf = _uriFromRes(res2.partOf);
				if (res2.location) {
					res2.location = _uriFromResArray(res2.location);
				}
				break;
			case 'Location':
				res2.partOf = _uriFromRes(res2.partOf);
				break;
			case 'MtRole':
				res2.organization = _uriFromRes(res2.organization);
				res2.associatedLocation = _uriFromResArray(res2.associatedLocation);
				res2.associatedOrganization = _uriFromResArray(res2.associatedOrganization);
				res2.start = _fromMomentDuration(res2.start);
				break;
			case 'MtAggregate':
				res2.start = _fromMomentDuration(res2.start);
				break;
			case 'MtRoleAssignment':
				res2.practitioner = _uriFromRes(res2.practitioner);
				res2.role = _uriFromRes(res2.role);
				res2.partOf = _uriFromRes(res2.partOf);
				res2.aggregate = _uriFromRes(res2.aggregate);
				res2.override = _uriFromRes(res2.override);
				res2.period = _fromMomentPeriod(res2.period);
				res2.shift = _uriFromRes(res2.shift, 'ignoreVersion');
				break;
			case 'Observation':
				res2.subject = _uriFromRes(res2.subject);
				res2.encounter = _uriFromRes(res2.encounter);
				break;
			case 'EpisodeOfCare':
				res2.patient = _uriFromRes(res2.patient);
				res2.managingOrganization = _uriFromRes(res2.managingOrganization);
				res2.period = _fromMomentPeriod(res2.period);
				break;
			case 'MtShift':
				res2.role = _uriFromRes(res2.role, 'ignoreVersion');
				res2.start = _fromMomentDuration(res2.start); // this is tz independent
				res2.duration = _fromMomentDuration(res2.duration); // this is tz independent
				res2.aggregate = _uriFromRes(res2.aggregate);
				res2.validPeriod = _fromMomentPeriod(res2.validPeriod);
				break;
			case 'MtRotation':
				res2.organization = _uriFromRes(res2.organization);
				res2.period = _fromMomentPeriod(res2.period);
				break;
			case 'MtTeamAssignment':
				res2.practitioner = _uriFromRes(res2.practitioner);
				res2.organization = _uriFromRes(res2.organization);
				res2.period = _fromMomentPeriod(res2.period);
				break;
			case 'MtTask':
				var ignoreVersion = _taskIsActive(res2.status);
				res2.alerts = void 0; //ignore the cached alerts
				res2.recipientRole = _uriFromResArray(res2.recipientRole, ignoreVersion);
				res2.requester = _uriFromRes(res2.requester, ignoreVersion);
				res2.recipient = _uriFromResArray(res2.recipient, ignoreVersion);
				res2.overrideTeam = _uriFromRes(res2.overrideTeam, ignoreVersion);
				res2.overrideWard = _uriFromRes(res2.overrideWard, ignoreVersion);
				if (res2.fulfillmentTime) {
					res2.fulfillmentTime = _fromMomentPeriod(res2.fulfillmentTime);
				}
				if (res2.patient) {
					res2.patient = _uriFromRes(res2.patient, ignoreVersion);
					res2.encounter = _uriFromRes(res2.encounter, ignoreVersion);
					if (res2.supportingInformation) {
						res2.supportingInformation = _uriFromResArray(res2.supportingInformation, ignoreVersion);
					}
				}
				if (res2.meta.versionId === 1) {
					res2.dateSent = res2.dateSent.format(); // Keep the tz info. Do not convert to utc.
				}
				_.forEach(res2.response, function (r) {
					r.sender = _uriFromRes(r.sender);
					r.dateSent = r.dateSent.format();
					if (r.photo) {
						_.forEach(r.photo, function (p) {
							if (p.metaData) {
								p.metaData = _uriFromRes(p.metaData, ignoreVersion);
							}
							if (p.attachment.timestamp) {
								p.attachment.timestamp = p.attachment.timestamp.format();
							}
						});
					}
				});
				_.forEach(res2.action, function (a) {
					a.practitioner = _uriFromRes(a.practitioner, ignoreVersion);
					a.targetPractitioner = _uriFromRes(a.targetPractitioner, ignoreVersion);
					a.targetRole = _uriFromRes(a.targetRole, ignoreVersion);
					a.actionedAt = a.actionedAt.format();
				});
				_.forEach(res2.cc, function (cc) {
					cc.practitioner = _uriFromRes(cc.practitioner, ignoreVersion);
					cc.role = _uriFromRes(cc.role, ignoreVersion);
				});
				_.forEach(res2.photo, function (p) {
					if (p.metaData) {
						p.metaData = _uriFromRes(p.metaData, ignoreVersion);
					}
					if (p.attachment.timestamp) {
						p.attachment.timestamp = p.attachment.timestamp.format();
					}
				});
				break;
			case 'Claim':
				res2.created = res2.created.format();
				res2.provider = _uriFromRes(res2.provider);
				res2.facility = _uriFromRes(res2.facility);
				res2.enterer = _uriFromRes(res2.enterer);
				res2.patient = _uriFromRes(res2.patient);
				_.forEach(res2.item, function (i) {
					i.provider = _uriFromRes(i.provider);
					i.serviceDate = i.serviceDate.format();
				});
				break;
			case 'MtRoleGroup':
				res2.role = _uriFromResArray(res2.role, 'ignoreVersion');
				break;
			case 'MtClinicalPhotograph':
				res2.takenAt = res2.takenAt.format();
				res2.takenBy = _uriFromRes(res2.takenBy);
				res2.uploader = _uriFromRes(res2.uploader);
				res2.encounter = _uriFromRes(res2.encounter);
				break;
			default:
				var err = 'Don\'t know how to convert a ' + res2.resourceType + ' object';
				log.error(err, 2, 'ConversionNotImplementedError');
				throw new Error(err);
		}
		return res2;
	}

	// Return the LDAP id for the practitioner
	// function _ldapId(practitioner) {
	// 	let ldapId = null;
	// 	_.forEach(practitioner.identifier, function(identifier) {
	// 		if (identifier.type.text === 'LDAP uid') {
	// 			ldapId = identifier.value;
	// 			return;  // break from forEach loop
	// 		}
	// 	});
	// 	return ldapId;
	// }

	var _httpConfig = {
		headers: {
			'Content-type': 'application/json'
		}
	};

	// Return the resource with the given id and optionally the given version
	function _get(ctx, resName, id, version) {
		var deferred = $q.defer();
		var url = _url(resName, id, version);
		log.debug('Getting: ' + url, false, false, true);
		$http.get(url).then(function (res) {
			log.debug('Got: ' + url, false, false, true);
			try {
				var entity = res.data;
				_registerRes(ctx, entity);
				_deserialize(ctx, entity).then(function () {
					deferred.resolve(entity);
				}, function (err) {
					deferred.reject(err);
				});
			} catch (err) {
				log.error(err, 2, 'DeserializationError');
				deferred.reject(err);
			}
		}, function (err) {
			if (err) {
				log.error(err.data, 1, 'HttpGetError');
			}
			deferred.reject(err);
		});
		return deferred.promise;
	}

	function _registerArray(ctx, resources, deferred) {
		if (!deferred) {
			deferred = $q.defer();
		}
		if (resources.length === 0) {
			deferred.resolve([]);
		}
		var retArray = [];

		ctx.archives = ctx.archives || {};
		var resolved = 0;
		for (var i = 0; i < resources.length; i++) {
			var res = resources[i];
			_registerRes(ctx, res);
		}
		for (var _i = 0; _i < resources.length; _i++) {
			var _res = resources[_i];
			if (_res) {
				_deserialize(ctx, _res).then(function (r) {
					//eslint-disable-line no-loop-func
					resolved++;
					retArray.push(r);
					if (resolved === resources.length) {
						//all resolved
						deferred.resolve(retArray);
					}
				}, function (err) {
					//eslint-disable-line no-loop-func
					log.error(err + ' when registering resources array ', 2, 'DeserializationError');
					deferred.reject(err);
				});
			} else {
				resolved++;
			}
		}
		//delete ctx.archives;

		return deferred.promise;
	}
	//if no resName provided, register all resources in the bundle
	function _registerBundle(ctx, bundle, resName, deferred) {
		if (!deferred) {
			deferred = $q.defer();
		}
		var retArray = [];
		var res = void 0;
		var i = void 0;
		var redacted = _.map(_.filter(bundle.entry, { search: { mode: 'redacted' } }), 'resource');
		if (redacted.length > 0) {
			var resTypes = _.uniq(_.map(redacted, 'resourceType')).join(', ');
			var err = 'Server refused access to following resources: ' + resTypes + ' when searching for ' + resName;
			log.error(err, 1, 'HttpSearchAuthorizationError');
			$rootScope.$broadcast('authorizationError', err);
			deferred.reject(err);
		} else {
			(function () {
				ctx.archives = ctx.archives || {};
				for (i = 0; i < bundle.entry.length; i++) {
					res = bundle.entry[i].resource;
					_registerRes(ctx, res);
				}
				var resolved = 0;
				if (bundle.entry.length === 0) {
					deferred.resolve([]);
				}
				for (i = 0; i < bundle.entry.length; i++) {
					res = bundle.entry[i].resource;
					if (res) {
						_deserialize(ctx, res).then(function (r) {
							//eslint-disable-line no-loop-func
							resolved++;
							if (!resName || r.resourceType === resName) {
								retArray.push(r);
							}
							if (resolved === bundle.entry.length) {
								//all resolved
								deferred.resolve(retArray);
							}
						}, function (err) {
							//eslint-disable-line no-loop-func
							log.error(err + ' when searching for ' + resName, 2, 'DeserializationError');
							deferred.reject(err);
						});
					} else {
						resolved++;
					}
				}
			})();
		}
		return deferred.promise;
	}
	// Returns an array of resources returned from the search
	// This array may be empty if no result is found. In this case no error is thrown either.
	// noIndex tells server to bypass Elastic Search and retrieve resource directly from the database
	// if 'cache', we store the results of the query so that if we're asked for the same resources again, we return the cached data
	function _search(ctx, resName, criteria, noIndex, cache) {
		var deferred = $q.defer();
		var url = _searchUrl(resName, criteria, noIndex);
		log.debug('Getting: ' + url, false, false, true);
		if (cache && _searchCache[url]) {
			log.info('Search ' + url + ' was cached. Returning cached results.');
			deferred.resolve(_searchCache[url]);
		} else {
			$http.get(url).then(function (bundle) {
				log.debug('Got: ' + url, false, false, true);
				_registerBundle(ctx, bundle.data, resName, deferred).then(function (data) {
					if (cache) {
						_searchCache[url] = data;
					}
				});
			}, function (err) {
				if (err) {
					log.error(err, 2, 'HttpSearchError');
				}
				deferred.reject(err);
			});
		}
		return deferred.promise;
	}

	function _clearCache() {
		_searchCache = {};
	}

	// Return a unique time base UUID suitable as a database id.
	// The id contains a time base component, incremented counter and a random component.
	function _generateId() {
		return uuid.v1();
	}

	// Create a new resource
	// cache = true if the resource is to be stored locally by this service
	function _create(ctx, res) {
		var deferred = $q.defer();
		res.id = _generateId();
		res.meta.versionId = 1;
		var payload = _serialize(res);
		$http.put(_url(res.resourceType, res.id), payload, _httpConfig).then(function () {
			deferred.resolve(res);
		}, function (err) {
			if (err) {
				log.error(err, 1, 'HttpCreateError');
			}
			deferred.reject(err);
		});
		return deferred.promise;
	}

	// Update an existing resource
	function _update(ctx, res) {
		var deferred = $q.defer();
		res.meta.versionId += 1;
		var payload = _serialize(_.omit(res, '$metadata'));
		if (payload) {
			$http.put(_url(res.resourceType, res.id), payload, _httpConfig).then(function () {
				deferred.resolve(res);
			}, function (err) {
				if (err) {
					log.error(err, 1, 'HttpUpdateError');
				}
				deferred.reject(err);
			});
		}
		return deferred.promise;
	}

	function _delete(ctx, res) {
		var deferred = $q.defer();
		$http.delete(_url(res.resourceType, res.id)).then(function () {
			_unregisterRes(ctx, res); // Remove from pool of 'current' resources
			deferred.resolve();
		}, function (err) {
			if (err) {
				log.error(err, 1, 'HttpDeleteError');
			}
			deferred.reject(err);
		});
		return deferred.promise;
	}

	// Perform a series of actions on the server using the Script API.
	// The returned content must contain two arrays, one containing the
	// new resources to register locally, and the other the resources
	// that must be deleted locally.
	function _scriptRequest(ctx, script) {
		var deferred = $q.defer();
		var req = {
			method: 'POST',
			url: UrlService.scriptUrl(),
			headers: { 'Content-Type': 'application/javascript' },
			data: script
		};
		$http(req).then(function (data) {
			var promises = [];
			_.forEach(data.created, function (res) {
				_registerRes(ctx, res);
				promises.push(_deserialize(ctx, res));
			});
			_.forEach(data.deleted, function (res) {
				_unregisterRes(ctx, res);
			});
			$q.all(promises).then(function () {
				deferred.resolve();
			}, function (err) {
				deferred.reject(err);
			});
		}, function (err) {
			if (err) {
				log.error(err, 2, 'HttpScriptError');
			}
			deferred.reject(err);
		});
		return deferred.promise;
	}

	function _objectToUrlParams(p) {
		if (angular.isObject(p)) {
			return Object.keys(p).map(function (k) {
				var v = void 0;
				if (angular.isArray(p[k])) {
					v = p[k].join();
				} else {
					v = p[k];
				}
				v = encodeURIComponent(v);
				return encodeURIComponent(k) + '=' + v;
			}).join('&');
		} else if (angular.isString(p)) {
			return p;
		}
		throw new Error('Invalid param type');
	}

	function _ping() {
		var req = {
			method: 'GET',
			url: UrlService.pingUrl()
		};
		return $http(req);
	}

	function _callProc(url, json) {
		var deferred = $q.defer();
		AuthService.setHttpAuthHeader();
		var req = {
			method: 'GET',
			url: url
		};
		if (json) {
			req.headers = { 'Accept': 'application/json' };
		} else {
			req.headers = { 'Accept': 'text/plain' };
		}
		$http(req).then(function (res) {
			deferred.resolve(res);
		}, function (err) {
			log.error(err, 2, 'HttpScriptError');
			deferred.reject(err);
		});
		return deferred.promise;
	}

	//Call an endpoint of the medtasker business logic API
	function _medtaskerProc(proc, params, json, useQueryParam) {
		var sep = useQueryParam ? '?' : '/';
		var url = UrlService.medtaskerUrl() + '/' + proc + (params ? sep + _objectToUrlParams(params) : '');
		return _callProc(url, json);
	}

	//Call an endpoint of the medtasker business logic API that return fhir resources, and register results in context
	function _medtaskerProcFhir(ctx, proc, params, resName, useQueryParam) {
		var deferred = $q.defer();
		_medtaskerProc(proc, params, true, useQueryParam).then(function (bundle) {
			_registerBundle(ctx, bundle.data, resName).then(function (resources) {
				deferred.resolve({
					data: resources,
					total: bundle.data.total
				});
			});
		});
		return deferred.promise;
	}

	function _storedProc(script, params, json) {
		var url = UrlService.scriptUrl() + '/' + script + '?' + _objectToUrlParams(params);
		return _callProc(url, json);
	}

	// the json parameter is little bit redundant as the server does not check the 
	// requested content type any way.
	// also not sure whether the reports API even accepts any thing other 
	// query parameter
	function _reportWithJsonAndQueryParamOptions(report, params, json, useQueryParam) {
		var sep = useQueryParam ? '?' : '/';
		var url = UrlService.reportsUrl() + '/' + report + (params ? sep + _objectToUrlParams(params) : '');
		return _callProc(url, json);
	}

	function _report(report, params) {
		var url = UrlService.reportsUrl() + '/' + report + '?' + _objectToUrlParams(params);
		return _callProc(url);
	}

	//Call an endpoint of the medtasker business logic API that return fhir resources, and register results in context
	function _reportFhir(ctx, proc, params, resName, useQueryParam) {
		var deferred = $q.defer();
		_reportWithJsonAndQueryParamOptions(proc, params, true, useQueryParam).then(function (bundle) {
			_registerBundle(ctx, bundle.data, resName).then(function (resources) {
				deferred.resolve({
					data: resources,
					total: bundle.data.total
				});
			});
		});
		return deferred.promise;
	}
	// Call a stored proc which returns FHIR resources and register in context
	function _storedProcFhir(ctx, script, params, resName) {
		var deferred = $q.defer();
		_storedProc(script, params, true).then(function (bundle) {
			_registerBundle(ctx, bundle.data, resName, deferred).then(function () {
				deferred.resolve(bundle.data);
			});
		});
		return deferred.promise;
	}

	var o = {
		/* eslint-disable key-spacing*/
		get: _get,
		search: _search,
		create: _create,
		update: _update,
		delete: _delete,
		clearCache: _clearCache,
		scriptRequest: _scriptRequest, // uses the JS API
		generateId: _generateId,
		storedProc: _storedProc, //uses the Otto JS API
		storedProcFhir: _storedProcFhir,
		report: _report,
		reportWithJsonAndQueryParamOptions: _reportWithJsonAndQueryParamOptions,
		reportFhir: _reportFhir,
		medtaskerProc: _medtaskerProc, //uses the Go API
		medtaskerProcFhir: _medtaskerProcFhir,
		objectToUrlParams: _objectToUrlParams,
		toMomentPeriod: _toMomentPeriod,
		// Used by EventService and Script API calls
		serialize: _serialize,
		deserialize: _deserialize,
		register: _registerRes,
		registerBundle: _registerBundle,
		unregister: _unregisterRes,
		registerArray: _registerArray,
		uriFromRes: _uriFromRes,
		addToContext: _addToContext,
		pluralize: _pluralize,
		toFriendlyName: _toFriendlyName,
		ping: _ping,
		/* eslint-disable key-spacing*/

		connectionStatus: {
			isOffline: false,
			serverUnavailable: false
		},

		newShift: function newShift(role, start, duration, schedule, startDate) {
			return {
				resourceType: 'MtShift',
				id: null, // assigned during the create call
				meta: {
					versionId: 0 },
				role: role,
				start: start,
				duration: duration,
				aggregate: void 0,
				schedule: schedule,
				validPeriod: { start: startDate || moment() }
			};
		},

		newRole: function newRole(type, level, responsibility, organization, start, telecom, associatedLocation, associatedOrganization, associatedBeds, core, active) {
			return {
				resourceType: 'MtRole',
				id: null, // assigned during the create call
				meta: {
					versionId: 0 },
				type: type,
				level: level,
				responsibility: responsibility,
				organization: organization,
				start: start,
				telecom: telecom,
				associatedLocation: associatedLocation || [],
				associatedOrganization: associatedOrganization || [],
				associatedBeds: associatedBeds,
				active: active,
				core: core
			};
		},

		newRoleGroup: function newRoleGroup(name) {
			return {
				resourceType: 'MtRoleGroup',
				id: null, // assigned during the create call
				meta: {
					versionId: 0 },
				name: name,
				role: []
			};
		},
		// Return a 'blank' RoleAssignment.
		// This object is meant to be created using the 'create(res)' call.
		// Do not include the `aggregate` arg if the rshift is not part of an aggregate
		newRoleAssignment: function newRoleAssignment(type, role, practitioner, period, aggregate, reason, overriddenRa, shift) {
			return {
				resourceType: 'MtRoleAssignment',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				type: type, // shift or override
				role: role,
				practitioner: practitioner,
				period: period,
				aggregate: aggregate,
				override: overriddenRa,
				shift: shift,
				note: reason
			};
		},

		newAggregate: function newAggregate(name, type, schedule) {
			if (type !== 'roleAssignment' && type !== 'shift') {
				log.error('Invalid type in newAggregate()', 2);
				return null;
			}
			return {
				resourceType: 'MtAggregate',
				id: null, // assigned during the create call
				meta: {
					versionId: 0 },
				schedule: schedule,
				name: name,
				type: {
					system: 'http://schema.medtasker.com/code/mtaggregate/type',
					code: type === 'shift' ? 'mtShiftAggregate' : 'mtRoleAssignmentAggregate'
				},
				start: moment.duration(0, 'sec') };
		},

		newCommunication: function newCommunication(category, recipient, payload, encounter) {
			var comm = {
				resourceType: 'Communication',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				category: category,
				medium: [{
					coding: [{ code: 'ELECTRONIC' }],
					text: 'medtasker'
				}],
				payload: payload,
				status: 'in-progress',
				recipient: [recipient],
				sent: moment(),
				encounter: encounter
			};
			return comm;
		},

		newOverrideRequest: function newOverrideRequest(requester, recipient, period, roleAssignments, note) {
			var or = {
				resourceType: 'MtOverrideRequest',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				sent: moment(),
				period: period,
				roleAssignment: roleAssignments,
				requester: requester,
				recipient: recipient,
				status: config.overrideRequestStatus[0].code,
				requestNote: note
			};
			return or;
		},

		// name: eg: Cardiology
		// identifier: eg: CARD
		// type:  eg: team, subteam, healthservice
		newOrganization: function newOrganization(name, identifier, type, parent, location, phone) {
			var org = {
				resourceType: 'Organization',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				name: name, // eg: Cardiology
				type: type, // eg: team, subteam, healthservice
				identifier: [{
					value: identifier }],
				telecom: [],
				partOf: parent,
				location: [location]
			};

			if (phone) {
				org.telecom.push({ system: 'phone', value: phone });
			}
			return org;
		},

		newRotation: function newRotation(team, levelCode, levelDisplay, period) {
			return {
				resourceType: 'MtRotation',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				organization: team,
				level: { system: 'http://schema.medtasker.com/code/demohospital/mtrole/level',
					code: levelCode, display: levelDisplay
				},
				period: period
			};
		},

		newClinicalPhotograph: function newClinicalPhotograph(takenAt, takenBy, uploader, imageUri, encounter, type, consent, clinicalImageType, bodySite, clinicalNotes) {
			return {
				resourceType: 'MtClinicalPhotograph',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				takenAt: takenAt,
				takenBy: takenBy,
				uploader: uploader,
				type: type,
				consentObtainedFrom: consent,
				imageUri: imageUri,
				encounter: encounter,
				clinicalImageType: clinicalImageType,
				bodySite: bodySite,
				clinicalNotes: clinicalNotes
			};
		},

		newTeamAssignment: function newTeamAssignment(team, level, practitioner, period) {
			return {
				resourceType: 'MtTeamAssignment',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				organization: team,
				level: level,
				practitioner: practitioner,
				period: period
			};
		},

		newClaim: function newClaim(practitioner, patient, mbsItems, hospital, serviceDate) {
			return {
				resourceType: 'Claim',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				type: 'institutional',
				ruleset: {
					code: '201507'
				},
				created: moment().startOf('day'),
				provider: practitioner,
				facility: hospital,
				enterer: practitioner,
				patient: patient,
				status: _.find(config.claimStatus, { 'code': 'active' }),
				item: _.transform(mbsItems, function (accum, item, index) {
					accum.push({
						sequence: index,
						provider: practitioner,
						serviceDate: serviceDate,
						service: {
							code: item.itemNum
						},
						notes: item.notes || void 0
					});
				})
			};
		},

		newObservation: function newObservation(observationName, value, minsSinceObsTaken, patient, encounter) {
			var valueQty = void 0;
			var valueCoding = void 0;

			// Depending on ObservationType, Observation Resource requires ValueQuantity or ValueCodeableConcept

			if (observationName === 'airway' || observationName === 'spo2qualifier' || observationName === 'avpu' || observationName === 'disability' || observationName === 'trend' || observationName === 'rd' || observationName === 'crt') {
				valueQty = null;
				valueCoding = value;
			} else {
				valueQty = value;
				valueCoding = {
					code: null,
					display: null
				};
			}

			return {
				resourceType: 'Observation',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				name: observationName,
				valueQuantity: {
					value: valueQty
				},
				valueCodeableConcept: {
					coding: [valueCoding]
				},
				appliesPeriod: {
					start: moment().subtract(minsSinceObsTaken || 0, 'minutes'),
					end: moment() },
				status: 'final',
				subject: patient,
				encounter: encounter
			};
		},

		newTask: function newTask(updatedBy, urgency, patient, encounter, overrideTeam, overrideWard, overrideBed, requester, recipient, cc, description, selectedTaskCode, selectedTaskDisplay, recipientRole, obsArray, overrideRecPager, overrideSendContact, sentByName, sentByRole, pagerMsg, pushMsg, fulfillmentTime, photo, action, customData) {

			var t = {
				resourceType: 'MtTask',
				id: null, // Will be assigned during the create() call
				meta: {
					versionId: 0 },
				status: 'sent',
				priority: {
					coding: [urgency]
				},
				requester: requester,
				recipient: [recipient],
				dateSent: moment(),
				description: description,

				recipientRole: [recipientRole],
				cc: cc,
				overrideRecipientPager: overrideRecPager,
				overrideSenderContact: overrideSendContact,
				overrideTeam: overrideTeam,
				overrideWard: overrideWard,
				overrideBed: overrideBed,
				sentOnBehalfOfName: sentByName,
				sentOnBehalfOfRole: sentByRole,
				pagerMessage: pagerMsg,
				pushMessage: pushMsg,
				fulfillmentTime: {
					end: fulfillmentTime
				},
				photo: photo || [],
				action: action || [],
				customData: customData
			};

			if (!patient) {
				_.merge(t, {
					patient: null,
					encounter: null
				});
			} else {
				_.merge(t, {
					patient: patient,
					encounter: encounter
				});
			}
			if (selectedTaskCode && selectedTaskDisplay) {
				_.merge(t, {
					serviceRequested: [{
						coding: [{
							code: selectedTaskCode,
							display: selectedTaskDisplay
						}]
					}]
				});
			} else {
				t.serviceRequested = null;
			}
			if (obsArray) {
				t.supportingInformation = obsArray;
			} else {
				t.supportingInformation = null;
			}
			return t;
		}
	};
	return o;
}]);