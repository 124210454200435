'use strict';

angular.module('medtasker').controller('PatientListCtrl', ['ctx', '$state', '$log', '$scope', '$rootScope', 'config', 'AppService', 'AUTH_EVENTS', '_', function (ctx, $state, $log, $scope, $rootScope, config, AppService, AUTH_EVENTS, _) {

	var _this = this;
	_this.selectedTeamFilterId = null;

	_this.ctx = ctx;

	_this.sortConfig = {
		team: {
			func: function func(e) {
				return e.serviceProvider.identifier[0].value;
			},
			dir: 'asc'
		},
		ward: {
			func: function func(e) {
				return AppService.displayLocation('ward', e.location[e.location.length - 1].location.partOf);
			},
			dir: 'asc'
		},
		bed: {
			func: function func(e) {
				if (config.bedSort === 'byLastNumber') {
					return AppService.lastNumber(e.location[e.location.length - 1].location.identifier[0].value);
				}
				return AppService.displayLocation('bed', e.location[e.location.length - 1].location);
			},
			dir: 'asc'
		}
	};

	_this.teamSort = ['team', 'ward', 'bed'];

	_this.locationSort = ['ward', 'bed'];

	_this.sortOrder = _this.teamSort;

	_this.filterEncountersByTeamId = AppService.filterEncountersByTeamId;

	_this.campusCode = AppService.campusDisplayCode;
	_this.displayLocation = AppService.displayLocation;

	function matchesFields(enc, searchStr) {
		var fields = (enc.patient.name[0].given[0] + ' ' + enc.patient.name[0].family[0] + ' ' + enc.location[enc.location.length - 1].location.identifier[0].value + ' ' + enc.location[enc.location.length - 1].location.partOf.identifier[0].value + ' ' + enc.serviceProvider.identifier[0].value + ' ' + enc.serviceProvider.name).split(' ');
		var terms = searchStr.toLowerCase().split(' ');

		return _.every(terms, function (term) {
			return _.some(fields, function (field) {
				return _.startsWith(field.toLowerCase(), term);
			});
		});
	}

	var init = function init() {
		_this.encounters = _this.getFilteredAndSortedEncounters();
		//Display tasks data for the tasks tab
		var tasks = AppService.patientsTasks();
		_this.numNewTasks = _.filter(tasks, function (t) {
			return t.status === 'sent';
		}).length;
		var completed = _.filter(tasks, function (t) {
			return t.status === 'completed';
		}).length;
		_this.numIncompleteTasks = tasks.length - completed;
	};

	_this.reinitialize = function () {
		$rootScope.$emit('AppServiceReInitialize', 'Getting the latest tasks...', 'hideLoading');
	};

	_this.userRoles = function () {
		return _.map(AppService.userRAs, 'role');
	};

	_this.usersTeams = function () {
		return _.union(_.map(AppService.userRAs, 'role.organization'), _.map(AppService.userTAs, 'organization'));
	};

	function reverseSortDirection(d) {
		_this.sortConfig[d].dir = _this.sortConfig[d].dir === 'asc' ? 'desc' : 'asc';
	}

	_this.sortByTeam = function () {
		if (_this.sortOrder === _this.teamSort) {
			reverseSortDirection('team');
		}
		_this.sortOrder = _this.teamSort;
		_this.sortEncounters(_this.filteredEncounters);
	};

	_this.sortByLocation = function () {
		if (_this.sortOrder === _this.locationSort) {
			reverseSortDirection('ward');
			reverseSortDirection('bed');
		}
		_this.sortOrder = _this.locationSort;
		_this.sortEncounters(_this.filteredEncounters);
	};

	_this.getEncounters = function () {
		_this.encounters = AppService.getUsersEncounters();
		return _this.encounters;
	};

	_this.filterEncounters = function (encs) {
		if (_this.selectedTeam) {
			encs = _.filter(encs, function (e) {
				return e.serviceProvider.id === _this.selectedTeam.id;
			});
		}
		if (_this.searchStr) {
			encs = _.filter(encs, function (enc) {
				return matchesFields(enc, _this.searchStr);
			});
		}
		_this.numPatients = encs.length;
		_this.filteredEncounters = encs;
		return _this.filteredEncounters;
	};

	_this.sortEncounters = function (encs) {
		var p = _.orderBy(encs,
		//return array of sort functions to apply
		_.transform(_this.sortOrder, function (funcs, sort) {
			funcs.push(_this.sortConfig[sort].func);
		}),
		//return array of sort directions
		_.transform(_this.sortOrder, function (dirs, sort) {
			dirs.push(_this.sortConfig[sort].dir);
		}));
		_this.sortedEncounters = p;
		return _this.sortedEncounters;
	};

	_this.getFilteredAndSortedEncounters = function () {
		return _this.sortEncounters(_this.filterEncounters(_this.getEncounters()));
	};

	_this.filterAndSortEncounters = function () {
		_this.sortEncounters(_this.filterEncounters(_this.encounters));
	};

	_this.newTask = function () {
		$state.go('app.compose');
	};

	_this.goTasks = function (encounter) {
		$state.go('app.tasksforpatient', { encounterId: encounter.id });
	};

	_this.addPrimaryTeam = function () {
		$state.go('app.primaryteam');
	};

	_this.info = function () {
		if (_this.selectedTeam) {
			return null;
		}
		var info = void 0;

		var t = _.transform(_this.usersTeams(), function (accum, team) {
			accum.push(AppService.campusDisplayCode(team.partOf.location[0].id) + team.name);
		}).join(', ');
		if (t.length > 0) {
			info = 'Patients in: ' + t;
		}
		return info;
	};

	// // TasksForPatients inbox
	_this.encNewTasks = function (encId) {
		var t = _.filter(AppService.encounterTasks(encId), function (rr) {
			return rr.status === 'sent';
		});
		_this.newCount = t.length;
		return t;
	};
	_this.encAcceptedTasks = function (encId) {
		var t = _.filter(AppService.encounterTasks(encId), function (rr) {
			return rr.status === 'accepted';
		});
		_this.acceptedCount = t.length;
		return t;
	};
	_this.encUncompletedTasks = function (encId) {
		var t = _.filter(AppService.encounterTasks(encId), function (rr) {
			return rr.status !== 'completed';
		});
		_this.incompleteCount = t.length;
		return t;
	};

	_this.toggleShowSort = function () {
		_this.showFilter = false;
		_this.showSort = !_this.showSort;
	};

	_this.toggleShowFilter = function () {
		_this.showSort = false;
		_this.showFilter = !_this.showFilter;
	};

	_this.displayName = AppService.displayName;

	var unbind = [];

	unbind.push($scope.$on('userDataLoaded', init));

	unbind.push($scope.$on('teamDataLoaded', init));

	unbind.push($scope.$on('sse.encounter', init));

	unbind.push($scope.$on(AUTH_EVENTS.logoutUserSuccess, init));

	unbind.push($scope.$on(AUTH_EVENTS.logoutWardSuccess, init));

	unbind.push($scope.$on(AUTH_EVENTS.loginUserSuccess, init));

	unbind.push($scope.$on(AUTH_EVENTS.userSessionRestored, init));

	unbind.push($scope.$on(AUTH_EVENTS.wardSessionRestored, init));

	$scope.$on('$destroy', function () {
		unbind.map(function (remove) {
			remove();
		});
	});
	init();
}]);