
'use strict';

angular.module('medtasker').controller('ContactEditCtrl', ['modalArgs', 'RosterService', 'AppService', '$modalInstance', '$scope', '_', function (modalArgs, RosterService, AppService, $modalInstance, $scope, _) {
	//eslint-disable-line
	var _this = this;
	_this.rshift = modalArgs.rshift;
	_this.telecoms = modalArgs.telecoms;
	_this.ctx = modalArgs.ctx;
	_this.contactTypes = ['pager', 'phone', 'switch'];
	_this.newContactType = _this.contactTypes[0];
	_this.pager = '';
	_this.phone = '';

	_this.formatTelecom = function (t) {
		if (!t) {
			return '';
		}
		var tel = AppService.formatTelecom(t);
		if (t.source) {
			return tel + ' (' + t.source + ')';
		}
		return tel;
	};

	if (_this.telecoms.length === 1) {
		if (_this.telecoms[0].system !== 'switch') {
			_this.selectedTelecom = _this.telecoms[0];
		}
	}

	_this.hasKnownContact = _.some(_this.telecoms, function (t) {
		return t.system !== 'switch';
	});

	if (!_this.hasKnownContact) {
		_this.contact = 'new';
	} else {
		_this.contact = 'known';
	}

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.invalidContact = function () {
		return $scope.editContact.$invalid || //invalid phone or pager OR
		_this.contact === 'known' && !_this.selectedTelecom || //known contact but none selected  - impossible? OR
		_this.contact === 'new' && ( //new contact AND...
		_this.newContactType === 'pager' && (!_this.pager || _this.pager.length === 0) || //pager selected but no pager number OR
		_this.newContactType === 'phone' && (!_this.phone || _this.phone.length === 0)); //phone selected but no phone number
	};

	_this.displayName = AppService.displayName;

	_this.save = function () {
		var agg = _this.rshift.assigned.aggregate;
		agg.telecom = [];
		if (_this.contact === 'known') {
			//HACK: can't we do better than this?
			agg.telecom.push(_.omit(_.find(_this.telecoms, function (t) {
				return _this.formatTelecom(t) === _this.selectedTelecom;
			}), 'source'));
		} else {
			switch (_this.newContactType) {
				case 'pager':
					agg.telecom.push({ system: 'pager', value: _this.pager });
					break;
				case 'phone':
					agg.telecom.push({ system: 'phone', value: _this.phone });
					break;
				case 'switch':
					agg.telecom.push({ system: 'switch', value: 'via switch' });
					break;
			}
		}
		AppService.update(agg).then(function () {
			$modalInstance.close();
		});
	};
}]);