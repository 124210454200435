
'use strict';

// remove "Don't make functions within a loop" warning
// jshint loopfunc:true

angular.module('medtasker').controller('RosterCloneCtrl', ['modalArgs', 'RosterService', 'AppService', '$q', '$timeout', '$scope', 'config', 'ResourceService', 'DialogService', '$modalInstance', 'moment', '_', function (modalArgs, RosterService, AppService, $q, $timeout, $scope, config, ResourceService, DialogService, $modalInstance, moment, _) {

	var _this = this;
	_this.ctx = modalArgs.ctx;
	_this.copiedRShifts = modalArgs.copiedRShifts;
	_this.schedules = config.shiftSchedules;
	_this.schedule = _this.ctx.selectedRShift.shift.schedule;
	_this.allCodes = false;
	_this.selCodes = {};

	_this.startDate = moment(_this.ctx.selectedRShift.period.start).add(1, 'day').toDate();
	_this.endDate = moment(_this.startDate).add(1, 'week').toDate();

	_this.campusCode = AppService.campusDisplayCode;

	_this.validateTimes = function () {
		$scope.cloneRoster.$setValidity('invalidTimes', +_this.startDate < +_this.endDate);
	};

	_this.scheduleChanged = function (sch) {
		_this.selCodes[sch.code] = !_this.selCodes[sch.code];
		if (_this.allCodes && !_this.selCodes[sch.code]) {
			_this.allCodes = false;
		}
		$scope.cloneRoster.$setValidity('noScheduleSelected', Object.keys(_this.selCodes).length > 0 && _.some(_this.selCodes, function (s) {
			return s;
		}));
	};

	_this.displayName = function (p) {
		var name = p ? AppService.displayName(p) : '';
		return name;
	};

	function init() {
		_this.selCodes[_this.schedule.code] = true;
	}

	init();

	_this.toggleAllCodes = function () {
		_this.allCodes = !_this.allCodes;
		_.forEach(_this.schedules, function (sch) {
			_this.selCodes[sch.code] = _this.allCodes;
		});
	};

	_this.clone = function () {
		var schedules = _.filter(_this.schedules, function (s) {
			return _this.selCodes[s.code];
		});
		RosterService.cloneRosterShifts(_this.copiedRShifts, schedules, _this.startDate, _this.endDate).then(function () {
			DialogService.openMessageDialog('Shifts cloned!', '', 'info', { duration: 1500 });
			$modalInstance.close();
		}, function (err) {
			var msg = void 0;
			if (err.status === 409) {
				msg = 'There was a conflict with existing shifts. You can only clone to unassigned shifts.';
				err = null;
			}
			DialogService.errorMessage('An error occurred. ' + msg, err);
		});
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};
}]);