'use strict';

angular.module('medtasker').controller('CcCtrl', ['_', 'moment', '$modalInstance', 'modalArgs', 'emptyRoles', '$scope', '$timeout', function (_, moment, $modalInstance, modalArgs, emptyRoles, $scope, $timeout) {
	// eslint-disable-line

	var _this = this;
	_this.group = modalArgs.group;
	_this.emptyRoles = emptyRoles;
	_this.primaryRA = _this.group.roleAssignments[0];

	_this.setPrimary = function (ra) {
		_this.primaryRA = ra === _this.primaryRA ? null : ra;
	};

	$timeout(function () {
		_this.focus = 0;
	}, 500);

	$scope.compose = modalArgs.compose; //eslint-disable-line

	_this.setCcs = function () {
		var recipients = {
			primary: _.find(_this.group.roleAssignments, function (ra) {
				return ra === _this.primaryRA;
			}),
			cc: _.filter(_this.group.roleAssignments, function (ra) {
				return ra !== _this.primaryRA;
			})
		};
		$modalInstance.close(recipients);
	};

	_this.down = function () {
		_this.focus++;
		if (_this.focus === _this.group.roleAssignments.length) {
			_this.focus = 0;
		}
		_this.setPrimary(_this.group.roleAssignments[_this.focus]);
	};

	_this.up = function () {
		_this.focus--;
		if (_this.focus === -1) {
			_this.focus = _this.group.roleAssignments.length - 1;
		}
		_this.setPrimary(_this.group.roleAssignments[_this.focus]);
	};

	//format role name consistently with compose
	_this.showRole = function (r) {
		var s = r.split(' ');
		if (s.length === 1 || s[0].trim() === s[1].trim()) {
			return s[0];
		}
		return s.slice(0, s.length - 1).join(' ').trim() + ' (' + s[s.length - 1].trim() + ')';
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};
}]);