'use strict';

/* global angular */
angular.module('medtasker.service').directive('charsLeft', [function () {
	'use strict';

	return {
		scope: {
			limit: '@',
			str: '='
		},
		template: '<span ng-class="{\'text-danger\': remaining < 0}">{{ remaining }}</span>', //jshint ignore:line
		restrict: 'E',
		link: function postLink(scope) {

			scope.$watch('str', function (val) {
				scope.remaining = val ? scope.limit - val.length : scope.limit;
			});
		}
	};
}]);

// Any letters will be removed
angular.module('medtasker.service').directive('onlyNumbers', [function () {
	'use strict';

	return {
		require: 'ngModel',
		link: function postLink(scope, element, attrs, ngModel) {
			ngModel.$parsers.push(function (value) {

				if (value) {
					value = value.replace(/[^0-9\.]/g, '');
				}

				element.val(value);
				return value;
			});
		}
	};
}]);

angular.module('medtasker.service').directive('enterKey', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 13) {
				scope.$apply(function () {
					scope.$eval(attrs.enterKey);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('tabKey', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 9 && !event.shiftKey) {
				scope.$apply(function () {
					scope.$eval(attrs.tabKey);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('ctrlG', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 71 && event.ctrlKey) {
				scope.$apply(function () {
					scope.$eval(attrs.ctrlG);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('shiftTab', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 9 && event.shiftKey) {
				scope.$apply(function () {
					scope.$eval(attrs.shiftTab);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('escKey', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 27) {
				scope.$apply(function () {
					scope.$eval(attrs.escKey);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('keyCursorDown', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 40) {
				scope.$apply(function () {
					scope.$eval(attrs.keyCursorDown);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('keyCursorLeft', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 37) {
				scope.$apply(function () {
					scope.$eval(attrs.keyCursorLeft);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('keyCursorRight', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 39) {
				scope.$apply(function () {
					scope.$eval(attrs.keyCursorRight);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('backspace', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 8) {
				scope.$apply(function () {
					scope.$eval(attrs.backspace);
				});
				// event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('keySpacebar', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 32) {
				scope.$apply(function () {
					scope.$eval(attrs.keySpacebar);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('keyCursorUp', [function () {
	'use strict';

	return function (scope, element, attrs) {
		element.bind('keydown', function (event) {
			if (event.which === 38) {
				scope.$apply(function () {
					scope.$eval(attrs.keyCursorUp);
				});
				event.preventDefault();
			}
		});
	};
}]);

angular.module('medtasker.service').directive('numericOnly', [function () {
	'use strict';

	return {
		require: 'ngModel',
		link: function link(scope, element, attr, ngModelCtrl) {
			function fromUser(text) {
				if (!text) {
					return text;
				}
				var transformedInput = text.toString().replace(/[^0-9]/g, '');
				if (transformedInput !== text) {
					ngModelCtrl.$setViewValue(transformedInput);
					ngModelCtrl.$render();
				}
				return transformedInput;
			}
			ngModelCtrl.$parsers.push(fromUser);
		}
	};
}]);

angular.module('medtasker.service').directive('decimalInput', [function () {
	return {
		require: 'ngModel',
		link: function link(scope, element, attr, ngModelCtrl) {
			function fromUser(text) {
				if (!text) {
					return text;
				}
				var transformedInput = text.toString().replace(/[^0-9\.]/g, '');
				if (transformedInput !== text) {
					ngModelCtrl.$setViewValue(transformedInput);
					ngModelCtrl.$render();
				}
				return transformedInput;
			}
			ngModelCtrl.$parsers.push(fromUser);
		}
	};
}]);

angular.module('medtasker.service').directive('timeRangeInput', [function () {
	'use strict';

	return {
		require: 'ngModel',
		link: function link(scope, element, attr, ngModelCtrl) {
			function fromUser(text) {
				if (!text) {
					return text;
				}
				var transformedInput = text.replace(/[^0-9^:^\-^,]/g, '');
				if (transformedInput !== text) {
					ngModelCtrl.$setViewValue(transformedInput);
					ngModelCtrl.$render();
				}
				return transformedInput;
			}
			ngModelCtrl.$parsers.push(fromUser);
		}
	};
}]);

angular.module('medtasker.service').directive('outsideClick', ['$document', function ($document) {
	'use strict';

	return {
		link: function link($scope, $element, $attributes) {
			var scopeExpression = $attributes.outsideClick;
			var onDocumentClick = function onDocumentClick(event) {
				var isChild = $element.find(event.target).length > 0;

				if (!isChild) {
					$scope.$apply(scopeExpression);
				}
			};

			$document.on('click', onDocumentClick);

			$element.on('$destroy', function () {
				$document.off('click', onDocumentClick);
			});
		}
	};
}]);

angular.module('medtasker.service').directive('focusMe', ['$timeout', '$parse', function ($timeout, $parse) {
	'use strict';

	return {
		//scope: true,   // optionally create a child scope
		link: function link(scope, element, attrs) {
			var model = $parse(attrs.focusMe);
			scope.$watch(model, function (value) {
				if (value === true) {
					$timeout(function () {
						element[0].focus();
					});
				}
			});
		}
	};
}]);