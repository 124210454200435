'use strict';

angular.module('medtasker').directive('mtTaskCheckbox', ['$log', function () {
	// eslint-disable-line
	return {
		scope: {
			checked: '=',
			disabled: '=',
			status: '=',
			onClick: '&',
			priority: '='
		},
		templateUrl: 'app/common/task-checkbox.html',
		replace: true,
		restrict: 'E',
		link: function link(scope, element) {
			element.on('click', function () {
				if (scope.disabled) {
					return;
				}
				if (angular.isFunction(scope.onClick)) {
					scope.onClick();
				}
			});
		}
	};
}]);