'use strict';

angular.module('medtasker').factory('CalltaskerAdminService', ['LogService', 'UrlService', '$http', function (LogService, UrlService, $http) {

  var _httpConfig = {
    headers: {
      'Content-type': 'application/json'
    }
  };

  var o = {

    init: function init() {},

    getCalltaskerConfigFromAPI: function getCalltaskerConfigFromAPI(calltaskerConfigProperty) {
      var queryParam = '?property=' + calltaskerConfigProperty;
      var url = UrlService.baseUrl() + '/api/v1/config/read-config-from-database' + queryParam;
      LogService.debug('Getting: ' + url, false, false, true);
      return $http.get(url);
    },

    getCalltaskerConfigForDevice: function getCalltaskerConfigForDevice(tasksetCollection, fromLocation) {
      var queryParam = '?tasksetcollection=' + tasksetCollection + '&fromlocation=' + fromLocation;
      var url = UrlService.baseUrl() + '/api/v1/config/device-specific-config' + queryParam;
      LogService.debug('Getting: ' + url, false, false, true);
      return $http.get(url);
    },

    putTaskOverride: function putTaskOverride(taskOverridesArray) {
      // Send to server
      var payload = taskOverridesArray;
      var queryParam = '?property=taskOverride';
      var url = UrlService.baseUrl() + '/api/v1/config/write-config-to-database' + queryParam;
      LogService.info('Putting: ' + url, false, false, true);
      return $http.put(url, payload, _httpConfig);
    }
  };

  return o;
}]);