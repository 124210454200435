'use strict';

angular.module('medtasker').controller('AddAnnotationCtrl', ['AppService', 'LogService', '$scope', 'modalArgs', function (AppService, LogService, $scope, modalArgs) {

	var _this = this;

	_this.annotations = modalArgs.annotations;
	_this.selectedAnnotation = null;
	_this.reason = null;
	_this.focus = 'event';

	_this.annotationSelected = function () {
		if (_this.selectedAnnotation.reasons && _this.selectedAnnotation.reasons.length) {
			_this.reasons = angular.copy(_this.selectedAnnotation.reasons);
			_this.reasons.push({
				display: 'Other'
			});
		} else {
			_this.reasons = [];
		}
	};

	_this.cancel = function () {
		$scope.inbox.cancelAddAnnotation();
	};

	_this.save = function (form) {
		if (form.$invalid) {
			_this.clicked = true;
			return;
		}
		var reason = _this.reason === 'Other' ? _this.otherReason : _this.reason;
		$scope.inbox.saveAnnotation(_this.selectedAnnotation, reason);
	};
}]);