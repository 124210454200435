'use strict';

angular.module('medtasker').controller('LoginCtrl', ['locations', 'AppService', '$modalInstance', 'EventService', 'InitService', 'LogService', 'AuthService', '$rootScope', '$window', '$modal', '$timeout', '$state', '$sce', 'config', 'hospitalCode', 'initModalMsg', 'appVersion', 'Title', '_', function (locations, AppService, $modalInstance, EventService, InitService, LogService, AuthService, $rootScope, $window, $modal, $timeout, $state, $sce, config, hospitalCode, initModalMsg, appVersion, Title, _) {

	var _this = this;
	_this.message = null;
	_this.failedAttemptsMsg = null;
	_this.failedLoginAttempts = 0;
	var noward = { id: 0, name: '--None--', order: -2 };
	var _storage = $window.localStorage;
	_this.ward = _storage.getItem('medtasker.ward');
	_this.campus = _storage.getItem('medtasker.campus');
	if (_this.campus) {
		_this.campus = angular.fromJson(_this.campus);
	}

	(function filterWards() {
		var filteredWardsByConfigLocCode = _.filter(locations, function (loc) {
			return loc.status === 'active' && loc.name.toLowerCase() !== 'temp ward' && loc.name.toLowerCase() !== 'no ward' && _.some(loc.physicalType.coding, function (c) {
				return c.code === 'wi';
			}) && loc.partOf && loc.partOf.identifier[0].value === hospitalCode;
		});
		//HACK: This ensures we still get wards if the hospital code is out of whack
		//ANDREW MADE ME DO IT!
		if (filteredWardsByConfigLocCode.length > 0) {
			_this.wards = filteredWardsByConfigLocCode;
		} else {
			_this.wards = _.filter(locations, function (loc) {
				return loc.status === 'active' && loc.name.toLowerCase() !== 'temp ward' && loc.name.toLowerCase() !== 'no ward' && _.some(loc.physicalType.coding, function (c) {
					return c.code === 'wi';
				});
			});
		}
		_this.wards.unshift(noward);
	})();

	function filterWardsSpecificToCampus(campusId) {
		_this.wardsForCurrentCampus = _.filter(_this.wards, function (loc) {
			return loc.status === 'active' && loc.name.toLowerCase() !== 'temp ward' && loc.name.toLowerCase() !== 'no ward' && _.some(loc.physicalType.coding, function (c) {
				return c.code === 'wi';
			}) && loc.partOf && loc.partOf.identifier[0].value === campusId;
		});
		_this.wardsForCurrentCampus.unshift(noward);
	}

	if (_this.campus) {
		filterWardsSpecificToCampus(_this.campus.identifier[0].value);
	}
	// remove the all from the campuses field
	_this.campuses = _.filter(AppService.campuses(), function (loc) {
		return loc.name.toLowerCase() !== 'all';
	});

	if (AuthService.isAuthenticated('ward')) {
		_this.ward = AuthService.getCurrentWard();
	}

	if (!_this.ward || !_.find(_this.wardsForCurrentCampus, function (w) {
		return w.name === _this.ward;
	})) {
		_this.ward = noward.name;
	}

	_this.wardLoggedIn = function () {
		return AuthService.isAuthenticated('ward') && AuthService.getCurrentWard();
	};

	_this.setCurrentWard = function (ward) {
		AuthService.setCurrentWard(ward);
	};

	_this.message = initModalMsg.value;

	_this.credentials = {};
	var u = _storage.getItem('medtasker.lastUsername');
	if (u) {
		_this.credentials.username = u;
	}

	_this.isTouchDevice = AppService.isTouchDevice;

	_this.wardName = function (w) {
		if (w.id === 0) {
			return $sce.trustAsHtml('<em>--None--</em>');
		}
		return w.name;
	};

	_this.formatCampus = AppService.formatCampus;

	_this.onCampusChange = function () {
		_this.ward = '--None--';
		filterWardsSpecificToCampus(_this.campus.identifier[0].value);
	};

	_this.selectCampusAndWard = function () {
		_this.message = null;
		Title.restore();
		var ward = null;
		if (_this.ward !== '--None--') {
			ward = _this.ward;
		}
		$rootScope.$broadcast('appLoadingPush', { source: 'login' });
		var loginTimeout = $timeout(function () {
			_this.message = 'Sorry - we can\'t seem to reach the Medtasker server. Please check your internet connection and contact IT if the problem persists.';
		}, 5000);
		$timeout.cancel(loginTimeout);
		var wardLoc = void 0;
		_storage.setItem('medtasker.campus', angular.toJson(_this.campus));
		_storage.setItem('medtasker.lastUsername', _this.credentials.username);
		if (ward) {
			_storage.setItem('medtasker.ward', ward);
			//when searching for ward id make sure we pick up ward that belongs to the currently selected campus
			wardLoc = _.find(AppService.ctx.locations, function (loc) {
				return loc.name === ward && loc.status === 'active' && loc.partOf.id === _this.campus.id;
			});
			if (wardLoc) {
				AuthService.setCurrentWard(ward);
				_storage.setItem('medtasker.wardId', wardLoc.id);
			}
		}
		config.init('force').then(function () {
			InitService.initBugsnag();
			LogService.log('appVersion=' + appVersion);
			LogService.log('config.clientVersion=' + config.clientVersion);
			var cv = 'MEDTASKER';
			cv = cv + '_VERSION'; //this weirdness is required to stop version being injected in minified code
			if (appVersion !== cv) {
				//in dev, ignore the version. It will be 'MEDTASKER_VERSION'
				if (AppService.compareVersion(appVersion, config.clientVersion) === 'lower') {
					LogService.log('client updated, reloading...');
					$window.location.reload(); // force refresh if version is lower than server says
					return;
				}
				LogService.log('No client update required1');
			}
			LogService.log('No client update required2');
			_this.failedLoginAttempts = 0;
			EventService.reset(); //clear any old event source
			EventService.addStandardListeners();
			// defaultCampusLoc is used to select default campus in the compose and
			// and directory controller. Previously this used to be set only when a valid
			// ward selected. Now with the campus becoming a mandatory field
			// setting this value for all logins
			config.setItem('defaultCampusLoc', _this.campus);
			InitService.initSessionStack();
			AppService.resetReady(); //force app service to reinit next time - gets user resources
			$rootScope.$broadcast('appLoadingPop', { source: 'login' });
			$modalInstance.close();
		}, function (err) {
			$rootScope.$broadcast('appLoadingPop', { source: 'login' });
			LogService.error('Login error. Couldn\'t load config: ' + angular.toJson(err));
		});
	};

	_this.login = function () {
		_this.message = null;
		Title.restore();
		var ward = null;
		if (_this.ward !== '--None--') {
			ward = _this.ward;
		}
		$rootScope.$broadcast('appLoadingPush', { source: 'login' });
		var loginTimeout = $timeout(function () {
			_this.message = 'Sorry - we can\'t seem to reach the Medtasker server. Please check your internet connection and contact IT if the problem persists.';
		}, 5000);
		AppService.login(_this.credentials, ward).then(function () {
			$timeout.cancel(loginTimeout);
			var wardLoc = void 0;
			_storage.setItem('medtasker.campus', angular.toJson(_this.campus));
			_storage.setItem('medtasker.lastUsername', _this.credentials.username);
			if (ward) {
				_storage.setItem('medtasker.ward', ward);
				//when searching for ward id make sure we pick up ward that belongs to the currently selected campus
				wardLoc = _.find(AppService.ctx.locations, function (loc) {
					return loc.name === ward && loc.status === 'active' && loc.partOf.id === _this.campus.id;
				});
				if (wardLoc) {
					_storage.setItem('medtasker.wardId', wardLoc.id);
				}
			}
			config.init('force').then(function () {
				InitService.initBugsnag();
				LogService.log('appVersion=' + appVersion);
				LogService.log('config.clientVersion=' + config.clientVersion);
				var cv = 'MEDTASKER';
				cv = cv + '_VERSION'; //this weirdness is required to stop version being injected in minified code
				if (appVersion !== cv) {
					//in dev, ignore the version. It will be 'MEDTASKER_VERSION'
					if (AppService.compareVersion(appVersion, config.clientVersion) === 'lower') {
						LogService.log('client updated, reloading...');
						$window.location.reload(); // force refresh if version is lower than server says
						return;
					}
					LogService.log('No client update required1');
				}
				LogService.log('No client update required2');
				_this.failedLoginAttempts = 0;
				EventService.reset(); //clear any old event source
				EventService.addStandardListeners();
				// defaultCampusLoc is used to select default campus in the compose and
				// and directory controller. Previously this used to be set only when a valid
				// ward selected. Now with the campus becoming a mandatory field
				// setting this value for all logins
				config.setItem('defaultCampusLoc', _this.campus);
				InitService.initSessionStack();
				AppService.resetReady(); //force app service to reinit next time - gets user resources
				$rootScope.$broadcast('appLoadingPop', { source: 'login' });
				$modalInstance.close();
			}, function (err) {
				$rootScope.$broadcast('appLoadingPop', { source: 'login' });
				LogService.error('Login error. Couldn\'t load config: ' + angular.toJson(err));
			});
		}, function (err) {
			$timeout.cancel(loginTimeout);
			$rootScope.$broadcast('appLoadingPop', { source: 'login' });

			switch (err.status) {
				case 401:
					_this.message = err.data.message || 'Unknown username/password';
					break;
				case 403:
					_this.message = 'Unfortunately you aren\'t allowed access to this page. Please contact IT.';
					break;
				case 404:
					LogService.error('Login error: ' + angular.toJson(err));
					_this.message = 'Sorry  - we can\'t contact the Medtasker server. Please check your internet connection and contact IT if the problem persists.';
					break;
				case -1:
					LogService.error('Login error: ' + angular.toJson(err));
					_this.message = 'Sorry, the auth server can\'t be reached. Please contact IT.';
				default:
					LogService.error('Login error: ' + angular.toJson(err));
					_this.message = 'Sorry  - there was a problem with logging in. If this occurs more than twice, please contact IT.';
			}
		});
	};
	_this.closeCampusSelectorDialog = function () {
		// do not allow closing of this dialog box .
		// users needs to select an option
	};

	_this.closeLoginDialog = function () {
		LogService.debug('login dialog dismissing...');
		$modalInstance.dismiss();
	};
}]);