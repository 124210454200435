
'use strict';

angular.module('medtasker').controller('RoleCtrl', ['ctx', 'ShiftService', 'AppService', 'DialogService', 'RoleService', 'moment', 'config', '$state', '$stateParams', '$log', '_', '$modal', function (ctx, ShiftService, AppService, DialogService, RoleService, moment, config, $state, $stateParams, $log, _, $modal) {
	// eslint-disable-line

	var _this = this;
	_this.ctx = ctx;
	_this.selectTeam = RoleService.selectTeam;
	_this.config = config;
	_this.enableDelete = false;
	_this.campuses = AppService.campuses();
	_this.teamsByCampus = function () {
		return ShiftService.teamsByCampus(ctx);
	};
	_this.formatCampus = ShiftService.formatCampus;
	if (config.defaultCampusLoc && !_this.ctx.selectedTeam) {
		_this.ctx.selectedCampus = _.find(_this.campuses, { 'id': config.defaultCampusLoc.id });
	}
	_this.campusCode = AppService.campusDisplayCode;

	function teamCode() {
		return _.find(_this.ctx.selectedTeam.identifier, function (i) {
			return i.system.startsWith(config.hospitalSystemPrefix);
		}).value;
	}

	function loadTeamShifts() {
		_this.enableDelete = false;
		AppService.loadShifts(null, ctx.selectedTeam).then(function () {
			_this.enableDelete = true;
		});
	}

	var args = {
		parent: _this
	};
	var dialogOptions = {
		templateUrl: 'app/role/role-dialog.html',
		// size: 'lg',
		backdrop: true,
		controller: 'RoleDialogCtrl as ctrl',
		resolve: {
			modalArgs: function modalArgs() {
				return args;
			}
		}
	};

	_this.newRoleDialog = function () {
		args.role = null;
		args.team = _this.ctx.selectedTeam;
		args.roles = _this.ctx.teamRoles;
		$modal.open(dialogOptions);
	};

	_this.editRoleDialog = function (role) {
		args.role = role;
		args.team = _this.ctx.selectedTeam;
		args.roles = _this.ctx.teamRoles;
		$modal.open(dialogOptions);
	};

	_this.deleteRole = function (role) {
		if (!_this.canDelete(role)) {
			return;
		}
		RoleService.deleteRole(role).then(function () {
			//refresh roles
			_this.selectTeam(ctx.selectedTeam);
		});
	};

	_this.showStart = function (s) {
		return moment.utc(s._milliseconds).format('HH:mm');
	};

	_this.teamsOnly = RoleService.teamsOnly;

	_this.goTeam = function (t) {
		_this.ctx.selectedTeam = t;
		_this.onTeamChange();
	};

	_this.onCampusChange = function () {
		ctx.selectedTeam = null;
	};

	_this.onTeamChange = function () {
		var id = null;
		if (ctx.selectedTeam) {
			id = teamCode();
		}
		$state.go($state.current, { team: id });
	};

	if ($stateParams.team) {
		ctx.selectedTeam = _.find(_this.ctx.organizations, function (org) {
			return _.some(org.identifier, function (id) {
				return id.system && id.system.startsWith(config.hospitalSystemPrefix) && id.value === $stateParams.team;
			});
		});
		_this.selectTeam(ctx.selectedTeam);
	}
	loadTeamShifts();

	_this.showTelecom = function (role, system) {
		var t = _.find(role.telecom, { 'system': system });
		if (t) {
			return t.value;
		}
		return '';
	};

	_this.cantDeleteMessage = function () {
		DialogService.openMessageDialog('Can\'t delete this role', 'This role has associated shifts. To delete it, first remove all associated shifts using the shift editor.', 'warning');
	};

	_this.canDelete = function (role) {
		var now = moment();
		role.canDelete = !_.some(_this.ctx.shifts, function (s) {
			return s.role && s.role.id === role.id && (!s.validPeriod.end || s.validPeriod.end && +s.validPeriod.end >= +now);
		});
		return role.canDelete;
	};

	// Filter
	_this.seniority = function (role) {
		return _.indexOf(config.mtRoleLevels, _.find(config.mtRoleLevels, { 'code': role.level.code }));
	};
}]);