
'use strict';

angular.module('medtasker').controller('PharmacyReportCtrl', ['ctx', 'ResourceService', 'AuthService', 'AppService', 'RosterService', '$q', '$rootScope', '$scope', '_', 'DialogService', 'moment', 'config', '$timeout', '$interval', '$log', '$window', 'wards', function (ctx, ResourceService, AuthService, AppService, RosterService, $q, $rootScope, $scope, _, DialogService, moment, config, $timeout, $interval, $log, $window, wards) {
	// eslint-disable-line
	var _this = this;
	var searchedWards = {};
	var MTSYSID = '7c921e8a-f4c5-11e6-bc64-92361f002671'; //This is the system practitioner on Medtasker
	_this.encounterTasks = {};
	_this.eocNote = {};
	_this.losWard = {};
	_this.los = {};
	_this.wards = _.filter(wards, function (w) {
		return w.identifier[0].value && w.identifier[0].value !== 'TEMP_WARD' && w.identifier[0].value !== 'NO_WARD';
	});
	_this.wardEncounters = {};
	_this.selectedWards = _.filter(wards, function (w) {
		return _.some(AppService.userRAs, function (ra) {
			return _.some(ra.role.associatedLocation, function (l) {
				return l.id === w.id;
			});
		});
	});
	_this.printScreen = function () {
		$window.print();
	};
	_this.displayName = AppService.displayName;
	_this.today = moment();
	//We need to keep encounters and tasks isolated from one another, otherwise included older versions of encounters contaminate the data
	_this.isoCtxTasks = { //isolated context for tasks - add vase resourc es to stop them being lazy loaded
		locations: ctx.locations,
		organizations: ctx.organizations
	};
	_this.isoCtxEncs = { //isolated context for encounters
		locations: ctx.locations,
		organizations: ctx.organizations
	};

	_this.locationAtLastStatus = function (en, task) {
		var loc = void 0;
		var a = _.findLast(task.action, function (act) {
			return act.action.code.indexOf(_.lowerCase(task.status)) > -1;
		});
		var t = a.actionedAt;
		_.forEach(_.values(en.episodeOfCare._encounters), function (enc) {
			if (+enc.period.start <= +t && (!enc.period.end || +enc.period.end >= +t)) {
				_.forEach(enc.location, function (l) {
					if (+l.period.start <= +t && (!l.period.end || +l.period.end >= +t)) {
						loc = l.location.partOf.name;
						return;
					}
				});
			}
			if (loc) {
				return;
			}
		});
		return loc || '?';
	};

	function lengthOfStayWard(en) {
		var encs = _.sortBy(_.values(en.episodeOfCare._encounters), function (e) {
			return +e.period.start;
		}); //sort eoc encounters in reverse time order
		var last = encs[encs.length - 1];
		var lastWard = last.location[last.location.length - 1].location.partOf;
		//iterate through encounters in reverse order, searching the encounter locations backwards until we find the point at which the patient
		//was somewhere else than the current ward.
		var encounter = void 0;
		for (var i = encs.length - 1; i >= 0; i--) {
			encounter = encs[i];
			var prevLoc = _.findLast(encounter.location, function (l) {
				return l.location.partOf.id !== lastWard.id;
			});
			if (prevLoc) {
				return moment().diff(prevLoc.period.end, 'days');
			}
		}
		return moment().diff(encounter.location[0].period.start, 'days');
	}

	function lengthOfStay(eoc) {
		return moment().diff(eoc.period.start, 'days');
	}

	function isPharmacyTask(t) {
		return config.pharmacyTaskCodes.indexOf(t.serviceRequested[0].coding[0].code) > -1;
	}

	function setWardEncounters(w) {
		_this.wardEncounters[w.id] = _.filter(_this.isoCtxEncs.encounters, function (e) {
			return e.location[e.location.length - 1].location.partOf.id === w.id;
		});
		_this.wardEncounters[w.id].forEach(function (e) {
			// we attach the completed tasks for prior encounters on this episode of care to the current encounter id
			// worksheet needs to show all completed tasks (even if completed whilst pt on different team - hence previous encounter)
			_this.encounterTasks[e.id] = _.filter(_this.isoCtxTasks.tasks, function (t) {
				return t.encounter && t.encounter.episodeOfCare.id === e.episodeOfCare.id && isPharmacyTask(t);
			});
			_this.eocNote[e.episodeOfCare.Id] = e.episodeOfCare.note;
			_this.losWard[e.id] = lengthOfStayWard(e);
			_this.los[e.episodeOfCare.id] = lengthOfStay(e.episodeOfCare);
		});
	}

	function updateForWards() {
		var promises = [];
		if (_this.selectedWards.length === 0) {
			return;
		}
		$rootScope.$broadcast('appLoadingPush', { source: 'pharmReport' });
		_this.selectedWards.forEach(function (w) {
			if (!searchedWards[w.id]) {
				var c = {
					ward: w.id,
					size: 10000,
					include: 'Encounter:EpisodeOfCare,Encounter:Patient'
				};
				var p1 = AppService.searchEncounters(c, 'fhir', 'addToContext', _this.isoCtxEncs).then(function () {
					searchedWards[w.id] = true;
				});
				var p2 = AppService.searchTasks(c, 'addToContext', _this.isoCtxTasks);
				promises.push(p1);
				promises.push(p2);
			}
		});
		$q.all(promises).then(function () {
			if (_this.isoCtxEncs.episodesOfCare) {
				var eocids = [];
				var eocmap = {};
				_this.isoCtxEncs.episodesOfCare.forEach(function (eoc) {
					if (eoc.note) {
						_this.eocNote[eoc.id] = eoc.note;
					}
					eocids.push(eoc.id);
					eocmap[eoc.id] = eoc;
				});
				AppService.search('Encounter', 'episodeOfCare=' + eocids.join()).then(function (encs) {
					//we need to get all the other encounters for the episodes of care so that we can accurately calculate patients' LOS
					var encounters = [];
					encs.forEach(function (enc) {
						// we need to fetch the completed tasks for the prior encounters for these episodes of care & for the current encounter where they have been completed > 24hrs ago
						encounters.push(enc.id);
						eocmap[enc.episodeOfCare.id]._encounters = eocmap[enc.episodeOfCare.id]._encounters || {}; //for convenience, add the array of encounters to each EOC
						eocmap[enc.episodeOfCare.id]._encounters[enc.id] = enc;
					});
					var p = encounters.length > 0 ? ResourceService.search(_this.isoCtxTasks, 'MtTask', 'encounter=' + encounters.join() + '&status=completed&requester=' + MTSYSID) : AppService.resolve();
					p.then(function () {
						_this.selectedWards.forEach(function (w) {
							setWardEncounters(w);
						});
						$rootScope.$broadcast('appLoadingPop', { source: 'pharmReport' });
					});
				});
			} else {
				$rootScope.$broadcast('appLoadingPop', { source: 'pharmReport' });
			}
		}, function (err) {
			DialogService.errorMessage('Could not load data for ward', err);
			$rootScope.$broadcast('appLoadingPop', { source: 'pharmReport' });
		});
	}

	_this.taskStatus = function (t) {
		return t.status === 'sent' ? 'New' : t.status.charAt(0).toUpperCase() + t.status.substr(1);
	};

	_this.displayName = AppService.displayName;

	_this.addWard = function (w) {
		if (!_.find(_this.selectedWards, w)) {
			_this.selectedWards.push(w);
			_this.ward = null;
			updateForWards();
		}
	};

	_this.removeWard = function (w) {
		_.remove(_this.selectedWards, w);
	};

	_this.displayWardShort = function (w) {
		return config.wardDisplayField === 'name' ? w.name : w.identifier[0].value;
	};

	_this.displayWardFull = function (w) {
		return w.identifier[0].value + ' - ' + w.name;
	};

	_this.encounterBed = function (e) {
		return e.location[e.location.length - 1].location.name;
	};

	_this.saveNote = function (eoc) {
		ResourceService.get(_this.isoCtxEncs, 'EpisodeOfCare', eoc.id).then(function (ne) {
			ne.note = _this.eocNote[eoc.id];
			AppService.update(ne).then(function () {
				$scope.pharmacyreport[eoc.id + '-note'].$setPristine();
			}, function (err) {
				DialogService.errorMessage('Couldn\'t save note', err);
			});
		}, function (err) {
			DialogService.errorMessage('Couldn\'t save note', err);
		});
	};

	_this.revertNote = function (eoc) {
		_this.eocNote[eoc.id] = eoc.note;
	};

	_this.displayNote = function (n) {
		return n ? n.replace(/(\r\n|\n|\r)/gm, '<br>') : '';
	};

	_this.formattedPatientName = function (name) {
		var prefix = '';
		if (name.use) {
			prefix = name.use === 'official' ? '' : '(' + name.use + ') ';
		}
		return prefix + name.given.join(' ') + ' ' + name.family.join(' ');
	};

	// Uses task actions to determine who the last practitioner was to complete a task
	// If the task has not been completed, it returns the practitioner who received the task
	// Use case:
	// - prac1 filling role A at time of auto task generation.
	// - prac2 who is now filling role A completes task (note the task recipient is still prac1).
	// - task list needs to show the practitioner who actually completed the task (prac2).
	_this.lastPractitionerToComplete = function (task) {
		var c = _.findLast(task.action, function (a) {
			return a.action.code === 'completed';
		});
		return c ? c.practitioner : task.recipient[0];
	};

	$interval(function () {
		_this.printTime = moment();
	}, 1000);

	updateForWards();
}]);