'use strict';

angular.module('medtasker').controller('TeamCtrl', ['$scope', 'ctx', 'AppService', 'ResourceService', '$state', '$log', 'moment', '$modal', '_', function ($scope, ctx, AppService, ResourceService, $state, $log, moment, $modal, _) {

	var _this = this;

	_this.ctx = ctx;

	_this.viewPeriod = {
		start: moment().subtract(3, 'months').toDate(),
		end: moment().add(3, 'months').toDate()
	};
	_this.selectedTeam = null;
	_this.selectedRotation = null;
	_this.teamNodes = null; // parent/child list of teams

	_this.rotations = [];
	_this.nonMembers = [];
	_this.teamAssignments = _this.ctx.teamAssignments;

	function _updateTeams() {
		// Reorder the teams list by parents, each parent followed by its subteams.
		var teams = {};
		_.forEach(ctx.organizations, function (org) {
			if (org.type === 'team') {
				teams[org.id] = teams[org.id] || { team: org, subteams: [] };
			} else if (org.type === 'subteam') {
				teams[org.partOf.id] = teams[org.partOf.id] || { team: org.partOf, subteams: [] };
				teams[org.partOf.id].subteams.push({ team: org, subteams: [] });
			}
		});

		var nodes = [];
		for (var id in teams) {
			nodes.push(teams[id]);
			nodes = nodes.concat(teams[id].subteams);
		}

		_this.teamNodes = nodes;
	}

	_updateTeams();

	_this.selectTeam = function (team) {
		_this.selectedTeam = team;
		_this.selectedRotation = null;
		_this.rotations = _.filter(_this.ctx.rotations, { organization: { id: team.id } });
		_this.nonMembers = _this.updateNonMembers(team);
	};

	_this.selectRotation = function (r) {
		_this.selectedRotation = r;
	};

	// Create a Shift RoleAssignment for the practitioner and the selected Role
	_this.createTeamAssignment = function (p) {
		var ta = ResourceService.newTeamAssignment(_this.selectedTeam, _this.selectedRotation.level, p, _this.selectedRotation.period);
		AppService.create(ta).then(function () {
			_this.nonMembers = _this.updateNonMembers(_this.selectedTeam);
		});
	};

	_this.removeTeamAssignment = function (ta) {
		AppService.delete(ta).then(function () {
			_this.nonMembers = _this.updateNonMembers(_this.selectedTeam);
		});
	};

	_this.updateNonMembers = function (team) {
		// First get all members of the team into a hastable
		var members = {};
		_.forEach(_this.ctx.teamAssignments, function (ta) {
			if (ta.organization.id === team.id) {
				members[ta.practitioner.id] = true;
			}
		});
		// then get the non members
		var nonMembers = _.filter(_this.ctx.practitioners, function (p) {
			return angular.isUndefined(members[p.id]);
		});
		return nonMembers;
	};

	_this.delete = function (res) {
		AppService.delete(res).then(function () {
			$log.debug('Deleted res');
		}, function (err) {
			$log.error('Error in delete: ' + err);
		});
	};

	_this.createTeamDialog = function () {
		var options = {
			templateUrl: 'app/team/team-dialog.html',
			backdrop: true,
			controller: 'TeamDialogCtrl as ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						mode: 'team',
						locations: _this.ctx.locations,
						organizations: _this.ctx.organizations
					};
				}
			}
		};
		var instance = $modal.open(options);
		instance.result.then(function () {
			_updateTeams();
		});
	};

	_this.createRotationDialog = function (team) {
		var options = {
			templateUrl: 'app/team/team-rotation-dialog.html',
			size: 'lg',
			backdrop: true,
			controller: 'TeamDialogCtrl as ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						mode: 'rotation',
						team: team
					};
				}
			}
		};
		var instance = $modal.open(options);
		instance.result.then(function () {
			_this.teams = _updateTeams();
		});
	};

	// Filter
	_this.teamsOnly = function (org) {
		return org.type === 'team' || org.type === 'subteam';
	};

	// Filter
	_this.filterByTeamAndLevel = function (ta) {
		// Only keep TeamAssignments for the selected team and level
		return _this.selectedRotation !== null && ta.organization.id === _this.selectedTeam.id && ta.level.code === _this.selectedRotation.level.code;
	};
}]);