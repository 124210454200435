'use strict';
/* global angular */

angular.module('stopwatch', []).constant('SW_DELAY', 1000).factory('stopwatch', ['SW_DELAY', '$interval', function (SW_DELAY, $interval) {
	var data = {
		value: 0
	};
	var stopwatch = null;

	var start = function start() {
		stopwatch = $interval(function () {
			data.value++;
			start();
		}, SW_DELAY, 1);
	};

	var stop = function stop() {
		$interval.cancel(stopwatch);
		stopwatch = null;
	};

	var reset = function reset() {
		stop();
		data.value = 0;
	};

	var restart = function restart() {
		stop();
		data.value = 0;
		start();
	};

	return {
		data: data,
		start: start,
		stop: stop,
		reset: reset,
		restart: restart
	};
}]);