'use strict';

angular.module('medtasker').factory('UserService', ['AppService', 'UrlService', 'config', '$http', function (AppService, UrlService, config, $http) {

	var _httpConfig = {
		headers: {
			'Content-type': 'application/json'
		}
	};

	var o = {

		init: function init() {},

		searchUsers: function searchUsers(term, roles) {
			term = term || '';
			roles = roles || [];
			var url = '?pageSize=10000';
			if (term.length > 0) {
				url = url + ('&usernamePrefix=' + term);
			}
			if (roles.length > 0) {
				url = url + ('&role=' + roles.join(','));
			}
			return $http.get(UrlService.userUrl() + url).then(function (res) {
				return AppService.resolve(res.data);
			});
		},

		updateUser: function updateUser(user) {
			return $http.put(UrlService.userUrl() + ('/' + user.username), user, _httpConfig);
		},

		createUser: function createUser(user) {
			return $http.post(UrlService.userUrl(), user, _httpConfig);
		},

		deleteUser: function deleteUser(username) {
			return $http.delete(UrlService.userUrl() + ('/' + username));
		},

		getUser: function getUser(username) {
			return $http.get(UrlService.userUrl() + ('/' + username)).then(function (res) {
				return AppService.resolve(res.data);
			});
		}
	};
	return o;
}]);