'use strict';

angular.module('medtasker').controller('AggregateCloneCtrl', ['AggregateService', 'AppService', 'DialogService', 'moment', '$modalInstance', 'modalArgs', '$q', '_', '$log', function (AggregateService, AppService, DialogService, moment, $modalInstance, modalArgs, $q, _, $log) {
	// eslint-disable-line

	var _this = this;
	_this.ctx = modalArgs.ctx;
	_this.aggregate = modalArgs.aggregate;
	_this.day = modalArgs.day;
	_this.days = modalArgs.days;
	_this.compatibleDays = {};
	_this.selDays = {};
	_this.allDaysSet = true;

	function _compatibleDays() {
		var compatibleDays = {};

		// Shifts for the aggregate
		var shifts = _.filter(_this.ctx.shifts, { aggregate: { id: _this.aggregate.id } });

		// For each day
		for (var i = 0; i < _this.days.length; i++) {
			var day = _this.days[i];
			if (day.code !== _this.day.code) {
				var dayMatch = true;
				// For each shift in the clone aggregate
				for (var j = 0; j < shifts.length; j++) {
					var dstShift = AggregateService.correspondingShift(shifts[j], day);
					if (!dstShift) {
						dayMatch = false;
						break;
					}
				}
				if (dayMatch) {
					compatibleDays[day.code] = true;
				}
			}
		}
		return compatibleDays;
	}

	(function init() {
		_this.compatibleDays = _compatibleDays();
		_this.disableSelectAll = false;
		var d = _.keys(_this.compatibleDays).length;
		if (d > 0 && d < _this.days.length - 1) {
			_this.message = 'Some schedules are unavailable to clone to because they lack matching shifts or shifts are already linked.';
		} else if (d === 0) {
			_this.message = 'No schedules are available to clone to. Either there are no matching shifts, or the shifts are already linked.';
			_this.disableSelectAll = true;
			_this.valid = false;
		}
		_this.selDays = angular.copy(_this.compatibleDays);
	})();

	_this.clone = function () {
		var days = _.reduce(_this.selDays, function (d, val, key) {
			if (val) {
				d.push(_.find(_this.days, { code: key }));
			}
			return d;
		}, []);

		var promises = [];
		_.forEach(days, function (day) {
			promises.push(AggregateService.clone(_this.aggregate, day));
		});
		$q.all(promises).then(function () {
			DialogService.openMessageDialog('Success', 'Linked shifts were successfully cloned', 'info', { duration: 1000, hideFooter: true, size: 'sm' });
			AppService.search('MtAggregate').then(function () {
				$modalInstance.close();
			});
		}, function (err) {
			DialogService.errorMessage('An error occurred trying to clone the aggregate.', err);
			$log.error('Error cloning aggregate: ' + angular.toJson(err));
		});
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.toggleDay = function (dayCode) {
		_this.selDays[dayCode] = !_this.selDays[dayCode];
		if (_this.allDaysSet && !_this.selDays[dayCode]) {
			_this.allDaysSet = false;
		}
	};

	_this.resetAllDays = function () {
		_this.allDaysSet = !_this.allDaysSet;
		if (_this.allDaysSet) {
			_this.selDays = angular.copy(_this.compatibleDays);
		} else {
			_this.selDays = {};
		}
	};
}]);