'use strict';

angular.module('medtasker').filter('formatDuration', [function () {
	return function (duration) {
		//if (typeof duration !== moment.duration) {
		//	$log.error('filter formatDuration requires a moment.duration object');
		//	return 'n/a';
		//}
		// NOTE: This filter doesn't handle negative durations
		var hours = duration.days() * 24 + duration.hours();
		return sprintf('%02d:%02d', hours, duration.minutes()); // eslint-disable-line no-undef
	};
}]);

angular.module('medtasker').filter('formatHours', [function () {
	return function (duration) {
		var hours = duration.days() * 24 + duration.hours() + duration.minutes() / 60;
		return hours;
	};
}]);

angular.module('medtasker').filter('capitalise', [function () {
	return function (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
}]);