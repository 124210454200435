'use strict';

/* global angular */
angular.module('medtasker.service').factory('DecoratorService', ['config', 'moment', '_', function (config, moment, _) {
	'use strict';

	function isEscalated(task) {
		if (angular.isDefined(task.escalated)) {
			return task.escalated;
		}
		// legacy handling
		var esc = false;
		for (var i = 0; i < task.action.length; i++) {
			if (task.action[i].action === 'escalated') {
				esc = true;
			}
			if (['forwarded', 'stolen', 'reassigned', 'accepted', 'started', 'completed', 'cancelled'].indexOf(task.action[i].action) > -1) {
				esc = false;
			}
		}
		return esc;
	}

	//The DecoratorService decorates entities with metadata to assist with filtering and classification
	//Metadata is an object named $metadata.
	var o = {
		decorateTask: function decorateTask(task, ctx) {
			var u = ctx.$userData;
			if (!u) {
				return;
			}
			var f = task.$metadata = {};

			var yesterday = moment().subtract(24, 'hours');

			f.generated = task.requester.identifier && task.requester.identifier[0].value.toLowerCase() === 'medtasker_system';

			f.active = task.status === 'sent' || task.status === 'accepted' || task.status === 'started';

			f.completedOrCancelled = task.status === 'completed' || task.status === 'cancelled';

			f.ccRecipient = _.some(task.cc, function (cc) {
				return _.some(u.userRoleIds, function (rid) {
					return cc.role.id === rid;
				});
			});

			var inbox = _.some(task.recipientRole, function (role) {
				return (// Check all recipient roles
					_.some(u.userRoleIds, function (rid) {
						return rid === role.id;
					})
				);
			}) || f.ccRecipient || _.some(task.action, function (a) {
				return a.targetRole && _.some(u.userRoleIds, function (rid) {
					return rid === a.targetRole.id;
				});
			}) && (f.active || +task.dateSent > +yesterday);

			f.systemTasks = inbox && f.generated;

			f.inbox = inbox && (!f.generated || _.some(task.action, function (a) {
				return ['forwarded', 'stolen', 'reassigned'].indexOf(a.action.code) > -1 && a.practitioner && a.practitioner.identifier[0].value.toLowerCase() !== 'medtasker_system';
			}));

			f.teamInbox = !f.generated && _.some(task.recipientRole, function (role) {
				return (// Check all recipient roles
					_.some(u.userTeamIds, function (tid) {
						return tid === role.organization.id;
					})
				);
			});

			f.sentItems = (f.active || f.completedOrCancelled && task.action[task.action.length - 1].actionedAt.isAfter(yesterday)) && (task.requester.id === u.userId || _.some(task.action, function (a) {
				return (a.action.code === 'forwarded' || a.action.code === 'reassigned') && a.practitioner && a.practitioner.id === u.userId;
			}));

			f.patientsTasks = !f.generated && _.some(u.userEncounterIds, function (eid) {
				return task.encounter && task.encounter.id === eid;
			});

			f.wardTasks = !f.generated && task.encounter && task.encounter.location && task.encounter.location[task.encounter.location.length - 1].location && task.encounter.location[task.encounter.location.length - 1].location.partOf && task.encounter.location[task.encounter.location.length - 1].location.partOf.id === u.ward;

			f.recipient = task && (_.some(task.recipient, function (r) {
				return r.id === u.userId;
			}) || // Check all recipients
			_.some(task.recipientRole, function (role) {
				return (// Check all recipient roles
					_.some(u.userRoleIds, function (rid) {
						return rid === role.id;
					})
				);
			}));

			f.priorTasks = moment(task.lastUpdated).isAfter(yesterday) && (_.some(task.recipient, function (r) {
				return r.id === u.userId;
			}) && // Check all recipients
			!_.some(task.recipientRole, function (role) {
				return (// Check all recipient roles
					_.some(u.userRoleIds, function (rid) {
						return rid === role.id;
					})
				);
			}) || _.some(task.cc, function (cc) {
				return cc.practitioner.id === u.userId;
			})) && !_.some(task.cc, function (cc) {
				return _.some(u.userRoleIds, function (rid) {
					return cc.role.id === rid;
				});
			});

			f.declinedCc = _.some(task.cc, function (cc) {
				return (cc.practitioner.id === u.userId || _.some(u.userRoleIds, function (rid) {
					return rid === cc.role.id;
				})) && cc.status === 'declined';
			});

			f.acknowledgedCc = _.some(task.cc, function (cc) {
				return (cc.practitioner.id === u.userId || _.some(u.userRoleIds, function (rid) {
					return rid === cc.role.id;
				})) && cc.status === 'acknowledged';
			});

			f.redirectedFromUser = !f.recipient && // task was redirected from user if the user is not the current recipient by role or practitioner but the task was previously sent or redirected to the user
			_.some(task.action, function (a) {
				return ['sent', 'forwarded', 'stolen', 'reassigned'].indexOf(a.action.code) > -1 && (a.targetPractitioner && a.targetPractitioner.id === u.userId || _.some(u.userRoleIds, function (rid) {
					return a.targetRole && rid === a.targetRole.id;
				}));
			});

			f.sender = task.requester.id === u.userId;

			f.archived = f.completedOrCancelled || // This really means 'archived from the point of view of my inbox and generated tasks inbox'. Other inboxes simply use completed/cancelled as their criteria for archiving.
			f.declinedCc && !f.recipient || f.redirectedFromUser && !f.ccRecipient;

			f.escalated = isEscalated(task);

			f.ccAcknowledgedCount = _.filter(task.cc, function (cc) {
				return cc.status === 'acknowledged';
			}).length;

			f.ccDeclinedCount = _.filter(task.cc, function (cc) {
				return cc.status === 'declined';
			}).length;
		},
		decorateTasks: function decorateTasks(tasks, ctx) {
			_.forEach(tasks, function (task) {
				o.decorateTask(task, ctx);
			});
		}
	};
	return o;
}]);