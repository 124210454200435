'use strict';

// jshint ignore: start
/*
 * angular-confirm
 * https://github.com/Schlogen/angular-confirm
 * @version v1.2.1 - 2015-11-18
 * @license Apache
 */
angular.module('medtasker').controller('ConfirmModalCtrl', ['$scope', '$modalInstance', 'data', function ($scope, $modalInstance, data) {
	/*eslint-disable */
	$scope.data = angular.copy(data);

	$scope.ok = function () {
		$modalInstance.close();
	};

	$scope.cancel = function () {
		$modalInstance.dismiss('cancel');
	};
	/*eslint-enable */
}]).value('$confirmModalDefaults', {
	template: '<div class="modal-header"><h3 class="modal-title">{{data.title}}</h3></div>' + '<div class="modal-body">{{data.text}}</div>' + '<div class="modal-footer">' + '<button class="btn btn-primary" ng-click="ok()">{{data.ok}}</button>' + '<button class="btn btn-default" ng-click="cancel()">{{data.cancel}}</button>' + '</div>',
	controller: 'ConfirmModalCtrl',
	defaultLabels: {
		title: 'Confirm',
		ok: 'OK',
		cancel: 'Cancel'
	}
}).factory('$confirm', ['$modal', '$confirmModalDefaults', function ($modal, $confirmModalDefaults) {
	return function (_data, settings) {
		var defaults = angular.copy($confirmModalDefaults);
		settings = angular.extend(defaults, settings || {});

		_data = angular.extend({}, settings.defaultLabels, _data || {});

		if ('templateUrl' in settings && 'template' in settings) {
			delete settings.template;
		}

		settings.resolve = {
			data: function data() {
				return _data;
			}
		};

		return $modal.open(settings).result;
	};
}]).directive('confirm', ['$confirm', function ($confirm) {
	return {
		priority: 1,
		restrict: 'A',
		scope: {
			confirmIf: '=',
			ngClick: '&',
			confirm: '@',
			confirmSettings: '=',
			confirmTitle: '@',
			confirmOk: '@',
			confirmCancel: '@'
		},
		link: function link(scope, element) {

			element.unbind('click').bind('click', function ($event) {

				$event.preventDefault();

				if (angular.isUndefined(scope.confirmIf) || scope.confirmIf) {

					var data = { text: scope.confirm };
					if (scope.confirmTitle) {
						data.title = scope.confirmTitle;
					}
					if (scope.confirmOk) {
						data.ok = scope.confirmOk;
					}
					if (scope.confirmCancel) {
						data.cancel = scope.confirmCancel;
					}
					$confirm(data, scope.confirmSettings || {}).then(scope.ngClick);
				} else {

					scope.$apply(scope.ngClick);
				}
			});
		}
	};
}]);