
'use strict';

angular.module('medtasker').controller('RoleGroupCtrl', ['ctx', 'ShiftService', 'AppService', 'DialogService', 'RoleService', 'ResourceService', 'moment', 'config', '$state', '$stateParams', 'LogService', '_', '$modal', function (ctx, ShiftService, AppService, DialogService, RoleService, ResourceService, moment, config, $state, $stateParams, LogService, _, $modal) {
	// eslint-disable-line

	var _this = this;
	_this.ctx = ctx;
	_this.selectTeam = RoleService.selectTeam;
	_this.config = config;
	_this.campusCode = AppService.campusDisplayCode;
	_this.teamsWithRoles = [];

	ResourceService.medtaskerProcFhir(_this.ctx, 'teams-with-roles', null, 'Organization').then(function (data) {
		_this.teamsWithRoles.length = 0;
		Array.prototype.push.apply(_this.teamsWithRoles, data.data);
	});

	_this.newRoleGroup = function () {
		var options = {
			templateUrl: 'app/role/rolegroup-new.html',
			// size: 'lg',
			backdrop: true,
			controller: 'RoleGroupNewCtrl',
			controllerAs: 'ctrl'
		};
		var instance = $modal.open(options);
		instance.result.then(function (res) {
			_this.selectedRoleGroup = res;
		});
	};

	_this.promote = function (r) {
		RoleService.promoteRoleInGroup(_this.selectedRoleGroup, r);
	};

	_this.deleteRoleGroup = function (roleGroup) {
		RoleService.deleteRoleGroup(roleGroup).then(function () {
			_this.selectedRoleGroup = null;
		});
	};

	_this.setRoleGroupName = function (n) {
		if (_.find(_this.ctx.roleGroups, function (g) {
			return g.name === n && g.id !== _this.selectedRoleGroup.id;
		})) {
			DialogService.openMessageDialog('Name in use', 'Role group names must be unique.', 'warning');
			return;
		}
		var old = _this.selectedRoleGroup.name;
		if (old !== n) {
			_this.selectedRoleGroup.name = n;
			AppService.update(_this.selectedRoleGroup).then(function () {
				_this.editName = false;
			}, function (err) {
				DialogService.errorMessage('Error updating name', err);
				_this.selectedRoleGroup.meta.versionId--;
				_this.selectedRoleGroup.name = old;
			});
		} else {
			_this.editName = false;
		}
	};

	// Remove role from the selected role group
	_this.removeRole = function (role) {
		// Sanity check
		if (!_this.selectedRoleGroup) {
			LogService.error('removeRole called without a selected role group');
			return void 0;
		}

		return RoleService.removeRoleFromGroup(_this.selectedRoleGroup, role);
	};

	// Add shift to the current aggregate
	_this.addRole = function (role) {
		if (_this.selectedRoleGroup) {
			return RoleService.addRoleToGroup(_this.selectedRoleGroup, role);
		}
		return void 0;
	};

	_this.dropRole = function (e, el) {
		var id = el.draggable[0].id;
		var role = _.find(_this.ctx.roles, { 'id': id });
		_this.addRole(role);
	};

	_this.startDrag = function (e) {
		angular.element(e.target).addClass('dragging'); //eslint-disable-line
	};

	_this.stopDrag = function (e) {
		angular.element(e.target).removeClass('dragging'); //eslint-disable-line
	};

	_this.showStart = function (s) {
		return moment.utc(s._milliseconds).format('HH:mm');
	};

	_this.teamsOnly = RoleService.teamsOnly;

	_this.showTelecom = function (role, system) {
		var t = _.find(role.telecom, { 'system': system });
		if (t) {
			return t.value;
		}
		return '';
	};

	_this.availableRoles = function (r) {
		return _this.selectedTeam.id === r.organization.id && !_.some(_this.selectedRoleGroup.role, function (rgr) {
			return rgr.id === r.id;
		});
	};

	_this.seniority = function (role) {
		return _.indexOf(config.mtRoleLevels, _.find(config.mtRoleLevels, { 'code': role.level.code }));
	};
}]);