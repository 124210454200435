
'use strict';

angular.module('medtasker').controller('AggregateCtrl', ['ctx', 'AggregateService', 'DialogService', 'AppService', 'ShiftService', 'config', '$log', '_', 'moment', '$modal', '$state', '$rootScope', function (ctx, AggregateService, DialogService, AppService, ShiftService, config, $log, _, moment, $modal, $state, $rootScope) {
	// eslint-disable-line

	var _this = this;
	_this.ctx = ctx; // Used by the view
	_this.selectedAggregate = null;
	_this.days = config.shiftSchedules;
	_this.config = config;
	_this.day = _this.days[0];
	_this.campuses = AppService.campuses();
	_this.teamsByCampus = function () {
		return ShiftService.teamsByCampus(ctx);
	};
	_this.formatCampus = ShiftService.formatCampus;
	_this.campusCode = AppService.campusDisplayCode;

	_this.selectAggregate = function (agg) {
		if (!agg) {
			return;
		}
		$rootScope.$emit('appLoadingPush', 'selectAggregate');
		AppService.loadShiftsForAggregate(agg.id, false).then(function () {
			//ensure we have loaded all relevant shifts into context.
			//We need them for cloning.
			var teams = _.chain(_this.ctx.shifts).filter(function (s) {
				return s.aggregate && s.aggregate.id === agg.id;
			}).map(function (s) {
				return s.role.organization;
			}) //get each shift's team
			.reduce(function (ts, t) {
				//make a map of unique teams
				if (!ts[t.id]) {
					ts[t.id] = t;
				}
				return ts;
			}, {}).values() //get the teams as an array
			.value();
			return AppService.loadShifts(null, teams).then(function () {
				$rootScope.$emit('appLoadingPop', 'selectAggregate');
			});
		});
	};

	(function init() {
		if ($state.params.id) {
			_this.selectedAggregate = _.find(_this.ctx.aggregates, { 'id': $state.params.id });
			_this.selectAggregate(_this.selectedAggregate);
			_this.day = _.find(_this.days, { 'code': _this.selectedAggregate.schedule.code });
		}
	})();

	_this.setAggregateName = function (n) {
		_this.selectedAggregate.name = n;
		AppService.update(_this.selectedAggregate).then(function () {
			_this.editAggregateName = false;
		});
	};

	// Remove shift from the selected aggregate
	_this.removeShift = function (shift) {
		// Sanity check
		if (!_this.selectedAggregate) {
			$log.error('Removeshift() called without a selectedAggregate');
			return void 0;
		}

		return AggregateService.removeShift(_this.selectedAggregate, shift);
	};

	// Add shift to the current aggregate
	_this.addShift = function (shift) {
		if (_this.selectedAggregate) {
			return AggregateService.addShift(_this.selectedAggregate, shift).then(null, function (err) {
				if (err.status === 400 && err.data && err.data.indexOf('existing role assignments') > -1) {
					DialogService.errorMessage('Error adding shift to aggregate. Shift has staff member assigned to it. Please remove these from the roster first.');
				} else {
					DialogService.errorMessage('Error adding shift to aggregate', err);
				}
				shift.aggregate = void 0;
				shift.meta.versionId--;
			});
		}
		return void 0;
	};

	_this.dropShift = function (e, el) {
		var id = el.draggable[0].id;
		var shift = _.find(_this.ctx.shifts, { 'id': id });
		_this.addShift(shift);
	};

	_this.startDrag = function (e) {
		angular.element(e.target).addClass('dragging'); //eslint-disable-line
	};

	_this.stopDrag = function (e) {
		angular.element(e.target).removeClass('dragging'); //eslint-disable-line
	};

	_this.validPeriod = function (s) {
		var f = 'DD-MM-YYYY';
		var sod = moment().startOf('day');
		if (s.validPeriod.end && s.validPeriod.end.isBefore(sod)) {
			return 'expired ' + s.validPeriod.end.format(f);
		} else if (s.validPeriod.start.isAfter(sod)) {
			return 'commences ' + s.validPeriod.start.format(f);
		}
		return '';
	};

	// _this.stopDrag = function(e) {
	// 	angular.element(e.target).removeClass('dragging');
	// };

	_this.selectTeam = function (team) {
		if (team) {
			$rootScope.$emit('appLoadingPush', 'selectAggregateTeam');
			AppService.loadShifts(null, team).then(function () {
				$rootScope.$emit('appLoadingPop', 'selectAggregateTeam');
			});
		}
	};

	_this.newAggregate = function () {
		var options = {
			templateUrl: 'app/shift/aggregate-new.html',
			// size: 'lg',
			backdrop: true,
			controller: 'AggregateNewCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						day: _this.day
					};
				}
			}
		};
		var instance = $modal.open(options);
		instance.result.then(function (res) {
			_this.selectedAggregate = res;
		});
	};

	_this.clone = function () {
		var options = {
			templateUrl: 'app/shift/aggregate-clone.html',
			// size: 'lg',
			backdrop: true,
			controller: 'AggregateCloneCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						day: _this.day,
						days: _this.days,
						aggregate: _this.selectedAggregate,
						ctx: _this.ctx
					};
				}
			}
		};
		var instance = $modal.open(options);
		instance.result.then(function () {
			// TODO: delete the aggregate if there's an error
		});
	};

	_this.delete = function () {
		AggregateService.delete(_this.selectedAggregate).then(function () {
			_this.selectedAggregate = null;
			_this.ctx.selectedTeam = null;
		});
	};

	_this.endShift = function (shift) {
		var s = moment().startOf('day').add(shift.start).add(shift.duration);
		return s.format('HH:mm');
	};

	// Filter - Accepts shifts that have aggregates for the selectedSchedule
	_this.filterShifts = function (s) {
		return _this.selectedAggregate && s.aggregate && _this.selectedAggregate.id === s.aggregate.id;
	};

	// Filter - Accepts shifts that have the same schedule as the current aggregate and aren't already part of an aggregate
	_this.filterAvailableShifts = function (s) {
		if (_this.selectedAggregate) {
			// ignore shifts that are already part of an aggregate
			if (s.aggregate) {
				return false;
			}
			return _this.ctx.selectedTeam ? _this.ctx.selectedTeam.id === s.role.organization.id && s.schedule.code === _this.selectedAggregate.schedule.code : s.schedule.code === _this.selectedAggregate.schedule.code;
		}
		return false;
	};

	_this.exit = function () {
		$state.go('app.admin.shift');
	};

	_this.aggregateChanged = function () {
		_this.selectedAggregate = null;
		//_this.day = day;
	};

	_this.toggleAddShifts = function () {
		_this.addShifts = !_this.addShifts;
	};
}]);