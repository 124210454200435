/* global angular */
/* global FS */
/* global sessionstack */
'use strict';

angular.module('medtasker').factory('InitService', ['AppService', 'appVersion', 'AuthService', 'LogService', 'config', '$q', '$window', function (AppService, appVersion, AuthService, LogService, config, $q, $window) {

	var o = {
		initBugsnag: function initBugsnag() {
			if (config.enableBugsnag) {
				var bsopts = {
					apiKey: config.bugsnagApiKeyWeb,
					autoNotify: config.bugsnagNotify,
					appVersion: config.mobileSoftVersion,
					user: {
						id: AuthService.userId(),
						name: AuthService.userDisplayName()
					},
					beforeSend: function beforeSend(report) {
						report.breadcrumbs.forEach(function (b) {
							if (b.metaData.targetText) {
								b.metaData.targetText = '<REDACTED>';
							}
						});
					}
				};
				$window.bugsnagClient = $window.bugsnag(bsopts);
			}
		},
		// initFullStory: () => {
		// 	/* eslint-disable */
		// 	window['_fs_debug'] = false;
		// 	window['_fs_host'] = 'www.fullstory.com';
		// 	window['_fs_org'] = '26RPY';
		// 	window['_fs_namespace'] = 'FS';
		// 	(function(m,n,e,t,l,o,g,y){
		// 		if (e in m && m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'); return;}
		// 		g=m[e]=function(a,b){g.q?g.q.push([a,b]):g._api(a,b);};g.q=[];
		// 		o=n.createElement(t);o.async=1;o.src='https://'+_fs_host+'/s/fs.js';
		// 		y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
		// 		g.identify=function(i,v){g(l,{uid:i});if(v)g(l,v)};g.setUserVars=function(v){g(l,v)};
		// 		g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
		// 		g.clearUserCookie=function(c,d,i){if(!c || document.cookie.match('fs_uid=[`;`]*`[`;`]*`[`;`]*`')){
		// 		d=n.domain;while(1){n.cookie='fs_uid=;domain='+d+
		// 		';path=/;expires='+new Date(0).toUTCString();i=d.indexOf('.');if(i<0)break;d=d.slice(i+1)}}};
		// 	})(window,document,window['_fs_namespace'],'script','user');
		// 	/* eslint-enable */
		// 	FS.clearUserCookie(true); //seems not to work, so we remove cookie manually below. TODO: why??
		// 	$cookies.remove('fs_uid');
		// 	FS.identify(AuthService.userId());
		// },
		initSessionStack: function initSessionStack() {
			/* eslint-disable */
			if (config.enableSessionStack) {
				(function () {
					!function (a, b) {
						var c = window;c.SessionStack = a, c[a] = c[a] || function () {
							c[a].q = c[a].q || [], c[a].q.push(arguments);
						}, c[a].t = b;var d = document.createElement("script");
						d.async = 1, d.src = "https://cdn.sessionstack.com/sessionstack.js";
						var e = document.getElementsByTagName("script")[0];e.parentNode.insertBefore(d, e);
					}("sessionstack", "8a33bde838b042d783d7e3171f70f949");
				})();
				/* eslint-enable */
				if (AppService.user) {
					sessionstack('identify', {
						username: AppService.user.identifier[0].value,
						userId: AppService.user.id,
						displayName: AppService.displayName(AppService.user),
						roles: AuthService.roles
					});
				}
			}
		}
	};

	return o;
}]);