'use strict';
/* global navigator */

angular.module('medtasker', ['medtasker.service', 'ngMessages', 'ngAnimate', 'ngCookies', 'download', 'jsonFormatter', 'ngSanitize', 'angularMoment', 'ngResource', 'ui.router', 'ngIdle', 'ngDragDrop', 'uuid', 'ui.bootstrap', 'ui.unique', 'ui.select', 'monospaced.placeholder', 'angular.img', 'lodash', 'angular-jwt', 'highcharts-ng', 'ui.utils', 'ui.bootstrap.datetimepicker', 'ui.utils.masks', 'angucomplete-alt', /* 'angulartics.mixpanel', */'angularAwesomeSlider']).config(['$httpProvider', function ($httpProvider) {
	$httpProvider.interceptors.push(['$injector', function ($injector) {
		return $injector.get('AuthInterceptor');
	}]);
	$httpProvider.useApplyAsync(true);
	$httpProvider.defaults.withCredentials = true;
}]).config(['$httpProvider', function ($httpProvider) {
	$httpProvider.interceptors.push('noCacheInterceptor');
}]).factory('noCacheInterceptor', function () {
	return {
		request: function request(config) {
			if (config.method === 'GET') {
				var separator = config.url.indexOf('?') === -1 ? '?' : '&';
				if (config.url.indexOf('.html') === -1) {
					config.url = config.url + separator + '_noCache=' + new Date().getTime();
				}
			}
			return config;
		}
	};
}).config(['JSONFormatterConfigProvider', function (JSONFormatterConfigProvider) {
	JSONFormatterConfigProvider.hoverPreviewEnabled = true;
}]).config(['$compileProvider', function ($compileProvider) {
	$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|data):/);
	// Angular before v1.2 uses $compileProvider.urlSanitizationWhitelist(...)
}]).config(['uiSelectConfig', function (uiSelectConfig) {
	uiSelectConfig.theme = 'bootstrap';
}]).config(['IdleProvider', 'idleTimeoutMins', function (IdleProvider, idleTimeoutMins) {
	IdleProvider.idle(idleTimeoutMins * 60);
	IdleProvider.timeout(5 * 60);
}]).constant('AUTH_EVENTS', {
	loginUserSuccess: 'auth-login-success',
	loginUserFailed: 'auth-login-failed',
	logoutUserSuccess: 'auth-logout-success',
	loggedOutWard: 'auth-ward-logout',
	sessionTimeout: 'auth-session-timeout',
	notAuthenticated: 'auth-not-authenticated',
	notAuthorized: 'auth-not-authorized',
	userSessionRestored: 'auth-userSession-restored',
	wardSessionRestored: 'auth-wardSession-restored',
	tokenExpired: 'auth-token-expired'
}).constant('USER_ROLES', {
	public: 'public',
	admin: 'admin',
	rosteradmin: 'rosteradmin',
	roster: 'roster',
	manager: 'manager',
	ward: 'ward',
	staffwithpatientaccess: 'clinician',
	staff: 'staff',
	insights: 'insights',
	reports: 'reports',
	codes: 'codes',
	switch: 'switch'
}).run(['$rootScope', '$timeout', 'AppService', 'AuthService', 'AudioService', 'ResourceService', 'ReconnectService', 'DialogService', 'LogService', 'EventService', 'InitService', 'Idle', 'config', '$log', '$state', '$q', 'baseUrl', '$templateCache', '$confirmModalDefaults', '$window', 'AUTH_EVENTS', 'moment', '_', 'appVersion', 'oauth', 'UrlService', function ($rootScope, $timeout, AppService, AuthService, AudioService, ResourceService, ReconnectService, DialogService, LogService, EventService, InitService, Idle, config, $log, $state, $q, baseUrl, $templateCache, $confirmModalDefaults, $window, AUTH_EVENTS, moment, _, appVersion, oauth, UrlService) {
	//IE 11 polyfill
	/*eslint-disable */
	if (!String.prototype.startsWith) {
		String.prototype.startsWith = function (searchString, position) {
			position = position || 0;
			return this.substr(position, searchString.length) === searchString;
		};
	}
	/*eslint-enable */
	config.preinit(); //restore any config items that are persisted in local storage
	config.setBaseUrl(baseUrl);
	config.webAppVersion = appVersion;

	var unbind = [];
	unbind.push($rootScope.$on(AUTH_EVENTS.userSessionRestored, EventService.addStandardListeners));

	unbind.push($rootScope.$on('userLoaded', function () {
		if (!config.disableSessionStack) {
			InitService.initSessionStack();
		}
	}));
	AuthService.reloadSessions(); // On application start, reload user and idle token info from Windows Session if available.
	AuthService.setHttpAuthHeader();

	function goToLogin(thenToState, stateParams) {
		oauth.config().then(function (res) {
			if (res.data.oauth2_service_enabled) {
				$window.location.href = UrlService.baseUrl() + '/#/?msg=' + encodeURIComponent('Please log in to view this page.');
			} else {
				DialogService.openLoginDialog('Please log in to view this page.').then(function () {
					$state.go(thenToState, stateParams);
				}, function (err) {
					$state.go('home');
					LogService.info('Login failed: ' + err);
				});
			}
		}, function (err) {
			_this.error = 'Error getting oauth config: ' + angular.toJson(err);
			LogService.error(err, 1, 'HttpGetError');
			DialogService.openLoginDialog('Please log in to view this page.').then(function () {
				$state.go(thenToState, stateParams);
			}, function (err) {
				$state.go('home');
				LogService.info('Login failed: ' + err);
			});
		});
	}

	function reloadUserData() {
		LogService.debug('Reloading user data...');
		AppService.appStatus.loadingData = true;
		AppService.reloadUserData(AuthService.userId()).then(function () {
			AppService.appStatus.loadingData = false;
		});
	}

	function appLoadingPush(src) {
		if ($rootScope.loadingStack.indexOf(src) === -1) {
			$rootScope.loadingStack.push(src);
			//LogService.debug(src + ': loading count up. Now=' + $rootScope.loadingStack.length);
		}
	}

	function setExpiryTimeout() {
		var exp = moment.duration(AuthService.sessionExpiryTime().diff(moment())).asSeconds() * 1000;
		if (exp > 0) {
			$timeout(function () {
				if (config.oauthconfig.oauth2_service_enabled && AuthService.oauthLoginHint() !== '') {
					oauth.getRefreshToken().then(function (res) {}, function (err) {
						var logouturl = oauth.getLogoutUrl('Your session has ended. Please log in to continue using Medtasker.');
						if (logouturl !== '') {
							AppService.logout('ward');
							AppService.logout('user');
							Idle.unwatch();
							$window.location.href = logouturl;
						}
						//DialogService.openLoginDialog('Your session has expired. Please log in again');
						$window.location.href = UrlService.baseUrl() + '/#/?msg=' + encodeURIComponent('Your session has ended. Please log in to continue using Medtasker.');
					});
				} else {
					DialogService.openLoginDialog('Your session has expired. Please log in again');
				}
			}, exp);
		}
	}

	function appLoadingPop(src) {
		_.remove($rootScope.loadingStack, function (l) {
			return l === src;
		});
		//LogService.debug(src + ': loading count down. Now=' + $rootScope.loadingStack.length);
	}

	$rootScope.loadingStack = [];
	$rootScope.tempUnbind = [];
	$rootScope.removeTempHandlers = function () {
		$rootScope.tempUnbind.map(function (remove) {
			remove();
		});
		$rootScope.tempUnbind.length = 0;
	};

	unbind.push($rootScope.$on('appLoadingPush', function (e, data) {
		// eslint-disable-line
		appLoadingPush(data.source);
	}));

	unbind.push($rootScope.$on('appLoadingPop', function (e, data) {
		// eslint-disable-line
		appLoadingPop(data.source);
	}));

	unbind.push($rootScope.$on('teamDataLoading', function (e, data) {
		// eslint-disable-line
		$rootScope.loadingTeamData = true;
	}));

	unbind.push($rootScope.$on('teamDataLoaded', function (e, data) {
		// eslint-disable-line
		$rootScope.loadingTeamData = false;
	}));

	var p = $q.defer(); //dummy promise

	if (AuthService.isAuthenticated('ward')) {
		setExpiryTimeout();
		config.init().then(function () {
			InitService.initBugsnag();
			if (!config.disableSessionStack) {
				InitService.initSessionStack();
			}
			p.resolve();
		});
	} else {
		p.resolve();
	}
	p.promise.then(function () {
		unbind.push($rootScope.$on('authorizationError', function () {
			DialogService.openLoginDialog('You are not authorized to view this page. Please log in.').then(function () {
				$window.location.reload();
			}, function (err) {
				LogService.info('Login failed: ' + err);
			});
		}));

		unbind.push($rootScope.$on(AUTH_EVENTS.loginUserSuccess, function () {
			// eslint-disable-line
			setExpiryTimeout();
			Idle.watch();
		}));

		unbind.push($rootScope.$on(AUTH_EVENTS.tokenExpired, function () {
			// eslint-disable-line
			DialogService.openLoginDialog('Your session has expired. Please log in again');
		}));

		unbind.push($rootScope.$on(AUTH_EVENTS.logoutUserSuccess, function () {
			// eslint-disable-line
			$window.bugsnagClient.user = null;
			EventService.removeAllListeners();
			Idle.unwatch();
		}));

		unbind.push($rootScope.$on('ReloadUserData', reloadUserData)); // eslint-disable-line

		$rootScope.$state = $state; // Used to set a class to the html element in index.html

		// $rootScope.$on(AUTH_EVENTS.notAuthenticated, DialogService.openLoginDialog);		// ?NOT required as login modal doesn't close if there's an error
		unbind.push($rootScope.$on(AUTH_EVENTS.sessionTimeout, DialogService.openLoginDialog)); // eslint-disable-line

		// Prevents switching states if the user is not allowed
		unbind.push($rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
			// eslint-disable-line
			appLoadingPush('stateChange');
			var authorizedRoles = toState.data.authorizedRoles;

			if (toState.name !== fromState.name) {
				//only track state change in analytics if actually changing to a different state (not reloading)
				var trackData = { 'toState': toState.name, 'fromState': fromState ? fromState.name : 'none', 'user': AuthService.userId(), 'loggedInWard': AuthService.getCurrentWard() ? AuthService.getCurrentWard().code : 'none' };
				if (AppService.stateTimer) {
					var diff = moment().diff(AppService.stateTimer);

					trackData.timeInLastState = moment.utc(diff).format('HH:mm:ss.SSS');
					//LogService.debug('Time in state:' + trackData.timeInLastState);
				}
				AppService.stateTimer = moment();
			}

			if (!AuthService.isAuthorized(authorizedRoles)) {
				event.preventDefault();
				appLoadingPop('stateChange');
				goToLogin(toState, toParams);
			}
		}));

		unbind.push($rootScope.$on('$stateChangeSuccess', function () {
			// eslint-disable-line
			appLoadingPop('stateChange');
		}));

		unbind.push($rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, err) {
			// eslint-disable-line
			if (err && err.message) {
				LogService.error('state change error: ', err.message);
			}
			if (err === 'unauthorized') {
				goToLogin(toState, toParams);
			}
			appLoadingPop('stateChange');
		}));

		unbind.push($rootScope.$on('sseConnectionLost', function (e, feed) {
			// eslint-disable-line
			$timeout(function () {
				ReconnectService.attemptReconnect(feed);
			});
		}));

		unbind.push($rootScope.$on('sseUnauthorized', function () {
			// eslint-disable-line
			var logouturl = oauth.getLogoutUrl('Your session has ended. Please log in to continue using Medtasker.');
			if (logouturl !== '') {
				AppService.logout('user');
				AppService.logout('ward');
				$window.location.href = logouturl;
			} else {
				AppService.logout('user');
				AppService.logout('ward');
				DialogService.openMessageDialog('Session expired', '<p>Your session has expired. Please log in again.</p>', 'error');
				$state.go('home');
			}
		}));

		unbind.push($rootScope.$on('sseConnectionOpen', function () {
			// eslint-disable-line
			//only do app init if we are reconnecting to lost event source, not on first connection
			if (EventService.reconnected()) {
				ReconnectService.cancelReconnection();
				AppService.init('force');
			}
			$rootScope.$apply();
		}));

		unbind.push($rootScope.$on('sse.appAlert', function (event, alert) {
			//eslint-disable-line
			//Warning: serializing the alert to output it to console will potentially give a circular serialization error,
			//since the embedded MtTask object will in turn have the alert embedded within it.
			if (alert.status === 'in-progress' && alert.reason[0].coding[0].code !== 'taskTransition' && alert.reason[0].coding[0].code !== 'taskReply') {
				AudioService.playReceivedTaskSound();
			}
		}));

		unbind.push($rootScope.$on('sse.pushNotification', function (event, notification) {
			//eslint-disable-line
			if (notification.status === 'in-progress') {
				//ensure we only notify for the first version
				var reason = notification.reason[0].coding[0].code;
				switch (reason) {
					case 'taskReply':
						AudioService.playReplyReceivedSound();
						break;
					case 'New task':
						if (notification.payload[0].contentReference.priority.coding[0].code !== 'routine') {
							AudioService.playUrgentTaskSound();
						} else {
							AudioService.playReceivedTaskSound();
						}
						break;
					case 'escalation-level2':
						AudioService.playEscalationSound();
						break;
				}
			}
		}));

		unbind.push($rootScope.$on('AppServiceReInitialize', AppService.reinit)); // eslint-disable-line

		$rootScope.connectionStatus = ResourceService.connectionStatus;
		$rootScope.appStatus = AppService.appStatus;
		unbind.push($rootScope.$watch(ResourceService.connectionStatus)); // eslint-disable-line

		$rootScope.$on('$destroy', function () {
			//remove all handlers
			unbind.map(function (remove) {
				remove();
			});
			$rootScope.removeTempHandlers();
		});
	});

	$confirmModalDefaults.templateUrl = 'app/common/confirm.modal.html';
	/* eslint-disable */
	$templateCache.put("bootstrap/choices.tpl.html", "<ul class=\"ui-select-choices ui-select-choices-content ui-select-dropdown dropdown-menu\" role=\"listbox\" style=\"display:{{$select.items.length && $select.open ? 'block':'none'}}\"><li class=\"ui-select-choices-group\" id=\"ui-select-choices-{{ $select.generatedId }}\"><div class=\"divider\" ng-show=\"$select.isGrouped && $index > 0\"></div><div ng-show=\"$select.isGrouped\" class=\"ui-select-choices-group-label dropdown-header\" ng-bind=\"$group.name\"></div><div id=\"ui-select-choices-row-{{ $select.generatedId }}-{{$index}}\" class=\"ui-select-choices-row\" ng-class=\"{active: $select.isActive(this), disabled: $select.isDisabled(this)}\" role=\"option\"><a href=\"javascript:void(0)\" class=\"ui-select-choices-row-inner\"></a></div></li></ul>"); //esline-disable-line
	/* eslint-enable */
}]).animation('.slide', function () {
	var NG_HIDE_CLASS = 'ng-hide';
	return {
		beforeAddClass: function beforeAddClass(element, className, done) {
			if (className === NG_HIDE_CLASS) {
				element.slideUp(done);
			}
		},
		removeClass: function removeClass(element, className, done) {
			if (className === NG_HIDE_CLASS) {
				element.hide().slideDown(done);
			}
		}
	};
});