'use strict';

angular.module('medtasker').factory('oauth', ['LogService', 'UrlService', 'AuthService', 'EventService', '$q', '$http', '$rootScope', 'InitService', 'config', function (LogService, UrlService, AuthService, EventService, $q, $http, $rootScope, InitService, config) {

    var _httpConfig = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    var o = {
        init: function init() {},

        config: function config() {
            var url = UrlService.baseUrl() + '/api/v1/oauth/config';
            //LogService.debug('Getting: ' + url, false, false, true);
            return $http.get(url);
        },

        getOauthLoginURL: function getOauthLoginURL() {
            var deferred = $q.defer();
            o.config().then(function (res) {
                if (res.data.oauth2_service_enabled) {
                    var url = UrlService.baseUrl() + '/api/v1/oauth/startloginprocess?redirect_url_destination=oauthproxy';

                    $http.get(url).then(function (res) {
                        LogService.debug('Getting: ' + res.data.url);
                        deferred.resolve(res.data.url);
                    }, function (err) {
                        deferred.resolve('');
                    });
                } else {
                    deferred.resolve('');
                }
            }, function (err) {
                deferred.resolve('');
            });
            return deferred.promise;
        },

        getRefreshToken: function getRefreshToken() {
            var deferred = $q.defer();

            var url = UrlService.baseUrl() + '/api/v1/oauth/refreshtoken';
            LogService.debug('Getting: ' + url, false, false, true);
            $http.post(url).then(function (res) {
                //LogService.debug('getRefreshToken '+ angular.toJson(res));
                if (res.status === 200) {
                    AuthService.setSessionInfo(res.data);
                    deferred.resolve(res.data);
                } else {
                    deferred.reject('Error getting refresh token');
                }
            }, function (err) {
                deferred.reject('Error getting refresh token');
            });
            return deferred.promise;
        },

        postConfigReadHandler: function postConfigReadHandler() {
            LogService.debug('postConfigReadHandler');
            //InitService.initBugsnag();
            //LogService.debug('appVersion=' + appVersion);
            LogService.debug('config.clientVersion=' + config.clientVersion);
            var cv = 'MEDTASKER';
            cv = cv + '_VERSION'; //this weirdness is required to stop version being injected in minified code
            LogService.debug('No client update required2' + angular.toJson(EventService.reset));

            //InitService.initSessionStack();

            //AppService.resetReady(); //force app service to reinit next time - gets user resources

            $rootScope.$broadcast('appLoadingPop', { source: 'login' });
        },

        // deleteSecureCookie: () => {
        //     let deferred = $q.defer();
        //     o.config().then((res)=>{
        //         if (res.data.oauth2_service_enabled) {
        //             let url = UrlService.baseUrl() + '/api/v1/oauth/deletecookie';
        //             return $http.get(url);
        //         }
        //     });
        // },
        getLogoutUrl: function getLogoutUrl(msg) {
            LogService.debug('getLogoutUrl ' + config.oauthconfig.oauth2_service_enabled + ' loginhint ' + AuthService.oauthLoginHint());

            if (config.oauthconfig.oauth2_service_enabled && AuthService.oauthLoginHint() !== '') {
                var errorMsg = '';
                if (angular.isDefined(msg)) {
                    errorMsg = '?msg=' + msg;
                }
                return config.oauthconfig.end_session_endpoint + '?logout_hint=' + AuthService.oauthLoginHint() + '&post_logout_redirect_uri=' + encodeURIComponent(UrlService.baseUrl() + '/#/' + errorMsg);
            } else {
                return '';
            }
        }
    };

    return o;
}]);