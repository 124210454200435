'use strict';

angular.module('medtasker').factory('AggregateService', ['AppService', 'ResourceService', 'config', '$http', '$q', '_', '$log', function (AppService, ResourceService, config, $http, $q, _, $log) {

	var _ctx = AppService.ctx;

	var o = {
		//type = 'shift' or 'roleAssignment'
		create: function create(name, type, schedule) {
			var aggr = ResourceService.newAggregate(name, type, schedule);
			return AppService.create(aggr);
		},

		delete: function _delete(aggregate) {
			if (aggregate.type.code !== 'mtShiftAggregate') {
				$log.error('Only MtShiftAggregates are supported at this time');
				// TODO: Add support for deleting RoleAssignment Aggregates
				return $q.when();
			}

			// Remove links from associated shifts
			var promises = [];
			_.forEach(_ctx.shifts, function (shift) {
				if (shift.aggregate && shift.aggregate.id === aggregate.id) {
					shift.aggregate = void 0;
					promises.push(AppService.update(shift));
				}
			});

			return $q.all(promises).then(function () {
				return AppService.delete(aggregate);
			});
		},

		// Clone the aggregate for the given day
		clone: function clone(aggregate, day) {
			// Shifts belonging to the aggregate
			var aggrShifts = _.filter(_ctx.shifts, { aggregate: { id: aggregate.id } });

			var mismatch = false;
			var newAggrShifts = [];
			for (var i = 0; i < aggrShifts.length; i++) {
				var shift = o.correspondingShift(aggrShifts[i], day);
				if (!shift) {
					mismatch = true;
					break;
				}
				newAggrShifts.push(shift);
			}

			// Sanity check
			if (mismatch) {
				throw new Error('Cannot clone aggregate for day ' + day.display + '. Shifts do not match.');
			}

			// LATER: Create a transaction for creating the aggr and updating its shifts
			var deferred = $q.defer();
			o.create(aggregate.name, 'shift', day).then(function (aggr) {
				var promises = [];
				_.forEach(newAggrShifts, function (shift) {
					promises.push(o.addShift(aggr, shift));
				});

				$q.all(promises).then(function () {
					deferred.resolve();
				}, function (err) {
					$log.error('Cannot assign shift to aggregate: ' + err);
					deferred.reject('Cannot assign shift to aggregate: ' + err);
				});
			}, function (err) {
				$log.error('Cannot create aggregate: ' + err);
				deferred.reject('Cannot create aggregate: ' + err);
			});
			return deferred.promise;
		},

		// Add a shift to an Aggregate
		addShift: function addShift(aggregate, shift) {
			// Sanity check
			if (shift.aggregate) {
				$log.warn('Adding an aggregate to a shift that is already part of an aggregate');
			}
			shift.aggregate = aggregate;
			return AppService.update(shift);
		},

		// Remove a shift from an Aggregate
		removeShift: function removeShift(aggregate, shift) {
			var promise = $q.when(shift);
			if (shift.aggregate) {
				shift.aggregate = null;
				promise = AppService.update(shift);
			}
			return promise;
		},

		// Returns the shift which matches 'shift' but for the given day.
		// A match is found is the shift has the same role, start, duration as the
		// given shift and has no aggregate
		correspondingShift: function correspondingShift(shift, day) {
			return _.find(_ctx.shifts, function (s) {
				return s.role.id === shift.role.id && !s.aggregate && +s.start === +shift.start && +s.duration === +shift.duration && s.schedule.code === day.code;
			});
		},

		// Filter
		teamsOnly: function teamsOnly(org) {
			return org.type === 'team' || org.type === 'subteam';
		}
	};

	return o;
}]);