'use strict';

//
// Convert js libraries into Angular modules
//
/* global angular */

angular.module('lodash', []).factory('_', ['$window', function ($window) {
	return $window._;
}]);

angular.module('uuid', []).factory('uuid', ['$window', function ($window) {
	return $window.uuid;
}]);