'use strict';

angular.module('medtasker').factory('TeamService', ['AppService', 'ResourceService', 'config', 'moment', '$http', '$q', '_', function (AppService, ResourceService, config, moment, $http, $q, _) {

	var _ctx = void 0;

	return {

		// Return the context object
		init: function init() {
			var deferred = $q.defer();
			AppService.init().then(function (ctx) {
				_ctx = ctx;
				$q.all([AppService.loadAllRotations(), AppService.loadAllTeamAssignments()]).then(function () {
					deferred.resolve(_ctx);
				});
			});
			return deferred.promise;
		},

		selectTeam: function selectTeam(team) {
			_ctx.selectedTeam = team;
			_ctx.selectedRole = null;

			// Set the next and previous teams from an alphabetised list of organizations
			//   (based on team identifier)
			var teams = _.filter(_ctx.organizations, function (org) {
				return org.type === 'team' || org.type === 'subteam';
			});
			var alphaSortedTeams = _.sortBy(teams, 'partOf.location[0].identifier[0].value', 'name');
			var selectedTeamIndex = _.findIndex(alphaSortedTeams, team);
			if (selectedTeamIndex !== -1) {
				_ctx.nextTeam = alphaSortedTeams[selectedTeamIndex + 1];
				_ctx.prevTeam = alphaSortedTeams[selectedTeamIndex - 1];
			}

			//			_ctx.teamRoles = _updateRoles(team);
		},

		// Filter
		teamsOnly: function teamsOnly(org) {
			return org.type === 'team' || org.type === 'subteam';
		}
	};
}]);