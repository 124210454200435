'use strict';

angular.module('medtasker').factory('InsightsService', ['AppService', 'ResourceService', '$log', '$timeout', '$rootScope', '$q', '$interval', 'config', '_', 'moment', 'LogService', function (AppService, ResourceService, $log, $timeout, $rootScope, $q, $interval, config, _, moment, LogService) {

	var _taskTypes = [];
	var _taskTypesDisplay = [];
	var _currentTasks = [];
	var _allTasks = [];
	var _roleTypeFilter = {};
	var _escalationFilter = {};
	var _campusFilter = {};
	var _generatedFilter = {};

	var _chartColors = ['#c8dae4', '#8594cd', '#949ca7', '#cec5bc', '#f1dbd0', '#a9c1cd', '#bdbfb4', '#dce1e5', '#6a6b66', '#d4afa6', '#86acbf', '#e5d8c8', '#dee2e1', '#c2cdd3', '#907f77', '#f3e8d6', '#d5dfe9', '#95a3ac', '#b5b5b3', '#acc8da', '#e98594', '#b3949c', '#d6cec5', '#77f1db', '#d3a9c1', '#e1bdbf', '#c8dce1', '#bf6a6b', '#a6d4af', '#6686ac', '#e5e5d8', '#b4dee2', '#cdc2cd', '#d0907f', '#bcf3e8', '#a7b5b5', '#cdd5df', '#e495a3'];

	var urgencyColors = {
		'routine': '#6c6c70',
		'urgent': '#d99800',
		'premet': '#d96616',
		'met': '#cd1419',
		'codeblue': '#0099cc'
	};

	function _matchesEscalationFilter(t) {
		switch (true) {
			case !_escalationFilter.level:
				return true;
			case _escalationFilter.level === 1:
				if (t.status === 'accepted') {
					return false;
				}
				var delay = config.escalationDelayMinutes || 15;
				if (t.urgency === 'routine') {
					delay += delay;
				}
				var time = moment(t.dateSent).add(delay, 'minutes');
				return +moment() > +time;
			case _escalationFilter.level === 2:
				return t.$metadata.escalated;
			case _escalationFilter.level === 3:
				// task forwarded 3+ times
				return _.filter(t.action, function (a) {
					return a.action.code === 'forwarded' || a.action.code === 'stolen' || a.action.code === 'reassigned';
				}).length > 2;
			case _escalationFilter.level === 4:
				// task outstanding > 4 hours
				return t.dateSent.isBefore(moment().add(-4, 'hours'));
		}
		return false;
	}

	function _filterTasks(tasks) {
		var ft = tasks.slice();
		if (_roleTypeFilter.code) {
			ft = _.filter(tasks, function (t) {
				return t.recipientRole[0].type.code === _roleTypeFilter.code;
			});
		}
		if (_escalationFilter.level !== 0) {
			//if escalation filter is on, we only want to see tasks that are still in sent state after 60 mins (routine tasks) or 30 mins (urgent or higher)
			ft = _.filter(ft, _matchesEscalationFilter);
		}
		if (_campusFilter && _campusFilter.id) {
			LogService.debug('Insight using campus ' + _campusFilter.name);
			ft = _.filter(ft, function (t) {
				var m = t.recipientRole[0].organization.partOf.location[0].id === _campusFilter.id;
				return m;
			});
		}
		// } else {
		// 	LogService.debug('Insight using data from all campuses');
		// }
		if (!_generatedFilter.on) {
			ft = _.filter(ft, function (t) {
				return !t.$metadata.generated;
			});
		}
		return ft;
	}

	function _taskMatchesCriteria(o, task) {
		return _filterTasks([task]).length > 0;
	}

	var _ctx = {}; // Set during init()

	function _chartColorsFor(catgs) {
		var c = {};
		for (var i = 0; i < catgs.length; i++) {
			c[catgs[i]] = _chartColors[i % _chartColors.length];
		}
		return c;
	}

	function _chartColorsForUrgencies() {
		return _.transform(config.urgencies, function (accum, u) {
			accum[u.display] = urgencyColors[u.code];
		});
	}

	function _createTaskTypesArr() {
		_taskTypes = [];
		_taskTypesDisplay = [];
		_.forEach(config.categories, function (category) {
			var subCategories = category.subCategories;
			_.forEach(subCategories, function (taskType) {
				_taskTypes.push(taskType);
				_taskTypesDisplay.push(taskType.display);
			});
		});
		_taskTypes.push({ code: 'other', display: 'Other' });
		_taskTypesDisplay.push('Other');
	}

	function _loadCurrentTasks() {
		var deferred = $q.defer();
		var p = {
			include: 'MtTask:patient,MtTask:requester,MtTask:recipient,MtTask:recipientRole,MtTask:encounter,Encounter:patient,Encounter:serviceProvider,Encounter:location.location,MtTask:action.practitioner,MtTask:action.targetPractitioner,MtTask:response.sender,MtTask:supportingInformation'
		};
		if (!_generatedFilter.on) {
			p.notgenerated = true;
		}
		p.status = 'sent,accepted,started';

		ResourceService.medtaskerProcFhir(_ctx, 'search-tasks-fhir', p, 'MtTask', true).then(function (bundle) {
			_currentTasks = bundle.data;
			_allTasks = _currentTasks.slice();
			deferred.resolve(bundle.data);
		}, function (err) {
			$log.error('Unable to load currentTask data: ' + err.data);
			deferred.reject(err);
		});

		return deferred.promise;
	}

	function getFactor(task, factor) {
		switch (factor) {
			case 'location':
				var location = void 0;
				if (task.encounter) {
					location = task.encounter.location[task.encounter.location.length - 1].location.partOf.name;
				} else {
					location = 'Unrelated to Patient';
				}
				return location;
			case 'practitioner':
				var practitioner = task.recipient[0];
				var pracName = AppService.displayName(practitioner, 'lastNameFirst');
				return pracName;
			case 'urgency':
				return task.priority.coding[0].display;
			case 'type':
				var taskType = void 0;
				if (task.serviceRequested) {
					taskType = task.serviceRequested[0].coding[0].display;
				} else {
					taskType = 'Non patient related';
				}
				return taskType;
			case 'team':
				return AppService.campusDisplayCode(task.recipientRole[0].organization.partOf.location[0].id) + task.recipientRole[0].organization.name;
			case 'role':
				var role = task.recipientRole[0];
				var now = moment();
				var roleAssgn = _.find(AppService.ctx.roleAssignments, function (ra) {
					return ra.role.id === role.id && ra.period.start.isBefore(now) && ra.period.end.isAfter(now);
				});
				if (roleAssgn && roleAssgn.shift.aggregate) {
					return roleAssgn.shift.aggregate.name;
				}
				return AppService.roleShortName(role);
			case 'staff type':
				return task.recipientRole[0].type.display;
			case 'orphans':
				var riskTypes = [];
				if (task.status === 'sent') {
					if (task.dateSent.add(1, 'hours').isBefore(moment())) {
						riskTypes.push('Late to accept');
					}
				}
				if (task.status === 'accepted') {
					if (task.dateSent.add(4, 'hours').isBefore(moment())) {
						riskTypes.push('Late to complete');
					}
				}
				if (!_.some(_ctx.roleAssignments, function (ra) {
					return ra.role.id === task.recipientRole[0].id && ra.practitioner && +ra.period.start < now && ra.period.end > now;
				})) {
					riskTypes.push('Orphaned');
				}
				if (task.fulfillmentTime && task.fulfillmentTime.end && task.fulfillmentTime.end.isBefore(moment())) {
					riskTypes.push('Overdue');
				}
				return riskTypes;
			default:
				return null;
		}
	}

	function _sortCategories(factor, catgs) {
		if (factor === 'urgency') {
			return _.sortBy(catgs, function (catg) {
				return -1 * _.findIndex(config.urgencies, function (c) {
					return c.display === catg;
				});
			});
		}
		return catgs.sort();
	}

	function _groupTasks(factor1, factor2) {
		var catgs = {};
		var subcatgs = {};
		var group = {};
		var catg = void 0;

		_.forEach(_currentTasks, function (task) {
			var f = getFactor(task, factor1);
			if (angular.isArray(f)) {
				if (factor2) {
					//TODO: implement support for this possibility?
					throw new Error('Graphing error - two-factor graphs require factor categories to be independent. That is, each task can must map to exactly one factor category.');
				}
				_.forEach(f, function (c) {
					catgs[c] = true;
					if (angular.isUndefined(group[c])) {
						group[c] = [];
					}
					group[c].push(task);
				});
			} else {
				catgs[f] = true;
				catg = f;
			}

			if (factor2) {
				var subcatg = getFactor(task, factor2);
				subcatgs[subcatg] = true;
				if (angular.isUndefined(group[subcatg])) {
					group[subcatg] = {};
				}
				if (angular.isUndefined(group[subcatg][catg])) {
					group[subcatg][catg] = [];
				}
				group[subcatg][catg].push(task);
			} else {
				if (angular.isUndefined(group[catg])) {
					group[catg] = [];
				}
				group[catg].push(task);
			}
		});

		return {
			group: group,
			categories: _sortCategories(factor1, _.keys(catgs)),
			subcategories: factor2 ? _sortCategories(factor2, _.keys(subcatgs)) : null
		};
	}

	function _setGeneratedFilter(on) {
		_generatedFilter.on = on;
	}

	function _setRoleTypeFilter(rt) {
		if (rt && rt.code) {
			_roleTypeFilter.code = rt.code;
		} else {
			_roleTypeFilter.code = void 0;
		}
		_currentTasks = _filterTasks(_allTasks);
	}

	function _getCampusFilter() {
		return _campusFilter;
	}

	function _setCampusFilter(c) {
		if (c && c.id) {
			_campusFilter = c;
		} else {
			_campusFilter = void 0;
		}
		_currentTasks = _filterTasks(_allTasks);
	}
	function _setEscalationsFilter(level) {
		//This 'level' business is a bit misleading. It's just a way to categorise the filtering
		//TODO: consider refactor when we have time.
		if (level) {
			_escalationFilter.level = level;
		} else {
			_escalationFilter.level = 0;
		}
		_currentTasks = _filterTasks(_allTasks);
	}
	var o = {

		taskTypes: _taskTypes,

		taskTypesDisplay: _taskTypesDisplay,

		currentTasks: _currentTasks, // Array of MtTasks where Task.status == sent | accepted | active

		groupTasks: _groupTasks,

		setRoleTypeFilter: _setRoleTypeFilter,

		getCampusFilter: _getCampusFilter,

		setCampusFilter: _setCampusFilter,

		setEscalationsFilter: _setEscalationsFilter,

		setGeneratedFilter: _setGeneratedFilter,

		generatedFilter: _generatedFilter,

		roleTypeFilter: _roleTypeFilter,

		escalationFilter: _escalationFilter,

		chartColors: _chartColors,

		chartColorsFor: _chartColorsFor, //return dictionary mapping categories to color values

		chartColorsForUrgencies: _chartColorsForUrgencies,

		hasTasks: function hasTasks() {
			return _currentTasks.length > 0;
		},

		init: function init() {
			$log.debug('init InsightsService');
			var deferred = $q.defer();
			AppService.init().then(function (ctx) {
				_ctx = ctx;
				_createTaskTypesArr();
				_loadCurrentTasks().then(function () {
					deferred.resolve(_ctx);
				});
			});
			return deferred.promise;
		},

		reload: function reload() {
			$rootScope.$emit('appLoadingPush', 'insights_reload');
			return _loadCurrentTasks().then(function () {
				$rootScope.$emit('appLoadingPop', 'insights_reload');
				_currentTasks = _filterTasks(_allTasks);
			}, function () {
				return $rootScope.$emit('appLoadingPop', 'insights_reload');
			});
		},

		handleNewTask: function handleNewTask(task) {
			if (_taskMatchesCriteria(o, task)) {
				_allTasks = _.filter(AppService.ctx.tasks, function (t) {
					return t.$metadata.active;
				});
				_currentTasks = _filterTasks(_allTasks);
				$rootScope.$broadcast('sse.insights.task', task);
			}
		}
	};
	return o;
}]);