'use strict';

/* global angular */

angular.module('medtasker.service').factory('TaskService', ['UrlService', 'AuthService', 'ResourceService', 'AppService', 'LogService', 'config', '$q', '$http', function (UrlService, AuthService, ResourceService, AppService, LogService, config, $q, $http) {
	'use strict';

	function apiPost(url, content, cfg) {
		var deferred = $q.defer();
		$http.post(url, content, cfg).then(function (res) {
			var task = res.data;
			ResourceService.addToContext(AppService.ctx, task).then(function () {
				deferred.resolve(task);
			}, function (err) {
				deferred.reject(err);
			});
		}, function (err) {
			LogService.error('Error updating task (' + url + '): ' + err.data);
			deferred.reject(err);
		});
		return deferred.promise;
	}

	var o = {
		accept: function accept(task, annotation, roleId) {
			var url = UrlService.taskUrl() + '/accept/' + task.id + '/' + task.meta.versionId;
			if (roleId) {
				url += '?roleid=' + roleId;
			}

			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, annotation, cfg);
		},
		start: function start(task, annotation) {
			var url = UrlService.taskUrl() + '/start/' + task.id + '/' + task.meta.versionId;
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, annotation, cfg);
		},
		complete: function complete(task, annotation, roleId) {
			var url = UrlService.taskUrl() + '/complete/' + task.id + '/' + task.meta.versionId;
			if (roleId) {
				url += '?roleid=' + roleId;
			}
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, annotation, cfg);
		},
		cancel: function cancel(task, annotation, reason) {
			var url = UrlService.taskUrl() + '/cancel/' + task.id + '/' + task.meta.versionId + '?reason=' + reason;
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, annotation, cfg);
		},
		acknowledgeCc: function acknowledgeCc(task) {
			var url = UrlService.taskUrl() + '/cc/acknowledge/' + task.id;
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, null, cfg);
		},
		declineCc: function declineCc(task) {
			var url = UrlService.taskUrl() + '/cc/decline/' + task.id;
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, null, cfg);
		},
		respond: function respond(task, response) {
			var url = UrlService.taskUrl() + '/respond/' + task.id;
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, response, cfg);
		},
		annotate: function annotate(task, annotation) {
			var url = UrlService.taskUrl() + '/annotate/' + task.id;
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, annotation, cfg);
		},
		redirect: function redirect(task, roleAssignment, reason, fromRoleId) {
			// LogService.debug('Redirecting task to ' + roleAssignment.practitioner.id + ' with reason: ' + reason+ ' from role: ' + fromRoleId);

			var r = {
				practitionerId: roleAssignment.practitioner.id,
				roleId: roleAssignment.role.id,
				reason: reason,
				fromRoleId: fromRoleId
			};
			var url = UrlService.taskUrl() + '/redirect/' + task.id + '/' + task.meta.versionId;
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			return apiPost(url, r, cfg);
		}
	};
	return o;
}]);