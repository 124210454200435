
'use strict';

angular.module('medtasker').controller('CalltaskerAdminCtrl', ['ctx', '_', 'LogService', 'AppService', 'config', 'RoleService', 'ShiftService', '$q', 'CalltaskerAdminService', 'DialogService', function (ctx, _, LogService, AppService, config, RoleService, ShiftService, $q, CalltaskerAdminService, DialogService) {

  var _this = this;

  _this.ctx = AppService.ctx;
  _this.config = config;
  // Roles Section Initialisation
  _this.selectTeam = RoleService.selectTeam;
  _this.campuses = AppService.campuses();
  _this.teamsByCampus = function () {
    return ShiftService.teamsByCampus(ctx);
  };
  _this.formatCampus = ShiftService.formatCampus;
  _this.campusCode = AppService.campusDisplayCode;

  // Role Group Section Initialisation
  _this.selectedRoleGroup = null;

  // Calltasker Config for Device Section Initialisation
  _this.calltaskerConfigProperties = ['taskset', 'tasksetCollection', 'taskOverride'];
  _this.calltaskerConfig = {};
  // _this.tasksetCollection = '';
  _this.tasksetCollection = 'p0'; // temp for testing
  // _this.fromLocation = '';
  _this.fromLocation = 'x'; // temp for testing
  _this.output = '';
  _this.calltaskerConfigForDevice = '';
  _this.error = '';

  // Create Task Override Initialisation
  _this.tasksetsFromSelectedTasksetCollection = [];
  _this.taskList = [];
  _this.taskListFromSelectedTaskset = [];
  _this.fromLocations = [];
  _this.selectedTaskOverride = {
    task: null,
    fromLocation: null,
    recipientRoles: [],
    recipientRoleGroups: [],
    taskset: null,
    tasksetCode: '', // use tasksetCode when it doesn't exist in dropdown
    tasksetCollection: null,
    tasksetCollectionCode: '', // use tasksetCollectionCode when it doesn't exist in dropdown
    tasksetTaskToOverride: null,
    tasksetTaskCodeToOverride: ''
  };
  _this.taskOverrideApiObject = {
    selector: {
      fromLocation: '',
      tasksetCollectionCode: '',
      tasksetCode: ''
    },
    task: {
      code: '',
      taskTypeCode: '',
      taskTypeName: '',
      primaryRecipientRoleId: '',
      recipientGroupIds: [],
      ccRecipientRoleIds: []
    }
  };
  _this.showTaskOverride = false;
  _this.showTaskOverrideApiObject = true;

  _this.init = function () {
    for (var i = 0; i < _this.calltaskerConfigProperties.length; i++) {
      _this.getAndStoreCalltaskerConfig(_this.calltaskerConfigProperties[i]);
    }
    _this.compileDropdownListTasks();
    _this.compileDropdownListFromLocations();
    _this.resetTaskOverrideForm();
  };

  _this.resetTaskOverrideForm = function () {
    _this.resetSelectedTaskOverride();
    _this.resetTaskOverrideApiObject();
    _this.resetDependentLists();
  };

  _this.resetDependentLists = function (listUpdated) {
    switch (listUpdated) {
      case 'tasksetCollection':
        _this.compileDropdownListTasksetsFromSelectedTasksetCollection();
        _this.selectedTaskOverride.taskset = null;
        _this.compileDropdownListTasksFromSelectedTaskset();
        _this.selectedTaskOverride.tasksetTaskToOverride = null;
        break;
      case 'taskset':
        _this.compileDropdownListTasksFromSelectedTaskset();
        _this.selectedTaskOverride.tasksetTaskToOverride = null;
        break;
      case 'taskInTaskset':
        // not required to clear anything here
        break;
      default:
        _this.compileDropdownListTasksetsFromSelectedTasksetCollection();
        _this.selectedTaskOverride.taskset = null;
        _this.compileDropdownListTasksFromSelectedTaskset();
        _this.selectedTaskOverride.tasksetTaskToOverride = null;
    }
  };

  _this.resetSelectedTaskOverride = function () {
    _this.selectedTaskOverride.task = null;
    _this.selectedTaskOverride.fromLocation = null;
    _this.selectedTaskOverride.taskset = null;
    _this.selectedTaskOverride.tasksetCode = '';
    _this.selectedTaskOverride.tasksetCollection = null;
    _this.selectedTaskOverride.tasksetCollectionCode = '';
    _this.selectedTaskOverride.tasksetTaskToOverride = null;
    _this.selectedTaskOverride.tasksetTaskCodeToOverride = '';
    _this.selectedTaskOverride.recipientRoles.length = 0;
    _this.selectedTaskOverride.recipientRoleGroups.length = 0;
  };

  _this.resetTaskOverrideApiObject = function () {
    _this.taskOverrideApiObject.selector.fromLocation = '';
    _this.taskOverrideApiObject.selector.tasksetCollectionCode = '';
    _this.taskOverrideApiObject.selector.tasksetCode = '';
    _this.taskOverrideApiObject.task.code = '';
    _this.taskOverrideApiObject.task.taskTypeCode = '';
    _this.taskOverrideApiObject.task.taskTypeName = '';
    _this.taskOverrideApiObject.task.primaryRecipientRoleId = '';
    _this.taskOverrideApiObject.task.recipientGroupIds.length = 0;
    _this.taskOverrideApiObject.task.ccRecipientRoleIds.length = 0;
  };

  // Create Task Override
  _this.addRoleToSelectedTaskOverride = function (role) {
    _this.selectedTaskOverride.recipientRoles.push(role);
  };

  _this.addRoleGroupToSelectedTaskOverride = function (roleGroup) {
    _this.selectedTaskOverride.recipientRoleGroups.push(roleGroup);
  };

  _this.makeRolePrimaryRecipient = function (role) {
    // remove role from recipient roles array
    _.pull(_this.selectedTaskOverride.recipientRoles, role);
    // add role as the first to the recipient roles array
    _this.selectedTaskOverride.recipientRoles.splice(0, 0, role);
  };

  // Converts selectedTaskOverride into a taskOverrideApiObject ready for validation & creation on server
  _this.prepareTaskOverrideApiObject = function () {
    _this.taskOverrideApiObject.selector.fromLocation = _this.selectedTaskOverride.fromLocation.code;
    LogService.debug('Task Override API Object' + angular.toJson(_this.taskOverrideApiObject));
    _this.taskOverrideApiObject.selector.tasksetCollectionCode = _this.selectedTaskOverride.tasksetCollectionCode;
    _this.taskOverrideApiObject.selector.tasksetCode = _this.selectedTaskOverride.tasksetCode;
    if (_this.selectedTaskOverride.task) {
      _this.taskOverrideApiObject.task.code = _this.selectedTaskOverride.tasksetTaskCodeToOverride;
      _this.taskOverrideApiObject.task.taskTypeCode = _this.selectedTaskOverride.task.taskTypeCode;
      _this.taskOverrideApiObject.task.taskTypeName = _this.selectedTaskOverride.task.taskTypeName;
    }

    if (!_this.selectedTaskOverride.task && _this.selectedTaskOverride.tasksetTaskToOverride) {
      _this.taskOverrideApiObject.task.code = _this.selectedTaskOverride.tasksetTaskToOverride.code;
      _this.taskOverrideApiObject.task.taskTypeCode = _this.selectedTaskOverride.tasksetTaskToOverride.taskTypeCode;
      _this.taskOverrideApiObject.task.taskTypeName = _this.selectedTaskOverride.tasksetTaskToOverride.taskTypeName;
    }

    if (_this.selectedTaskOverride.recipientRoles.length > 0) {
      _this.taskOverrideApiObject.task.primaryRecipientRoleId = _this.selectedTaskOverride.recipientRoles[0].id;
      // primary recipient is the first object in recipientRoles array
      // remove the primary recipient from recipientRoles to get the ccRecipientRoles
      var ccRecipientRoles = _this.selectedTaskOverride.recipientRoles.slice(1);
      if (ccRecipientRoles.length > 0) {
        _.forEach(ccRecipientRoles, function (role) {
          _this.taskOverrideApiObject.task.ccRecipientRoleIds.push(role.id);
        });
      }
    }

    if (_this.selectedTaskOverride.recipientRoleGroups.length > 0) {
      _.forEach(_this.selectedTaskOverride.recipientRoleGroups, function (roleGroup) {
        _this.taskOverrideApiObject.task.recipientGroupIds.push(roleGroup.id);
      });
    }
  };

  // Task Override Scenarios:
  // 1. Primary recipient set only
  // 2. Primary recipient + CCs set
  // 3. Primary recipient + CCs + Role Group(s) set
  // 4. Primary recipient + Role Group(s) set - primary recipient should override what is set in the role group
  // 5. Role Group set only - primary recipient should use the first in the role group (as per current Medtasker behaviour)
  // 6. Role Group + CCs set - primary recipient should be set from role group
  // 7. CCs set only - should not be allowed
  _this.validateTaskOverrideApiObject = function () {
    _this.error = '';
    LogService.info('Validating Task Override');
    if (!_this.taskOverrideApiObject.selector.fromLocation) {
      _this.error += 'You must set a From Location. ';
    }
    if (!_this.taskOverrideApiObject.selector.tasksetCollectionCode || _this.taskOverrideApiObject.selector.tasksetCollectionCode.length === 0) {
      _this.error += 'You must enter a Taskset Collection Code. ';
    }
    if (!_this.taskOverrideApiObject.selector.tasksetCode) {
      _this.error += 'You must enter a Taskset Code. ';
    }
    if (!_this.taskOverrideApiObject.task || !_this.taskOverrideApiObject.task.code) {
      _this.error += 'You must enter the Code of the Task in the Taskset to override. ';
    }
    if (!_this.taskOverrideApiObject.task || !_this.taskOverrideApiObject.task.taskTypeCode) {
      _this.error += 'You must enter a Task Type Code. ';
    }
    if (!_this.taskOverrideApiObject.task || !_this.taskOverrideApiObject.task.taskTypeName) {
      _this.error += 'You must enter a Task Type Name. ';
    }
    if (!_this.taskOverrideApiObject.task.primaryRecipientRoleId && _this.taskOverrideApiObject.task.recipientGroupIds.length === 0) {
      // Covers scenario 7 above
      _this.error += 'You must set either a Primary Recipient or a Recipient Role Group. ';
    }
    if (_this.error.length !== 0) {
      return false;
    }
    LogService.info('---Task Override Validated!---');
    return true;
  };

  _this.createTaskOverrideOnServer = function () {
    // Don't update config on server if validation fails
    if (!_this.validateTaskOverrideApiObject()) {
      return;
    }

    // Get latest Calltasker Config, as we need to append Task Override to existing
    _this.getAndStoreCalltaskerConfig('taskOverride').then(function () {
      LogService.info('Calltasker Config Task Override: ' + angular.toJson(_this.calltaskerConfig.taskOverride));
      // Add taskOverrideApiObject to existing Calltasker Task Override Config
      _this.calltaskerConfig.taskOverride.push(_this.taskOverrideApiObject);
      CalltaskerAdminService.putTaskOverride(_this.calltaskerConfig.taskOverride).then(function (res) {
        LogService.info('Task Override sent to server' + angular.toJson(res.data));
        // Refresh config from API
        _this.getAndStoreCalltaskerConfig('taskOverride');
        // Reset TaskOverride form
        _this.resetTaskOverrideForm();
        // Show success modal
        DialogService.openMessageDialog('Task Override Created!', '', 'sent', { duration: 1500 });
      }, function (err) {
        var errorMsg = 'Error sending Task Override to server: ';
        _this.error = errorMsg + angular.toJson(err);
        LogService.error(err, 1, 'HttpCreateError');
        DialogService.errorMessage(errorMsg, err);
      });
    }, function (err) {
      _this.error = angular.toJson(err.data);
    });
  };

  // Compile Task List
  // - Return array of objects like below:
  //	{
  //	  'category': 'Admission',
  //	  'taskTypeCode': 'Admit Pt-ED',
  //	  'taskTypeName': 'Admit in ED',
  //	  'recipientRoleGroupName': 'Admission Team',
  //	  'userTypes': 'doctor, nurse',
  //	}
  _this.compileDropdownListTasks = function () {
    _this.taskList.length = 0;
    _.forEach(_this.config.categories, function (category) {
      _.forEach(category.subCategories, function (task) {
        _this.taskList.push({
          'category': category.display,
          'taskTypeCode': task.code,
          'taskTypeName': task.display,
          'recipientRoleGroupName': task.recipientRoleGroup ? task.recipientRoleGroup : '',
          'userTypes': _.join(task.userTypes, ', ')
        });
      });
    });
  };

  _this.compileDropdownListTasksetsFromSelectedTasksetCollection = function () {
    _this.tasksetsFromSelectedTasksetCollection.length = 0;
    if (!_this.selectedTaskOverride || !_this.selectedTaskOverride.tasksetCollection || _this.selectedTaskOverride.tasksetCollection.tasksetCodes === 0) {
      return;
    }

    _.forEach(_this.selectedTaskOverride.tasksetCollection.tasksetCodes, function (tasksetCodeInCollection) {
      _.forEach(_this.calltaskerConfig.taskset, function (tasksetInConfig) {
        if (tasksetInConfig.code === tasksetCodeInCollection) {
          _this.tasksetsFromSelectedTasksetCollection.push(tasksetInConfig);
        }
      });
    });
  };

  _this.compileDropdownListTasksFromSelectedTaskset = function () {
    _this.taskListFromSelectedTaskset.length = 0;
    if (!_this.selectedTaskOverride || !_this.selectedTaskOverride.taskset || _this.selectedTaskOverride.taskset.tasks.length === 0) {
      return;
    }

    _.forEach(_this.taskList, function (task) {
      _.forEach(_this.selectedTaskOverride.taskset.tasks, function (taskInTaskset) {
        if (taskInTaskset.taskTypeCode === task.taskTypeCode) {
          _this.taskListFromSelectedTaskset.push({
            'category': task.category,
            'taskTypeCode': task.taskTypeCode,
            'taskTypeName': task.taskTypeName,
            'recipientRoleGroupName': task.recipientRoleGroupName,
            'userTypes': task.userTypes,
            'code': taskInTaskset.code,
            'primaryRecipientRoleId': taskInTaskset.primaryRecipientRoleId,
            'recipientGroupIds': taskInTaskset.recipientGroupIds,
            'ccRecipientRoleIds': taskInTaskset.ccRecipientRoleIds
          });
        }
      });
    });
  };

  _this.compileDropdownListFromLocations = function () {
    _.forEach(_this.config.customTaskFields, function (field) {
      if (field.name === 'from') {
        _.forEach(field.values, function (value) {
          _this.fromLocations.push(value);
        });
      }
    });
  };

  // formatDisplayString is used to display options in a dropdown list
  // assumes an object has a code property and
  // either a name or display property
  _this.formatDropdownDisplayString = function (object) {

    if (!object.code) {
      return '';
    }
    if (object.name) {
      return object.name + ' (' + object.code + ')';
    }
    if (object.display) {
      return object.display + ' (' + object.code + ')';
    }
    return '';
  };

  // displayTaskForSelection
  // Format display of Task in dropdown list as "Category - Task Type"
  _this.formatDropdownDisplayStringTask = function (task) {
    if (!task.category || !task.taskTypeName) {
      return '';
    }
    // task.code is the code set on the taskset for the task to be overridden
    if (!task.code) {
      return task.category + ' - ' + task.taskTypeName + ' (' + task.userTypes + ')';
    }
    return task.category + ' - ' + task.taskTypeName + ' (' + task.userTypes + ') ' + '[' + task.code + ']';
  };

  _this.getAndStoreCalltaskerConfig = function (property) {
    var deferred = $q.defer();
    CalltaskerAdminService.getCalltaskerConfigFromAPI(property).then(function (res) {
      LogService.debug('Calltasker config retrieved from API: ' + angular.toJson(res.data));
      _this.calltaskerConfig[property] = res.data;
      deferred.resolve();
    }, function (err) {
      _this.error = 'Error getting calltasker config from API: ' + angular.toJson(err);
      LogService.error(err, 1, 'HttpGetError');
      deferred.reject(err);
    });
    return deferred.promise;
  };

  _this.getAndStoreCalltaskerConfigForDevice = function (tasksetCollection, fromLocation) {
    _this.error = '';
    if (!tasksetCollection) {
      _this.error = 'Please enter Taskset Collection';
      return;
    }
    if (!fromLocation) {
      _this.error = 'Please enter From Location';
      return;
    }
    CalltaskerAdminService.getCalltaskerConfigForDevice(tasksetCollection, fromLocation).then(function (res) {
      LogService.debug('Calltasker Device-specific Config: ' + angular.toJson(res.data));
      _this.calltaskerConfigForDevice = res.data;
    }, function (err) {
      _this.error = 'Error getting device-specific config: ' + angular.toJson(err);
      LogService.error(err, 1, 'HttpGetError');
    });
  };

  // Filter
  _this.seniority = function (role) {
    return _.indexOf(config.mtRoleLevels, _.find(config.mtRoleLevels, { 'code': role.level.code }));
  };

  _this.init();
}]);