'use strict';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('medtasker').factory('ShiftService', ['AppService', 'ResourceService', 'UrlService', '$http', 'uuid', '$q', '_', '$log', '$timeout', 'config', 'moment', function (AppService, ResourceService, UrlService, $http, uuid, $q, _, $log, $timeout, config, moment) {

	var _ctx = void 0;
	var _startTime = moment(config.viewPeriodStartTime, 'HH:mm');

	function _updateRoles(team) {
		var roles = _.filter(_ctx.roles, function (role) {
			return role.organization.id === team.id;
		});
		return _.sortBy(roles, 'level');
	}

	function _fromMomentDuration(duration) {
		var hours = duration.hours() + duration.days() * 24;
		return sprintf('%02d:%02d', hours, duration.minutes()); // eslint-disable-line no-undef
	}

	var createShiftsParam = function createShiftsParam(roleIds, schedules, times, applyDate, override) {
		_classCallCheck(this, createShiftsParam);

		this.roleIds = roleIds;
		this.schedules = schedules;
		this.applyDate = moment(applyDate).format('YYYY-MM-DD');
		this.overrideExisting = override;
		this.times = _.map(times, function (t) {
			return {
				start: _fromMomentDuration(t.start),
				duration: _fromMomentDuration(t.duration)
			};
		});
	};

	var changeShiftParam = function changeShiftParam(shiftId, start, duration, applyDate, schedules) {
		_classCallCheck(this, changeShiftParam);

		this.shiftId = shiftId;
		this.start = _fromMomentDuration(start);
		this.duration = _fromMomentDuration(duration);
		this.applyFrom = moment(applyDate).format('YYYY-MM-DD');
		this.schedules = schedules;
	};

	var clearChangePointParam = function clearChangePointParam(roleId, cpdate, schedule) {
		_classCallCheck(this, clearChangePointParam);

		this.roleId = roleId;
		this.date = cpdate.format('YYYY-MM-DD');
		this.schedule = schedule;
	};

	var deleteShiftParam = function deleteShiftParam(shiftId, deleteFromDate) {
		_classCallCheck(this, deleteShiftParam);

		this.shiftId = shiftId;
		this.deleteFrom = deleteFromDate.format('YYYY-MM-DD');
	};

	var cloneShiftParam = function cloneShiftParam(roleIds, fromSchedule, toSchedules, applyDate, override) {
		_classCallCheck(this, cloneShiftParam);

		this.roleIds = roleIds;
		this.fromSchedule = fromSchedule;
		this.toSchedules = toSchedules;
		this.applyDate = moment(applyDate).format('YYYY-MM-DD');
		this.overrideExisting = override;
	};

	var o = {

		// Return the context object
		init: function init(team, campusId) {
			var deferred = $q.defer();
			AppService.init().then(function () {
				_ctx = AppService.ctx;
				AppService.loadAllShiftAggregates().then(function () {
					var schedules = _.map(config.shiftSchedules, 'code');
					AppService.loadShifts(schedules, team).then(function () {
						var campus = void 0;
						if (campusId) {
							campus = _.find(AppService.campuses(), function (c) {
								return c.id === campusId;
							});
						} else {
							campus = _.find(AppService.campuses(), function (c) {
								return c.name === 'All';
							});
						}
						_ctx.timeSpan = 1;
						_ctx.viewTimes = [];
						_ctx.selectedTeam = null;
						_ctx.selectedRole = null;
						_ctx.selectedCampus = campus;
						_ctx.teamRoles = [];
						_ctx.viewPeriod = {
							start: moment(_startTime),
							end: moment(_startTime).add(moment.duration(1, 'day'))
						};
						deferred.resolve(_ctx);
					});
				});
			});
			return deferred.promise;
		},

		selectTeam: function selectTeam(team) {
			_ctx.selectedTeam = team;
			_ctx.selectedRole = null;

			// Set the next and previous teams from an alphabetised list of organizations with shifts
			//   (based on team identifier)
			var teams = _.sortBy(o.teamsByCampus(), ['partOf.location[0].identifier[0].value', 'name']);
			var selectedTeamIndex = _.findIndex(teams, team);
			if (selectedTeamIndex !== -1) {
				_ctx.nextTeam = selectedTeamIndex === teams.length - 1 ? teams[0] : teams[selectedTeamIndex + 1];
				_ctx.prevTeam = selectedTeamIndex > 0 ? teams[selectedTeamIndex - 1] : teams[teams.length - 1];
			}

			_ctx.teamRoles = _updateRoles(team);
		},

		selectRole: function selectRole(role) {
			_ctx.selectedRole = role;
		},

		selectShift: function selectShift(rshift) {
			_ctx.selectedShift = rshift; // roster shift
		},

		teamsByCampus: function teamsByCampus(ctx) {
			ctx = ctx || _ctx;
			if (!ctx.selectedCampus || !ctx.selectedCampus.id) {
				return _.sortBy(_.filter(_.filter(ctx.organizations, o.teamsOnly), function (t) {
					return t.identifier[0].value !== 'UNKNOWN_TEAM';
				}), 'name');
			}
			return _.sortBy(_.filter(_.filter(ctx.organizations, o.teamsOnly), function (t) {
				return t.identifier[0].value !== 'UNKNOWN_TEAM' && t.partOf && t.partOf.location && t.partOf.location[0].id === ctx.selectedCampus.id;
			}), 'name');
		},

		formatCampus: function formatCampus(c) {
			if (!c) {
				return '';
			}
			if (!c.id) {
				return '--' + c.name + '--';
			}
			return '(' + c.identifier[0].value + ') ' + c.name;
		},

		createShifts: function createShifts(roleIds, schedules, times, applyDate, override) {
			var p = new createShiftsParam(roleIds, schedules, times, applyDate, override);
			var url = UrlService.shiftsUrl() + '/create';
			var req = {
				method: 'POST',
				url: url,
				headers: { 'Content-Type': 'application/json' },
				data: p
			};
			return $http(req);
		},

		cloneShifts: function cloneShifts(roleIds, schedules, times, applyDate, override) {
			var p = new cloneShiftParam(roleIds, schedules, times, applyDate, override);
			var url = UrlService.shiftsUrl() + '/clone';
			var req = {
				method: 'POST',
				url: url,
				headers: { 'Content-Type': 'application/json' },
				data: p
			};
			return $http(req);
		},

		shiftsForRoleDateAndSchedule: function shiftsForRoleDateAndSchedule(date, roleId, schedule) {
			return _.filter(_ctx.shifts, function (s) {
				return s.role.id === roleId && s.schedule.code === schedule.code && +s.validPeriod.start <= +date && (s.validPeriod.end ? +s.validPeriod.end > +date : true);
			});
		},

		roleSeniority: function roleSeniority(role) {
			return _.indexOf(config.mtRoleLevels, _.find(config.mtRoleLevels, { 'code': role.level.code }));
		},

		getChangePoints: function getChangePoints(roleId, schedule) {
			var url = UrlService.shiftsUrl() + ('/change-points?roleId=' + roleId + '&schedule=' + schedule.code);
			var req = {
				method: 'GET',
				url: url
			};
			var d = $q.defer();
			$http(req).then(function (res) {
				d.resolve(_.map(res.data, function (cp) {
					return moment(cp);
				}));
			}, function (err) {
				d.reject(err);
			});
			return d.promise;
		},

		clearChangePoint: function clearChangePoint(roleId, date, schedule) {
			var p = new clearChangePointParam(roleId, date, schedule);
			var url = UrlService.shiftsUrl() + '/clear-change-point';
			var req = {
				method: 'POST',
				url: url,
				headers: { 'Content-Type': 'application/json' },
				data: p
			};
			return $http(req);
		},

		changeShift: function changeShift(shiftId, start, duration, applyFrom, schedules) {
			var p = new changeShiftParam(shiftId, start, duration, applyFrom, schedules);
			var url = UrlService.shiftsUrl() + '/change';
			var req = {
				method: 'POST',
				url: url,
				headers: { 'Content-Type': 'application/json' },
				data: p
			};
			return $http(req);
		},

		deleteShift: function deleteShift(shiftId, deleteFrom) {
			var p = new deleteShiftParam(shiftId, deleteFrom);
			var url = UrlService.shiftsUrl() + '/delete';
			var req = {
				method: 'POST',
				url: url,
				headers: { 'Content-Type': 'application/json' },
				data: p
			};
			return $http(req);
		},

		// Filter
		// Excludes health services and hospitals
		teamsOnly: function teamsOnly(org) {
			return org.active && org.type === 'team' || org.type === 'subteam' || org.type === 'ward';
		},

		// Create the rows of cells for the given schedule
		// that have shifts or no shifts. A shift can result in more than two cells if it spans the end of the view period
		updateCells: function updateCells(schedule, startHour, totalHours, applyDate) {
			//var startHour = _ctx.viewPeriod.start.hour();
			var endHour = startHour + totalHours;
			// //return a time shifted to the reference frame of the view period
			function viewTime(t, noWrap) {
				var vt = moment.duration(t).subtract(startHour, 'hours');
				return noWrap ? vt : AppService.wrapDuration(vt);
			}
			//return a view time as a real time
			function realTime(t, noWrap) {
				var rt = moment.duration(t).add(startHour, 'hours');
				return noWrap ? rt : AppService.wrapDuration(rt);
			}
			//start and end are millisecond values from the view start time
			function makeEmptyCells(start, end, cells, role) {
				var s = moment.duration(start);
				var e = moment.duration(end);
				var realStart = realTime(s);
				var realEnd = realTime(e);
				if (realEnd <= realStart) {
					realEnd.add(1, 'days');
				}
				var roleStart = startHour > role.start.asHours() ? role.start.add(1, 'days') : role.start;

				// if empty cell will span the role start time boundary then create two cells

				if (realStart < roleStart && realEnd > roleStart) {
					var emptyCell1 = {
						cellStart: realStart,
						cellEnd: roleStart,
						start: s,
						duration: moment.duration(roleStart - realStart),
						role: role,
						shift: null };
					cells.push(emptyCell1);
					var emptyCell2 = {
						cellStart: AppService.wrapDuration(roleStart),
						cellEnd: AppService.wrapDuration(realEnd),
						start: AppService.wrapDuration(viewTime(roleStart)),
						duration: moment.duration(realEnd - roleStart),
						role: role,
						shift: null };
					cells.push(emptyCell2);
				} else {
					var emptyCell = {
						cellStart: AppService.wrapDuration(realStart),
						cellEnd: AppService.wrapDuration(realEnd),
						start: s,
						duration: moment.duration(realEnd - realStart),
						role: role,
						shift: null };
					// Add en empty cell before the cell with a shift
					cells.push(emptyCell);
				}
			}

			var cells = {};

			_.forEach(_ctx.teamRoles, function (role) {

				// STEP ONE:
				// Create the cells
				// Cells are used to display shifts (or lack of shifts)
				// If a shift straddles the last hour in the view, two cells are used to display it; one at the
				// beginning of the row and one at the end.

				//the offset of the cells from the left
				var shifts = _.filter(_ctx.shifts, function (s) {
					return s.role.id === role.id && s.schedule.code === schedule.code && +s.validPeriod.start <= +applyDate && (s.validPeriod.end ? +s.validPeriod.end >= +applyDate : true);
				});

				// Create cells for the shifts
				var cell = void 0;
				var cells1 = _.transform(shifts, function (c1, shift) {
					var start = shift.start.asHours();
					if (start < startHour) {
						start += 24;
					}
					var end = start + shift.duration.asHours();

					var openRight = false;
					if (end > endHour) {
						// The shift wraps from the beginning: create the cell at the start of the period for the overlap duration
						cell = {
							cellStart: moment.duration(startHour, 'hours'),
							cellEnd: AppService.wrapDuration(moment.duration(end - endHour + startHour, 'hours')),
							start: moment.duration(0, 'hours'),
							duration: moment.duration(end - endHour, 'hours'),
							shift: shift,
							openLeft: true
						};
						c1.unshift(cell);
						end = endHour; //truncate shift cell at end of view period.
						openRight = true;
					}
					cell = {
						cellStart: moment.duration(start, 'hours'),
						cellEnd: AppService.wrapDuration(moment.duration(end, 'hours')),
						start: moment.duration(start - startHour, 'hours'),
						duration: moment.duration(end - start, 'hours'),
						shift: shift,
						openRight: openRight
					};
					c1.push(cell);
				});

				// Order cells by time
				var cells2 = _.sortBy(cells1, function (c) {
					return +c.start;
				});

				// Create cells for the empty periods (without shifts)
				var last = 0;
				var cells3 = _.transform(cells2, function (c3, c) {
					if (+c.start > last) {
						makeEmptyCells(last, c.start, c3, role);
					}
					c3.push(c);
					last = c.start + c.duration;
				});
				// If there's empty space at the end of a row, create an emptyCell for it
				var oneDay = moment.duration(24, 'hours');
				if (last < +oneDay) {
					makeEmptyCells(last, moment.duration(oneDay), cells3, role);
				}

				// STEP TWO:
				// Create "empty shifts" for the empty cells
				// Empty shifts are used to create shifts
				var firstShift = null;
				_.forEach(cells3, function (c) {
					if (!c.shift) {
						var newShift = true; //we need to make sure that 'firstShift' does not get added to the shift duration in the case that
						//an empty shift goes exactly to the end of but does not overlap the view period.
						// Empty shifts are only local objects. No MtShift is created.
						var emptyShift = {
							type: 'empty',
							id: ResourceService.generateId(),
							role: c.role,
							start: moment.duration(c.cellStart),
							duration: moment.duration(c.duration)
						};
						c.shift = emptyShift;
						if (!firstShift) {
							// Keep the first shift in case it's an extension of the last shift in the row
							firstShift = emptyShift;
							newShift = false;
						}

						// if c starts at > 0 and finished at the end of the row we assume it overlaps the end of the view period
						var rs = c.role.start.asHours(); //don't join cells if the end of the view period and role period coincide
						if (c.start > 0 && newShift && rs !== startHour && c.start + c.duration === moment.duration(24, 'hours').asMilliseconds()) {
							// Make the empty shift spans across midnight
							firstShift.start = c.cellStart;
							firstShift.duration.add(c.duration);
							c.shift = firstShift;
						} else {
							c.shift = emptyShift;
						}
					}
				});
				cells[role.id] = cells3;
			});
			return cells;
		}
	};
	return o;
}]);