'use strict';

angular.module('medtasker').factory('TodaysRosterService', ['LogService', 'UrlService', '$http', 'ResourceService', 'AuthService', function (LogService, UrlService, $http, ResourceService, AuthService) {

  var o = {

    init: function init() {},
    getRosterForPrint: function getRosterForPrint(start, end, selectedCampus, selectedTeam) {
      var params = null;
      // if _ctx.selectedTeam
      // log.debug('getRosterForPrint ' + angular.toJson(_ctx.selectedTeam));
      // use id of 0 to designate all wards . 
      // Not entrely certain how the const definition works in javascript
      // when a ward is selected pass in team id else pass team id as empty
      if (selectedTeam && selectedTeam.id !== 0) {
        params = ResourceService.objectToUrlParams({
          from: start.format('YYYY-MM-DDTHH:mm:ss'),
          to: end.format('YYYY-MM-DDTHH:mm:ss'),
          campus: selectedCampus.id,
          team: selectedTeam.id,
          print: true
        });
      } else {
        params = ResourceService.objectToUrlParams({
          from: start.format('YYYY-MM-DDTHH:mm:ss'),
          to: end.format('YYYY-MM-DDTHH:mm:ss'),
          campus: selectedCampus.id,
          team: '',
          print: true
        });
      }
      var url = UrlService.rosterUrl() + '/full?' + params;
      AuthService.setHttpAuthHeader();
      var req = {
        method: 'GET',
        url: url
      };
      req.headers = { 'Accept': 'application/json' };
      return $http(req);
    }
  };

  return o;
}]);