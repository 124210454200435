'use strict';

/* global angular */
angular.module('medtasker.service').factory('ReconnectService', ['$interval', 'AppService', 'EventService', 'ResourceService', 'AUTH_EVENTS', 'AuthService', '$rootScope', function ($interval, AppService, EventService, ResourceService, AUTH_EVENTS, AuthService, $rootScope) {
	'use strict';

	var retryDelay = [1, 1, 1, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 27];
	var o = {
		attemptReconnect: function attemptReconnect(feed) {
			if (AuthService.sessionExpired()) {
				$rootScope.$broadcast(AUTH_EVENTS.tokenExpired);
				return;
			}
			ResourceService.connectionStatus.serverUnavailable = true;
			AppService.appStatus.reconnectAttempt = AppService.appStatus.reconnectAttempt || 0;
			if (AppService.appStatus.reconnectAttempt) {
				return; //ignore connectionLost messages triggered by our own attempts to connect
			}

			AppService.appStatus.reconnect = function () {
				if (AuthService.sessionExpired() || !AuthService.isAuthenticated('user')) {
					o.cancelReconnection();
					ResourceService.connectionStatus.serverUnavailable = false;
					return;
				}
				var t = void 0;
				if (AppService.appStatus.reconnectAttempt > retryDelay.length - 1) {
					t = retryDelay[retryDelay.length - 1];
				} else {
					t = retryDelay[AppService.appStatus.reconnectAttempt];
				}
				AppService.appStatus.reconnectAttempt += 1;
				AppService.appStatus.reconnectCountdown = t;
				AppService.appStatus.reconnectMessage = 'Reconnecting in ' + AppService.appStatus.reconnectCountdown.toString() + ' seconds...';
				AppService.appStatus.reconnectInterval = $interval(function () {
					if (AppService.appStatus.reconnectCountdown === 0) {
						EventService.reset();
						EventService.recreateEventSource(feed);
						$interval.cancel(AppService.appStatus.reconnectInterval);
						AppService.appStatus.reconnect();
					} else {
						AppService.appStatus.reconnectMessage = 'Reconnecting in ' + AppService.appStatus.reconnectCountdown.toString() + ' seconds...';
					}
					AppService.appStatus.reconnectCountdown -= 1;
				}, 1000);
			};
			AppService.appStatus.initOnReconnect = true;
			AppService.appStatus.reconnect();
		},

		cancelReconnection: function cancelReconnection() {
			if (AppService.appStatus.reconnectInterval) {
				$interval.cancel(AppService.appStatus.reconnectInterval);
				AppService.appStatus.reconnectInterval = null;
				AppService.appStatus.reconnectAttempt = 0;
			}
		}
	};
	return o;
}]);