'use strict';

angular.module('medtasker').controller('AggregateNewCtrl', ['ShiftService', 'AggregateService', '$log', '_', 'moment', '$modalInstance', 'modalArgs', '$scope', function (ShiftService, AggregateService, $log, _, moment, $modalInstance, modalArgs, $scope) {
	// eslint-disable-line

	var _this = this;
	_this.day = modalArgs.day;
	_this.aggregateName = null;

	_this.save = function () {
		_this.triedSave = true;
		AggregateService.create(_this.aggregateName, 'shift', _this.day).then(function (res) {
			$modalInstance.close(res);
		});
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};
}]);