
'use strict';

angular.module('medtasker').controller('ShiftCloneCtrl', ['AppService', 'ShiftService', 'RosterService', 'DialogService', 'ResourceService', 'config', 'modalArgs', '$modalInstance', '$timeout', '$scope', '$rootScope', 'moment', 'LogService', '$q', '_', '$modal', function (AppService, ShiftService, RosterService, DialogService, ResourceService, config, modalArgs, $modalInstance, $timeout, $scope, $rootScope, moment, LogService, $q, _, $modal) {

	var _this = this;
	_this.roles = modalArgs.roles;
	_this.applyDate = modalArgs.applyDate;
	_this.schedule = modalArgs.schedule;
	_this.schedules = config.shiftSchedules;
	_this.selCodes = {};
	_this.allCodes = false;
	_this.includeRole = {};

	function init() {
		_this.selCodes[_this.schedule.code] = true;
		_this.shiftRoles = _.reduce(_this.roles, function (res, role) {
			_this.includeRole[role.id] = true;
			res[role.id] = ShiftService.shiftsForRoleDateAndSchedule(_this.applyDate, role.id, _this.schedule);
			return res;
		}, {});
	}

	init();

	_this.hasShifts = function (role) {
		return _this.shiftRoles[role.id] && _this.shiftRoles[role.id].length > 0;
	};

	_this.scheduleChanged = function (sch) {
		_this.selCodes[sch.code] = !_this.selCodes[sch.code];
		if (_this.allCodes && !_this.selCodes[sch.code]) {
			_this.allCodes = false;
		}
	};

	_this.toggleIncludeRole = function (id) {
		_this.includeRole[id] = !_this.includeRole[id];
	};

	_this.toggleAllCodes = function () {
		_this.allCodes = !_this.allCodes;
		_.forEach(_this.schedules, function (sch) {
			if (_this.schedule.code !== sch.code) {
				_this.selCodes[sch.code] = _this.allCodes;
			}
		});
	};

	_this.showTime = function (t) {
		return moment(t).format('HH:mm');
	};

	_this.invalid = function () {
		var roleSelected = _.some(_.keys(_this.includeRole), function (k) {
			return _this.includeRole[k];
		});
		var scheduleSelected = _.some(_.keys(_this.selCodes), function (k) {
			return _this.selCodes[k] && k !== _this.schedule.code;
		});
		return !roleSelected || !scheduleSelected;
	};

	_this.seniority = ShiftService.roleSeniority;

	_this.save = function () {
		// Exclude the original schedule since MtShifts have already been created for those.
		var schedules = _.transform(_this.selCodes, function (accum, selected, code) {
			if (selected && _this.schedule.code !== code) {
				var sch = _.find(_this.schedules, { code: code });
				accum.push(sch);
			}
		}, []);
		$rootScope.$emit('appLoadingPush', { source: 'cloneShifts' });
		var rids = _.keys(_this.includeRole);
		ShiftService.cloneShifts(rids, _this.schedule.code, _.map(schedules, 'code'), _this.applyDate, _this.overwriteExisting).then(function () {
			$rootScope.$emit('appLoadingPop', { source: 'cloneShifts' });
			var modalOptions = {
				template: '	<div class="text-center"><h1 id="confirm-sent" style="margin: 25px">Shifts cloned</h1></div> ', // eslint-disable-line
				backdrop: true,
				size: 'sm'
			};
			var clonedShiftsModal = $modal.open(modalOptions);
			$timeout(function () {
				clonedShiftsModal.close();
				$modalInstance.close();
			}, 1500);
		}, function (err) {
			$rootScope.$emit('appLoadingPop', { source: 'cloneShifts' });
			DialogService.errorMessage('An error occurred trying to clone shifts.', err);
			LogService.error('Error in clone shifts: ' + err.data);
		});
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.endOfShift = function (shift) {
		return AppService.formatDuration(AppService.wrapDuration(moment.duration(shift.start + shift.duration)));
	};
}]);