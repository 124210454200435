'use strict';

/* global angular */

angular.module('medtasker.service').factory('AuthService', ['UrlService', '$rootScope', '$http', '$window', '$q', 'LogService', '_', 'jwtHelper', '$state', 'USER_ROLES', 'AUTH_EVENTS', 'moment', 'Idle', function (UrlService, $rootScope, $http, $window, $q, log, _, jwtHelper, $state, USER_ROLES, AUTH_EVENTS, moment, Idle) {
	'use strict';

	var _userSession = {
		name: 'userSession',
		id: null, // null = not authenticated
		token: '',
		expirationDate: null,
		displayName: '',
		authRoles: [],
		authRolesByFunction: {}, //map of two-part claims to groups, for example if authRoles includes 'roster.GENMED' and 'roster.GENSURG', then this object will include a key of 'roster' and a value of ['GENMED','GENSURG']
		apns: '',
		gcm: '',
		oauthLoginHint: ''
	};

	var _wardSession = {
		name: 'wardSession',
		id: null, // null = not authenticated
		token: '',
		expirationDate: null,
		displayName: '',
		currentWard: ''
	};

	function makeAuthMap(claims) {
		var map = _.reduce(claims, function (accum, c) {
			if (c.indexOf('.') === -1) {
				accum[c] = [];
			} else {
				var c2 = c.split('.');
				(accum[c2[0]] || (accum[c2[0]] = [])).push(c2[1]);
			}
			return accum;
		}, {});
		return map;
	}

	function _storeSession(session, persist) {
		var storage = persist ? $window.localStorage : $window.sessionStorage;
		storage.setItem(session.name, angular.toJson(session));
	}

	function _retrieveSession() {
		if ($window.sessionStorage.userSession) {
			return angular.fromJson($window.sessionStorage.userSession);
		} else if ($window.localStorage.userSession) {
			return angular.fromJson($window.localStorage.userSession);
		}
		return null;
	}

	function _removeSession(name) {
		$window.localStorage.removeItem(name);
		$window.sessionStorage.removeItem(name);
	}

	var o = {
		setSessionInfo: function setSessionInfo(token, ward) {
			_userSession.oauthLoginHint = token.oauthLoginHint;
			o.createSession(_userSession, token.user, token.userToken, token.expires, null, false);
			o.createSession(_wardSession, token.user, token.userToken, token.expires, ward); //don't ever persist the ward token
			o.setHttpAuthHeader(); // HACK - move out of login()
			$rootScope.$broadcast(AUTH_EVENTS.loginUserSuccess);
		},

		// Don't call directly. Use AppService.login() instead
		login: function login(credentials, ward, persist) {
			var deferred = $q.defer();
			var conf = {
				method: 'POST',
				withCredentials: true,
				url: UrlService.ldapUrl(),
				headers: {
					'Content-Type': 'application/json'
				},
				data: credentials
			};
			$http(conf).then(function (res) {
				_userSession.oauthLoginHint = '';
				_wardSession.oauthLoginHint = '';
				o.createSession(_userSession, res.data.User, res.data.User.userToken, res.data.expires, null, persist);
				o.createSession(_wardSession, res.data.User, res.data.User.idleToken, res.data.expires, ward); //don't ever persist the ward token
				o.setHttpAuthHeader(); // HACK - move out of login()
				$rootScope.$broadcast(AUTH_EVENTS.loginUserSuccess);
				deferred.resolve();
			}, function (err) {
				$rootScope.$broadcast(AUTH_EVENTS.loginUserFailed);
				if (err.status !== 401) {
					// Ignore if the error is due to incorrect credentials
					log.error(err, 1, 'HttpLoginFailure');
				}
				deferred.reject(err);
			});
			return deferred.promise;
		},

		// Don't call directly. Use AppService.logout() instead
		logout: function logout(type) {
			switch (type) {
				case 'ward':
					// destroy session & clear header for both _wardSession & _userSession
					o.destroySession(_wardSession);
					o.clearHttpAuthHeader(_wardSession);
					o.destroySession(_userSession);
					o.clearHttpAuthHeader(_userSession);
					$rootScope.$broadcast(AUTH_EVENTS.logoutWardSuccess);
					break;

				default:
					o.destroySession(_userSession);
					o.clearHttpAuthHeader(_userSession);
					o.setHttpAuthHeader(); // Set HTTP header to wardSession token.
					$rootScope.$broadcast(AUTH_EVENTS.logoutUserSuccess);
					break;
			}
		},
		// Return true only if the userSession or wardSession have a role that
		// matches one of the authorizedRoles
		isAuthorized: function isAuthorized(authorizedRoles) {
			// Ensures authorizedRoles is an array.
			if (!angular.isArray(authorizedRoles)) {
				authorizedRoles = [authorizedRoles];
			}
			// If the page / state is available to the public, anyone is authorized to view it.
			if (authorizedRoles.indexOf(USER_ROLES.public) !== -1) {
				return true;
			}

			if (authorizedRoles.indexOf(USER_ROLES.ward) !== -1) {
				return o.isAuthenticated('ward');
			}

			// Checks to see if you're authenticated
			if (o.isAuthenticated('user')) {
				//return true if there is some claim = r
				for (var i = 0; i < _userSession.authRoles.length; i++) {
					if (authorizedRoles.indexOf(_userSession.authRoles[i]) !== -1) {
						return true;
					}
				}
				//return true if there is some claim of form <r>.*
				if (_.some(authorizedRoles, function (r) {
					return _userSession.authRolesByFunction[r] && _userSession.authRolesByFunction[r].length > 0;
				})) {
					return true;
				}
				$rootScope.$broadcast(AUTH_EVENTS.notAuthorized);
				return false;
			}
			$rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
			return false;
		},

		// type = 'user' or 'ward' or undefined
		isAuthenticated: function isAuthenticated(type) {
			var userAuth = !!(_userSession.id && moment().isBefore(moment(_userSession.expirationDate)));
			var wardAuth = !!(_wardSession.id && moment().isBefore(moment(_wardSession.expirationDate)));
			switch (type) {
				case 'user':
					return userAuth;
				case 'ward':
					return wardAuth;
				default:
					return userAuth && wardAuth;
			}
		},

		createSession: function createSession(session, user, token, expires, ward, persist) {
			session.id = user.id;
			session.token = token;
			session.expirationDate = new Date(expires * 1000);
			session.displayName = user.username;
			if (session.name === 'wardSession') {
				session.currentWard = ward;
			} else {
				session.authRoles = user.roles;
				session.authRolesByFunction = makeAuthMap(user.roles);
			}
			_storeSession(session, persist);
		},

		destroySession: function destroySession(session) {
			session.id = null; // not authenticated
			session.token = '';
			session.expirationDate = null;
			session.displayName = '';
			session.authRoles = [];
			session.oauthLoginHint = '';
			if (session.name === 'wardSession') {
				session.currentWard = '';
			}
			_removeSession(session.name);
		},

		sessionExpired: function sessionExpired() {
			if (_userSession && _userSession.expirationDate) {
				return moment().isAfter(moment(_userSession.expirationDate));
			}
			if (_wardSession && _wardSession.expirationDate) {
				return moment().isAfter(moment(_wardSession.expirationDate));
			}
			return true;
		},

		sessionExpiryTime: function sessionExpiryTime() {
			if (_userSession) {
				return moment(_userSession.expirationDate);
			}
			if (_wardSession) {
				return moment(_wardSession.expirationDate);
			}
			return null;
		},

		setHttpAuthHeader: function setHttpAuthHeader() {
			// token didn't come with auth, so will be sent with cookies instead (if those are set)
			if (_wardSession.token === '' && _userSession.token === '') {
				return;
			}
			if (!o.isAuthenticated('user')) {
				if (o.isAuthenticated('ward')) {
					$http.defaults.headers.common.Authorization = 'Bearer ' + _wardSession.token;
				}
				return;
			}
			$http.defaults.headers.common.Authorization = 'Bearer ' + _userSession.token;
		},

		clearHttpAuthHeader: function clearHttpAuthHeader(session) {
			switch (session) {
				// Switch to _wardSession token when _userSession cleared
				case '_userSession':
					$http.defaults.headers.common.Authorization = _wardSession.token;
					break;

				// Remove all tokens from AuthHeader if _wardSession cleared
				default:
					$http.defaults.headers.common.Authorization = null;
					break;
			}
		},

		reloadSessions: function reloadSessions() {
			var session = _retrieveSession();
			if (session) {
				_userSession = session;
				Idle.watch();
				//log.debug('watching for user idle state');
				$rootScope.$broadcast(AUTH_EVENTS.userSessionRestored);
			}
			if ($window.sessionStorage.wardSession) {
				_wardSession = angular.fromJson($window.sessionStorage.wardSession);
				$rootScope.$broadcast(AUTH_EVENTS.wardSessionRestored);
			}
		},

		userId: function userId() {
			return _userSession.id;
		},

		setDeviceToken: function setDeviceToken(type, deviceToken) {
			if (type === 'apns') {
				_userSession.apns = deviceToken;
			} else if (type === 'gcm') {
				_userSession.gcm = deviceToken;
			}
		},

		getDeviceToken: function getDeviceToken(type) {
			if (type === 'apns') {
				return _userSession.apns;
			} else if (type === 'gcm') {
				return _userSession.gcm;
			}
			return void 0;
		},

		// TODO: change to JWT
		token: function token() {
			return _userSession.token || _wardSession.token;
		},

		getCurrentWard: function getCurrentWard() {
			return _wardSession.currentWard;
		},

		setCurrentWard: function setCurrentWard(ward) {
			_wardSession.currentWard = ward;
		},

		wardId: function wardId() {
			return _wardSession.id;
		},

		userDisplayName: function userDisplayName() {
			return _userSession.displayName;
		},
		oauthLoginHint: function oauthLoginHint() {
			return _userSession.oauthLoginHint;
		},
		roles: function roles() {
			return _userSession.authRoles;
		},

		belongsTo: function belongsTo(group) {
			return _.some(_userSession.authRoles, function (role) {
				return role === group;
			});
		},

		authRolesByFunction: function authRolesByFunction(fn) {
			return _userSession.authRolesByFunction[fn];
		},

		hasAdminPriv: function hasAdminPriv() {
			return _.some(_userSession.authRoles, function (role) {
				return role === 'admin';
			});
		},

		hasManagerPriv: function hasManagerPriv() {
			return _.some(_userSession.authRoles, function (role) {
				return role === 'manager';
			});
		},

		hasRosterPriv: function hasRosterPriv() {
			return _.some(_userSession.authRoles, function (role) {
				return role === 'rosteradmin';
			}) || _userSession.authRolesByFunction.roster;
		},

		hasSwitchPriv: function hasSwitchPriv() {
			return _.some(_userSession.authRoles, function (role) {
				return role === 'switch';
			});
		}
	};
	return o;
}]).factory('AuthInterceptor', ['$rootScope', '$q', 'AUTH_EVENTS', function ($rootScope, $q, AUTH_EVENTS) {
	'use strict';

	return {
		responseError: function responseError(response) {
			if (response.status === 401 || response.status === 403) {
				$rootScope.$broadcast({
					401: AUTH_EVENTS.notAuthenticated,
					403: AUTH_EVENTS.notAuthorized
				}[response.status], response);
			}
			return $q.reject(response);
		}
	};
}]);