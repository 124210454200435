'use strict';

angular.module('medtasker').config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', 'moment', function ($stateProvider, $urlRouterProvider, USER_ROLES, moment) {
	$stateProvider.state('home', {
		url: '/?msg&guest',
		templateUrl: 'app/app/home.html',
		controller: 'HomeCtrl as ctrl',
		data: {
			authorizedRoles: USER_ROLES.public
		},
		resolve: {
			ctx: ['$stateParams', function ($stateParams) {}]
		}
	}).state('switch', {
		url: '/switch',
		template: '<div ui-view></div>',
		controller: 'SwitchCtrl as ctrl',
		data: {
			authorizedRoles: USER_ROLES.public
		}
	}).state('directory', {
		url: '/directory',
		templateUrl: 'app/directory/directory.html',
		controller: 'DirectoryCtrl as ctrl',
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.roster, USER_ROLES.staffwithpatientaccess, USER_ROLES.staff]
		},
		resolve: {
			ctx: ['AppService', function (AppService) {
				return AppService.init();
			}]
		}
	}).state('app', {
		abstract: true,
		template: '<ui-view/>',
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.roster, USER_ROLES.staffwithpatientaccess, USER_ROLES.insights, USER_ROLES.reports, USER_ROLES.rosteradmin, USER_ROLES.staff]
		},
		controller: 'AppCtrl as app',
		resolve: {
			ctx: ['AppService', function (AppService) {
				return AppService.init();
			}]
		}
	}).state('app.compose', {
		url: '/compose?roleAssignmentId&urn',
		templateUrl: 'app/compose/compose.html',
		controller: 'ComposeCtrl as compose',
		resolve: { wards: ['AppService', function (AppService) {
				return AppService.search('Location', 'physicalType=wi');
			}], codeCompose: function codeCompose() {
				return false;
			} }
	}).state('app.codecompose', {
		url: '/code-compose?campus',
		templateUrl: 'app/compose/code-compose.html',
		controller: 'ComposeCtrl as compose',
		resolve: { wards: ['AppService', '$stateParams', 'config', function (AppService, $stateParams, config) {
				return AppService.search('Location', 'physicalType=wi');
			}], codeCompose: function codeCompose() {
				return true;
			} },
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.codes]
		}
	}).state('app.cc', {
		url: '/cc',
		templateUrl: 'app/compose/cc.html',
		controller: 'ComposeCtrl as ctrl'
	}).state('app.inbox', {
		url: '/inbox?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			viewName: function viewName() {
				return 'Current';
			},
			mode: function mode() {
				return 'myinbox';
			}
		}
	}).state('app.task', {
		url: '/task?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			viewName: function viewName() {
				return 'Task';
			},
			mode: function mode() {
				return 'task';
			}
		}
	}).state('app.entity', {
		url: '/entity',
		templateUrl: 'app/debug/entity.html',
		controller: 'EntityCtrl as ctrl',
		data: {
			authorizedRoles: [USER_ROLES.admin]
		}
	}).state('app.priortasks', {
		url: '/priortasks?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			viewName: function viewName() {
				return 'Past';
			},
			mode: function mode() {
				return 'priortasks';
			}
		}
	}).state('app.teaminbox', {
		url: '/teaminbox?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			viewName: function viewName() {
				return 'Team';
			},
			mode: function mode() {
				return 'teaminbox';
			}
		}
	}).state('app.senttasks', {
		url: '/senttasks?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			viewName: function viewName() {
				return 'Sent';
			},
			mode: function mode() {
				return 'senttasks';
			}
		}
	}).state('app.patients', {
		url: '/patients',
		templateUrl: 'app/patient/patientlist.html',
		controller: 'PatientListCtrl as ctrl'
	}).state('app.patientstasks', {
		url: '/patientstasks?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			mode: function mode() {
				return 'patientstasks';
			},
			viewName: function viewName() {
				return 'Patients';
			}
		}
	}).state('app.tasksforpatient', {
		url: '/tasksforpatient?encounterId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			mode: function mode() {
				return 'tasksforpatient';
			},
			viewName: function viewName() {
				return 'Patient Tasks';
			}
		}
	}).state('app.systeminbox', {
		url: '/systeminbox',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			mode: function mode() {
				return 'systemtasks';
			},
			viewName: function viewName() {
				return 'Generated Tasks';
			}
		}
	}).state('app.pharmacyreport', {
		url: '/pharmacyreport',
		templateUrl: 'app/pharmacy-report/pharmacy-report.html',
		controller: 'PharmacyReportCtrl as ctrl',
		resolve: {
			wards: ['AppService', 'config', '$q', '_', function (AppService, config, $q, _) {
				var deferred = $q.defer();
				AppService.search('Location', 'physicalType=wi').then(function (wards) {
					var twards = _.transform(wards, function (accum, w) {
						w.display = config.wardDisplayField === 'name' ? w.name : w.identifier[0].value;
						accum.push(w);
					});
					deferred.resolve(twards);
				}, function (err) {
					deferred.reject(err);
				});
				return deferred.promise;
			}]
		}
	}).state('app.wardtasks', {
		url: '/wardtasks?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			mode: function mode() {
				return 'wardtasks';
			},
			viewName: function viewName() {
				return 'Ward tasks';
			}
		}
	}).state('app.residualtasks', {
		url: '/residualtasks?taskId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			mode: function mode() {
				return 'residualtasks';
			},
			viewName: function viewName() {
				return 'Residual tasks';
			}
		}
	}).state('app.admin', {
		url: '/admin',
		abstract: true,
		template: '<ui-view/>',
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.roster, USER_ROLES.rosteradmin, USER_ROLES.insights, USER_ROLES.reports]
		},
		resolve: {
			roles: ['AppService', function (AppService) {
				return AppService.ready.promise.then(function () {
					return AppService.loadRoles();
				});
			}]
		}
	}).state('app.admin.roster', {
		url: '/roster?team&campus&startDate&noShifts',
		templateUrl: 'app/roster/roster.html',
		controller: 'RosterCtrl as ctrl',
		resolve: {
			ctx: ['RosterService', 'AppService', 'AuthService', '$stateParams', '$q', '_', 'config', function (RosterService, AppService, AuthService, $stateParams, $q, _, config) {
				var deferred = $q.defer();
				AppService.ready.promise.then(function () {
					$q.all([RosterService.loadTeamsWithRoles(), AppService.rolesLoaded.promise]).then(function () {
						AppService.appStatus.noTeams = false;
						$stateParams.startDate = $stateParams.startDate || moment().format('YYYY-MM-DD');
						var admin = AuthService.belongsTo('rosteradmin') || AuthService.belongsTo('roster') || AuthService.hasAdminPriv();
						if (!$stateParams.campus) {
							if (config.defaultCampusLoc) {
								$stateParams.campus = config.defaultCampusLoc.id;
							}
						}
						if (!$stateParams.team) {
							var t = _.chain(AppService.ctx.organizations).filter(function (o) {
								//show all teams if admin or only the teams the user can access
								return admin || _.some(AuthService.authRolesByFunction('roster'), function (g) {
									return g === o.identifier[0].value;
								});
							}).filter(function (team) {
								if (!$stateParams.campus) {
									return true;
								}
								return team.partOf && team.partOf.location[0].id === $stateParams.campus;
							}).sortBy(['partOf.location[0].identifier[0].value', 'org.name']).find(RosterService.teamsWithRolesOnly).value();

							if (t) {
								$stateParams.team = t.identifier[0].value;
							} else {
								AppService.appStatus.noTeams = true;
							}
						}
						var team = void 0;
						if (!AppService.appStatus.noTeams) {
							team = _.find(AppService.ctx.organizations, function (org) {
								return org.identifier[0].value === $stateParams.team && ($stateParams.campus ? org.partOf.location[0].id === $stateParams.campus : true);
							});
							if (!team) {
								AppService.appStatus.noTeams = true;
							} else if (!admin && !_.some(AuthService.authRolesByFunction('roster'), function (g) {
								return g === team.identifier[0].value;
							})) {
								deferred.reject('unauthorized');
							}
						}
						RosterService.init($stateParams.startDate, team, false, $stateParams.campus).then(function (ctx) {
							var d = moment($stateParams.startDate);
							var sched = RosterService.scheduleForDay(d).code;
							AppService.appStatus.noShifts = !_.some(ctx.shifts, function (s) {
								return team && s.role.organization.id === team.id && s.schedule.code === sched && +s.validPeriod.start <= +d && (!s.validPeriod.end || +s.validPeriod.end >= +d);
							});
							deferred.resolve(ctx);
						});
					});
				});
				return deferred.promise;
			}]
		},
		onEnter: ['EventService', 'AppService', 'RosterService', '$rootScope', function (EventService, AppService, RosterService, $rootScope) {
			EventService.reset();
			EventService.addStandardListeners('nofilter');
			EventService.addListeners(['MtAggregate'], RosterService.aggregateSseHandler);
			EventService.addListeners(['MtRoleAssignment'], AppService.allRoleAssignmentsSseHandler);
			$rootScope.tempUnbind.push($rootScope.$on('sse.roleAssignment', function (ev, ra) {
				//eslint-disable-line
				if (ra) {
					RosterService.handleNewRoleAssignment(ra);
				}
			}));
			$rootScope.tempUnbind.push($rootScope.$on('sse.roleAssignment.deleted', function (ev, inf) {
				//eslint-disable-line
				RosterService.handleDeletedRoleAssignment(inf.id);
			}));
		}],
		onExit: ['EventService', 'AppService', 'RosterService', 'AuthService', function (EventService, AppService, RosterService, AuthService) {
			if (AuthService.isAuthenticated('user')) {
				EventService.reset();
				EventService.addStandardListeners();
			}
		}],
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.rosteradmin, USER_ROLES.roster]
		}
	}).state('app.admin.rosterclone', {
		url: '/rosterclone?team',
		templateUrl: 'app/roster/roster-clone.html',
		controller: 'RosterCtrl as ctrl',
		resolve: {
			ctx: ['RosterService', function (RosterService) {
				return RosterService.init(null, null, true);
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.roster, USER_ROLES.rosteradmin]
		}
	}).state('app.admin.printroster', {
		url: '/printroster?date&team&campus',
		templateUrl: 'app/roster/roster-print.html',
		controller: 'RosterPrintCtrl as ctrl',
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.roster, USER_ROLES.manager, USER_ROLES.switch, USER_ROLES.rosteradmin, USER_ROLES.insights]
		},
		resolve: {
			ctx: ['RosterService', 'AppService', 'AuthService', '$stateParams', '$q', '_', 'config', function (RosterService, AppService, AuthService, $stateParams, $q, _, config) {
				var deferred = $q.defer();
				// console.log('print '+ angular.toJson($stateParams));
				AppService.ready.promise.then(function () {
					$q.all([RosterService.loadTeamsWithRoles(), AppService.rolesLoaded.promise]).then(function () {
						AppService.appStatus.noTeams = false;
						$stateParams.startDate = $stateParams.startDate || moment().format('YYYY-MM-DD');
						var admin = AuthService.belongsTo('rosteradmin') || AuthService.belongsTo('roster') || AuthService.hasAdminPriv();
						if (!$stateParams.campus) {
							if (config.defaultCampusLoc) {
								$stateParams.campus = config.defaultCampusLoc.id;
							}
						}
						if (!$stateParams.team || $stateParams.team && $stateParams.team === 0) {
							AppService.appStatus.noTeams = true;
						}

						var team = void 0;
						if (!AppService.appStatus.noTeams) {
							team = _.find(AppService.ctx.organizations, function (org) {
								return org.identifier[0].value === $stateParams.team && ($stateParams.campus ? org.partOf.location[0].id === $stateParams.campus : true);
							});
							if (!team) {
								AppService.appStatus.noTeams = true;
							} else if (!admin && !_.some(AuthService.authRolesByFunction('roster'), function (g) {
								return g === team.identifier[0].value;
							})) {
								deferred.reject('unauthorized');
							}
						}
						RosterService.init($stateParams.startDate, team, false, $stateParams.campus).then(function (ctx) {
							var d = moment($stateParams.startDate);
							var sched = RosterService.scheduleForDay(d).code;
							AppService.appStatus.noShifts = !_.some(ctx.shifts, function (s) {
								return team && s.role.organization.id === team.id && s.schedule.code === sched && +s.validPeriod.start <= +d && (!s.validPeriod.end || +s.validPeriod.end >= +d);
							});
							deferred.resolve(ctx);
						});
					});
				});
				return deferred.promise;
			}]
		}
	}).state('app.admin.team', {
		url: '/team',
		templateUrl: 'app/team/team.html',
		controller: 'TeamCtrl as ctrl',
		resolve: {
			ctx: ['TeamService', function (TeamService) {
				return TeamService.init();
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin]
		}
	}).state('app.admin.user', {
		url: '/user',
		templateUrl: 'app/user/user.html',
		controller: 'UserCtrl as ctrl',
		resolve: {
			ctx: ['UserService', function (UserService) {
				return UserService.init();
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin]
		}
	}).state('app.admin.role', {
		url: '/role?team',
		templateUrl: 'app/role/role.html',
		controller: 'RoleCtrl as ctrl',
		resolve: {
			ctx: ['RoleService', 'AppService', function (RoleService, AppService) {
				return AppService.rolesLoaded.promise.then(function () {
					return RoleService.init();
				});
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.rosteradmin]
		}
	}).state('app.admin.unassignedRoles', {
		url: '/unassigned-roles',
		templateUrl: 'app/role/unassigned-roles.html',
		controller: 'UnassignedRolesCtrl as ctrl',
		resolve: {
			unassignedRoles: ['RosterService', function (RosterService) {
				return RosterService.unassignedRoles();
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.rosteradmin, USER_ROLES.roster, USER_ROLES.manager]
		}
	}).state('app.admin.roleGroup', {
		url: '/rolegroup',
		templateUrl: 'app/role/role-group.html',
		controller: 'RoleGroupCtrl as ctrl',
		resolve: {
			ctx: ['RoleService', 'AppService', function (RoleService, AppService) {
				return AppService.rolesLoaded.promise.then(function () {
					return AppService.loadRoleGroups().then(function () {
						return RoleService.init();
					});
				});
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.rosteradmin]
		}
	}).state('app.admin.shift', {
		url: '/shift?team&campus&schedule&applyDate&role',
		templateUrl: 'app/shift/shift.html',
		controller: 'ShiftCtrl as ctrl',
		resolve: {
			ctx: ['ShiftService', 'AppService', 'RosterService', '$q', '$stateParams', '_', 'config', function (ShiftService, AppService, RosterService, $q, $stateParams, _, config) {
				var deferred = $q.defer();
				AppService.ready.promise.then(function () {
					AppService.rolesLoaded.promise.then(function () {
						AppService.appStatus.noTeams = false;
						if (!$stateParams.campus) {
							if (config.defaultCampusLoc) {
								$stateParams.campus = config.defaultCampusLoc.id;
							}
						}
						if (!$stateParams.team) {
							var orgs = $stateParams.campus ? _.filter(AppService.ctx.organizations, function (t) {
								return $stateParams.campus ? t.partOf && t.partOf.location && t.partOf.location[0].id === $stateParams.campus : true;
							}) : AppService.ctx.organizations;
							var t = _.sortBy(_.filter(orgs, RosterService.teamsOnly), function (org) {
								return org.identifier[0].value;
							})[0];
							if (t) {
								$stateParams.team = t.identifier[0].value;
							} else {
								AppService.appStatus.noTeams = true;
								deferred.resolve(AppService.ctx);
							}
						}
						if (!AppService.appStatus.noTeams) {
							var team = _.find(AppService.ctx.organizations, function (org) {
								return org.identifier[0].value === $stateParams.team && ($stateParams.campus ? org.partOf.location[0].id === $stateParams.campus : true);
							});

							ShiftService.init(team, $stateParams.campus).then(function (ctx) {
								$stateParams.noShifts = !_.some(ctx.shifts, function (s) {
									return s.role.organization.id === team.id;
								});
								deferred.resolve(ctx);
							});
						}
					});
				});
				return deferred.promise;
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.rosteradmin]
		},
		onEnter: ['EventService', 'AppService', function (EventService, AppService) {
			EventService.reset();
			EventService.addStandardListeners('nofilter');
			EventService.addListeners(['MtShift'], AppService.shiftSseHandler);
		}],
		onExit: ['EventService', 'AuthService', function (EventService, AuthService) {
			if (AuthService.isAuthenticated('user')) {
				EventService.reset();
				EventService.addStandardListeners();
			}
		}]
	}).state('app.admin.aggregate', {
		url: '/aggregate?id',
		templateUrl: 'app/shift/aggregate.html',
		controller: 'AggregateCtrl as ctrl',
		resolve: {
			ctx: ['AppService', function (AppService) {
				return AppService.loadAllShiftAggregates().then(function () {
					return AppService.ctx;
				});
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.rosteradmin]
		}
	}).state('app.admin.reports', {
		url: '/reports?report',
		templateUrl: 'app/reports/reports.html',
		controller: 'ReportsCtrl as ctrl',
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.reports, USER_ROLES.manager]
		}
	}).state('app.admin.patientmonitor', {
		url: '/patientmonitor?period&urgency',
		templateUrl: 'app/monitor/monitor.html',
		controller: 'MonitorCtrl as ctrl',
		resolve: {
			ctx: ['MonitorService', function (MonitorService) {
				return MonitorService.init();
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager]
		}
	}).state('app.admin.patientmonitor.tasks', {
		url: '/tasks?patientId',
		templateUrl: 'app/inbox/inbox.html',
		controller: 'InboxCtrl as inbox',
		resolve: {
			mode: function mode() {
				return 'monitortasks';
			},
			viewName: function viewName() {
				return 'Tasks';
			}
		}
	}).state('app.admin.insights', {
		url: '/insights',
		abstract: true,
		templateUrl: 'app/insights/insights.container.html',
		resolve: {
			ctx: ['InsightsService', function (InsightsService) {
				return InsightsService.init();
			}]
		},
		onEnter: ['EventService', 'InsightsService', '$rootScope', function (EventService, InsightsService, $rootScope) {
			EventService.reset();
			EventService.addStandardListeners('nofilter');
			$rootScope.tempUnbind.push($rootScope.$on('sse.task', function (ev, t) {
				//eslint-disable-line
				InsightsService.handleNewTask(t);
			}));
		}],
		onExit: ['EventService', 'AuthService', function (EventService, AuthService) {
			if (AuthService.isAuthenticated('user')) {
				EventService.reset();
				EventService.addStandardListeners();
			}
		}],
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.insights]
		}
	}).state('app.admin.insights.detail', {
		url: '?f1&f2&custom&includeGenerated',
		views: {
			'graphs': {
				templateUrl: 'app/insights/insights.html',
				controller: 'InsightsCtrl as insights'
			},
			'tasks': {
				templateUrl: 'app/inbox/inbox.html',
				controller: 'InboxCtrl as inbox',
				resolve: {
					mode: function mode() {
						return 'insighttasks';
					},
					viewName: function viewName() {
						return 'Tasks';
					}
				}
			}
		}
	}).state('app.admin.tasks', {
		url: '/tasks',
		abstract: true,
		templateUrl: 'app/tasksearch/task-search.container.html',
		onEnter: ['EventService', function (EventService) {
			EventService.reset();
			EventService.addStandardListeners('nofilter');
		}],
		onExit: ['EventService', function (EventService) {
			EventService.reset();
			EventService.addStandardListeners();
		}],
		data: {
			authorizedRoles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.insights]
		}
	}).state('app.admin.tasks.search', {
		url: '/search',
		views: {
			'search': {
				templateUrl: 'app/tasksearch/task-search.html',
				controller: 'TaskSearchCtrl as ctrl'
			},
			'list': {
				templateUrl: 'app/inbox/inbox.html',
				controller: 'InboxCtrl as inbox',
				resolve: {
					mode: function mode() {
						return 'tasksearch';
					},
					viewName: function viewName() {
						return 'Tasks';
					}
				}
			}
		}
	}).state('oauth', {
		url: '/oauth/callback',
		templateUrl: 'app/compose/compose.html',
		controller: 'HomeCtrl as ctrl',
		data: {
			authorizedRoles: USER_ROLES.public
		},
		resolve: {
			ctx: ['AppService', 'LogService', 'EventService', 'config', 'oauth', '$rootScope', 'DialogService', function (AppService, LogService, EventService, config, oauth, $rootScope, DialogService) {
				oauth.getRefreshToken().then(function () {
					config.init('force').then(function () {
						oauth.postConfigReadHandler();
						AppService.init().then(function () {
							EventService.reset(); //clear any old event source
							EventService.addStandardListeners();
							DialogService.openCampusSelectorDialog('Select Campus and Ward').then(function () {
								getUserPriv();
								if (AuthService.isAuthenticated('user')) {
									$state.go(config.defaultState || 'app.compose');
								}
							}, function () {
								//$log.debug('login dialog reject');
							});
							//$state.go('app.compose');
						});
					}, function (err) {
						$rootScope.$broadcast('appLoadingPop', { source: 'login' });
						LogService.error('Login error. Couldn\'t load config: ' + angular.toJson(err));
					});
				});
			}]
		}
	})
	//   .state('oautherror', {
	// 	url: '/oauth/error?msg',
	// 	templateUrl: 'app/app/home.html',
	// 	controller: 'HomeCtrl as ctrl',
	// 	data: {
	// 		authorizedRoles: USER_ROLES.public,
	// 	},
	// 	resolve: {
	// 		ctx: ['$stateParams', function($stateParams) {

	// 		}]
	// 	},
	//   })
	.state('app.admin.calltasker', {
		url: '/calltasker',
		templateUrl: 'app/calltasker/calltasker-admin.html',
		controller: 'CalltaskerAdminCtrl as ctrl',
		resolve: {
			ctx: ['RoleService', 'AppService', function (RoleService, AppService) {
				return AppService.rolesLoaded.promise.then(function () {
					return AppService.loadRoleGroups().then(function () {
						return RoleService.init();
					});
				});
			}]
		},
		data: {
			authorizedRoles: [USER_ROLES.admin]
		}
	});

	$urlRouterProvider.otherwise('/switch');
}]);