'use strict';

angular.module('medtasker').controller('AccordionCtrl', [function () {
	var _this = this;

	_this.oneAtATime = true;

	_this.status = {
		isFirstOpen: true,
		isFirstDisabled: false
	};
}]);