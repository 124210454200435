
'use strict';

angular.module('medtasker').controller('UserDialogCtrl', ['UserService', 'AppService', 'config', '$log', '_', 'moment', '$modalInstance', 'modalArgs', function (UserService, AppService, config, $log, _, moment, $modalInstance, modalArgs) {

	var _this = this;

	_this.config = config;

	if (modalArgs.user) {
		_this.edit = true;
		_this.user = angular.copy(modalArgs.user);
	} else {
		_this.user = {
			username: '',
			roles: []
		};
	}

	_this.teams = modalArgs.teams;

	_this.userRoles = modalArgs.userRoles;

	_this.invalid = function () {
		if (!_this.user.username || _this.user.username.length === 0) {
			_this.invalidMessage = 'User name must be provided';
			return true;
		}
		return false;
	};

	_this.addRole = function (r, t) {
		var s = r + (t ? '.' + t : '');
		if (!_.some(_this.user.roles, function (role) {
			return role === s;
		})) {
			_this.user.roles.push(s);
		}
	};

	_this.removeRole = function (r) {
		_.remove(_this.user.roles, function (role) {
			return role === r;
		});
	};

	_this.save = function () {
		_this.error = null;
		if (_this.edit) {
			UserService.updateUser(_this.user).then(function () {
				$modalInstance.close();
			}, function (err) {
				_this.error = 'Error updating user. ' + err.statusText + ': ' + err.data;
			});
		} else {
			_this.user.username = _this.user.username.toLowerCase();
			UserService.createUser(_this.user).then(function () {
				$modalInstance.close();
			}, function (err) {
				_this.error = 'Error updating user. ' + err.statusText + ': ' + err.data;
			});
		}
	};

	_this.delete = function () {
		_this.error = null;
		UserService.deleteUser(_this.user.username).then(function () {
			$modalInstance.close();
		}, function (err) {
			_this.error = 'Error deleting user. ' + err.statusText + ': ' + err.data;
		});
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};
}]);