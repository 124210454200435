'use strict';

// Main Resources Involved:
//
//   MtShift           A shift including only a time and duration
//   MtRole            Role with a level and responsibility
//   TempRShift        A MtShift but with a period (date/time)
//   TempRAggregate    An aggregate for TempRShifts
//   MtRoleAssignment  Created by assigning a practitioner to a TempRShift
//
/* global angular */

angular.module('medtasker.service').factory('RosterService', ['AppService', 'AuthService', 'ResourceService', 'UrlService', 'config', 'moment', '$q', '_', 'LogService', '$interval', '$rootScope', '$timeout', '$http', function (AppService, AuthService, ResourceService, UrlService, config, moment, $q, _, log, $interval, $rootScope, $timeout, $http) {

	var _ctx = {};
	var refreshRequests = {};
	var _startTime = moment().startOf('day').set({ hour: 6 }); // ViewPeriod  (today at 8am)  TODO: Make this configurable
	//let _startTime = moment(config.viewPeriodStartTime, 'HH:mm');
	//We need to track the teams that have shifts so that the ones without aren't listed in the selector in the roster
	var _teamsWithShifts = [];
	//Quicker method os restricting listed teams to those with roles
	var _teamsWithRoles = [];

	function getCampusCodeForTheTeam() {
		if (angular.isDefined(_ctx) && angular.isDefined(_ctx.selectedTeam)) {
			if (_ctx.selectedTeam.type === 'team' || _ctx.selectedTeam.type === 'ward') {
				if (angular.isDefined(_ctx.selectedTeam.partOf) && _ctx.selectedTeam.partOf.type === 'hospital' && angular.isDefined(_ctx.selectedTeam.partOf.location)) {
					return _ctx.selectedTeam.partOf.location[0].identifier[0].value;
				}
			} else if (_ctx.selectedTeam.type === 'subteam') {
				if (angular.isDefined(_ctx.selectedTeam.partOf) && _ctx.selectedTeam.partOf.type === 'team' && angular.isDefined(_ctx.selectedTeam.partOf.partOf) && angular.isDefined(_ctx.selectedTeam.partOf.partOf.location)) {
					return _ctx.selectedTeam.partOf.partOf.location[0].identifier[0].value;
				}
			}
		}
		return '';
	}

	function _isHoliday(day) {
		var campusHolidays = void 0;
		if (angular.isDefined(_ctx) && angular.isDefined(_ctx.selectedTeam) && angular.isDefined(config.campusSpecificHolidays)) {
			var campusId = getCampusCodeForTheTeam();
			if (angular.isDefined(campusId)) {
				campusHolidays = config.campusSpecificHolidays[campusId];
			}
		}
		var holiday = false;
		_.forEach(config.holidays, function (h) {
			if (day.format('YYYY-MM-DD') === h.date) {
				holiday = true;
				return; // break out of forEach loop
			}
		});
		if (holiday === false && angular.isDefined(campusHolidays)) {
			_.forEach(campusHolidays, function (h) {
				if (day.format('YYYY-MM-DD') === h.date) {
					holiday = true;
					return; // break out of forEach loop
				}
			});
		}
		return holiday;
	}

	function _scheduleForDay(day) {
		var code = day.format('ddd').toLowerCase();
		if (_isHoliday(day)) {
			code = 'holiday';
		}
		return _.find(config.shiftSchedules, { code: code });
	}

	// after re-generating the roster-shifts, find the selected RS by id in the newly generated set of roster shifts and set it as selected
	function _refreshSelectedRosterShift() {
		if (!_ctx.selectedRShift) {
			return;
		}
		_ctx.selectedRShift = _.find(_ctx.rshiftsByRole[_ctx.selectedRShift.shift.role.id], { id: _ctx.selectedRShift.id });
	}

	function _issueRosterRefresh(team, role) {
		// o.refreshRoster(team);
		// return;
		if (team) {
			if (refreshRequests[team.id]) {
				return;
			}
			refreshRequests[team.id] = true;
		} else if (role) {
			if (refreshRequests[role.id] || refreshRequests[role.organization.id]) {
				return;
			}
			refreshRequests[role.id] = true;
		}
		var p = {
			team: team,
			role: role
		};
		$timeout(function () {
			if (p.team) {
				o.refreshRoster(team); //eslint-disable-line
				refreshRequests[team.id] = void 0;
			} else {
				o.refreshRosterForRole(role);
				refreshRequests[role.id] = void 0;
			}
			o.refreshSelectedRosterShift(); //eslint-disable-line
		}, 300, 1);
	}

	function _switchPractitioner(rshift, practitioner) {
		var deferred = $q.defer();
		var p = {
			roleAssignmentId: rshift.assigned.id,
			newPractitionerId: practitioner.id
		};
		var url = UrlService.rosterUrl() + '/switch';
		AuthService.setHttpAuthHeader();
		var cfg = {
			headers: { 'Content-Type': 'application/json' }
		};
		$http.post(url, p, cfg).then(function () {
			o.refreshRosterForRole(rshift.shift.role).then(function () {
				_refreshSelectedRosterShift();
				deferred.resolve();
			}, function (err) {
				log.debug(err);
			});
		}, function () {
			deferred.reject('An error occurred reassigning the staff member for this shift. Please contact IT if the problem recurs.');
		});
		return deferred.promise;
	}

	// Create a RoleAssignment for the practitioner
	//
	// Optional arguments for Override case:
	//   reason:       Text description for the override
	//   includeAggr:  If true (default), a new override RA is created for each shift in the aggregate.
	//	 excludedAggShifts: Aggregate shifts not to be assigned to
	function _assignPractitioner(rshift, practitioner, period, reason, includeAggr, excludedAggShifts) {
		var deferred = $q.defer();
		var p = {
			practitionerId: practitioner.id,
			shiftId: rshift.shift.id,
			date: rshift.period.start.format()
		};
		if (rshift.assigned) {
			// i.e., it's an override
			p.override = {
				period: period,
				reason: reason,
				includeAggregates: includeAggr,
				excludedAggregateShiftIds: excludedAggShifts,
				roleAssignmentId: rshift.assigned.id
			};
		}
		var url = UrlService.rosterUrl() + '/assign';
		AuthService.setHttpAuthHeader();
		var cfg = {
			headers: { 'Content-Type': 'application/json' }
		};
		$http.post(url, p, cfg).then(function () {
			if (rshift.aggregate) {
				o.refreshRoster(_ctx.selectedTeam).then(function () {
					_refreshSelectedRosterShift();
					deferred.resolve();
				}, function (err) {
					deferred.reject('Could not refresh roster');
					log.debug(err);
				});
			} else {
				o.refreshRosterForRole(rshift.shift.role).then(function () {
					_refreshSelectedRosterShift();
					deferred.resolve();
				}, function (err) {
					deferred.reject('Could not refresh roster');
					log.debug(err);
				});
			}
		}, function (err) {
			log.debug(err);
			deferred.reject('An error occurred assigning the staff member. Please contact IT if the problem recurs.');
		});
		return deferred.promise;
	}

	function _removeFromRoster(ra) {
		var deferred = $q.defer();
		var url = UrlService.rosterUrl() + '/remove/' + ra.id;
		AuthService.setHttpAuthHeader();
		$http.delete(url).then(function () {
			if (ra.aggregate) {
				o.refreshRoster(_ctx.selectedTeam).then(function () {
					_refreshSelectedRosterShift();
					deferred.resolve();
				}, function (err) {
					log.debug(err);
				});
			} else {
				o.refreshRosterForRole(ra.shift.role).then(function () {
					_refreshSelectedRosterShift();
					deferred.resolve();
				}, function (err) {
					log.debug(err);
				});
			}
		}, function () {
			deferred.reject('An error occurred assigning the staff member. Please contact IT if the problem recurs.');
		});
		return deferred.promise;
	}

	// Return the list of start and end times for each shift shown.
	// This will be used to create the time scale header
	// Return: array of objects, each with time and span
	function _viewTimes(team, rshiftsByRole, vperiod) {
		var times = [vperiod.start, vperiod.end]; // start and end time markers

		times = _.reduce(rshiftsByRole, function (t, rshifts) {
			if (rshifts.length > 0 && rshifts[0].shift.role.organization.id === team.id) {
				_.forEach(rshifts, function (rshift) {
					if (+rshift.period.start >= +vperiod.start && +rshift.period.start <= +vperiod.end) {
						t.push(rshift.period.start);
					}
					if (+rshift.period.end >= +vperiod.start && +rshift.period.end <= +vperiod.end) {
						t.push(rshift.period.end);
					}
				});
			}
			return t;
		}, times);

		// Remove duplicates
		var uniqueTimes = {};
		_.forEach(times, function (t) {
			uniqueTimes[t.unix()] = t;
		});
		times = _.values(uniqueTimes);

		// Sort the time entries
		times.sort(function (a, b) {
			return a.isAfter(b) ? 1 : a.isBefore(b) ? -1 : 0;
		});

		var minDist = (vperiod.end - vperiod.start) / 22;
		var maxRow = 4;
		var timeSpans = [[]]; // 1 row to start with
		var row = 1; // basically the row index
		for (var i = 0; i < times.length; i++) {
			var delta = i === times.length - 1 ? 0 : times[i + 1] - times[i];
			if (delta < minDist && delta !== 0) {
				if (row === maxRow) {
					row = 1;
				} else {
					row += 1;
					if (row > timeSpans.length) {
						timeSpans.push([]); // Add a row
					}
				}
			} else {
				row = 1;
			}
			timeSpans[row - 1].push({
				time: times[i],
				span: delta
			});
		}
		return timeSpans;
	}

	//recursive function to find end of contiguous block of times
	function endOfBlock(times, index) {
		var end = { end: times[index].end, nextIndex: index + 1 };
		if (times.length > index + 1) {
			if (+times[index + 1].start <= +times[index].end) {
				return endOfBlock(times, index + 1);
			}
		}
		return end;
	}

	//take an array of start and end times and consolidate into an array which
	//merges contiguous periods
	function consolidateContiguousTimes(times) {
		var i = 0;
		var consolidated = [];
		while (times.length > i) {
			var r = endOfBlock(times, i);
			consolidated.push({ start: times[i].start, end: r.end });
			i = r.nextIndex;
		}
		return consolidated;
	}

	function initRosterContext(start, end, team, campus) {
		_ctx.timeSpan = 1;
		_ctx.viewTimes = [];
		_ctx.selectedTeam = team;
		_ctx.nextTeam = null;
		_ctx.prevTeam = null;
		_ctx.filteredPractitioners = [];
		_ctx.selectedRole = null;
		_ctx.selectedRShift = null;
		_ctx.selectedCampus = campus;
		_ctx.rshiftsByRole = {};
		_ctx.viewPeriod = {
			start: start,
			end: end
		};
	}

	var o = {
		ctx: _ctx,

		initForSelfAssign: function initForSelfAssign(team) {
			var deferred = $q.defer();
			var schedules = [_scheduleForDay(moment()).code];
			if (moment().hours() >= 22) {
				schedules.push(_scheduleForDay(moment().add(1, 'day')).code);
			}
			// Need to get day before in case there are shifts that run over the midnight boundary, and one self-assigns past midnight.
			// There's still a potential issue here if there were a shift that started at say 23:00, and ran for more than 13 hours, but
			// such a situation seems very unlikely to arise in real life, so we won't cater for this.
			if (moment().hours() <= 12) {
				schedules.push(_scheduleForDay(moment().subtract(1, 'day')).code);
			}
			_ctx = AppService.ctx;
			// Default values
			var start = moment().startOf('day').add(_startTime.hours(), 'hours').add(_startTime.minutes(), 'minutes');
			var end = moment(start).add(moment.duration(1, 'day'));
			// As we no longer load all Roles, we need to ensure the roles specific to the team selected exist in ctx
			AppService.search('MtRole', 'active=true&organization=' + team.id, true).then(function () {
				$q.all([AppService.loadShifts(schedules, team), o.loadTeamsWithRoles()]).then(function () {
					initRosterContext(start, end, team);
					deferred.resolve(_ctx);
				}, function (err) {
					return deferred.reject(err);
				});
			}, function (err) {
				deferred.reject(err);
			});
			return deferred.promise;
		},

		// Return the context object
		init: function init(startDate, team, allSchedules, campusId) {
			var deferred = $q.defer();
			AppService.ready.promise.then(function () {
				_ctx = AppService.ctx;
				// Default values
				var startDay = moment(startDate, 'YYYY-MM-DD');
				var start = moment(startDay).add(_startTime.hours(), 'hours').add(_startTime.minutes(), 'minutes');
				var end = moment(start).add(moment.duration(1, 'day'));
				var schedules = [_scheduleForDay(startDay).code, _scheduleForDay(moment(startDay).add(1, 'day')).code, _scheduleForDay(moment(startDay).subtract(1, 'day')).code];
				var getRoles = team ? AppService.search('MtRole', 'active=true&organization=' + team.id) : AppService.search('MtRole', 'active=true', true);
				getRoles.then(function () {
					var s = moment(startDay).startOf('day').add(1, 'minutes');
					var e = moment(s).add(1, 'days');
					var getShifts = team ? AppService.loadShifts(allSchedules ? null : schedules, team, s, e) : AppService.loadAllShifts(schedules, 'cache');
					$q.all([getShifts, o.loadTeamsWithRoles()]).then(function () {
						var campus = void 0;
						if (campusId) {
							campus = _.find(AppService.campuses(), function (c) {
								return c.id === campusId;
							});
						} else {
							campus = _.find(AppService.campuses(), function (c) {
								return c.name === 'All';
							});
						}
						initRosterContext(start, end, team, campus);
						deferred.resolve(_ctx);
					});
				});
			});
			return deferred.promise;
		},

		// Only change the date. Time stays untouched.
		changeStartDate: function changeStartDate(newStartDate) {
			var deferred = $q.defer();
			var timeSlice = moment.duration(_ctx.timeSpan, 'days');
			_ctx.viewPeriod.start.set({
				'year': newStartDate.year(),
				'month': newStartDate.month(),
				'date': newStartDate.date() });
			_ctx.viewPeriod.end = moment(_ctx.viewPeriod.start).add(timeSlice);

			o.refreshRoster(_ctx.selectedTeam).then(function () {
				_ctx.selectedRShift = null; //clear the selected roster shift when changing dates
				deferred.resolve();
			});
			return deferred.promise;
		},

		goBackOneDay: function goBackOneDay() {
			var newStartDate = moment(_ctx.viewPeriod.start).subtract(1, 'days');
			return o.changeStartDate(newStartDate);
		},

		goForwardOneDay: function goForwardOneDay() {
			var newStartDate = moment(_ctx.viewPeriod.start).add(1, 'days');
			return o.changeStartDate(newStartDate);
		},

		prevTimeslice: function prevTimeslice() {
			var timeSlice = moment.duration(_ctx.timeSpan, 'days');
			_ctx.viewPeriod.start.subtract(timeSlice);
			_ctx.viewPeriod.end.subtract(timeSlice);
			_ctx.selectedRShift = null;
			o.refreshRoster(_ctx.selectedTeam);
		},

		nextTimeslice: function nextTimeslice() {
			var timeSlice = moment.duration(_ctx.timeSpan, 'days');
			_ctx.viewPeriod.start.add(timeSlice).toDate();
			_ctx.viewPeriod.end.add(timeSlice).toDate();
			_ctx.selectedRShift = null;
			o.refreshRoster(_ctx.selectedTeam);
		},

		loadTeamsWithShifts: function loadTeamsWithShifts(forSelfAssign) {
			var deferred = $q.defer();
			if (_teamsWithShifts && _teamsWithShifts.length > 0) {
				deferred.resolve(_teamsWithShifts);
			} else {
				ResourceService.medtaskerProcFhir(_ctx || AppService.ctx, 'teams-with-shifts', forSelfAssign ? 'selfassign' : 'all', 'Organization').then(function (data) {
					_teamsWithShifts.length = 0;
					Array.prototype.push.apply(_teamsWithShifts, data.data);
					deferred.resolve(_teamsWithShifts);
				}, function (err) {
					return deferred.reject(err);
				});
			}
			return deferred.promise;
		},

		loadTeamsWithRoles: function loadTeamsWithRoles() {
			var deferred = $q.defer();
			if (_teamsWithRoles && _teamsWithRoles.length > 0) {
				deferred.resolve(_teamsWithRoles);
			} else {
				ResourceService.medtaskerProcFhir(_ctx || AppService.ctx, 'teams-with-roles', null, 'Organization').then(function (data) {
					_teamsWithRoles.length = 0;
					Array.prototype.push.apply(_teamsWithRoles, data.data);
					deferred.resolve(_teamsWithRoles);
				}, function (err) {
					return deferred.reject(err);
				});
			}
			return deferred.promise;
		},
		scheduleForDay: _scheduleForDay,

		refreshRoster: function refreshRoster(team) {
			var deferred = $q.defer();
			if (team) {
				log.debug('refreshing roster');
				var params = ResourceService.objectToUrlParams({
					from: _ctx.viewPeriod.start.format('YYYY-MM-DDTHH:mm:ss'),
					to: _ctx.viewPeriod.end.format('YYYY-MM-DDTHH:mm:ss')
				});
				var url = UrlService.rosterUrl() + '/team/' + team.id + '?' + params;
				AuthService.setHttpAuthHeader();
				var req = {
					method: 'GET',
					url: url
				};
				req.headers = { 'Accept': 'application/json' };
				$rootScope.$broadcast('appLoadingPush', { source: 'refreshRoster' });
				$http.get(url).then(function (res) {
					var allq = [];
					_ctx.rshiftsByRole = {};
					_.forEach(res.data.roleRosters, function (rr) {
						var q = [];
						_.forEach(rr.rosterShifts, function (rs) {
							rs.period = ResourceService.toMomentPeriod(rs.period);
							q.push(ResourceService.deserialize(_ctx, rs.shift));
							if (rs.assigned) {
								q.push(ResourceService.deserialize(_ctx, rs.assigned));
							}
						});
						allq = allq.concat(q);
						$q.all(q).then(function () {
							_ctx.rshiftsByRole[rr.roleId] = rr.rosterShifts;
						});
					});
					$q.all(allq).then(function () {
						_refreshSelectedRosterShift();
						_ctx.viewTimes = _viewTimes(team, _ctx.rshiftsByRole, _ctx.viewPeriod);
						deferred.resolve();
						$rootScope.$broadcast('appLoadingPop', { source: 'refreshRoster' });
					}, function () {
						$rootScope.$broadcast('appLoadingPop', { source: 'refreshRoster' });
					});
				});
			} else {
				_ctx.rshiftsByRole = null;
				_ctx.viewTimes = _viewTimes(team, null, _ctx.viewPeriod);
				deferred.resolve();
			}
			return deferred.promise;
		},

		refreshRosterForRole: function refreshRosterForRole(role) {
			var deferred = $q.defer();
			if (role) {
				log.debug('refreshing roster for role');
				var params = ResourceService.objectToUrlParams({
					from: _ctx.viewPeriod.start.format('YYYY-MM-DDTHH:mm:ss'),
					to: _ctx.viewPeriod.end.format('YYYY-MM-DDTHH:mm:ss')
				});
				var url = UrlService.rosterUrl() + '/role/' + role.id + '?' + params;
				AuthService.setHttpAuthHeader();
				var req = {
					method: 'GET',
					url: url
				};
				req.headers = { 'Accept': 'application/json' };
				$http.get(url).then(function (res) {
					_ctx.rshiftsByRole[role.id] = void 0;
					var q = [];
					_.forEach(res.data.rosterShifts, function (rs) {
						rs.period = ResourceService.toMomentPeriod(rs.period);
						q.push(ResourceService.deserialize(_ctx, rs.shift));
						if (rs.assigned) {
							q.push(ResourceService.deserialize(_ctx, rs.assigned));
						}
					});
					$q.all(q).then(function () {
						_ctx.rshiftsByRole[role.id] = res.data.rosterShifts;
						_refreshSelectedRosterShift();
						_ctx.viewTimes = _viewTimes(_ctx.selectedTeam, _ctx.rshiftsByRole, _ctx.viewPeriod);
						deferred.resolve();
					});
				});
			} else {
				deferred.resolve();
			}
			return deferred.promise;
		},

		deleteRosterShiftRoleAssignment: function deleteRosterShiftRoleAssignment(rshift, aggregateRShifts) {
			var rshifts = rshift.aggregate ? aggregateRShifts : [rshift];
			var promises = [];
			_.forEach(rshifts, function (rs) {
				if (rs.assigned) {
					promises.push(ResourceService.delete(_ctx, rs.assigned));
				}
			});
			return $q.all(promises).then(function () {
				if (aggregateRShifts) {
					return o.refreshRoster(_ctx.selectedTeam);
				}
				return o.refreshRosterForRole(rshift.shift.role);
			});
		},

		// get full roster from roster API
		getRosterForPrint: function getRosterForPrint(start, end) {
			var params = ResourceService.objectToUrlParams({
				from: start.format('YYYY-MM-DDTHH:mm:ss'),
				to: end.format('YYYY-MM-DDTHH:mm:ss'),
				print: true
			});
			var url = UrlService.rosterUrl() + '/full?' + params;
			AuthService.setHttpAuthHeader();
			var req = {
				method: 'GET',
				url: url
			};
			req.headers = { 'Accept': 'application/json' };

			return $http(req);
		},

		refreshSelectedRosterShift: _refreshSelectedRosterShift,

		switchPractitioner: _switchPractitioner,

		selectTeam: function selectTeam(team) {
			_ctx.selectedTeam = team;
			_ctx.selectedRole = null;

			// Set the next and previous teams from an alphabetised list of organizations with shifts
			//   (based on team identifier)
			var teams = _.sortBy(o.teamsByCampus(), ['partOf.location[0].identifier[0].value', 'name']);
			var selectedTeamIndex = _.findIndex(teams, team);
			if (selectedTeamIndex !== -1) {
				_ctx.nextTeam = selectedTeamIndex === teams.length - 1 ? teams[0] : teams[selectedTeamIndex + 1];
				_ctx.prevTeam = selectedTeamIndex > 0 ? teams[selectedTeamIndex - 1] : teams[teams.length - 1];
			}

			o.refreshRoster(team);
		},

		selectRole: function selectRole(role) {
			_ctx.selectedRole = role;
		},

		selectRShift: function selectRShift(rshift) {
			_ctx.selectedRShift = rshift; // roster shift
		},

		removeRoleAssignment: function removeRoleAssignment(ra) {
			AppService.delete(ra).then(function () {
				o.refreshRosterForRole(_ctx.selectedRShift.shift.role);
			});
		},

		// Return the rshifts that share the given roster aggregate.
		rshiftsForAggregate: function rshiftsForAggregate(rAggr) {
			if (!rAggr) {
				return [];
			}

			var rshifts = _.flatten(_.values(_ctx.rshiftsByRole));
			var siblings = [];
			_.forEach(rshifts, function (rs) {
				if (rs.aggregate && rs.aggregate.id === rAggr.id && !_.some(siblings, { 'id': rs.id })) {
					siblings.push(rs);
				}
			});
			return siblings;
		},

		// Return the rshifts that share the given roster aggregate.
		rshiftsForAggregateGroupedByTeam: function rshiftsForAggregateGroupedByTeam(rAggr) {
			var aggr = this.rshiftsForAggregate(rAggr);
			var grp = _.groupBy(aggr, function (rs) {
				return rs.shift.role.organization.name;
			});

			var results = [];
			_.forEach(Object.keys(grp), function (team) {
				results.push({ team: team, shifts: grp[team] });
			});
			return _.sortBy(results, 'team');
		},

		assignPractitioner: _assignPractitioner,

		removeFromRoster: _removeFromRoster,

		// Return the level of the practitioner from its team assignment
		// LATER: Integrate this with the ResourceService
		getTeamAssignment: function getTeamAssignment(practitioner) {
			return _.find(_ctx.teamAssignments, { practitioner: { id: practitioner.id } });
		},

		fetchPractitioners: AppService.fetchPractitioners,

		// Filter
		teamsOnly: function teamsOnly(org) {
			return org.type === 'team' || org.type === 'subteam';
		},

		//depends on loadTeamsWithShifts being called first
		teamsWithShiftsOnly: function teamsWithShiftsOnly(t) {
			return _.find(_teamsWithShifts, { 'id': t.id });
		},

		//depends on loadTeamsWithRoles being called first
		teamsWithRolesOnly: function teamsWithRolesOnly(t) {
			return _.find(_teamsWithRoles, { 'id': t.id });
		},

		teamsByCampus: function teamsByCampus() {
			if (!_ctx.selectedCampus || !_ctx.selectedCampus.id) {
				return _.chain(_teamsWithRoles).filter(o.allowedTeams).sortBy('name').value();
			}
			return _.chain(_teamsWithRoles).filter(o.allowedTeams).filter(function (t) {
				return t.partOf.location[0].id === _ctx.selectedCampus.id;
			}).sortBy('name').value();
		},

		allowedTeams: function allowedTeams(t) {
			return AuthService.belongsTo('rosteradmin') || AuthService.belongsTo('roster') || AuthService.hasAdminPriv() || _.some(AuthService.authRolesByFunction('roster'), function (g) {
				return g === t.identifier[0].value;
			});
		},

		periodsOverlap: function periodsOverlap(p1, p2) {
			return +p1.end > +p2.start && +p1.start < +p2.end;
		},

		overlapsViewPeriod: function overlapsViewPeriod(s) {
			var ol = o.periodsOverlap(s.period, _ctx.viewPeriod);
			return ol;
		},
		// Find the first time that an override shift can start at.
		// This will be either the start time of the overridden shift (if no existing overrides)
		// or the end time of last existing override. We don't let user add overrides prior to
		// other existing overrides
		getOverrideStartTime: function getOverrideStartTime(rshift) {
			return moment(rshift.assigned.override.period.end).toDate();
		},

		overriddenTimes: function overriddenTimes(rshift) {
			//find all other overrides of rshift and create an array of start and end times
			var times = [];
			_.forEach(_.filter(_.flatten(_.values(_ctx.rshiftsByRole)), function (rs) {
				return rs && rs.assigned && rs.assigned.override && rs.assigned.override.id === rshift.assigned.id;
			}), function (or) {
				times.push({ start: or.period.start, end: or.period.end });
			});
			//now sort them and merge any contiguous time periods
			return consolidateContiguousTimes(_.sortBy(times, 'start'));
		},

		teamsWithShifts: _teamsWithShifts,

		isHoliday: _isHoliday,

		cloneRosterShifts: function cloneRosterShifts(rshiftsToClone, schedulesToCloneFor, startDate, endDate) {
			var deferred = $q.defer();
			var p = {
				roleAssignmentIds: _.map(rshiftsToClone, 'assigned.id'),
				schedules: _.map(schedulesToCloneFor, 'code'),
				startDate: moment(startDate).format(),
				endDate: moment(endDate).format(),
				campusCode: getCampusCodeForTheTeam()
			};
			var url = UrlService.rosterUrl() + '/clone';
			AuthService.setHttpAuthHeader();
			var cfg = {
				headers: { 'Content-Type': 'application/json' }
			};
			$http.post(url, p, cfg).then(function () {
				deferred.resolve();
			}, function (err) {
				deferred.reject(err);
			});
			return deferred.promise;
		},

		unassignedRoles: function unassignedRoles() {
			var deferred = $q.defer();
			var url = UrlService.reportsUrl() + '/roster/unassigned-core-roles';
			AuthService.setHttpAuthHeader();
			var req = {
				method: 'GET',
				url: url
			};
			req.headers = { 'Accept': 'application/json' };
			$http(req).then(function (res) {
				deferred.resolve(res.data);
			}, function (err) {
				deferred.reject(err);
			});
			return deferred.promise;
		},

		handleNewRoleAssignment: function handleNewRoleAssignment(ra) {
			ResourceService.addToContext(_ctx, ra);
			if (o.overlapsViewPeriod(ra) && ra.role.organization.id === _ctx.selectedTeam.id) {
				if (ra.aggregate) {
					_issueRosterRefresh(ra.role.organization);
				} else {
					_issueRosterRefresh(null, ra.role);
				}
			}
		},

		handleDeletedRoleAssignment: function handleDeletedRoleAssignment() {
			_issueRosterRefresh(_ctx.selectedTeam);
		},

		aggregateSseHandler: function aggregateSseHandler(data) {
			var feedData = AppService.parseFeedData(data);

			var criteria = void 0;
			if (feedData.resourceType === 'MtAggregate') {
				criteria = 'aggregate:MtAggregate=' + feedData.id + '\n\t\t\t\t\t&_include=MtRoleAssignment:practitioner\n\t\t\t\t\t&_include=MtRoleAssignment:role\n\t\t\t\t\t&_include=MtRoleAssignment:shift\n\t\t\t\t\t&_include=MtRoleAssignment:aggregate';

				if (feedData.deleted) {
					ResourceService.unregister(_ctx, _.find(_ctx.aggregates, { 'id': feedData.id }));
					ResourceService.search(_ctx, 'MtRoleAssignment', criteria).then(function () {
						o.refreshRoster(_ctx.selectedTeam);
					});
				}
			}
		}
	};

	return o;
}]);