
'use strict';

angular.module('medtasker').controller('RosterDialogCtrl', ['modalArgs', 'RosterService', 'AppService', 'DialogService', 'ResourceService', '$modalInstance', 'moment', '_', '$q', 'LogService', 'config', function (modalArgs, RosterService, AppService, DialogService, ResourceService, $modalInstance, moment, _, $q, LogService, config) {

	var _this = this;
	_this.ctx = modalArgs.ctx;

	_this.title = modalArgs.mode === 'override' ? 'Override Shift' : 'Edit Shift';
	_this.mode = modalArgs.mode;
	_this.aggregateRShifts = modalArgs.rshiftsForAggregate;

	_this.rshiftsByRole = _this.ctx.rshiftsByRole;
	_this.selectedReason = null;
	_this.reasons = config.overrideReasons;
	_this.includeAllAggShifts = true;
	_this.otherReason = null;
	_this.selectedAggShift = {};

	_this.fetchPractitioners = RosterService.fetchPractitioners;

	if (modalArgs.mode === 'edit') {
		_this.selectedPractitioner = _this.ctx.selectedRShift.assigned.practitioner;
	}
	_this.invalid = false;
	_this.invalidTimes = [];
	_this.shiftFull = false;

	var overrides = [];

	_this.campusCode = AppService.campusDisplayCode;

	function setStartAndEndTimes() {
		var start = moment(_this.ctx.selectedRShift.period.start);
		var end = moment(_this.ctx.selectedRShift.period.end);
		_this.startTime = start.toDate();
		_this.endTime = end.toDate();

		if (_this.invalidTimes.length > 0) {
			//overrides exist - start at earliest valid time, end at latest valid time
			if (+_this.invalidTimes[0].start === +start) {
				//do overrides fill the whole roster shift's duration? This shouldn't happen...
				if (+_this.invalidTimes[0].end === +end) {
					_this.shiftFull = true;
					_this.invalid = true;
					return;
				}
				_this.startTime = _this.invalidTimes[0].end.toDate();
				//if there are any more overridden blocks, initial end time is start of that override
				if (_this.invalidTimes.length > 1) {
					_this.endTime = _this.invalidTimes[1].start.toDate();
				}
			} else {
				_this.endTime = _this.invalidTimes[0].start.toDate();
			}
		}
	}

	if (_this.ctx.selectedRShift.assigned.override) {
		//we are editing an existing override

		//find all other overrides of the same rs that this rs overrides
		overrides = _.filter(_.flatten(_.values(_this.ctx.rshiftsByRole)), function (rs) {
			return rs.assigned && rs.assigned.override && rs.assigned.override.id === _this.ctx.selectedRShift.assigned.override.id && rs.id !== _this.ctx.selectedRShift.id;
		});

		//find the min start time - could be the end of the latest earlier override, or the start time of the shift being overridden
		//if no earlier overrides
		var earlier = _.filter(overrides, function (o) {
			return +o.period.end <= +_this.ctx.selectedRShift.period.start;
		});
		if (earlier.length > 0) {
			_this.origStartDate = moment(_.last(_.sortBy(earlier, 'period.end')).period.end).toDate();
		} else {
			_this.origStartDate = moment(_this.ctx.selectedRShift.assigned.override.period.start).toDate();
		}

		//find the max end time - could be the start of the earliest later override, or the end time of the shift being overridden
		//if no later overrides
		var later = _.filter(overrides, function (o) {
			return +o.period.start >= +_this.ctx.selectedRShift.period.end;
		});
		if (later.length > 0) {
			_this.origEndDate = moment(_.first(_.sortBy(later, 'period.end')).period.start).toDate();
		} else {
			_this.origEndDate = moment(_this.ctx.selectedRShift.assigned.override.period.end).toDate();
		}
		var rsn = _.find(_this.reasons, function (r) {
			return r.display === _this.ctx.selectedRShift.assigned.note;
		});
		if (!rsn) {
			rsn = _.find(_this.reasons, function (r) {
				return r.code === 'other';
			});
			_this.otherReason = _this.ctx.selectedRShift.assigned.note;
		}
		_this.selectedReason = rsn;
		//we are either editing a non-override rs or we are overriding a rs
		//ignore editing a non-override as we can't change the time in that case
	} else if (modalArgs.mode === 'override') {
		_this.invalidTimes = RosterService.overriddenTimes(_this.ctx.selectedRShift);

		_this.origStartDate = moment(_this.ctx.selectedRShift.period.start).toDate();
		_this.origEndDate = moment(_this.ctx.selectedRShift.period.end).toDate();
	}

	_this.rshiftStart = _this.ctx.selectedRShift.period.start.toDate();
	_this.rshiftEnd = _this.ctx.selectedRShift.period.end.toDate();

	setStartAndEndTimes();
	//only set up slider if we're going to show it...
	if (modalArgs.mode === 'override' || _this.ctx.selectedRShift.assigned.override) {
		//configure the slider
		var ft = moment(_this.origStartDate);
		var tt = moment(_this.origEndDate);
		var base = moment(ft).startOf('day');
		var from = ft.diff(base, 'minutes');
		var to = tt.diff(base, 'minutes');
		var low = moment(_this.startTime).diff(base, 'minutes');
		var high = moment(_this.endTime).diff(base, 'minutes');
		_this.sliderModel = low + ';' + high;

		//generate the scale
		var scale = [];
		var t = moment(_this.origStartDate);
		do {
			if (t.minute() === 0) {
				//only show time on the hours
				//show day on first scale item and day changeover
				if (t.hour() === 0 || +t === +moment(_this.origStartDate)) {
					scale.push(t.format('HH:mm ddd'));
				} else {
					scale.push(t.format('HH:mm'));
				}
			} else {
				scale.push('');
			}
			t.add(15, 'minutes');
		} while (+t <= +_this.origEndDate);

		_this.calculate = function (value) {
			if (value || value === 0) {
				return moment(base).add(value, 'minutes').format('ddd HH:mm');
			}
			return void 0;
		};

		_this.onsliderstatechange = function (value) {
			var vals = value.split(';');
			_this.startTime = moment(base).add(vals[0], 'minutes');
			_this.endTime = moment(base).add(vals[1], 'minutes');
			_this.validateTimes(value);
		};

		_this.sliderOptions = {
			from: from,
			to: to,
			smooth: false,
			step: 15,
			scale: scale,
			limits: false,
			calculate: _this.calculate,
			onstatechange: _this.onsliderstatechange
		};
	}

	// sanity check
	if (!_this.ctx.selectedRShift) {
		LogService.error('RosterDialogCtrl called when no roster shift is selected');
		return;
	}

	_.forEach(_this.aggregateRShifts, function (s) {
		_this.selectedAggShift[s.id] = true;
	});

	//filter out the currently selected practitioner if overriding
	_this.notSelected = function (p) {
		return !(modalArgs.mode === 'override' && p.id === _this.ctx.selectedRShift.assigned.practitioner.id);
	};

	_this.validateTimes = function () {
		if (_this.invalidTimes.length === 0) {
			return;
		}
		_this.invalid = false;
		var s = moment(_this.startTime);
		var e = moment(_this.endTime);
		//check the start and end are not within any of the invalid time zones
		_this.invalid = !!_.find(_this.invalidTimes, function (t) {
			return +s > +t.start && +s < +t.end || +e > +t.start && +e < +t.end;
		});
		//check that no invalid time zone falls between the start and end times
		if (!_this.invalid) {
			_this.invalid = !!_.find(_this.invalidTimes, function (t) {
				return +t.start > +s && +t.end < +e;
			});
		}
	};

	_this.isInvalid = function () {
		return _this.invalid || !_this.selectedReason || !_this.selectedPractitioner || _this.selectedReason.code === 'other' && (!_this.otherReason || _this.otherReason.length === 0);
	};

	_this.searchPracName = function (p) {
		return p ? AppService.displayName(p) + ' (' + p.identifier[0].value + ')' : '';
	};

	_this.displayName = AppService.displayName;

	_this.setReason = function (r) {
		_this.selectedReason = r;
	};

	_this.showOverride = function () {
		return _this.ctx.selectedRShift.assigned && _this.ctx.selectedRShift.type !== 'override';
	};

	function getReason() {
		var reason = '';
		if (_this.selectedReason.code === 'other') {
			reason = 'Other: ' + _this.otherReason;
		} else {
			reason = _this.selectedReason.display;
		}
		return reason;
	}
	// Update RShift with the selectedPractitioner
	// If the RShift is part of an aggregate also update all rshifts of the aggregate.
	_this.edit = function () {
		var updates = [];
		// TODO: Make a single call to the server for all RA updates (transaction)
		// This can't be done until the server supports Bundled requests
		var rshifts = RosterService.rshiftsForAggregate(_this.ctx.selectedRShift.aggregate);
		rshifts = rshifts.length > 0 ? rshifts : [_this.ctx.selectedRShift]; // covers aggr and non aggr cases
		_.forEach(rshifts, function (rshift) {

			// sanity checks
			if (rshift.assigned.length === 0) {
				LogService.error('RosterShift has no practitioner assigned to it: ' + _this.ctx.selectedRShift.id);
				return; // next rshift
			} else if (rshift.assigned.length > 1) {
				LogService.error('RosterShift has more than one practitioner assigned to it: ' + _this.ctx.selectedRShift.id);
				return; // next rshift
			}

			var ra = rshift.assigned;
			ra.period.start = moment(_this.startTime);
			ra.period.end = moment(_this.endTime);
			var updatedRA = angular.copy(ra);
			updatedRA.note = getReason();
			updatedRA.practitioner = _this.selectedPractitioner;
			updates.push(AppService.update(updatedRA));
		});

		$q.all(updates).then(function (ras) {
			RosterService.refreshRoster(modalArgs.ctx.selectedTeam);
			$modalInstance.close(ras);
		}, function (err) {
			DialogService.errorMessage('An error occurred trying to edit the roster.', err);
			LogService.error('Unable to update MtRoleAssignment: ' + angular.toJson(err));
		});
	};

	_this.selectedAggsChanged = function (id) {
		var aggShift = _this.selectedAggShift[id];
		_this.selectedAggShift[id] = !_this.selectedAggShift[id];
		if (aggShift === false) {
			_this.includeAllAggShifts = false;
		}
	};

	// Create an override RShift with the selectedPractitioner
	// If the underlying RShift is part of an aggregate also update all rshifts of the aggregate.
	_this.save = function () {
		if (modalArgs.mode === 'edit') {
			_this.edit();
			return;
		}
		_this.triedSave = true;
		if (!_this.selectedPractitioner || !_this.selectedReason) {
			return;
		}

		var rshift = _this.ctx.selectedRShift;

		var period = {
			start: moment(_this.startTime),
			end: moment(_this.endTime)
		};

		var reason = getReason();
		var excluded = void 0;
		//get a list of the roster shifts in the aggregate that we *don't* want to override
		if (rshift.aggregate) {
			excluded = _.map(_.filter(_this.aggregateRShifts, function (s) {
				return !_this.selectedAggShift[s.id] && s.id !== rshift.id; //always include the original selected roster shift
			}), 'shift.id');
		}
		RosterService.assignPractitioner(rshift, _this.selectedPractitioner, period, reason, !_this.ignoreAggregate, excluded).then(function (resArray) {
			RosterService.refreshRoster(_this.ctx.selectedTeam);
			$modalInstance.close(resArray);
		}, function (err) {
			DialogService.errorMessage(err);
			LogService.error('Unable to create override RoleAssignment: ' + err);
		});
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.selectAllAggregateShifts = function () {
		_this.includeAllAggShifts = !_this.includeAllAggShifts;
		_.forEach(_.filter(_this.aggregateRShifts, function (s) {
			return s.id !== _this.ctx.selectedRShift.id;
		}), function (s) {
			_this.selectedAggShift[s.id] = _this.includeAllAggShifts;
		});
	};
}]);