
'use strict';

angular.module('medtasker').controller('RosterPrintCtrl', ['ResourceService', 'AppService', 'RosterService', 'AuthService', '$scope', '$rootScope', '$state', '$stateParams', '_', 'moment', 'config', '$timeout', '$interval', 'LogService', '$window', 'TodaysRosterService', function (ResourceService, AppService, RosterService, AuthService, $scope, $rootScope, $state, $stateParams, _, moment, config, $timeout, $interval, LogService, $window, TodaysRosterService) {
	// eslint-disable-line
	var _this = this;
	// use id of 0 to designate all wards . Not entrely certain how the const definition works in javascript
	var noward = { id: 0, name: 'All', type: 'team' };
	_this.ctx = AppService.ctx; // used in view
	_this.teamsByCampus = RosterService.teamsByCampus;

	_this.config = config;
	var s = $stateParams.date || moment().format('YYYY-MM-DD');
	_this.start = moment(s).startOf('day');
	_this.end = moment(_this.start).add(1, 'days').add(8, 'hours');
	// remove the all from the campuses field
	_this.campuses = _.filter(AppService.campuses(), function (loc) {
		return loc.name.toLowerCase() !== 'all';
	});

	function teamCode() {
		if (_this.ctx.selectedTeam.identifier) {
			return _.find(_this.ctx.selectedTeam.identifier, function (i) {
				return i.system.startsWith(config.hospitalSystemPrefix);
			}).value;
		}
		return '';
	}

	if ($stateParams.campus) {
		_this.ctx.selectedCampus = _.find(_this.campuses, { 'id': $stateParams.campus });
	} else {
		_this.ctx.selectedCampus = _this.campuses[0];
	}
	noward.name = '(' + _this.ctx.selectedCampus.identifier[0].value + ') All Teams';
	_this.campusCode = AppService.campusDisplayCode;
	_this.teams = _this.teamsByCampus();
	_this.teams.unshift(noward);
	if ($stateParams.team && $stateParams.team === '0') {
		_this.ctx.selectedTeam = _this.teams[0];
	}
	_this.formatCampus = function (c) {
		if (!c.id) {
			return '--' + c.name + '--';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	_this.onCampusChange = function () {
		// LogService.debug('on campus change ' + angular.toJson(_this.selectedCampus));
		$state.go($state.current, { team: null, campus: _this.ctx.selectedCampus.id });
		// _this.ctx.selectedTeam = noward;
	};

	_this.onTeamChange = function () {
		if (_this.ctx.selectedTeam.id !== 0) {
			var id = teamCode();
			$state.go($state.current, { team: id, campus: _this.ctx.selectedCampus.id });
		} else {
			$state.go($state.current, { team: 0, campus: _this.ctx.selectedCampus.id });
		}
	};
	if ($stateParams.team) {
		$rootScope.$broadcast('appLoadingPush', { source: 'RosterPrint' });
		TodaysRosterService.getRosterForPrint(_this.start, _this.end, _this.ctx.selectedCampus, _this.ctx.selectedTeam).then(function (res) {
			$rootScope.$broadcast('appLoadingPop', { source: 'RosterPrint' });
			_this.roster = res.data;
		}, function (err) {
			LogService.error(err, 2);
			$rootScope.$broadcast('appLoadingPop', { source: 'RosterPrint' });
		});
	}

	_this.printScreen = function () {
		$window.print();
	};

	_this.contact = function (telecoms) {
		var dflt = 'via switch';

		if (!telecoms || telecoms.length === 0) {
			return dflt;
		}
		var p = _.find(telecoms, { 'system': 'pager' });
		if (p) {
			return '#' + p.value;
		}
		p = _.find(telecoms, { 'system': 'phone' });
		if (p) {
			if (p.value.length === 5) {
				return 'x' + p.value; //extension
			}
			return p.value;
		}
		return dflt;
	};

	_this.printTime = moment();

	$interval(function () {
		_this.printTime = moment();
	}, 1000);
}]);