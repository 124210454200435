'use strict';

/* global angular */
/* global sprintf */

angular.module('medtasker.service').filter('formatDuration', [function () {
	'use strict';

	return function (duration) {
		//if (typeof duration !== moment.duration) {
		//	$log.error('filter formatDuration requires a moment.duration object');
		//	return 'n/a';
		//}
		// NOTE: This filter doesn't handle negative durations
		var hours = duration.days() * 24 + duration.hours();
		return sprintf('%02d:%02d', hours, duration.minutes());
	};
}]);

angular.module('medtasker.service').filter('capitalize', [function () {
	'use strict';

	return function (input) {
		return input ? input.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}) : '';
	};
}]);

angular.module('medtasker.service').filter('friendlyAge', ['moment', function (moment) {
	'use strict';

	return function (dob) {
		var years = moment().diff(dob, 'years');
		if (years > 1) {
			return years + 'yo';
		}
		var months = moment().diff(dob, 'months');
		if (months > 2) {
			return months + 'mo';
		}
		var weeks = moment().diff(dob, 'weeks');
		if (weeks > 0) {
			return weeks + 'weeks old';
		}
		return moment().diff(dob, 'days') + ' days old';
	};
}]);

angular.module('medtasker.service').filter('shortGender', [function () {
	'use strict';

	return function (gender) {
		return gender.charAt(0).toUpperCase();
	};
}]);

angular.module('medtasker.service').filter('displayName', ['AppService', function (AppService) {
	'use strict';

	return function (p) {
		return AppService.displayName(p);
	};
}]);

angular.module('medtasker.service').filter('momentFormat', ['moment', function (moment) {
	'use strict';

	return function (date, dispString) {
		return moment(date).format(dispString);
	};
}]);

angular.module('medtasker.service').filter('showFirst30Chars', [function () {
	'use strict';

	function removeTags(t) {
		var regex = /(<([^>]+)>)/ig;
		return t.replace(regex, '');
	}
	return function (input) {
		if (!input) {
			return void 0;
		}
		if (input.length <= 30) {
			return removeTags(input);
		}
		return removeTags(input).slice(0, 30) + '...';
	};
}]);

angular.module('medtasker.service').filter('urNumber', [function () {
	'use strict';

	return function (patient) {
		if (patient.identifier && patient.identifier.length) {
			return '#' + patient.identifier[0].value;
		}
		return 'None found';
	};
}]);

angular.module('medtasker.service').filter('genderIcon', [function () {
	'use strict';

	return function (gender) {
		if (!gender) {
			return null;
		}
		var g = gender.charAt(0).toUpperCase();
		switch (g) {
			case 'M':
				return 'ion-male';
			case 'F':
				return 'ion-female';
			default:
				return 'ion-transgender';
		}
	};
}]);

angular.module('medtasker.service').filter('genderIconFA', [function () {
	'use strict';

	return function (gender) {
		if (!gender) {
			return null;
		}
		var g = gender.charAt(0).toUpperCase();
		switch (g) {
			case 'M':
				return 'fa-mars';
			case 'F':
				return 'fa-venus';
			default:
				return 'fa-intersex';
		}
	};
}]);

angular.module('medtasker.service').filter('friendlyDateTime', ['moment', function (moment) {
	'use strict';

	return function (date) {
		return moment(date).calendar();
	};
}]);

angular.module('medtasker.service').filter('roleFullName', ['AppService', function (AppService) {
	'use strict';

	return function (role) {
		return AppService.roleFullName(role);
	};
}]);

angular.module('medtasker.service').filter('roleShortName', ['AppService', function (AppService) {
	'use strict';

	return function (role) {
		return AppService.roleShortName(role);
	};
}]);

angular.module('medtasker.service').filter('startFrom', [function () {
	'use strict';

	return function (input, start) {
		start = +start; //parse to int
		return input.slice(start);
	};
}]);