'use strict';

angular.module('medtasker').controller('ReportsCtrl', ['ctx', 'ResourceService', 'InsightsService', 'AppService', 'DialogService', 'moment', '_', 'download', '$q', '$log', '$window', '$timeout', '$rootScope', 'config', function (ctx, ResourceService, InsightsService, AppService, DialogService, moment, _, download, $q, $log, $window, $timeout, $rootScope, config) {

	var _this = this;
	var csvFooter = void 0;

	function unescape(text) {
		return text.replace(/\"/g, '').replace(/\'/g, '');
	}
	function tzoffset() {
		return moment().format('ZZ');
	}
	function baseChartConfig(title, xCaption, type) {
		return {
			options: {
				legend: {
					borderRadius: 5,
					borderColor: '#dddddd',
					borderWidth: 1
				},
				chart: {
					type: type,
					style: {
						fontFamily: 'Cabin'
					}
				},
				tooltip: {
					style: {
						padding: 10,
						fontWeight: 'bold'
					}
				},
				plotOptions: {
					series: {
						dataLabels: {
							enabled: true
						}
					}
				}
			},
			credits: {
				enabled: false
			},
			showInLegend: false,
			title: {
				text: title
			},
			loading: _this.loading,
			xAxis: {
				title: {
					text: xCaption,
					style: {
						fontWeight: 'bold'
					}
				},
				categories: []
			},

			yAxis: {
				min: 0,
				allowDecimals: false,
				title: {
					style: {
						fontWeight: 'bold'
					}
				}
			}
		};
	}

	function simpleChartConfig(type, title, xCaption) {
		var cfg = baseChartConfig(title, xCaption, type);
		cfg.series = [{
			name: 'Tasks',
			colorByPoint: true,
			animation: true
		}];
		cfg.xAxis.type = 'category';
		return cfg;
	}

	function stackedChartConfig(type, categories, title, xCaption) {
		var cfg = baseChartConfig(title, xCaption, type);
		cfg.options.plotOptions = {
			series: {
				stacking: 'normal'
			}
		};
		cfg.xAxis.categories = categories;
		cfg.yAxis.stackLabels = {
			enabled: true,
			style: {
				fontWeight: 'bold'
			}
		};
		return cfg;
	}

	_this.config = config;

	_this.roleTypes = config.mtRoleTypes;

	_this.campuses = AppService.campuses();
	if (config.defaultCampusLoc) {
		_this.campus = _.find(_this.campuses, { 'id': config.defaultCampusLoc.id });
	}
	_this.formatCampus = function (c) {
		if (!c) {
			return '';
		}
		if (!c.id) {
			return '--' + c.name + '--';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	function parseQuotedFields(r) {
		var rx = /[^\,"]+|"([^"]*)"/gi;
		var fields = [];
		var match = void 0;
		do {
			match = rx.exec(r);
			if (match !== null) {
				fields.push(match[1] ? match[1] : match[0]);
			}
		} while (match !== null);
		return fields;
	}

	function getReportParams() {
		var p = {
			from: moment(_this.startDate).format('YYYY-MM-DD'),
			to: moment(_this.endDate).format('YYYY-MM-DD')
		};
		if (_this.ward) {
			p.ward = _this.ward.id;
		}
		if (_this.team) {
			p.team = _this.team.identifier[0].value;
		}
		if (_this.roleType) {
			p.roleType = _this.roleType.display;
		}
		if (_this.campus && _this.campus.id) {
			p.campus = _this.campus.identifier[0].value;
		}
		return p;
	}

	function showTasksByDay() {
		var p = getReportParams();
		$rootScope.$broadcast('appLoadingPush', { source: 'showTasksByDay' });
		return ResourceService.report('tasks-by-day', p).then(function (data) {
			$rootScope.$broadcast('appLoadingPop', { source: 'showTasksByDay' });
			_this.csv1 = data.data + csvFooter;
			var lines = data.data.split('\n');
			var days = lines[0].split(',');
			days.shift();
			var sent = lines[1].split(',');
			_this.total1 = parseInt(sent.splice(sent.length - 1, 1)[0]); //remove total
			sent.shift();
			days.splice(days.length - 1, 1); //remove total
			var completed = lines[2].split(',');
			completed.shift();
			completed.pop();
			var cancelled = lines[3].split(',');
			cancelled.shift();
			cancelled.pop();

			_this.chartConfig1 = {
				title: {
					text: 'Tasks by day'
				},
				xAxis: {
					categories: days
				},
				series: [{
					data: _.map(sent, function (d) {
						return parseInt(d);
					}),
					name: 'Sent',
					color: InsightsService.chartColors[28]
				}, {
					data: _.map(completed, function (d) {
						return parseInt(d);
					}),
					name: 'Completed',
					color: InsightsService.chartColors[27]
				}, {
					data: _.map(cancelled, function (d) {
						return parseInt(d);
					}),
					name: 'Cancelled',
					color: InsightsService.chartColors[29]
				}],
				yAxis: [{
					allowDecimals: false,
					style: {
						color: InsightsService.chartColors[28]
					},
					title: {
						text: 'Tasks sent'
					}
				}]
			};
		});
	}

	function showTaskTimeByUrgency() {
		var p = getReportParams();
		$rootScope.$broadcast('appLoadingPush', { source: 'showTaskTimeByUrgency' });
		return ResourceService.report('task-time-by-urgency', p).then(function (data) {
			$rootScope.$broadcast('appLoadingPop', { source: 'showTaskTimeByUrgency' });
			_this.csv2 = data.data + csvFooter;
			var lines = data.data.split('\n');
			var items = [];
			var group = [];

			for (var i = 1; i < lines.length - 1; i++) {
				var line = lines[i].split(',');
				var urgency = line.splice(0, 1)[0];
				var o = {
					urgency: urgency,
					results: line
				};
				group.push(o);
			}
			group = _.sortBy(group, function (o) {
				return -1 * _.findIndex(config.urgencies, function (u) {
					return u.display === o.urgency;
				});
			});

			items.push({
				type: 'column',
				name: 'No. of completed tasks',
				color: InsightsService.chartColors[28],
				data: _.transform(group, function (accum, o) {
					accum.push(parseInt(o.results[0]));
				}),
				yAxis: 0,
				stack: 'tasks'
			});

			items.push({
				type: 'column',
				name: 'Avg (mean) time accept to complete',
				color: InsightsService.chartColors[29],
				data: _.transform(group, function (accum, o) {
					accum.push(Math.round((parseFloat(o.results[2]) - parseFloat(o.results[1])) * 100) / 100);
				}),
				stack: 'time',
				yAxis: 1
			});

			items.push({
				type: 'column',
				name: 'Avg (mean) time to accept',
				color: InsightsService.chartColors[27],
				data: _.transform(group, function (accum, o) {
					accum.push(Math.round(parseFloat(o.results[1]) * 100) / 100);
				}),
				yAxis: 1,
				stack: 'time'
			});

			_this.chartConfig2 = stackedChartConfig('column', _.map(group, 'urgency'), 'Average Task Times by Urgency');
			_this.chartConfig2.zoomType = 'xy';

			_this.chartConfig2.yAxis = [{
				style: {
					color: InsightsService.chartColors[28]
				},
				title: {
					text: 'Tasks'
				},
				opposite: true
			}, {
				title: {
					text: 'Minutes'
				}
			}];
			_this.chartConfig2.options.tooltip = {
				style: {
					padding: 15,
					fontWeight: 'bold'
				},
				formatter: function formatter() {
					/*eslint-disable */
					var s = '<span style="color: ' + this.color + '">' + this.key + ' - ' + this.series.name + '</span><br>';
					if (this.series.stackKey === 'columntime') {
						s = s + this.y + ' mins';
					} else {
						s = s + this.y + ' tasks';
					}
					/*eslint-enable */
					return s;
				}
			};
			_this.chartConfig2.series = items;
		});
	}

	function showTaskTimeByType() {
		var p = getReportParams();
		$rootScope.$broadcast('appLoadingPush', { source: 'showTaskTimeByType' });
		return ResourceService.report('task-time-by-type', p).then(function (data) {
			$rootScope.$broadcast('appLoadingPop', { source: 'showTaskTimeByType' });
			_this.csv5 = data.data + csvFooter;
			var lines = data.data.split('\n');
			var items = [];
			var group = [];

			for (var i = 1; i < lines.length - 1; i++) {
				var line = lines[i].split(',');
				var type = unescape(line.splice(0, 1)[0]);
				var o = {
					type: type,
					results: line
				};
				group.push(o);
			}
			group = _.sortBy(group, function (o) {
				return parseFloat(o.results[2]) * -1;
			});

			items.push({
				type: 'column',
				name: 'No. of completed tasks',
				color: InsightsService.chartColors[28],
				data: _.transform(group, function (accum, o) {
					accum.push(parseInt(o.results[0]));
				}),
				yAxis: 0,
				stack: 'tasks'
			});

			items.push({
				type: 'column',
				name: 'Avg (mean) time accept to complete',
				color: InsightsService.chartColors[29],
				data: _.transform(group, function (accum, o) {
					accum.push(Math.round((parseFloat(o.results[2]) - parseFloat(o.results[1])) * 100) / 100);
				}),
				stack: 'time',
				yAxis: 1
			});

			items.push({
				type: 'column',
				name: 'Avg (mean) time to accept',
				color: InsightsService.chartColors[27],
				data: _.transform(group, function (accum, o) {
					accum.push(Math.round(parseFloat(o.results[1]) * 100) / 100);
				}),
				yAxis: 1,
				stack: 'time'
			});

			_this.chartConfig5 = stackedChartConfig('bar', _.map(group, 'type'), 'Time to Complete by Task Type');
			_this.chartConfig5.zoomType = 'xy';

			_this.chartConfig5.yAxis = [{
				style: {
					color: InsightsService.chartColors[28]
				},
				title: {
					text: 'Tasks'
				},
				opposite: true
			}, {
				title: {
					text: 'Minutes'
				}
			}];
			_this.chartConfig5.options.tooltip = {
				style: {
					padding: 15,
					fontWeight: 'bold'
				},
				formatter: function formatter() {
					/*eslint-disable */
					var s = '<span style="color: ' + this.color + '">' + this.key + ' - ' + this.series.name + '</span><br>';
					if (this.series.stackKey === 'columntime') {
						s = s + this.y + ' mins';
					} else {
						s = s + this.y + ' tasks';
					}
					/*eslint-enable */
					return s;
				}
			};
			_this.chartConfig5.series = items;
		});
	}

	function showTasksByTime() {
		var p = getReportParams();
		_this.chartConfig3 = simpleChartConfig('line', 'Sent Tasks by Time of Day');
		$rootScope.$broadcast('appLoadingPush', { source: 'showTasksByTime' });
		return ResourceService.report('tasks-by-hour', p).then(function (data) {
			$rootScope.$broadcast('appLoadingPop', { source: 'showTasksByTime' });
			_this.csv3 = data.data + csvFooter;
			var lines = data.data.split('\n');
			var times = lines[0].split(',');
			times.splice(0, 1);
			var totals = [];

			for (var l = 1; l < lines.length - 2; l++) {
				var tasks = lines[l].split(',');
				tasks.splice(0, 1); //remove type name column
				for (var t = 0; t < times.length; t++) {
					totals[t] = (totals[t] || 0) + parseInt(tasks[t]);
					//$log.debug(totals[t]);
				}
			}

			var item = {
				name: 'Tasks',
				data: totals,
				color: InsightsService.chartColors[9],
				colorByPoint: false,
				animation: true
			};
			_this.chartConfig3.series[0] = item;
			_this.chartConfig3.xAxis.categories = times;
			_this.chartConfig3.yAxis.title.text = 'Tasks';
			//$log.debug(angular.toJson(_this.chartConfig3));
		});
	}

	function showTasksByRoleAndType() {
		var p = getReportParams();
		$rootScope.$broadcast('appLoadingPush', { source: 'showTasksByRoleAndType' });
		return ResourceService.report('tasks-by-role-and-type', p).then(function (data) {
			$rootScope.$broadcast('appLoadingPop', { source: 'showTasksByRoleAndType' });
			_this.csv4 = data.data + csvFooter;
			var lines = data.data.split('\n');
			var roles = parseQuotedFields(lines[0]);
			roles.splice(0, 1);

			var items = [];

			for (var l = 1; l < lines.length - 2; l++) {
				var line = lines[l].split(',');
				var type = unescape(line.splice(0, 1)[0]);
				var tasks = _.transform(line, function (accum, t) {
					// eslint-disable-line
					accum.push(parseInt(t));
				});
				items.push({
					name: type,
					data: tasks,
					color: InsightsService.chartColors[l % lines.length]
				});
			}
			var chartType = items.length > 25 ? 'bar' : 'column';
			_this.chartConfig4 = stackedChartConfig(chartType, _.map(roles, function (r) {
				return unescape(r);
			}), 'Tasks by Role and Type', '');
			_this.chartConfig4.series = items;
			_this.chartConfig4.yAxis.min = 0;
			_this.chartConfig4.yAxis.title.text = 'Tasks';
			if (chartType === 'column') {
				var sum = [];
				if (items[0]) {
					var _loop = function _loop(i) {
						sum[i] = _.sum(items, function (item) {
							return item.data[i];
						}); // eslint-disable-line
					};

					for (var i = 0; i < items[0].data.length; i++) {
						_loop(i);
					}
					var maxItems = _.max(sum) + 1;
					var height = 250 + maxItems * 20;

					_this.chartConfig4.options.chart.height = _.min([600, height]);
				}
			} else {
				_this.chartConfig4.options.chart.height = 200 + items.length * 18;
			}
			//$log.debug(angular.toJson(_this.chartConfig4));
		});
	}

	function showAverageTransitionTime() {
		function showTime(t) {
			var s = Math.round(parseFloat(t) * 100) / 100;
			return s.toString() + ' mins';
		}
		var p = getReportParams();
		$rootScope.$broadcast('appLoadingPush', { source: 'showAverageTransitionTime' });
		return ResourceService.report('task-time', p).then(function (data) {
			$rootScope.$broadcast('appLoadingPop', { source: 'showAverageTransitionTime' });
			_this.validAcceptAverages = true;
			_this.validCompleteAverages = true;
			_this.csv0 = data.data + csvFooter;
			var lines = data.data.split('\n');
			var summary = lines[lines.length - 2].split(',');
			if (isNaN(summary[1]) || isNaN(summary[5])) {
				_this.validAcceptAverages = false;
			}
			if (isNaN(summary[3]) || isNaN(summary[7])) {
				_this.validCompleteAverages = false;
			}
			_this.medianAccept = showTime(summary[1]);
			_this.medianComplete = showTime(summary[3]);
			_this.meanAccept = showTime(summary[5]);
			_this.meanComplete = showTime(summary[7]);
		});
	}

	_this.export = function () {
		var sd = moment(_this.startDate).add(config.reportDateOffsetHours * 1, 'hours');
		var ed = moment(_this.endDate).add(1, 'days').add(config.reportDateOffsetHours * 1, 'hours');
		var p = {
			csv: true,
			deidentify: !!_this.deidentify,
			sort: 'sent',
			sentAfter: AppService.formatDateForQuery(sd),
			sentBefore: AppService.formatDateForQuery(ed),
			includeInactive: true
		};
		if (_this.roleType) {
			p.roleType = _this.roleType.display;
		}
		if (_this.team) {
			p.team = _this.team.id;
		}
		if (_this.ward) {
			p.ward = _this.ward.id;
		}
		if (_this.campus) {
			p.campus = _this.campus.identifier[0].value;
		}

		$rootScope.$broadcast('appLoadingPush', { source: 'reportExport' });
		ResourceService.reportWithJsonAndQueryParamOptions('search-tasks-fhir', p, 'MtTask', true).then(function (res) {
			$rootScope.$broadcast('appLoadingPop', { source: 'reportExport' });
			_this.csv6 = res.data + '\nMedian mins to accept,' + _this.medianAccept;
			_this.csv6 = _this.csv6 + '\nMedian mins to complete,' + _this.medianComplete;
			_this.csv6 = _this.csv6 + csvFooter;
			_this.downloadCsv(6);
		}, function (err) {
			$rootScope.$broadcast('appLoadingPop', { source: 'reportExport' });
			DialogService.errorMessage('Something went wrong exporting your tasks: ' + err.data);
		});
	};

	_this.colors = InsightsService.chartColors;

	_this.dateDialog = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		_this.showDateDialog = true;
	};

	_this.reportStartDateInfo = function () {
		return 'Report period starts at ' + config.reportDateOffsetHours + ' o\'clock am on the selected date';
	};

	_this.reportEndDateInfo = function () {
		return 'Report period ends at ' + config.reportDateOffsetHours + ' o\'clock am on the day following the selected date';
	};

	_this.displayTeam = function (t) {
		if (!t) {
			return '';
		}
		return AppService.campusDisplayCode(t.partOf.location[0].id) + t.name;
	};

	_this.teams = _.sortBy(_.filter(AppService.ctx.organizations, function (org) {
		return org.type === 'team' || org.type === 'subteam';
	}), function (t) {
		if (t.partOf.location) {
			return t.partOf.location[0].identifier[0].value + t.name;
		}
		return '';
	});

	_this.wards = _.filter(AppService.ctx.locations, function (loc) {
		return _.some(loc.physicalType.coding, { 'code': 'wi' });
	});

	_this.isLoading = function () {
		return _this.loading;
	};

	function fileName(chartNum) {
		var params = moment(_this.startDate).format('DD-MM-YYYY') + '-' + moment(_this.endDate).format('DD-MM-YYYY');
		if (_this.roleType) {
			params = params + '-' + _this.roleType.code;
		}
		if (_this.team) {
			params = params + '-' + _this.team.identifier[0].value;
		}
		if (_this.ward) {
			params = params + '-' + _this.ward.identifier[0].value;
		}
		if (_this.campus) {
			params = params + '-' + _this.campus.identifier[0].value;
		}
		switch (chartNum) {
			case 0:
				return 'task-summary-' + params + '.csv';
			case 1:
				return 'tasks-by-day-' + params + '.csv';
			case 2:
				return 'tasks-by-urgency-' + params + '.csv';
			case 3:
				return 'tasks-by-time-' + params + '.csv';
			case 4:
				return 'tasks-by-role-' + params + '.csv';
			case 5:
				return 'tasks-by-type-' + params + '.csv';
			case 6:
				return 'tasks-export-' + params + '.csv';
		}
		return void 0;
	}

	_this.downloadCsv = function (chartNum) {
		download(_this['csv' + chartNum], fileName(chartNum), 'text/csv');
	};

	_this.startDate = moment().startOf('day').add(-6, 'days').toDate();
	_this.endDate = moment().startOf('day').toDate();
	function init() {
		_this.loading = true;

		_this.exported = false;
		_this.params = 'from=' + moment(_this.startDate).toISOString() + '&to=' + moment(_this.endDate).add(1, 'days').toISOString() + (_this.roleType ? '&roleType=' + _this.roleType.display : '') + (_this.team ? '&team=' + _this.team.identifier[0].value : '') + (_this.ward ? '&ward=' + _this.ward.id : '') + '&tzoffset=' + tzoffset();
		csvFooter = '\n\nDate range:,' + moment(_this.startDate).format('DD/MM/YYYY') + '-' + moment(_this.endDate).format('DD/MM/YYYY') + '\nGenerated:,' + moment().format('DD/MM/YYYY HH:mm:ss');
		var p = [];
		_this.chart1loading = true;
		_this.chart2loading = true;
		_this.chart3loading = true;
		_this.chart4loading = true;
		_this.chart5loading = true;
		_this.chart6loading = true;

		p.push(showTasksByDay());
		$q.all(p).then(function () {
			_this.loading = false;
			_this['chartConfig' + 1].loading = false;
			_this.chart1loading = false;

			$timeout(function () {
				//$log.debug('chart reflow');
				$rootScope.$broadcast('highchartsng.reflow');
			}, 500);
			p.pop();
			p.push(showTaskTimeByUrgency());
			$q.all(p).then(function () {
				_this['chartConfig' + 2].loading = false;
				_this.chart2loading = false;
				$timeout(function () {
					//$log.debug('chart reflow');
					$rootScope.$broadcast('highchartsng.reflow');
				}, 500);
				p.pop();
				p.push(showTasksByTime());
				$q.all(p).then(function () {
					_this['chartConfig' + 3].loading = false;
					_this.chart3loading = false;
					$timeout(function () {
						//$log.debug('chart reflow');
						$rootScope.$broadcast('highchartsng.reflow');
					}, 500);
				});
				p.pop();
				p.push(showTasksByRoleAndType());
				$q.all(p).then(function () {
					_this['chartConfig' + 4].loading = false;
					_this.chart4loading = false;
					$timeout(function () {
						//$log.debug('chart reflow');
						$rootScope.$broadcast('highchartsng.reflow');
					}, 500);
				});
				p.pop();
				p.push(showTaskTimeByType());
				$q.all(p).then(function () {
					_this['chartConfig' + 5].loading = false;
					_this.chart5loading = false;
					$timeout(function () {
						//$log.debug('chart reflow');
						$rootScope.$broadcast('highchartsng.reflow');
					}, 500);
					p.pop();
					p.push(showAverageTransitionTime());
					$q.all(p).then(function () {
						_this['chartConfig' + 6].loading = false;
						_this.chart6loading = false;
						$timeout(function () {
							//$log.debug('chart reflow');
							$rootScope.$broadcast('highchartsng.reflow');
						}, 500);
					});
				});
			});
		});
	}

	init();

	_this.refresh = init;
}]);

// Filter below used by insights.jade
angular.module('medtasker').filter('taskDisplay', ['config', '_', function (config, _) {
	return function (input) {
		return _.find(config.taskStatus, { 'code': input }).display;
	};
}]);