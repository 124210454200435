'use strict';

/*eslint-disable */
$(document).on('click', '#menu-toggle', function (e) {
  e.preventDefault();
  $('#wrapper').toggleClass('toggled');
});

$(document).on('click', '#menu-toggle-2', function (e) {
  e.preventDefault();
  $('#wrapper').toggleClass('toggled-2');
  $('#menu ul').hide();
});

function initMenu() {
  $('#menu ul').hide();
  $('#menu ul').children('.current').parent().show();
  //$('#menu ul:first').show();
  $(document).on('click', '#menu li a', function () {
    var checkElement = $(this).next();
    if (checkElement.is('ul') && checkElement.is(':visible')) {
      return false;
    }
    if (checkElement.is('ul') && !checkElement.is(':visible')) {
      $('#menu ul:visible').slideUp('normal');
      checkElement.slideDown('normal');
      return false;
    }
  });
}

$(document).ready(function () {
  initMenu();
});
/*eslint-enable */