
'use strict';

angular.module('medtasker').controller('TeamDialogCtrl', ['$scope', 'AppService', 'ResourceService', 'DialogService', 'config', 'modalArgs', '$modalInstance', 'moment', '_', '$log', function ($scope, AppService, ResourceService, DialogService, config, modalArgs, $modalInstance, moment, _, $log) {

	var _this = this;
	_this.config = config;
	_this.mode = modalArgs.mode;
	_this.team = modalArgs.team; // only for a new rotation
	_this.period = modalArgs.period; // only for a new rotation
	_this.locations = modalArgs.locations;
	_this.organizations = modalArgs.organizations;

	_this.teamName = '';
	_this.teamType = 'team';
	_this.teamParent = _.find(_this.organizations, { name: 'The Alfred' });
	_this.teamLocation = '';
	_this.teamIdentifier = '';
	_this.teamType = 'team';
	_this.teamPhone = '';

	_this.rotLevel = '';
	_this.rotStart = moment().startOf('month').toDate();
	_this.rotEnd = moment().add(6, 'months').endOf('month').toDate();

	_this.createTeam = function () {
		var team = ResourceService.newOrganization(_this.teamName, _this.teamIdentifier, _this.teamType, _this.teamParent, _this.teamLocation, _this.teamPhone);
		AppService.create(team).then(function () {
			$log.debug('Created team');
			$modalInstance.close();
		}, function (err) {
			$modalInstance.close();
			DialogService.errorMessage('An error occurred trying to create team.', err);
			$log.error('Error in create team: ' + err);
		});
	};

	_this.createRotation = function () {
		var period = {
			start: moment(_this.rotStart)
		};

		if (_this.rotEnd) {
			period.end = moment(_this.rotEnd);
		}

		var rotation = ResourceService.newRotation(_this.team, _this.rotLevel.code, _this.rotLevel.display, period);
		AppService.create(rotation).then(function () {
			$log.debug('Created rotation');
			$modalInstance.close();
		}, function (err) {
			$scope.$apply();
			$modalInstance.close();
			DialogService.errorMessage('An error occurred trying to create rotation.', err);
			$log.error('Error in create rotation: ' + err);
		});
	};

	_this.cancel = function () {
		$modalInstance.dismiss();
	};

	_this.selectTeamType = function (type) {
		_this.teamType = type;
		_this.teamParent = _.find(_this.organizations, { name: type === 'team' ? 'The Alfred' : 'Cardiology' });
	};

	// Filter
	_this.filterParents = function (t) {
		if (_this.teamType === 'subteam') {
			return t.type === 'team';
		}
		return t.type !== 'subteam' && t.type !== 'team';
	};
}]);