
'use strict';

angular.module('medtasker').controller('DirectoryCtrl', ['config', '_', 'AppService', 'ComposeService', 'AuthService', '$log', '$sce', '$state', '$scope', '$timeout', function (config, _, AppService, ComposeService, AuthService, $log, $sce, $state, $scope, $timeout) {

	var _this = this;

	_this.ctx = AppService.ctx;
	_this.config = config;
	_this.searchText = '';
	_this.filter = {};

	// Vars for filtered and selected resource
	_this.practitionerResults = [];
	_this.selectedRoleAssignmentIndex = 0;
	_this.selectedRoleAssignment = null;
	_this.roleAssignments = _this.ctx.roleAssignments;
	_this.organizations = _this.ctx.organizations;
	_this.roleShortName = AppService.roleShortName;
	_this.displayName = AppService.displayName;
	_this.formatTelecom = AppService.formatTelecom;

	_this.showCampus = config.multiCampus;
	_this.campuses = AppService.campuses();
	if (config.defaultCampusLoc) {
		_this.filter.byCampus = _.find(_this.campuses, { 'id': config.defaultCampusLoc.id });
	} else {
		_this.filter.byCampus = _this.campuses[0];
	}
	_this.displayCampus = function (c) {
		if (!c || !c.id) {
			return 'All';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	_this.campusCode = AppService.campusDisplayCode;

	_this.bestTelecom = function (contacts) {
		return _.find(contacts, { 'resource': 'MtAggregate' }) || _.find(contacts, { 'resource': 'MtRole' }) || _.find(contacts, { 'resource': 'Practitioner' }) || { system: '', value: '?' };
	};

	_this.unique = function (ras) {
		var uniqueRas = {};
		_.forEach(ras, function (ra) {
			if (uniqueRas[ra.role.id]) {
				if (ra.type === 'override') {
					// Replace the ra stored
					uniqueRas[ra.role.id] = ra;
				}
			} else {
				uniqueRas[ra.role.id] = ra;
			}
		});
		return _.values(uniqueRas);
	};

	_this.searchTextChanged = function () {
		if (_this.hasSelected) {
			_this.hasSelected = false;
			_this.selectedRoleAssignment = null;
			//TODO: check if still necessary
			$timeout();
		}
		_this.searchRoleAssignments();
	};

	_this.searchRoleAssignments = function (page) {
		_this.practitionerHits = 0;
		if (!_this.searchText) {
			_this.practitionerResults = [];
			return;
		}
		_this.practitionerPage = page = page || 0;
		_this.searchingPractitioners = true;
		var p = {
			terms: _this.searchText.toLowerCase(),
			team: _this.filter.byTeam ? _this.filter.byTeam.id : null,
			role: _this.filter.byLevel,
			campus: _this.filter.byCampus ? _this.filter.byCampus.id : null,
			//TODO: pager and level/responsibility
			size: 20,
			from: page * 20
		};
		AppService.searchRoleAssignments(p).then(function (e) {
			_this.searchingPractitioners = false;
			if (e.results.length > 0) {
				if (page === 0) {
					_this.practitionerResults = e.results;
				} else {
					_this.practitionerResults = _this.practitionerResults.concat(e.results);
				}

				_this.practitionerHits = e.totalHits;
				_this.highlightedRoleAssignment = _this.practitionerResults[0];
				_this.roleAssignmentIndex = 0;
				for (var i = 0; i < e.results.length; i++) {
					if (_this.highlightedRoleAssignment === e.results[i]) {
						_this.highlightedRoleAssignment = e.results[0];
						_this.roleAssignmentIndex = i;
						break;
					}
				}
			} else {
				_this.highlightedRoleAssignment = null;
				_this.roleAssignmentIndex = -1; // Should not be used
				_this.practitionerResults = [];
			}
		}, function () {
			_this.searchingPractitioners = false;
		});
	};

	_this.downRoleAssignment = function () {
		if (_this.practitionerResults.length !== 0) {
			_this.roleAssignmentIndex++;
			if (_this.roleAssignmentIndex === _this.practitionerResults.length) {
				_this.roleAssignmentIndex = 0;
			}
			_this.highlightedRoleAssignment = _this.practitionerResults[_this.roleAssignmentIndex];
		} else {
			return;
		}
	};

	_this.upRoleAssignment = function () {
		if (_this.practitionerResults.length !== 0) {
			_this.roleAssignmentIndex--;
			if (_this.roleAssignmentIndex < 0) {
				_this.roleAssignmentIndex = _this.practitionerResults.length - 1;
			}
			_this.highlightedRoleAssignment = _this.practitionerResults[_this.roleAssignmentIndex];
		} else {
			return;
		}
	};

	_this.showMorePractitioners = function () {
		_this.searchRoleAssignments(_this.practitionerPage + 1);
	};

	_this.clearFilters = function () {
		_this.filter.byTeam = null;
		_this.filter.byLevel = null;
		_this.searchRoleAssignments();
	};

	_this.selectRoleAssignment = function (id) {
		if (id) {
			AppService.get('MtRoleAssignment', id).then(function (ra) {
				_this.selectedRoleAssignment = ra;
				_this.practitionerResults.length = 0;
				_this.searchText = AppService.displayName(ra.practitioner);
				_this.contactForRA = AppService.formatTelecom(AppService.telecomForRoleAssignment(ra)[0]);
				_this.clearFilters();
				_this.hasSelected = true;
				//TODO: Are we still doing ths analytics stuff? Remove if not
				// var prop = {
				// 	searchDeterminationMethod: eventName,
				// 	usedAdvSearch: false,
				// 	searchText: _this.searchText,
				// 	loggedInWard: _this.getCurrentWard(),
				// };
				// _this.analyticsEvent('Directory Search', prop);
			});
		}
	};

	_this.formattedTelecom = function (ra) {
		return AppService.formatTelecom(AppService.telecomForRoleAssignment(ra)[0]);
	};

	_this.deleteSelectionIfAssigned = function () {
		if (_this.hasSelected) {
			_this.selectedRoleAssignment = null;
			_this.searchText = '';
			_this.practitionerResults = [];
			_this.practitionerHits = 0;
			_this.hasSelected = false;
		}
	};

	_this.goToCompose = function (roleAssignment) {
		$state.go('app.compose', { roleAssignmentId: roleAssignment.id });
	};

	// Filter
	_this.teamsOnly = function (org) {
		return org.type === 'team' || org.type === 'subteam';
	};

	_this.teamsByCampus = function (team) {
		return team.partOf.location && team.partOf.location[0].id === _this.filter.byCampus.id;
	};

	_this.getCurrentWard = function () {
		return AppService.getCurrentWard();
	};

	_this.firstName = AppService.firstName;
	_this.lastName = AppService.lastName;

	//highlight occurrences of term within text by wrapping with <span class="highlight">term</span>
	_this.highlight = function (text, terms, className) {
		className = className || 'hilight';
		return $sce.trustAsHtml(ComposeService.highlight(text, terms, className));
	};
}]);