
'use strict';

angular.module('medtasker').controller('EntityCtrl', ['config', '_', 'AppService', function (config, _, AppService) {

	var _this = this;

	_this.ctx = AppService.ctx;
	_this.entities = [];
	_this.resourceType = '';
	_this.lastVersion = {};
	_this.displayName = AppService.displayName;
	_this.showjson = true;
	_this.maxCount = 10;

	_this.changeEntity = function (id, version) {
		_this.error = null;
		_this.searchType = 'id';
		AppService.get(_this.resourceType, id, version).then(function (e) {
			e = _.omit(e, '$metadata');
			if (_this.entities.length) {
				var i = _.findIndex(_this.entities, function (enc) {
					return enc.id === e.id;
				});
				if (i >= 0) {
					_this.entities[i] = e;
				}
			} else {
				_this.entities.length = 0;
				_this.entities.push(e);
			}
			if (!version) {
				_this.lastVersion[e.id] = e.meta.versionId;
			}
		}, function (err) {
			_this.entities.length = 0;
			_this.error = angular.toJson(err.data);
		});
	};

	_this.changeResourceType = function () {
		_this.criteria = '';
		_this.entityId = null;
		_this.versionId = null;
	};

	_this.changeCriteria = function (c) {
		_this.error = null;
		_this.searchType = 'criteria';
		AppService.search(_this.resourceType, '_count=' + _this.maxCount + (c ? '&' + c : '')).then(function (res) {
			_this.entities = res;
			_this.searched = true;
			_.forEach(_this.entities, function (e) {
				_this.lastVersion[e.id] = e.meta.versionId;
			});
		}, function (err) {
			_this.entities.length = 0;
			_this.error = angular.toJson(err.data);
		});
	};

	_this.prevVersion = function (e) {
		_this.changeEntity(e.id, e.meta.versionId - 1);
	};

	_this.nextVersion = function (e) {
		_this.changeEntity(e.id, e.meta.versionId + 1);
	};
}]);