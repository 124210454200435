'use strict';

angular.module('medtasker').controller('MonitorCtrl', ['ctx', 'MonitorService', '$state', '$scope', '$timeout', '$log', 'AppService', '_', 'moment', function (ctx, MonitorService, $state, $scope, $timeout, $log, AppService, _, moment) {

	var _this = this;

	_this.ctx = ctx;

	_this.timeDiffHuman = function (t) {
		return moment(t).fromNow();
	};

	function refresh() {
		if ($state.params.period) {
			var p = $state.params.period;
			if (p === '24' || p === '36' || p === '48') {
				_this.period = parseInt(p);
			}
		}
		if (!_this.period) {
			_this.period = 48;
		}
		if ($state.params.urgency) {
			var u = $state.params.urgency;
			if (u === 'premet' || u === 'met') {
				_this.urgency = u;
			}
		} else {
			_this.urgency = 'premet';
		}
		_this.patients = MonitorService.findDeterioratingPatients(moment().subtract(_this.period, 'hours'), _this.urgency);
		if ($state.params.patientId) {
			_this.selectedPatientId = $state.params.patientId;
			var patient = _.find(_this.patients, function (p) {
				return p.id === _this.selectedPatientId;
			});
			if (patient) {
				AppService.ctx.filteredTasks = patient.tasks;
			} else {
				_this.selectedPatientId = null;
			}
		}
	}

	function goState() {
		var state = 'app.admin.patientmonitor';
		var params = {
			urgency: _this.urgency,
			period: _this.period
		};
		if (_this.selectedPatientId) {
			state = state + '.tasks';
			params.patientId = _this.selectedPatientId;
		}
		$state.go(state, params).then(function () {
			refresh();
		});
	}

	_this.setPeriod = function (hours) {
		_this.period = hours;
		goState();
	};

	_this.setUrgency = function (urgency) {
		_this.urgency = urgency;
		goState();
	};

	_this.displayName = function (p) {
		return AppService.displayName(p, 'surnameFirst');
	};

	refresh();

	_this.toggleTasks = function (patient) {
		if (patient.id === _this.selectedPatientId) {
			_this.selectedPatientId = null;
		} else {
			_this.selectedPatientId = patient.id;
			goState();
		}
	};

	var unbind = $scope.$on('sse.task', function (e, task) {
		if (task.priority.coding[0].code === 'premet' || task.priority.coding[0].code === 'met' || task.priority.coding[0].code === 'codeblue') {
			refresh();
		}
	});

	$scope.$on('$destroy', function () {
		unbind();
	});
}]);