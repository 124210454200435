
'use strict';

angular.module('medtasker').controller('UserCtrl', ['UserService', 'AppService', 'config', '$modal', '_', function (UserService, AppService, config, $modal, _) {
	// eslint-disable-line

	var _this = this;

	_this.userRoles = config.editorUserPermissions;
	_this.selectedRoles = [];

	var args = {
		parent: _this,
		userRoles: _this.userRoles
	};

	AppService.search('Organization', 'type=team,subteam').then(function (teams) {
		_this.teams = args.teams = teams;
	});

	var dialogOptions = {
		templateUrl: 'app/user/user-dialog.html',
		backdrop: true,
		controller: 'UserDialogCtrl as ctrl',
		resolve: {
			modalArgs: function modalArgs() {
				return args;
			}
		}
	};

	_this.newUserDialog = function () {
		args.user = '';
		$modal.open(dialogOptions).result.then(function () {
			_this.searchUsers(_this.searchUser, _this.selectedRoles);
		});
	};

	_this.editUserDialog = function (user) {
		args.user = user;
		$modal.open(dialogOptions).result.then(function () {
			_this.searchUsers(_this.searchUser, _this.selectedRoles);
		});
	};

	(function init() {
		UserService.searchUsers().then(function (users) {
			_this.users = users;
		});
	})();

	_this.searchUsers = function (s, roles) {
		UserService.searchUsers(s, roles).then(function (users) {
			_this.users = users;
		});
	};

	_this.addRole = function (r, t) {
		var s = r + (t ? '.' + t : '');
		if (!_.some(_this.selectedRoles, function (role) {
			return role === s;
		})) {
			_this.selectedRoles.push(s);
		}
		_this.searchUsers(_this.searchUser, _this.selectedRoles);
	};

	_this.removeRole = function (r) {
		_.remove(_this.selectedRoles, function (role) {
			return role === r;
		});
		_this.searchUsers(_this.searchUser, _this.selectedRoles);
	};
}]);