/*eslint angular/controller-as:0 */
'use strict';

angular.module('medtasker').controller('RosterCtrl', ['ResourceService', 'AppService', 'RosterService', 'DialogService', '$rootScope', '$scope', '_', '$modal', 'moment', 'config', '$timeout', '$q', 'LogService', '$state', '$stateParams', function (ResourceService, AppService, RosterService, DialogService, $rootScope, $scope, _, $modal, moment, config, $timeout, $q, LogService, $state, $stateParams) {

	var _this = this;

	var ctx = _this.ctx = AppService.ctx; // used in view
	_this.rshiftsForAggregate = []; // RShifts siblings part of the same aggregate for the selectedRShift
	_this.typeaheadModels = {}; // this holds the ng-model for the typeahead ui components
	_this.uiselectTestSelected = '';
	_this.selectedPractitioner = null;
	_this.shiftPractitioner = {};
	_this.showDateDialog = false;
	_this.config = config;

	// Functions
	_this.prevTimeslice = RosterService.prevTimeslice;
	_this.nextTimeslice = RosterService.nextTimeslice;
	_this.selectTeam = RosterService.selectTeam; // args: team
	_this.selectRole = RosterService.selectRole; // args: role
	_this.removeRoleAssignment = RosterService.removeRoleAssignment; // args: ra
	_this.refreshRoster = RosterService.refreshRoster;
	_this.fetchPractitioners = RosterService.fetchPractitioners;
	_this.times = { period: {}, times: [] };
	_this.savedPager = '';
	_this.viaSwitch = false;
	_this.telecoms = [];
	_this.viewPeriodStartTime = config.viewPeriodStartTime;
	_this.inViewPeriod = RosterService.overlapsViewPeriod;
	_this.teamsByCampus = RosterService.teamsByCampus;

	// remove the all from the campuses field
	_this.campuses = _.filter(AppService.campuses(), function (loc) {
		return loc.name.toLowerCase() !== 'all';
	});

	if ($stateParams.campus) {
		_this.ctx.selectedCampus = _.find(_this.campuses, { 'id': $stateParams.campus });
		// } else if (config.defaultCampusLoc){
		// 	_this.ctx.selectedCampus = _.find(_this.campuses, {'id': config.defaultCampusLoc.id}) ;
		// 	$state.go($state.current, {team: null, campus: config.defaultCampusLoc.id, startDate: ctx.viewPeriod.start.format('YYYY-MM-DD')});
	} else {
		_this.ctx.selectedCampus = _this.campuses[0];
	}
	_this.teams = _this.teamsByCampus();
	if (_this.teams.length === 0) {
		DialogService.errorMessage('Campus has no teams with roles! Please ask a roster administrator to add roles.');
		$state.go(config.defaultState);
		return;
	}
	_this.campusCode = AppService.campusDisplayCode;

	_this.noShifts = AppService.appStatus.noShifts;
	if ($stateParams.team) {
		_this.ctx.selectedTeam = _.find(_this.ctx.organizations, function (org) {
			return _.some(org.identifier, function (id) {
				return id.system.startsWith(config.hospitalSystemPrefix) && id.value === $stateParams.team;
			});
		});
		if (_this.ctx.selectedTeam) {
			_this.selectTeam(_this.ctx.selectedTeam);
		}
	} else {
		if (!AppService.appStatus.noTeams) {
			_this.noTeams = true;
			return;
		}
		var team = _.find(_.sortBy(_this.ctx.organizations, function (org) {
			return org.identifier[0].value;
		}), function (org) {
			return RosterService.teamsWithRolesOnly(org);
		});
		$state.go($state.current, { team: team.identifier[0].value });
	}

	if ($stateParams.startDate) {
		//startDate should always be set
		ctx.viewPeriod.start = moment($stateParams.startDate, 'YYYY-MM-DD');
	} else {
		ctx.viewPeriod.start = moment();
	}
	//now set to correct (default) time for start of view period
	var t = moment(config.viewPeriodStartTime, 'hh:mm');
	ctx.viewPeriod.start = ctx.viewPeriod.start.startOf('day').set({ hour: t.hour(), minute: t.minute() });
	ctx.viewPeriod.end = moment(ctx.viewPeriod.start).add(ctx.timeSpan, 'days');
	_this.viewDate = ctx.viewPeriod.start.toDate();
	_this.holidayMessage = function () {
		var h1 = RosterService.isHoliday(ctx.viewPeriod.start);
		var h2 = RosterService.isHoliday(ctx.viewPeriod.end);
		var startDay = ctx.viewPeriod.start.format('dddd');
		var endDay = ctx.viewPeriod.end.format('dddd');
		if (h1 && h2) {
			return startDay + ' and ' + endDay + ' are public holidays';
		}
		if (h1) {
			return startDay + ' is a public holiday';
		}
		if (h2) {
			return endDay + ' is a public holiday';
		}
		return false;
	};

	function updateTelecomsDisplay(rshift) {
		_this.telecoms = [];
		if (rshift.assigned && rshift.assigned.aggregate) {
			_this.telecoms = AppService.telecomForRoleAssignment(rshift.assigned, _this.rshiftsForAggregate);
		} else if (rshift.assigned) {
			_this.telecoms = AppService.telecomForRoleAssignment(rshift.assigned, null);
		}
		_this.viaSwitch = false;
		_this.pager = null;
		if (_this.telecoms.length === 1) {
			var tc = _this.telecoms[0];
			if (tc.system === 'pager') {
				_this.phone = null;
				_this.viaSwitch = false;
				_this.pager = AppService.formatTelecom(tc);
			} else if (tc.system === 'phone') {
				_this.pager = null;
				_this.viaSwitch = false;
				_this.phone = AppService.formatTelecom(tc);
			} else if (tc.system === 'switch' && !tc.byDefault) {
				_this.pager = null;
				_this.phone = null;
				_this.viaSwitch = true;
			}
		}
	}

	function teamCode() {
		return _.find(_this.ctx.selectedTeam.identifier, function (i) {
			return i.system.startsWith(config.hospitalSystemPrefix);
		}).value;
	}

	//Set up the time axis ticks
	function makeTimes() {
		_this.times.period.start = moment('08:00', 'HH:mm');
		for (var i = 0; i <= 24; i += 2) {
			_this.times.times.push(moment(_this.times.period.start).add(i, 'h'));
		}
		_this.times.period.end = _this.times.times[_this.times.times.length - 1];
	}
	makeTimes();

	_this.showTime = function (time) {
		return time.format('HH:mm');
	};

	_this.showDuration = function (period) {
		var hours = period.end.diff(period.start, 'hours');
		var mins = period.end.diff(period.start, 'minutes');
		if (mins < 60) {
			return mins + ' mins';
		}
		var h = ' hour';
		if (hours > 1) {
			h = h + 's';
		}
		if (mins % 60 !== 0) {
			return hours + h + ', ' + mins % 60 + ' mins';
		}
		return hours + h;
	};

	_this.nameMatches = function (prac, input) {
		var terms = input.split(' ');
		return _.all(terms, function (term) {
			var regex = new RegExp('\\b' + term + '.*', 'i');
			return regex.test(prac);
		});
	};

	_this.changeTimeSpan = function () {
		RosterService.timeSpan = ctx.timeSpan;
		var span = moment.duration(_this.ctx.timeSpan, 'days');
		ctx.viewPeriod.end = moment(_this.ctx.viewPeriod.start).add(span);
		RosterService.refreshRoster(_this.ctx.selectedTeam);
	};

	_this.isPartOfCurrentAggregate = function (rshift) {
		for (var i = 0; i < _this.rshiftsForAggregate.length; i++) {
			if (rshift.id === _this.rshiftsForAggregate[i].id) {
				return true;
			}
		}
		return false;
	};

	_this.hasVisibleRShift = function (rshifts) {
		return _.some(rshifts, function (rs) {
			return _this.inViewPeriod(rs);
		});
	};

	_this.goTeam = function (team) {
		_this.ctx.selectedTeam = team;
		_this.onTeamChange();
	};

	_this.onTeamChange = function () {
		var id = teamCode();
		$state.go($state.current, { team: id, campus: ctx.selectedCampus.id, startDate: ctx.viewPeriod.start.format('YYYY-MM-DD') });
	};

	_this.multiSelects = {};

	function _select(rs, select) {
		var rshifts = [rs];
		if (rs.aggregate) {
			rshifts = RosterService.rshiftsForAggregate(rs.aggregate);
		}

		_.forEach(rshifts, function (rshift) {
			_this.multiSelects[rshift.id] = select ? rshift : void 0;
		});
	}

	_this.onCampusChange = function () {
		$state.go($state.current, { team: null, campus: ctx.selectedCampus.id, startDate: ctx.viewPeriod.start.format('YYYY-MM-DD') });
	};

	// When Ctrl/Cmd is pressed, the cell is marked as 'multi-selected' (css class)
	// Only one cell is 'selected' and it corresponds to the selectedRShift
	_this.selectCell = function ($event, rs) {
		if ($event.ctrlKey || $event.metaKey) {
			if (_this.ctx.selectedRShift && rs.id !== _this.ctx.selectedRShift.id) {
				if (_this.multiSelects[rs.id]) {
					// Unselect
					_select(rs, false);
				} else if (rs.shift.schedule.code === _this.selectedSchedule.code) {
					// only select rshifts from the same schedule
					_select(rs, true);
				}
			}
		} else {
			_this.selectRShift(rs);
			_this.multiSelects = {}; // clear multi-selections
			// this is the first cell multi-selected, get its schedule
			_this.selectedSchedule = rs.shift.schedule;
			_select(rs, true);
		}
	};

	_this.deleteWarning = function (rs) {
		if (rs.aggregate && rs.override) {
			return 'Removing this staff member will also remove them from all linked shifts';
		} else if (rs.aggregate && !rs.override) {
			return 'Removing this staff member will also remove them from any linked shifts and will also remove any overrides of those shifts';
		}
		return 'Are you sure you wish to remove this staff member from the roster?';
	};

	_this.selectRShift = function (rshift) {
		RosterService.selectRShift(rshift);
		_this.selectedPractitioner = null;
		//enable delete if there are no tasks for the RA
		if (rshift.assigned) {
			rshift.assigned.hasTasks = true; // disable delete until we know we have no tasks
			var q = 'recipientRole=' + rshift.assigned.role.id + '&recipient=' + rshift.assigned.practitioner.id + '&dateSent=>=' + rshift.assigned.period.start.format() + '&dateSent=<=' + rshift.assigned.period.end.format();
			AppService.search('MtTask', q).then(function (task) {
				if (task.length === 0) {
					rshift.assigned.hasTasks = false;
				} else {
					rshift.assigned.hasTasks = true;
				}
			});
		}

		_this.aggregateRShiftsGrouped = RosterService.rshiftsForAggregateGroupedByTeam(rshift.aggregate);
		var rs = _.filter(_this.aggregateRShiftsGrouped, { 'team': _this.ctx.selectedTeam.name });
		_this.aggregateRShiftsThisTeam = rs.length > 0 ? rs[0].shifts : [];
		_this.aggregateRShiftsOtherTeams = _.filter(_this.aggregateRShiftsGrouped, function (s) {
			return _this.ctx.selectedTeam.name !== s.team;
		});

		updateTelecomsDisplay(rshift);
	};

	_this.assignPractitioner = function (practitioner) {
		var rs = _this.ctx.selectedRShift;
		// Only assign one practitioner per shift, even though the model allows for more
		if (!rs.assigned) {
			RosterService.assignPractitioner(rs, practitioner, null, null, true).then(function () {
				_this.rshiftsForAggregate = RosterService.rshiftsForAggregate(rs.aggregate);
				rs = _this.ctx.selectedRShift;
				_select(rs, true);
				updateTelecomsDisplay(rs);
			}, function (err) {
				DialogService.errorMessage(err);
				LogService.error(err, 2);
			});
		} else {
			// Sanity check
			LogService.error('Attempt to assign a practitioner for a roster shift which already has one: ' + angular.toJson(practitioner));
		}
	};

	_this.replacePractitioner = function (practitioner) {
		RosterService.switchPractitioner(_this.ctx.selectedRShift, practitioner).then(function () {
			_this.editPractitioner = false;
			_this.editedPractitioner = null;
		}, function (err) {
			DialogService.errorMessage(err);
		});
	};

	_this.cancelEditPractitioner = function () {
		_this.editPractitioner = false;
		_this.editedPractitioner = null;
	};

	_this.periodDuration = function (period) {
		var duration = moment.duration(period.end - period.start);
		if (duration.days() > 0) {
			LogService.error('Shift period duration lasts longer than a day: ' + angular.toJson(period));
		}
		var hours = duration.hours() + duration.days() * 24;
		return sprintf('%02d:%02d', hours, duration.minutes()); // eslint-disable-line
	};

	_this.isInPast = function (rshift) {
		if (!rshift) {
			return null;
		}
		return +rshift.period.start < +moment();
	};

	_this.isExpired = function (rshift) {
		if (!rshift) {
			return null;
		}
		return +rshift.period.end < +moment();
	};
	_this.searchPracName = function (p) {
		if (!p) {
			return '';
		}
		return AppService.displayName(p) + ' (' + p.identifier[0].value + ')';
	};

	_this.displayName = AppService.displayName;
	_this.displayNameAbbrev = AppService.displayNameAbbrev;
	_this.displayNameInitials = AppService.displayNameInitials;

	_this.formatCampus = function (c) {
		if (!c.id) {
			return '--' + c.name + '--';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	_this.time = function (date) {
		return moment(date).format();
	};

	_this.dateChanged = function () {
		if (_this.viewDate) {
			$state.go($state.current, { team: teamCode(), startDate: moment(_this.viewDate).format('YYYY-MM-DD') });
		}
	};

	_this.dateDialog = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		_this.showDateDialog = true;
	};

	_this.backOneDay = function () {
		ctx.viewPeriod.start = moment(ctx.viewPeriod.start).subtract(1, 'days');
		_this.viewDate = ctx.viewPeriod.start.toDate();
		_this.dateChanged();
	};

	_this.forwardOneDay = function () {
		ctx.viewPeriod.start = moment(ctx.viewPeriod.start).add(1, 'days');
		_this.viewDate = ctx.viewPeriod.start.toDate();
		_this.dateChanged();
	};

	// Return true only if the rshift start time is > now
	//
	_this.editEnabled = function (rs) {
		return +rs.period.start > +moment() && rs.assigned;
	};

	_this.overrideEnabled = function (rs) {
		if (rs.type === 'override' || !rs.assigned) {
			return false;
		}
		var ot = RosterService.overriddenTimes(rs);
		if (ot.length === 1 && +ot[0].start === +rs.period.start && +ot[0].end === +rs.period.end) {
			return false;
		}
		return true;
	};

	_this.hasOverride = function (rs) {
		var ot = RosterService.overriddenTimes(rs);
		return ot.length > 0;
	};

	_this.editContactDialog = function (rs) {
		// eslint-disable-line
		_this.editPractitioner = false;
		var options = {
			templateUrl: 'app/roster/contact-edit.dialog.html',
			size: 'lg',
			backdrop: true,
			controller: 'ContactEditCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						ctx: _this.ctx,
						rshift: _this.ctx.selectedRShift,
						telecoms: _this.telecoms
					};
				}
			}

		};
		$modal.open(options).result.then(function () {
			// eslint-disable-line
			updateTelecomsDisplay(_this.ctx.selectedRShift);
		});
	};

	_this.editOverrideDialog = function (rs) {
		// eslint-disable-line
		var options = {
			templateUrl: 'app/roster/roster-override.dialog.html',
			size: 'lg',
			backdrop: true,
			controller: 'RosterDialogCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						mode: 'edit',
						ctx: ctx,
						rshiftsForAggregate: _this.rshiftsForAggregate
					};
				}
			}

		};
		$modal.open(options).result.then(function () {
			// eslint-disable-line
			RosterService.selectRShift(_this.ctx.selectedRShift);
		});
	};

	_this.overrideDialog = function (rs) {
		// eslint-disable-line
		var options = {
			templateUrl: 'app/roster/roster-override.dialog.html',
			size: 'lg',
			backdrop: true,
			controller: 'RosterDialogCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						mode: 'override',
						ctx: ctx,
						rshiftsForAggregate: _.filter(_this.rshiftsForAggregate, function (rsh) {
							return !rsh.override;
						})
					};
				}
			}

		};
		$modal.open(options).result.then(function () {
			// eslint-disable-line
			RosterService.selectRShift(_this.ctx.selectedRShift);
		});
	};

	_this.cloneDialog = function (rs) {
		// eslint-disable-line
		var options = {
			templateUrl: 'app/roster/roster-clone.dialog.html',
			size: 'lg',
			backdrop: true,
			controller: 'RosterCloneCtrl',
			controllerAs: 'ctrl',
			resolve: {
				shifts: function shifts() {
					return AppService.loadShifts(null, _this.ctx.selectedTeam);
				},
				modalArgs: function modalArgs() {
					return {
						ctx: ctx,
						copiedRShifts: _.values(_this.multiSelects)
					};
				}
			}
		};
		$modal.open(options).result.then(function () {
			// eslint-disable-line
			$state.go($state.current, { team: $stateParams.team, startDate: ctx.viewPeriod.start.format('YYYY-MM-DD') }, { reload: true });
			//			RosterService.selectRShift(_this.ctx.selectedRShift);
		});
	};

	// Filter
	_this.externalRShifts = function (rs) {
		return rs.shift.role.organization.id !== ctx.selectedTeam.id;
	};

	_this.setEditPager = function (editMode) {
		_this.editPager = editMode;
		if (editMode) {
			_this.savedPager = _this.pager || null;
		} else {
			_this.pager = _this.savedPager;
		}
	};

	_this.deleteEnabled = function (rs) {
		return +rs.period.start > +moment() && rs.assigned && rs.assigned.noTasks;
	};

	_this.deleteRosterShiftRoleAssignment = function (rs) {
		RosterService.removeFromRoster(rs.assigned).then(function () {
			_this.editedPractitioner = null;
			_this.selectedPractitioner = null;
		}, function (err) {
			if (err.data && err.data.indexOf('active tasks exist') > -1) {
				var m = '';
				if (rs.assigned.aggregate) {
					m = ' or a linked role';
				}
				DialogService.errorMessage('Could not remove the practitioner from this role as there are active tasks for the role' + m + '.');
			} else {
				DialogService.errorMessage('An error occurred removing the practitioner from the role', err);
			}
		});
	};

	_this.alwaysPermitRADelete = config.alwaysPermitRADelete;
	// Filter
	_this.seniority = function (role) {
		return _.indexOf(config.mtRoleLevels, _.find(config.mtRoleLevels, { 'code': role.level.code }));
	};

	_this.toTitleCase = function (str) {
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	};
}]);

// This controller is shared by all instances of the mtShiftCell directive.
// We use it to compute the layout and keep the width of the ViewPeriod
angular.module('medtasker').controller('RosterShiftCellCtrl', ['RosterService', 'moment', 'LogService', '$scope', function (RosterService, moment, LogService, $scope) {
	var _this = this;

	_this.shiftLayout = function (rshift, viewPeriod) {
		var viewDuration = viewPeriod.end.diff(viewPeriod.start, 'seconds');

		// Skip if not within the bounds of the view period
		if (!RosterService.overlapsViewPeriod(rshift)) {
			return null;
		}
		// used to display whether the shift cell border is left open on the left or/and right
		rshift.boundedLeft = true;
		rshift.boundedRight = true;

		var start = moment(rshift.period.start);
		var end = moment(rshift.period.end);
		if (+start < +viewPeriod.start) {
			// rshift starts before ViewPeriod
			start = moment(viewPeriod.start);
			rshift.boundedLeft = false;
		}
		if (+end > +viewPeriod.end) {
			// rshift ends after the ViewPeriod
			end = moment(viewPeriod.end);
			rshift.boundedRight = false;
		}

		if (rshift.boundedLeft) {
			$scope.startTime = rshift.period.start.format('HH:mm');
		} else {
			$scope.startTime = '...' + $scope.viewPeriod.start.format('HH:mm');
		}

		if (rshift.boundedRight) {
			$scope.endTime = rshift.period.end.format('HH:mm');
		} else {
			$scope.endTime = $scope.viewPeriod.end.format('HH:mm') + '...';
		}

		var duration = end.diff(start, 'seconds');
		var position = start.diff(viewPeriod.start, 'seconds');

		var layout = {
			width: duration * 100 / viewDuration, // in percent units
			offset: position * 100 / viewDuration };
		return layout;
	};
}]);

angular.module('medtasker').directive('mtShiftCell', ['RosterService', function (RosterService) {

	return {
		restrict: 'E',
		scope: {
			rshift: '=',
			viewPeriod: '=',
			rshifts: '='
		},
		transclude: true,
		template: '<div><div class="shift-content"><div class="show-start-time" tooltip="{{startTime}}"></div><div class="show-end-time"  tooltip="{{endTime}}"></div><div ng-transclude></div></div></div>',
		replace: true,
		controller: 'RosterShiftCellCtrl',
		link: function link(scope, element, attrs, ShiftCellCtrl) {
			var layout = ShiftCellCtrl.shiftLayout(scope.rshift, scope.viewPeriod);
			if (!layout) {
				element.css({ 'display': 'none' });
			} else {
				for (var i = 0; i < scope.rshifts.length; i++) {
					if (RosterService.periodsOverlap(scope.rshift.period, scope.rshifts[i].period)) {
						if (scope.rshifts[i].type === 'override' && scope.rshift.type !== 'override' && scope.rshifts[i].shift.id === scope.rshift.shift.id) {
							element.addClass('overridden');
						} else if (scope.rshift.period.start < scope.rshifts[i].period.start && scope.rshifts[i].shift.id !== scope.rshift.shift.id) {
							element.addClass('overlaps');
							scope.rshift.overlaps = true;
						}
					}
				}

				if (scope.rshift.type === 'override') {
					element.addClass('override');
				}

				if (scope.rshift.period.start.hours() === scope.rshift.shift.role.start.hours() && scope.rshift.period.start.minutes() === scope.rshift.shift.role.start.minutes()) {
					element.addClass('first-shift');
				}

				if (scope.rshift.aggregate) {
					element.addClass('aggregate');
				}

				if (scope.rshift.assigned) {
					element.addClass('assigned');
				}

				if (scope.rshift.boundedLeft) {
					element.addClass('bounded-left');
				} else {
					element.addClass('unbounded-left');
				}

				if (scope.rshift.boundedRight) {
					element.addClass('bounded-right');
				} else {
					element.addClass('unbounded-right');
				}

				element.css({ 'width': layout.width + '%',
					'left': layout.offset + '%'
				});
			}
		}
	};
}]);

// hide the element if it is less than the specified width
angular.module('medtasker').directive('minCellWidth', ['$window', '$timeout', function ($window, $timeout) {

	function _applyMinWidth(element, minWidth) {
		element.removeClass('below-min-width');
		element.removeClass('narrow-width');
		element.removeClass('wide-width');
		if (element.width() < minWidth / 2) {
			element.addClass('below-min-width');
			return;
		}
		if (element.width() < minWidth) {
			element.addClass('narrow-width');
			return;
		}
		element.addClass('wide-width');
	}

	return {
		restrict: 'A',
		scope: {
			minWidth: '@minCellWidth'
		},
		link: function link(scope, element) {
			$timeout(function () {

				_applyMinWidth(element, scope.minWidth);

				// console.log('inside directive elt width: ' + element.width() + '  type: ' + element[0].offsetWidth);
				angular.element($window).bind('resize', function () {
					scope.$apply(function () {
						_applyMinWidth(element, scope.minWidth);
					});
				});
			}, 0);
		}
	};
}]);