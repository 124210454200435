'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('medtasker').controller('TaskSearchCtrl', ['AppService', 'ResourceService', 'InboxService', 'DialogService', 'moment', 'config', '$modal', '$timeout', '_', '$sce', '$state', '$stateParams', '$scope', '$rootScope', '$q', 'download', function (AppService, ResourceService, InboxService, DialogService, moment, config, $modal, $timeout, _, $sce, $state, $stateParams, $scope, $rootScope, $q, download) {
	var _this = this;

	_this.criteria = [];
	_this.selectedCriteria = {};
	//_this.includeCompleted = true;
	_this.includeGenerated = false;
	_this.patientRelatedOnly = false;
	_this.escalatedOnly = false;
	_this.pageSize = 10;
	// _this.dirty = true;
	$rootScope.tasksSearched = false;

	var criterion = function () {
		function criterion() {
			_classCallCheck(this, criterion);

			this.items = [];
			this.options = [];
		}

		_createClass(criterion, [{
			key: 'itemsToParam',
			value: function itemsToParam() {
				return _.map(this.items, function (i) {
					return i.value;
				}).join(',');
			}
		}, {
			key: 'addItem',
			value: function addItem(display, value, invert) {
				this.items.push({
					display: display,
					value: value,
					invert: invert
				});
				_this.refresh();
			}
		}, {
			key: 'removeItem',
			value: function removeItem(i) {
				_.remove(this.items, function (item) {
					return item.display === i.display;
				});
				_this.refresh();
			}
		}, {
			key: 'field',
			set: function set(val) {
				this._field = val;
				_this.selectedCriteria[val] = true;
			},
			get: function get() {
				return this._field;
			}
		}]);

		return criterion;
	}();

	_this.config = config;
	_this.roleTypes = config.mtRoleTypes;

	var incs = ['MtTask:patient', 'MtTask:requester', 'MtTask:recipient', 'MtTask:recipientRole', 'MtTask:encounter', 'Encounter:patient', 'Encounter:episodeOfCare', 'Encounter:serviceProvider', 'Encounter:location.location', 'MtTask:action.practitioner', 'MtTask:action.targetPractitioner', 'MtTask:response.sender', 'MtTask:supportingInformation'];

	_this.searches = [{ display: 'Staff type', param: 'roleType' }, { display: 'Status', param: 'status' }, { display: 'Urgency', param: 'urgency' }, { display: 'Recipient team', param: 'team' }, { display: 'Ward', param: 'ward' }, { display: 'Campus', param: 'campus' }, { display: 'Action', param: 'action' }];

	_this.sorts = [{ display: 'Sent time', field: 'sent', order: 'desc' }, { display: 'Patient name', field: 'patient', order: 'asc' }, { display: 'Urgency', field: 'urgency', order: 'desc' }, { display: 'Status', field: 'status', order: 'asc' }, { display: 'Patient team', field: 'team', order: 'asc' }, { display: 'Type', field: 'type', order: 'asc' }, { display: 'Location', field: 'location', order: 'asc' }];
	_this.focus = 'searchtext';
	_this.sort = _this.sorts[0];
	_this.urgencies = config.urgencies;
	_this.actions = config.taskActions;
	_this.statuses = _.filter(config.taskStatus, function (s) {
		return !(s.code === 'draft' || s.code === 'refused');
	});
	_this.campuses = AppService.campuses();

	_this.searchFields = [{
		display: 'Sender',
		fields: ['sender', 'senderRole']
	}, {
		display: 'Recipient',
		fields: ['recipient', 'recipientTeam', 'recipientRole']
	}, {
		display: 'Patient',
		fields: ['patient', 'patientTeam']
	}, {
		display: 'Location',
		fields: ['location']
	}, {
		display: 'Type',
		fields: ['type']
	}, {
		display: 'Description',
		fields: ['description']
	}, {
		display: 'Replies',
		fields: ['replies']
	}];

	_this.showOptions = false;

	_this.showDateRange = false;

	_this.selectedFields = [];

	function makeTextCriterion() {
		_this.textCriterion = new criterion();
		_this.textCriterion.field = _this.searchText;
		_this.textCriterion.text = true;
		_this.textCriterion.items = _this.selectedFields;
	}

	function clearTasks() {
		AppService.ctx.filteredTasks = [];
		$rootScope.$broadcast('refreshFilteredTasks');
	}

	function fileName() {
		return 'medtasker-task-export-' + moment().format('YYYY-DD-MM hh:mm:ss') + '.csv';
	}

	_this.refresh = function () {
		// if (!_this.dirty) {
		_this.searchTasks();
		// }
	};

	makeTextCriterion();

	_this.noCriteria = function () {
		return !_this.searchText && !(AppService.filteredTasks && AppService.filteredTasks.length) && !_this.textCriterion.items.length && !_this.startDate && !_this.endDate && !_.some(_this.criteria, function (c) {
			return c.field && c.items.length;
		});
	};

	_this.clearCriteria = function () {
		_this.searchText = '';
		_this.selectedFields.length = 0;
		makeTextCriterion();
		_this.criteria.length = 0;
		_this.startDate = null;
		_this.endDate = null;
		_this.showDateRange = false;
		clearTasks();
	};

	_this.addField = function (field) {
		_this.selectedFields.push(field);
		_this.selectedField = void 0;
		makeTextCriterion();
	};

	_this.selectSort = function (s) {
		_this.sort = s;
		_this.searchTasks();
	};

	_this.removeField = function (field) {
		_.remove(_this.selectedFields, field);
		_this.selectedField = void 0;
		makeTextCriterion();
	};

	_this.fieldChanged = function (c) {
		if (!c.field) {
			_.remove(_this.criteria, c);
		}
	};

	_this.filterSelected = function (opts, selected, field) {
		field = field || 'display';
		return _.filter(opts, function (o) {
			return !_.some(selected, function (s) {
				return s[field] === o[field];
			});
		});
	};

	_this.resetSearchText = function () {
		_this.searchText = '';
		// _this.dirty = true;
		clearTasks();
		makeTextCriterion();
		_this.focus = 'searchtext';
	};

	_this.searchFieldRemoved = function () {
		if (_this.searchText) {
			_this.refresh();
		}
	};

	_this.setPageSize = function (n) {
		if (_this.pageSize !== n) {
			_this.pageSize = n;
			_this.searchTasks(AppService.ctx.pagerInfo.page);
		}
	};

	function _getCriteria() {
		var sf = void 0;
		if (_this.selectedFields.length === 0) {
			sf = _.map(_this.searchFields, function (f) {
				return f.fields;
			}).join(',');
		} else {
			sf = _.flatten(_.map(_this.selectedFields, function (f) {
				return f.fields;
			})).join(',');
		}
		var p = {
			include: incs.join(','),
			pageSize: AppService.ctx.pagerInfo.pageSize,
			page: AppService.ctx.pagerInfo.page
		};
		if (_this.startDate) {
			p.sentAfter = AppService.formatDateForQuery(moment(_this.startDate));
		}
		if (_this.endDate) {
			p.sentBefore = AppService.formatDateForQuery(moment(_this.endDate).add(1, 'days'));
		}
		if (_this.searchText) {
			p.text = _this.searchText;
			p.textSearchFields = sf;
		}
		p.sort = _this.sort.field;
		p.order = _this.sort.order;
		p.notgenerated = !_this.includeGenerated;
		p.includeInactive = true;
		p.patientRelatedOnly = _this.patientRelatedOnly;
		p.escalated = _this.escalatedOnly;
		_.forEach(_this.criteria, function (c) {
			if (!c.field) {
				return;
			}
			if (c.items.length) {
				p[c.field.param] = c.itemsToParam();
			}
		});
		return p;
	}

	//Task Search
	_this.searchTasks = function (page) {
		// _this.dirty = false;
		$rootScope.tasksSearched = true;
		var deferred = $q.defer();

		AppService.ctx.pagerInfo = AppService.ctx.pagerInfo || {};
		if (!page) {
			AppService.ctx.pagerInfo.pageSize = _this.pageSize;
			AppService.ctx.pagerInfo.page = 1;
		} else {
			AppService.ctx.pagerInfo.page = page;
		}

		var p = _getCriteria();

		$rootScope.$broadcast('appLoadingPush', { source: 'taskSearch' });
		ResourceService.reportFhir(_this.ctx, 'search-tasks-fhir', p, 'MtTask', true).then(function (res) {
			$rootScope.$broadcast('appLoadingPop', { source: 'taskSearch' });
			AppService.ctx.filteredTasks = res.data;
			AppService.ctx.pagerInfo.total = res.total;
			AppService.ctx.pagerInfo.pages = Math.ceil(res.total / AppService.ctx.pagerInfo.pageSize);
			_this.total = res.total;
			$rootScope.$broadcast('refreshFilteredTasks');
			deferred.resolve(res.data);
		}, function (err) {
			$rootScope.$broadcast('appLoadingPop', { source: 'taskSearch' });
			if (_.startsWith(err.data, 'Too many results')) {
				DialogService.openMessageDialog('Too many results', 'Your search returned too many results to show. Please narrow your search criteria.', 'warning');
			} else {
				DialogService.errorMessage('Something went wrong searching tasks: ' + err.data);
			}
			deferred.reject(err);
		});
		return deferred.promise;
	};

	_this.formatCampus = function (c) {
		if (!c) {
			return '';
		}
		if (!c.id) {
			return '--' + c.name + '--';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	_this.addCriterion = function () {
		if (_this.availableSearches().length > 0 && _this.criteria.length < _this.searches.length) {
			_this.criteria.push(new criterion());
		}
	};

	_this.searchTextChanged = function () {
		// _this.dirty = true;
		clearTasks();
		makeTextCriterion();
	};

	_this.clearDates = function () {
		_this.endDate = null;
		_this.startDate = null;
		_this.showDateRange = false;
		_this.refresh();
	};

	_this.availableSearches = function () {
		return _.filter(_this.searches, function (s) {
			return !_.some(_this.criteria, function (c) {
				return c.field && c.field.display === s.display;
			});
		});
	};

	_this.displayTeam = function (t) {
		if (!t) {
			return '';
		}
		return AppService.campusDisplayCode(t.partOf.location[0].id) + t.name;
	};

	_this.toggleOptions = function () {
		_this.showOptions = !_this.showOptions;
	};

	_this.toggleDateRange = function () {
		if (_this.showDateRange) {
			return;
		}
		_this.showDateRange = !_this.showDateRange;
	};

	_this.teams = _.sortBy(_.filter(AppService.ctx.organizations, function (org) {
		return org.type === 'team' || org.type === 'subteam';
	}), function (t) {
		if (t.partOf.location) {
			return t.partOf.location[0].identifier[0].value + t.name;
		}
		return '';
	});

	_this.wards = _.filter(AppService.ctx.locations, function (loc) {
		return _.some(loc.physicalType.coding, { 'code': 'wi' });
	});

	_this.export = function () {
		var p = _getCriteria();
		if (_this.total > 5000) {
			DialogService.openMessageDialog('Too many tasks', 'Tasks export is limited to 5000 tasks. Please narrow your search criteria.', 'warning');
			return;
		}

		p.csv = true;
		$rootScope.$broadcast('appLoadingPush', { source: 'taskSearch' });
		ResourceService.reportWithJsonAndQueryParamOptions('search-tasks-fhir', p, 'MtTask', true).then(function (res) {
			$rootScope.$broadcast('appLoadingPop', { source: 'taskSearch' });
			download(res.data, fileName(p), 'text/csv');
		}, function (err) {
			$rootScope.$broadcast('appLoadingPop', { source: 'taskSearch' });
			DialogService.errorMessage('Something went wrong exporting tasks: ' + err.data);
		});
	};

	(function init() {
		AppService.init().then(function () {
			_this.ctx = AppService.ctx;
		});
	})();

	var unbind = [];

	//If an RA that affects me is added while live, user data will be reloaded so we need to fetch tasks again.
	unbind.push($scope.$on('ReloadUserData', function () {
		_this.sortedTasks = _this.getFilteredAndSortedTasks();
	}));
	unbind.push($scope.$on('TaskPageUp', function () {
		_this.searchTasks(AppService.ctx.pagerInfo.page + 1);
	}));
	unbind.push($scope.$on('TaskPageDown', function () {
		_this.searchTasks(AppService.ctx.pagerInfo.page - 1);
	}));
	unbind.push($scope.$on('TaskPageFirst', function () {
		_this.searchTasks(1);
	}));
	unbind.push($scope.$on('TaskPageLast', function () {
		_this.searchTasks(AppService.ctx.pagerInfo.pages);
	}));
	unbind.push($scope.$on('TaskRefresh', function () {
		_this.setPageSize(AppService.ctx.pagerInfo.pageSize);
	}));
	unbind.push($scope.$on('TaskSort', function () {
		_this.selectSort(AppService.ctx.pagerInfo.sort);
	}));
}]);