'use strict';
/* global Math */

angular.module('medtasker').controller('NavbarCtrl', ['$scope', '$log', '$state', '$modal', 'DialogService', 'InboxService', 'AppService', 'AuthService', 'ResourceService', 'config', '$window', 'AUTH_EVENTS', 'Idle', '$timeout', '_', 'moment', 'oauth', 'LogService', function ($scope, $log, $state, $modal, DialogService, InboxService, AppService, AuthService, ResourceService, config, $window, AUTH_EVENTS, Idle, $timeout, _, moment, oauth, LogService) {
	var unbind = [];
	var _this = this;

	_this.campusDisplayCode = AppService.campusDisplayCode;

	_this.stateName = function () {
		return $state.current.name;
	};

	_this.adminMsg = 'Please log in with the appropriate credentials to access the admin section.';

	var idleWarningModalOptions = {
		template: '	<div class="mt-modal"><h3 class="warning-dialog">You have been inactive for {{modal.timeOutMinutes}} minutes.<br> You will be logged out in {{modal.showCount(modal.count)}}</h3></div ', // eslint-disable-line
		backdrop: true,
		controllerAs: 'modal',
		controller: ['Idle', 'config', '$scope', 'moment', 'idleTimeoutMins', function (Idle, config, $scope, moment, idleTimeoutMins) {
			//eslint-disable-line no-shadow
			var _this = this; //eslint-disable-line no-shadow
			_this.count = config.userTimeOutWarningSeconds;
			_this.timeOutMinutes = idleTimeoutMins;
			_this.showCount = function (c) {
				var e = moment.duration(c, 'seconds');
				var mins = Math.floor(e.asMinutes());
				var secs = Math.floor(e.asSeconds()) - mins * 60;
				return (mins > 0 ? mins + ' minute' + (mins > 1 ? 's ' : ' ') : '') + (secs > 0 ? (mins > 0 ? 'and ' : '') + secs + ' seconds' : '');
			};

			$scope.$on('IdleWarn', function (e, countdown) {
				_this.count = countdown;
				$scope.$apply();
			});
		}]
	};

	var logoutModalOptions = {
		template: '	<h3 style="margin: 25px" class="warning-dialog">Session expired! Logging out...</h3> ', // eslint-disable-line
		backdrop: true
	};

	function getUserPriv() {
		_this.hasAdminPriv = AuthService.hasAdminPriv();
		_this.hasRosterPriv = AuthService.hasRosterPriv();
		_this.hasManagerPriv = AuthService.hasManagerPriv();
		_this.hasSwitchPriv = AuthService.hasSwitchPriv();
		_this.hasCodesPriv = AuthService.belongsTo('codes');
		_this.belongsTo = AuthService.belongsTo;
		_this.hasExtraPermissions = _.some(AuthService.roles(), function (r) {
			return r !== 'clinician';
		});
		_this.isPharmacist = _.some(AppService.userRAs, function (ra) {
			return ra.role.type.code === 'pharmacist';
		});
	}

	function incompleteTasks(tasks, inbox) {
		return _.filter(tasks, function (t) {
			return t.$metadata.active && (inbox ? !t.$metadata.archived : true);
		});
	}

	function newTasks(tasks, inbox) {
		return _.filter(tasks, function (t) {
			return t.status === 'sent' && (inbox ? !t.$metadata.archived : true);
		});
	}

	function clear() {
		_this.myNewTasks = 0;
		_this.myUncompletedTasks = 0;
		_this.wardNewTasks = 0;
		_this.wardUncompletedTasks = 0;
		_this.teamNewTasks = 0;
		_this.teamUncompletedTasks = 0;
		_this.sentNewTasks = 0;
		_this.sentUncompletedTasks = 0;
		_this.systemNewTasks = 0;
		_this.systemUncompletedTasks = 0;
		_this.patientsNewTasks = 0;
		_this.patientsTasks = 0;
		_this.patientsUncompletedTasks = 0;
		_this.priorNewTasks = 0;
		_this.priorTasks = 0;
		_this.priorUncompletedTasks = 0;

		_this.priorEscalation = false;
		_this.myEscalation = false;
		_this.sentEscalation = false;
		_this.teamEscalation = false;
		_this.wardEscalation = false;
		_this.priorEscalation = false;
		_this.patientsEscalation = false;
		_this.hasExtraPermissions = false;
	}

	function setConfig() {
		_this.supportNumber = config.supportPhoneNumber;
		_this.supportWebsite = config.supportWebsite;
		_this.adminSupportWebsite = config.adminSupportWebsite;
		_this.showSupportLinks = config.showSupportLinks;
		_this.webAppVersion = config.webAppVersion;
		_this.disableUserEditor = config.disableUserEditor;
		_this.enableDeteriorationDashboard = config.enableDeteriorationDashboard;
		_this.enableGeneratedTasks = config.enableGeneratedTasks;
		_this.enableCodeCompose = config.enableCodeCompose;
		_this.enableUnassignedRolesReport = config.enableUnassignedRolesReport;
		var deploymentEnvironment = config.deploymentEnv || '';
		if (deploymentEnvironment.toLowerCase() !== 'prod' && deploymentEnvironment.toLowerCase() !== 'production') {
			_this.deploymentEnvironment = deploymentEnvironment;
		} else {
			_this.deploymentEnvironment = '';
		}
	}

	function init() {

		if (!AppService.user) {
			clear();
			return;
		}
		if (!AppService.tasksStatus.loaded) {
			return;
		}
		if (AppService.userRAs) {
			_this.userRAs = AppService.userRAs;
		}
		getUserPriv();

		// returns the side bar entry that would be used for navigating to the
		// emergency response screen.
		// If config has got the emergencyResponseSideBarEntry value that value would be used
		// instead it would use Emergency Response
		_this.emergencyResponseSideBarEntry = config.emergencyResponseSideBarEntry ? config.emergencyResponseSideBarEntry : 'Emergency Response';

		//TODO: OK, so "new" tasks are always a subset of "uncompleted" tasks so there's a potential
		//optimization to be made by filtering the new tasks from uncompleted ones, rather than
		//from the entire list of tasks. It will be a marginal gain however.

		// Clinician inbox
		_this.myTasks = AppService.inboxTasks();
		_this.myNewTasks = newTasks(_this.myTasks, 'inbox').length;
		_this.myUncompletedTasks = incompleteTasks(_this.myTasks, 'inbox').length;
		_this.myEscalation = _.some(_this.myTasks, function (t) {
			return t.$metadata.escalated && !t.$metadata.archived;
		});

		//Sent tasks
		_this.sentTasks = AppService.sentTasks();
		_this.sentNewTasks = newTasks(_this.sentTasks).length;
		_this.sentUncompletedTasks = incompleteTasks(_this.sentTasks).length;
		_this.sentEscalation = _.some(_this.sentTasks, function (t) {
			return t.$metadata.escalated && !t.$metadata.archived;
		});

		//Team tasks
		_this.teamTasks = AppService.teamInboxTasks();
		_this.teamNewTasks = newTasks(_this.teamTasks).length;
		_this.teamUncompletedTasks = incompleteTasks(_this.teamTasks).length;
		_this.teamEscalation = _.some(_this.teamTasks, function (t) {
			return t.$metadata.escalated && !t.$metadata.archived;
		});

		//Ward tasks
		_this.wardTasks = AppService.wardTasks();
		_this.wardNewTasks = newTasks(_this.wardTasks).length;
		_this.wardUncompletedTasks = incompleteTasks(_this.wardTasks).length;
		_this.wardEscalation = _.some(_this.wardTasks, function (t) {
			return t.$metadata.escalated && !t.$metadata.archived;
		});

		//Generated tasks
		_this.systemTasks = AppService.systemTasks();
		_this.systemNewTasks = newTasks(_this.systemTasks).length;
		_this.systemUncompletedTasks = incompleteTasks(_this.systemTasks).length;

		//Prior tasks
		_this.priorTasks = AppService.priorTasks();
		_this.priorNewTasks = newTasks(_this.priorTasks).length;
		_this.priorUncompletedTasks = incompleteTasks(_this.priorTasks).length;
		_this.priorEscalation = _.some(_this.priorTasks, function (t) {
			return t.$metadata.escalated && !t.$metadata.archived;
		});

		//Patients tasks
		_this.patientsTasks = AppService.patientsTasks();
		_this.patientsNewTasks = newTasks(_this.patientsTasks).length;
		_this.patientsUncompletedTasks = incompleteTasks(_this.patientsTasks).length;
		_this.patientsEscalation = _.some(_this.patientsTasks, function (t) {
			return t.$metadata.escalated && !t.$metadata.archived;
		});

		if (AppService.user && AppService.displayName(AppService.user)) {
			_this.userDisplayName = AppService.displayName(AppService.user);
		} else {
			_this.userDisplayName = AuthService.userDisplayName();
		}

		_this.webNavLinks = config.webNavLinks;
		_this.showSupportLinks = config.showSupportLinks;
	}

	function closeModals() {
		if (_this.warning) {
			_this.warning.close();
			_this.warning = null;
		}

		if (_this.timedout) {
			_this.timedout.close();
			_this.timedout = null;
		}
	}

	unbind.push($scope.$on('IdleStart', function () {
		closeModals();
		_this.warning = $modal.open(idleWarningModalOptions);
	}));

	unbind.push($scope.$on('ConfigInitComplete', function () {
		setConfig();
	}));

	unbind.push($scope.$on(AUTH_EVENTS.logoutUserSuccess, function () {
		clear();
		$timeout();
	}));

	unbind.push($scope.$on('IdleTimeout', function () {
		closeModals();
		var logouturl = oauth.getLogoutUrl();
		if (logouturl !== '') {
			AppService.logout('ward');
			AppService.logout('user');
			Idle.unwatch();
			$window.location.href = logouturl;
		} else {
			var logoutModal = $modal.open(logoutModalOptions);
			$timeout(function () {
				logoutModal.close();
			}, 1500);
			$state.go('home');
			AppService.logout();
		}
	}));

	_this.userLoggedIn = function () {
		var l = AuthService.isAuthenticated('user');
		return l;
	};

	_this.wardLoggedIn = function () {
		var l = AuthService.isAuthenticated('ward') && AppService.getCurrentWard();
		return l;
	};

	_this.logoutUser = function () {
		var logouturl = oauth.getLogoutUrl();
		if (logouturl !== '') {
			AppService.logout('ward');
			AppService.logout('user');
			$window.location.href = logouturl;
		} else {
			AppService.logout('user');
			$state.go('home', null, { reload: true });
		}
	};

	_this.logoutWard = function () {
		AppService.logout('ward');
		var logouturl = oauth.getLogoutUrl();
		if (logouturl !== '') {
			$window.location.href = logouturl;
		}
	};

	_this.loginDialog = function (msg) {
		oauth.getOauthLoginURL().then(function (res) {
			LogService.debug('openLoginDialog ' + angular.toJson(res));
			if (res !== '') {
				$window.location.href = res;
			} else {
				DialogService.openLoginDialog(msg).then(function () {
					getUserPriv();
					if (AuthService.isAuthenticated('user')) {
						$state.go(config.defaultState || 'app.compose');
					}
				}, function () {
					//$log.debug('login dialog reject');
				});
			}
		}, function (err) {
			_this.error = 'Error getting oauth config: ' + angular.toJson(err);
			LogService.error(err, 1, 'HttpGetError');
			deferred.reject(err);
		});
	};

	_this.toggleNav = function () {
		_this.narrowToggle = !_this.narrowToggle;
	};

	_this.showReplier = function (alert) {
		return AppService.displayName(alert.sender);
	};

	_this.clearAllAlerts = function () {
		_.forEach(_this.newAlerts(), function (alert) {
			alert.status = 'completed';
			AppService.update(alert);
		});
	};

	_this.goTask = function (task) {
		function containsTask(tasks) {
			return _.some(tasks, function (t) {
				return t.id === task.id;
			});
		}
		var dest = void 0;
		if (containsTask(AppService.inboxTasks())) {
			dest = 'app.inbox';
		} else if (containsTask(AppService.sentTasks())) {
			dest = 'app.senttasks';
		} else if (containsTask(AppService.priorTasks())) {
			dest = 'app.priortasks';
		} else if (containsTask(AppService.teamInboxTasks())) {
			dest = 'app.teaminbox';
		} else if (containsTask(AppService.patientsTasks())) {
			dest = 'app.patientstasks';
		} else if (containsTask(AppService.wardTasks())) {
			dest = 'app.wardtasks';
		} else {
			dest = 'app.task';
		}
		$state.go(dest, { taskId: task.id });
	};

	_this.timeDiffHuman = function (t) {
		return moment(t).fromNow();
	};

	_this.newAlerts = AppService.newAlerts;

	_this.alertTitle = function (alert) {
		var task = alert.payload[0].contentReference;
		if (task.encounter && task.encounter.patient) {
			return task.serviceRequested[0].coding[0].display;
		}
		var td = task.description;
		if (td.length <= 40) {
			return td;
		}
		return td.slice(0, 40) + '...';
	};

	_this.currentWard = function () {
		return _.find(AppService.ctx.locations, { 'name': AuthService.getCurrentWard() });
	};

	unbind.push($scope.$on(AUTH_EVENTS.logoutUserSuccess, init));

	unbind.push($scope.$on(AUTH_EVENTS.logoutWardSuccess, init));

	unbind.push($scope.$on(AUTH_EVENTS.loginUserSuccess, init));

	unbind.push($scope.$on(AUTH_EVENTS.userSessionRestored, init));

	unbind.push($scope.$on(AUTH_EVENTS.wardSessionRestored, init));

	unbind.push($scope.$on('userDataLoaded', init));

	unbind.push($scope.$on('IdleEnd', function () {
		closeModals();
	}));

	unbind.push($scope.$on('teamDataLoaded', init));

	unbind.push($scope.$on('sse.task', init));

	unbind.push($scope.$on('appInitComplete', init));

	// Patient list
	_this.patientCount = function () {
		return AppService.getUsersEncounterIds().length;
	};

	// Directory
	_this.practitionerCount = function () {
		return AppService.ctx.practitioners.length;
	};

	init();

	//Remove all the handlers
	$scope.$on('$destroy', function () {
		unbind.map(function (remove) {
			remove();
		});
	});
}]);