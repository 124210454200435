'use strict';

angular.module('medtasker').controller('AppCtrl', ['$log', 'config', 'AppService', 'ReconnectService', function ($log, config, AppService, ReconnectService) {
	var _this = this;
	_this.configMap = AppService.configMap;
	_this.displayFromCode = AppService.displayFromCode;

	_this.reconnect = function () {
		if (AppService.appStatus.reconnect && AppService.appStatus.reconnectCountdown > 0) {
			AppService.appStatus.reconnectCountdown = 0;
		} else {
			ReconnectService.attemptReconnect();
		}
	};
}]);