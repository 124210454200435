'use strict';
/* global angular */

angular.module('medtasker').controller('InsightsCtrl', ['ctx', 'ResourceService', 'InsightsService', 'AppService', 'AuthService', '_', '$window', '$scope', '$state', '$stateParams', '$rootScope', 'LogService', '$timeout', 'moment', 'config', function (ctx, ResourceService, InsightsService, AppService, AuthService, _, $window, $scope, $state, $stateParams, $rootScope, LogService, $timeout, moment, config) {

	var colors = void 0;
	var _this = this;
	_this.includeGenerated = false;
	_this.campuses = _.filter(AppService.campuses(), function (c) {
		return c.name !== 'All';
	});
	_this.campus = InsightsService.getCampusFilter();
	// check for undefined as when you clear campus filter for all campuses  , 
	// we do not want to set the campus in this scenario. 
	// for some reason when we clear the campus filter
	// _this.campus is set to undefined
	if (angular.isDefined(_this.campus) && (!_this.campus || !_this.campus.id)) {
		// set the  campus only if campus set in the service is empty
		// this would happen only first time insight we are coming to this page
		_this.campus = _.find(_this.campuses, { 'id': config.defaultCampusLoc.id });
	}
	InsightsService.setCampusFilter(_this.campus);

	function baseChartConfig(title, xCaption) {
		return {
			options: {
				legend: {
					borderRadius: 5,
					borderColor: '#dddddd',
					borderWidth: 1
				},
				chart: {
					type: 'column',
					style: {
						fontFamily: 'Cabin'
					}
				},
				tooltip: {
					style: {
						padding: 10,
						fontWeight: 'bold'
					}
				},
				plotOptions: {
					series: {
						dataLabels: {
							enabled: true
						}
					}
				}
			},
			credits: {
				enabled: false
			},
			showInLegend: false,
			title: {
				text: title
			},
			loading: false,

			xAxis: {
				title: {
					text: xCaption,
					style: {
						fontWeight: 'bold'
					}
				}
			},

			yAxis: {
				allowDecimals: false,
				title: {
					text: 'Number of Tasks',
					style: {
						fontWeight: 'bold'
					}
				}
			}
		};
	}

	function setTasksViewData(tasks) {
		if (!AppService.ctx.filteredTasks) {
			AppService.ctx.filteredTasks = [];
		} else {
			//preserve the reference rather than creating a new array
			AppService.ctx.filteredTasks.length = 0;
		}
		AppService.ctx.filteredTasks.push.apply(AppService.ctx.filteredTasks, tasks);
	}

	function clearTasksView() {
		setTasksViewData([]);
		_this.reloadFilteredTasks = null;
		$rootScope.$broadcast('refreshFilteredTasks');
	}

	function simpleColChartConfig(categories, subcategories, title, xCaption) {
		var simpleChartClick = function simpleChartClick(e) {
			_this.filterParam = e.point.name;
			_this.reloadFilteredTasks = function (arg) {
				var s = _.find(_this.chartData, function (d) {
					return d.name === arg;
				});
				var tasks = [];
				if (s) {
					//we need this in case a category disappears
					tasks = s.tasks();
				}
				setTasksViewData(tasks);
				$rootScope.$broadcast('refreshFilteredTasks', arg);
			};
			_this.reloadFilteredTasks(_this.filterParam);
		};

		var cfg = baseChartConfig(title, xCaption);
		cfg.series = [{
			data: _this.chartData,
			name: 'Tasks',
			colorByPoint: true,
			animation: !_this.hasLoaded,
			point: {
				events: {
					click: simpleChartClick
				}
			}
		}];
		cfg.xAxis.type = 'category';
		//config.options.chart.height = 400 + subcategories.length * 5;
		return cfg;
	}

	function simpleBarChartConfig(categories, subcategories, title) {
		var cfg = simpleColChartConfig(categories, subcategories, title, title);
		cfg.options.chart.type = 'bar';
		cfg.options.chart.marginTop = 40;
		cfg.options.chart.marginBottom = 135;
		cfg.options.chart.height = _this.chartData.length * 20 + 200; // 20px per data item plus top and bottom margins
		return cfg;
	}

	function stackedChartConfig(categories, subcategories, title, xCaption) {
		var stackedChartClick = function stackedChartClick(e) {
			_this.filterParam = {
				series: e.point.series.name,
				category: e.point.category
			};
			_this.reloadFilteredTasks = _this.reloadGraphSegmentTasks;
			_this.reloadFilteredTasks(_this.filterParam);
		};

		var cfg = baseChartConfig(title, xCaption);
		cfg.options.plotOptions = {
			series: {
				animation: !_this.hasLoaded,
				point: {
					events: {
						click: stackedChartClick
					}
				},
				stacking: 'normal'
			}
		};
		cfg.series = _this.chartData;
		cfg.xAxis.categories = categories;
		cfg.yAxis.min = 0;
		cfg.yAxis.stackLabels = {
			enabled: true,
			style: {
				fontWeight: 'bold'
			}
		};
		cfg.options.chart.height = 400 + subcategories.length * 5;
		return cfg;
	}

	function barChartConfig(categories, subcategories, title) {
		var cfg = stackedChartConfig(categories, title, title);
		cfg.options.chart = {
			type: 'bar',
			marginTop: 40,
			marginBottom: 135,
			height: _this.chartData[0].data.length * 20 + 200, // 20px per data item plus top and bottom margins
			style: {
				fontFamily: 'Cabin'
			}
		};
		return cfg;
	}

	_this.hasLoaded = false;

	var teams = _.filter(ctx.organizations, function (org) {
		return org.type === 'team' || org.type === 'subteam';
	});

	var teamNames = _.map(teams, 'name');

	var wards = _.filter(ctx.locations, function (loc) {
		return _.some(loc.physicalType.coding, { 'code': 'wi' });
	});

	var wardsNames = _.map(wards, 'name');

	var allTaskTypes = _.transform(config.categories, function (accum, cat) {
		_.forEach(_.map(cat.subCategories, 'display'), function (sc) {
			accum.push(sc);
		});
	}).sort();

	allTaskTypes.push('Other');

	_this.roleTypes = config.mtRoleTypes;

	_this.factors = ['urgency', 'team', 'location', 'type', 'practitioner', 'role', 'staff type'];

	_this.escalationOpts = [{ display: 'All', level: 0 }, { display: 'Escalation - Reminder', level: 1 }, { display: 'Escalation - Team', level: 2 }, { display: 'Forwarded 3+ times', level: 3 }, { display: 'Outstanding >4 hours', level: 4 }];

	_this.filteredFactors = function (factor) {
		if (factor === 2) {
			return _.filter(_this.factors, function (f) {
				return f !== _this.f1 && f !== 'role' && f !== 'practitioner';
			});
		}
		return _.filter(_this.factors, function (f) {
			return f !== _this.f2;
		});
	};

	_this.toTitleCase = AppService.toTitleCase;

	_this.changeRoleType = function (rt) {
		InsightsService.setRoleTypeFilter(rt);
		clearTasksView();
		_this.makeGraph();
	};

	_this.changeCampus = function (rt) {
		InsightsService.setCampusFilter(rt);
		clearTasksView();
		_this.makeGraph();
	};

	_this.changeEscalations = function (e) {
		InsightsService.setEscalationsFilter(e);
		clearTasksView();
		_this.makeGraph();
	};

	_this.includeGeneratedChanged = function () {
		_this.goGraph(_this.factor1, _this.factor2);
	};

	_this.reloadGraphSegmentTasks = function (arg) {
		var desc = arg.category + ': ' + arg.series;
		var s = _.find(_this.chartData, function (d) {
			return d.name === arg.series;
		});
		setTasksViewData(s.tasks()[arg.category]);
		$rootScope.$broadcast('refreshFilteredTasks', desc);
	};

	function newColor(colrs) {
		var colorsUsed = {};

		//initialize color use count to zero
		_.forEach(InsightsService.chartColors, function (c) {
			colorsUsed[c] = 0;
		});

		//count the number of times a color used
		_.forEach(_.values(colrs), function (c) {
			colorsUsed[c] = colorsUsed[c] + 1;
		});

		//find lowest count
		var minCount = _.min(_.values(colorsUsed));

		//find colors used the least number of times
		var leastUsed = _.filter(_.keys(colorsUsed), function (c) {
			return colorsUsed[c] === minCount;
		});
		//choose one at random
		return _.sample(leastUsed);
	}

	function makeGraphSeries(data, colrs, colSort, subcatgSort) {
		var series = void 0;
		if (data.subcategories) {
			if (subcatgSort) {
				data.subcategories = _.sortBy(data.subcategories, subcatgSort);
			}
			series = data.subcategories.map(function (subcatg) {
				var catgGroup = data.group[subcatg] || {};
				var countForCatg = function countForCatg(catg) {
					return (catgGroup[catg] || []).length;
				};
				var color = colrs[subcatg];
				if (!color) {
					//idea of this newColor function is that when the chart is live, new categories may appear as tasks are created
					//We don't want the colors to change for other categories when this occurs. So we choose from the least used colors
					//and save to the category-colors dictionary
					color = newColor(colrs);
					colrs[subcatg] = color;
				}
				return {
					name: subcatg,
					data: data.categories.map(countForCatg),
					color: color,
					tasks: function tasks() {
						return catgGroup;
					}
				};
			});
		} else {
			series = _.transform(data.categories, function (accum, catg) {
				var color = colrs[catg];
				if (!color) {
					color = newColor(colrs);
					colrs[catg] = color;
				}
				accum.push({
					name: catg,
					y: data.group[catg].length,
					color: color,
					tasks: function tasks() {
						return data.group[catg];
					}
				});
			});
		}
		if (colSort) {
			series = _.sortBy(series, colSort);
		}
		return series;
	}

	function makeColors(factor, categories) {
		var colrs = void 0;
		switch (factor) {
			case 'urgency':
				return InsightsService.chartColorsForUrgencies();
			case 'location':
				colrs = InsightsService.chartColorsFor(wardsNames);
				break;
			case 'type':
				colrs = InsightsService.chartColorsFor(allTaskTypes);
				break;
			case 'team':
				colrs = InsightsService.chartColorsFor(teamNames);
				break;
			default:
				colrs = InsightsService.chartColorsFor(categories);
		}
		return colrs;
	}

	function getColumnSort(factor) {
		switch (factor) {
			case 'urgency':
				return function (col) {
					return -1 * _.findIndex(config.urgencies, function (c) {
						return c.display === col.name;
					});
				};
			default:
				return void 0;
		}
	}

	function getSubcategorySort(factor) {
		switch (factor) {
			case 'urgency':
				return function (subcatg) {
					return _.findIndex(config.urgencies, function (c) {
						return c.display === subcatg;
					});
				};
			default:
				return void 0;
		}
	}

	_this.goGraph = function (f1, f2) {
		$state.go('app.admin.insights.detail', { f1: f1, f2: f2, custom: _this.custom || void 0, includeGenerated: _this.includeGenerated || void 0 });
	};

	_this.makeGraph = function () {
		var res = InsightsService.groupTasks(_this.factor1, _this.factor2);
		var colSort = void 0;
		var subcatgSort = void 0;
		colSort = getColumnSort(_this.factor1);
		if (_this.factor2) {
			if (!colors) {
				colors = makeColors(_this.factor2, res.subcategories);
			}
			subcatgSort = getSubcategorySort(_this.factor2);
		} else if (!colors) {
			colors = makeColors(_this.factor1, res.categories);
		}
		_this.chartData = makeGraphSeries(res, colors, colSort, subcatgSort);

		var chartConfigFn = _this.factor2 ? res.categories.length > 20 ? barChartConfig : stackedChartConfig : res.categories.length > 20 ? simpleBarChartConfig : simpleColChartConfig;
		var title = void 0;
		var caption = void 0;
		if (_this.factor1 === 'orphans') {
			title = 'Late and orphaned tasks';
			caption = '';
		} else {
			title = 'Tasks by ' + (_this.factor2 ? _this.factor1 + '/' + _this.factor2 : _this.factor1);
			caption = _this.factor2 ? AppService.toTitleCase(_this.factor1) + ' (grouped by ' + _this.factor2 + ')' : 'Task ' + _this.factor1;
		}

		_this.chartConfig = chartConfigFn(res.categories, res.subcategories, title, caption);
		_this.hasLoaded = true;
		_this.hasTasks = InsightsService.hasTasks();
		$timeout(function () {
			angular.element($window).resize();
		});
	};

	if ($stateParams.f1) {
		_this.factor1 = _this.f1 = $stateParams.f1;
	} else {
		_this.factor1 = _this.f1 = null;
	}

	if ($stateParams.f2) {
		_this.factor2 = _this.f2 = $stateParams.f2;
	} else {
		_this.factor2 = _this.f2 = null;
	}

	if ($stateParams.custom) {
		_this.custom = true;
	}

	if (InsightsService.roleTypeFilter.code) {
		_this.roleType = _.find(_this.roleTypes, function (rt) {
			return rt.code === InsightsService.roleTypeFilter.code;
		});
	}

	if (angular.isDefined(InsightsService.escalationFilter.level)) {
		_this.escalations = _.find(_this.escalationOpts, function (esc) {
			return esc.level === InsightsService.escalationFilter.level;
		});
	}

	if (_this.roleType) {
		InsightsService.setRoleTypeFilter(_this.roleType);
	}

	if (_this.escalations) {
		InsightsService.setEscalationsFilter(_this.escalations.level);
	}

	if ($stateParams.f1) {
		clearTasksView();
	} else {
		_this.factor1 = _this.f1 = 'urgency';
		_this.factor2 = _this.f2 = null;
	}

	var mg = true; //don't call makeGraph twice if we don't have to
	_this.includeGenerated = !!$stateParams.includeGenerated;
	if (InsightsService.generatedFilter.on !== _this.includeGenerated) {
		mg = false;
		InsightsService.setGeneratedFilter(_this.includeGenerated);
		InsightsService.reload().then(function () {
			clearTasksView();
			_this.makeGraph();
		});
	}

	if (mg) {
		_this.makeGraph();
	}

	$scope.$on('sse.insights.task', function () {
		_this.makeGraph();
		if (_this.reloadFilteredTasks) {
			_this.reloadFilteredTasks(_this.filterParam);
		}
		$rootScope.$broadcast('refreshFilteredTasks');
		//angular.element($window).resize();
	});
}]);

// Filter below used by insights.jade
angular.module('medtasker').filter('taskDisplay', ['config', '_', function (config, _) {
	return function (input) {
		return _.find(config.taskStatus, { 'code': input }).display;
	};
}]);