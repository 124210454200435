'use strict';

angular.module('medtasker').controller('ForwardTaskCtrl', ['AppService', 'LogService', '$scope', 'modalArgs', function (AppService, LogService, $scope, modalArgs) {

    var _this = this;
    _this.reasons = modalArgs.reasons;
    _this.reasonRequired = modalArgs.reasonRequired;
    _this.firstName = AppService.firstName;
    _this.lastName = AppService.lastName;
    _this.selectedFromRole = null;

    // Only get primary recipients if task has multiple recipients
    _this.primaryRecipients = [];
    if ($scope.inbox.selectedTask.recipient && $scope.inbox.selectedTask.recipient.length > 1) {
        _this.primaryRecipients = _.map($scope.inbox.selectedTask.recipientRole, function (role, index) {
            return {
                role: role,
                recipient: $scope.inbox.selectedTask.recipient[index],
                status: $scope.inbox.selectedTask.recipientStatuses ? $scope.inbox.selectedTask.recipientStatuses[index] : null
            };
        });
    }

    // Format the display for primary recipient dropdown
    _this.formatPrimaryRecipient = function (primaryRecipient) {
        var roleName = AppService.roleShortName(primaryRecipient.role);
        var recipientName = AppService.displayName(primaryRecipient.recipient);
        var status = primaryRecipient.status ? ' (' + primaryRecipient.status.status + ')' : '';
        return roleName + ' - ' + recipientName + status;
    };

    // Add user roles for from role selection
    // _this.fromRoles = AppService.userRoles;
    //_this.selectedFromRole = _this.fromRoles[0]; // Default to first role

    // _this.formatFromRole = function(role) {
    //     return AppService.roleShortName(role);
    // };

    _this.cancel = function () {
        $scope.inbox.cancelForward();
    };

    _this.onPrimaryRoleChange = function (index) {
        if (_this.selectedPrimaryRole) {
            // _this.selectedPrimaryRole directly contains the role object, not just the ID
            LogService.debug(_this.selectedPrimaryRole.role.id);
            LogService.debug(JSON.stringify(_this.selectedPrimaryRole));
            // No need to find the role again since selectedPrimaryRole already contains the full object
            // _this.selectedFromRole = _this.selectedPrimaryRole.role;
            LogService.debug('Selected from role:', _this.selectedFromRole);
        }
    };

    _this.forwardTask = function (recipient, invalid) {
        _this.clicked = true;
        if (invalid) {
            return;
        }
        var reason = _this.reason === 'Other' ? _this.otherReason : _this.reason;
        var primaryRoleId = _this.selectedPrimaryRole ? _this.selectedPrimaryRole.role.id : null;
        LogService.error('Forwarding task to ' + recipient + ' with reason: ' + reason);
        LogService.error('Primary Role ID: ' + primaryRoleId);
        // LogService.error('From Role ID: ' + _this.selectedFromRole.id);
        // Only include fromRoleId if task has multiple recipients
        // let fromRoleId = null;
        // if ($scope.inbox.selectedTask.recipient && $scope.inbox.selectedTask.recipient.length > 1) {
        //     fromRoleId = _this.selectedPrimaryRole.role.id;
        // }
        // if (_this.selectedFromRole) {
        // 	LogService.error('Selected from role:');
        // 	LogService.error(_this.selectedFromRole);
        // }
        // LogService.error(fromRoleId);
        $scope.inbox.forward(recipient, reason, primaryRoleId);
    };
}]);