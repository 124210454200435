'use strict';

angular.module('medtasker').controller('MessageModalCtrl', ['$modalInstance', 'modalArgs', '$timeout', function ($modalInstance, modalArgs, $timeout) {

	var _this = this;
	_this.message = modalArgs.message;
	_this.title = modalArgs.title;
	_this.type = modalArgs.type;
	_this.more = modalArgs.more;
	if (modalArgs.options) {
		_this.hideFooter = !!modalArgs.options.disallowClose;
		if (modalArgs.options.duration) {
			_this.hideFooter = true;
			$timeout(function () {
				$modalInstance.dismiss();
			}, modalArgs.options.duration);
		}
		_this.modalClass = modalArgs.options.modalClass;
	}
	_this.ok = function () {
		$modalInstance.dismiss();
	};

	_this.iconClass = function () {
		switch (_this.type) {
			case 'error':case 'warning':
				return 'fa fa-warning fa-4x';
			case 'info':
				return 'fa fa-info-circle fa-4x';
			case 'cancel':
				return 'fa fa-close fa-4x';
			case 'fwd':
				return 'fa fa-mail-forward fa-4x';
			case 'annotation':
				return 'fa fa-flag fa-4x';
			case 'sent':
				return 'svg-48';
			default:
				return '';
		}
	};
}]);