
'use strict';

angular.module('medtasker').controller('ShiftCtrl', ['ctx', 'AppService', 'ResourceService', 'DialogService', 'ShiftService', 'LogService', 'config', '$rootScope', '$state', '$scope', '$timeout', '$stateParams', '$log', '_', '$modal', 'moment', function (ctx, AppService, ResourceService, DialogService, ShiftService, LogService, config, $rootScope, $state, $scope, $timeout, $stateParams, $log, _, $modal, moment) {

	var _this = this;

	_this.ctx = ctx; // Used by the view
	_this.cells = {}; // key: role.id, value: display cells
	_this.config = config;
	_this.schedules = config.shiftSchedules;
	_this.aggregateShifts = [];
	_this.campuses = _.filter(AppService.campuses(), function (loc) {
		return loc.name.toLowerCase() !== 'all';
	});
	_this.teamsByCampus = ShiftService.teamsByCampus;
	_this.campusCode = AppService.campusDisplayCode;

	// Functions
	_this.prevTimeslice = ShiftService.prevTimeslice;
	_this.nextTimeslice = ShiftService.nextTimeslice;
	_this.selectTeam = ShiftService.selectTeam; // args: team
	_this.selectRole = ShiftService.selectRole; // args: role
	_this.selectedShift = null;
	_this.viewPeriodStartTime = config.viewPeriodStartTime;
	_this.applyDate = moment().startOf('day').toDate();
	_this.tempApplyDate = Date();

	if ($stateParams.campus) {
		_this.ctx.selectedCampus = _.find(_this.campuses, { 'id': $stateParams.campus });
		// } else if (config.defaultCampusLoc){
		// 	_this.ctx.selectedCampus = _.find(_this.campuses, {'id': config.defaultCampusLoc.id}) ;
		// 	$state.go($state.current, {team: null, campus: config.defaultCampusLoc.id, startDate: ctx.viewPeriod.start.format('YYYY-MM-DD')});
	} else {
		_this.ctx.selectedCampus = _this.campuses[0];
	}

	var unbind = [];

	function getChangePoints() {
		if (!_this.selectedRole) {
			return;
		}
		ShiftService.getChangePoints(_this.selectedRole.id, _this.selectedSchedule).then(function (cps) {
			_this.changePoints = cps;
			_this.findApplyDateChangePoint();
			_this.selectedShift = null;
		}, function (err) {
			LogService.error('Error getting change points: ' + err.data);
		});
	}
	var sseTimeout = false;

	function reload() {
		$rootScope.$emit('appLoadingPush', { source: 'shiftReload' });
		sseTimeout = true;
		$timeout(function () {
			sseTimeout = false;
			AppService.loadShifts([_this.selectedSchedule.code], _this.ctx.selectedTeam).then(function () {
				_this.cells = ShiftService.updateCells(_this.selectedSchedule, _this.viewStart, _this.hourRange, moment(_this.applyDate));
				_this.updateForSelectedTeamAndDate();
				getChangePoints();
				$rootScope.$emit('appLoadingPop', { source: 'shiftReload' });
			});
		}, 500);
	}

	function shiftSseHandler(ev, shift) {
		if (sseTimeout || shift.schedule.code !== _this.selectedSchedule.code || shift.role.organization.id !== _this.ctx.selectedTeam.id) {
			return;
		}
		reload();
	}

	function shiftSseDeletedHandler(ev, shift) {
		if (sseTimeout || !_.find(_this.shifts, function (s) {
			return s.id === shift.id;
		})) {
			return;
		}
		reload();
	}
	unbind.push($scope.$on('sse.shift', shiftSseHandler));
	unbind.push($scope.$on('sse.shift.deleted', shiftSseDeletedHandler));

	$scope.$on('$destroy', function () {
		_.forEach(unbind, function (remove) {
			return remove();
		});
	});

	_this.updateForSelectedTeamAndDate = function () {
		if (!ctx.selectedTeam) {
			return;
		}
		if (_this.selectedRole) {
			_this.findApplyDateChangePoint();
		}
		ShiftService.selectTeam(ctx.selectedTeam);
		_this.selectedShift = null;
		_this.refreshCells();
		var d = +moment(_this.applyDate);
		_this.shifts = _.filter(_this.ctx.shifts, function (s) {
			return s.role.organization.id === _this.ctx.selectedTeam.id && s.schedule.code === _this.selectedSchedule.code && +s.validPeriod.start <= d && (s.validPeriod.end ? +s.validPeriod.end >= d : true);
		});
	};

	function teamId() {
		var id = _.find(_this.ctx.selectedTeam.identifier, function (i) {
			return i.system.startsWith(config.hospitalSystemPrefix);
		}).value;
		return id;
	}

	_this.refreshCells = function () {
		if (_this.ctx.teamRoles.length === 0) {
			return;
		}
		var lowestStartTime = _.min(_this.ctx.teamRoles, function (r) {
			return +r.start;
		}).start;

		var highestEndTime = moment.duration(_.max(_this.ctx.teamRoles, function (r) {
			return +r.start;
		}).start).add(24, 'hours');

		_this.viewStart = lowestStartTime.asHours();
		_this.hourRange = highestEndTime.asHours() - _this.viewStart;
		_this.startHour = AppService.formatDuration(lowestStartTime);
		_this.cells = ShiftService.updateCells(_this.selectedSchedule, _this.viewStart, _this.hourRange, moment(_this.applyDate));
	};

	_this.selectShift = function (cell) {
		_this.selectedRole = null;
		_this.selectedShift = cell.shift;
		_this.aggregateShifts = _this.selectedShift.aggregate ? _.filter(_this.ctx.shifts, { aggregate: { id: _this.selectedShift.aggregate.id } }) : [];
		_this.aggregateShiftsThisTeam = [];
		_this.aggregateShiftsOtherTeams = [];
		_.forEach(_this.aggregateShifts, function (s) {
			if (s.role.organization.identifier[0].value === _this.ctx.selectedTeam.identifier[0].value) {
				_this.aggregateShiftsThisTeam.push(s);
			} else {
				_this.aggregateShiftsOtherTeams.push(s);
			}
		});
		var grp = _.groupBy(_this.aggregateShiftsOtherTeams, 'role.organization.name');
		_this.aggregateShiftsGrouped = [];
		_.forOwn(grp, function (g, t) {
			_this.aggregateShiftsGrouped.push({ team: t, shifts: g });
		});
	};

	_this.fixCpTime = function () {
		_this.tempApplyDate = moment(moment(_this.tempApplyDate).local().format('YYYY-MM-DD')).toDate();
	};

	_this.scheduleChanged = function () {
		_this.selectedShift = null;
		_this.onTeamChange();
	};

	_this.dateChanged = function () {
		_this.applyDate = _this.tempApplyDate;
		_this.onTeamChange();
	};

	//enable change points for the future/today only
	_this.dateUnavailable = function (date) {
		if (+moment(date) < +moment().startOf('day')) {
			return true;
		}
		return false;
	};

	_this.inPast = function () {
		return +moment(_this.applyDate) < +moment().startOf('day');
	};

	_this.changePointDialog = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		_this.showDateDialog = true;
	};

	_this.sameDay = function (d1, d2) {
		return +moment(d1) === +moment(d2);
	};

	_this.applyDateIsToday = function () {
		return +moment(_this.applyDate) === +moment().startOf('day');
	};

	_this.goToDate = function (d) {
		_this.tempApplyDate = d;
		_this.dateChanged();
	};

	_this.goToToday = function () {
		_this.tempApplyDate = moment().startOf('day').toDate();
		_this.dateChanged();
	};

	_this.displayChangePoint = function (cp) {
		if (+cp === +moment().startOf('day')) {
			return 'Today';
		}
		return cp.format('DD/MM/YYYY');
	};

	_this.isChangePoint = function (role) {
		var ad = moment(_this.applyDate);
		if (role) {
			//it's a change point if some shift exists that starts on this day, or ends the day before today
			return _.some(_this.ctx.shifts, function (s) {
				return s.role.id === role.id && s.schedule.code === _this.selectedSchedule.code && (+moment(s.validPeriod.start) === ad || +s.validPeriod.end < +ad && ad.diff(s.validPeriod.end, 'days') === 1);
			});
		}
		return false;
	};

	_this.clearChangePoint = function (date) {
		ShiftService.clearChangePoint(_this.selectedRole.id, moment(date), _this.selectedSchedule.code).then(function () {
			var newDate = ShiftService.getPreviousChangePoint(_this.selectedRole.id, date, _this.selectedSchedule.code);
			if (newDate) {
				_this.goToDate(newDate);
			} else {
				_this.goToToday();
			}
		}).then(function () {
			_this.selectRole(_this.selectedRole); //refresh the change points
			_this.goToToday();
		});
	};

	_this.goToPreviousChangePoint = function (date, roleId) {
		var prev = ShiftService.getPreviousChangePoint(roleId, date, _this.selectedSchedule);
		if (prev) {
			_this.goToDate(prev);
		} else {
			var cp = moment(date).subtract(1, 'weeks').toDate();
			_this.goToDate(cp);
		}
	};

	_this.formatDate = function (d) {
		return moment(d).format('ddd D MMM YYYY');
	};

	_this.isInAggregate = function (aggregate, shift) {
		if (!aggregate) {
			return false;
		}
		return shift.aggregate && shift.aggregate.id === aggregate.id;
	};

	_this.findApplyDateChangePoint = function () {
		var p = _.find(_this.changePoints, function (cp) {
			return cp.format('YYYY-MM-DD') === moment(_this.applyDate).format('YYYY-MM-DD');
		});
		//in event this is not a change point yet (we added one or went back to today when there is no CP today)
		//then add a temporary change point to the array and select it
		if (p) {
			_this.selectedChangePoint = p;
		} else {
			var m = moment(_this.applyDate);
			_this.changePoints.push(m);
			_this.changePoints = _.sortBy(_this.changePoints, function (cp) {
				return +cp;
			});
			_this.selectedChangePoint = m;
		}
	};

	_this.selectRole = function (role) {
		_this.selectedRole = role;
		getChangePoints();
	};

	_this.endOfShift = function (shift) {
		return AppService.formatDuration(AppService.wrapDuration(moment.duration(shift.start + shift.duration)));
	};

	_this.goTeam = function (t) {
		_this.ctx.selectedTeam = t;
		_this.selectedRole = null;
		_this.onTeamChange();
	};

	_this.onTeamChange = function () {
		var id = teamId();
		var p = { team: id, schedule: _this.selectedSchedule.code };
		if (_this.applyDate !== moment().startOf('day').toDate()) {
			p.applyDate = moment(_this.applyDate).format('YYYY-MM-DD');
		}
		if (_this.selectedRole) {
			p.role = _this.selectedRole.id;
		}
		$state.go($state.current, p);
	};

	_this.onCampusChange = function () {
		$state.go($state.current, { team: null, campus: _this.ctx.selectedCampus.id, schedule: _this.selectedSchedule.code });
	};

	_this.formatCampus = function (c) {
		if (!c.id) {
			return '--' + c.name + '--';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	_this.periodDuration = function (period) {
		var duration = moment.duration(period.end - period.start);
		if (duration.days() > 0) {
			$log.error('Shift period duration lasts longer than a day: ' + angular.toJson(period));
		}
		var hours = duration.hours() + duration.days() * 24;
		return sprintf('%02d:%02d', hours, duration.minutes()); // eslint-disable-line no-undef
	};

	_this.cantEditMessage = function () {
		DialogService.openMessageDialog('Can\'t edit this shift', 'You can\'t edit a shift in the past.', 'warning');
	};

	_this.shiftDialog = function (shift) {
		if (_this.inPast()) {
			_this.cantEditMessage();
			return;
		}
		var d = +moment(_this.applyDate);
		var shifts = _.filter(_this.ctx.shifts, function (s) {
			return s.role.id === shift.role.id && s.schedule.code === _this.selectedSchedule.code && (+s.validPeriod.start <= d && s.validPeriod.end ? +s.validPeriod.end >= d : true);
		});

		var options = {
			templateUrl: 'app/shift/shift-dialog.html',
			// size: 'lg',
			backdrop: true,
			controller: 'ShiftDialogCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						mode: shift.type === 'empty' ? 'create' : 'edit',
						shift: shift, // only for edit
						role: shift.role,
						roles: _this.rolesForTeam(_this.ctx.selectedTeam),
						start: AppService.wrapDuration(moment.duration(shift.start + ctx.viewPeriodStartTime)),
						duration: shift.duration,
						schedule: _this.selectedSchedule,
						shifts: shifts,
						applyDate: _this.applyDate
					};
				}
			}
		};
		$modal.open(options);
	};

	_this.cloneDialog = function () {
		var options = {
			templateUrl: 'app/shift/shift-clone.html',
			// size: 'lg',
			backdrop: true,
			controller: 'ShiftCloneCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						applyDate: _this.applyDate,
						schedule: _this.selectedSchedule,
						roles: _this.rolesForTeam(_this.ctx.selectedTeam)
					};
				}
			}
		};
		var instance = $modal.open(options);
		instance.result.then(function () {
			_this.refreshCells();
		});
	};

	// Filter
	_this.teamsOnly = function (org) {
		return org.type === 'team' || org.type === 'subteam';
	};

	_this.rolesForTeam = function (t) {
		return _.filter(ctx.roles, function (r) {
			return r.active && r.organization.id === t.id;
		});
	};

	_this.addOneDay = function (d) {
		return moment(d).add(1, 'days');
	};

	_this.lessOneDay = function (d) {
		return moment(d).subtract(1, 'days');
	};

	function init() {
		if ($stateParams.team) {
			_this.ctx.selectedTeam = _.find(_this.ctx.organizations, function (org) {
				return _.some(org.identifier, function (id) {
					return id.system.startsWith(config.hospitalSystemPrefix) && id.value === $stateParams.team;
				});
			});
		} else {
			//go to first team
			var orgs = _this.ctx.organizations;
			if ($stateParams.campus) {
				orgs = _.filter(orgs, function (org) {
					return org.partOf && org.partOf.location.id === $stateParams.campus;
				});
			}
			var team = _.find(_.sortBy(orgs, function (org) {
				return org.identifier[0].value;
			}), function (org) {
				return (org.type === 'team' || org.type === 'subteam') && org.identifier[0].value;
			});
			if (team && team.identifier[0].value) {
				//we should have this value, but if not, avoid infinite loop
				$state.go($state.current, { team: team.identifier[0].value, applyDate: moment(_this.applyDate).format('YYYY-MM-DD') });
				return;
			}
			DialogService.errorMessage('Campus has no teams with roles! Please ask a roster administrator to add roles.');
			$state.go(config.defaultState);
		}
		if ($stateParams.applyDate) {
			_this.applyDate = moment($stateParams.applyDate, 'YYYY-MM-DD').toDate();
		}
		var sched = $state.params.schedule || moment(_this.applyDate).format('ddd').toLowerCase();
		_this.selectedSchedule = _.find(_this.schedules, { code: sched }); // initial value
		_this.updateForSelectedTeamAndDate();
		if ($stateParams.role) {
			var role = _.find(ctx.roles, function (r) {
				return r.id === $stateParams.role;
			});
			if (role) {
				_this.selectRole(role);
			}
		}
	}

	// Filter
	_this.seniority = ShiftService.roleSeniority;

	init();
}]);

/* jshint ignore:start */
angular.module('medtasker').directive('timeGrid', [function () {
	return {
		restrict: 'A',
		scope: {
			dividers: '='
		}
	};
}]);
/* jshint ignore:end */

// Time axis markers
angular.module('medtasker').directive('shiftAxis', [function () {
	return {
		restrict: 'E',
		scope: {},
		replace: true,
		transclude: true,
		template: '<div ng-transclude></div>',
		link: function link(scope, element) {
			var viewDuration = 24; // hrs
			var content = '';
			for (var i = 0; i <= viewDuration; i += 4) {
				var start = i * 100 / viewDuration;
				var hrs = Math.floor(i % 24);
				var min = i % 1 * 60;
				content += '<div style=\"left:' + start + '%\">' + sprintf('%02d:%02d', hrs, min) + '</div>\n'; // eslint-disable-line
			}
			element.html(content);
		}
	};
}]);

angular.module('medtasker').directive('shiftCell', ['AppService', function (AppService) {
	return {
		restrict: 'E',
		scope: {
			cell: '=',
			viewDuration: '@',
			cells: '='
		},
		transclude: true,
		replace: true,
		template: '<div><div class="shift-content"><div class="show-start-time" tooltip="{{cell.startTime}}"></div><div class="show-end-time"  tooltip="{{cell.endTime}}"></div><div ng-transclude></div></div></div>',
		link: function link(scope, element) {
			// in %
			var start = scope.cell.start.asHours() * 100 / scope.viewDuration;
			var width = scope.cell.duration.asHours() * 100 / scope.viewDuration;
			element.css({ 'width': width + '%', 'left': start + '%' });

			if (scope.cell.cellStart.hours() === scope.cell.shift.role.start.hours() && scope.cell.cellStart.minutes() === scope.cell.shift.role.start.minutes()) {
				element.addClass('first-shift');
			}
			if (scope.cell.shift.type === 'empty') {
				element.addClass('empty-shift');
			}
			scope.cell.startTime = AppService.formatDuration(AppService.wrapDuration(scope.cell.cellStart));
			scope.cell.endTime = AppService.formatDuration(AppService.wrapDuration(scope.cell.cellEnd));
			if (scope.cell.openLeft) {
				element.css({ 'border-left': 'none' });
			}
			if (scope.cell.openRight) {
				element.css({ 'border-right': 'none' });
			}
			var c1 = scope.cell;
			for (var i = 0; i < scope.cells.length; i++) {
				var c = scope.cells[i];
				var s = c.cellStart.asMinutes();
				var e = c.cellEnd.asMinutes();
				var s1 = c1.cellStart.asMinutes();
				var e1 = c1.cellEnd.asMinutes();
				if (c1 !== c && (s1 <= e && s1 >= s || e1 <= s && s1 <= s)) {
					element.addClass('overlaps');
					scope.cell.overlaps = true;
				}
			}
		}
	};
}]);