'use strict';

angular.module('medtasker').controller('SwitchCtrl', ['AuthService', '$state', 'config', 'LogService', function (AuthService, $state, config, LogService) {
	LogService.log('In switch');
	if (AuthService.isAuthenticated('user')) {
		$state.go(config.defaultState || 'app.compose');
	} else if (AuthService.isAuthenticated('ward')) {
		$state.go('home');
	} else {
		$state.go('home');
	}
}]);