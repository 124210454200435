'use strict';

/* global angular */
/* global Connection */
angular.module('medtasker.service').factory('EventService', ['$rootScope', '$timeout', '$http', '$window', 'AppService', 'ResourceService', 'AuthService', 'UrlService', 'LogService', 'config', '_', function ($rootScope, $timeout, $http, $window, AppService, ResourceService, AuthService, UrlService, log, config, _) {
	'use strict';

	var _feed = null;
	var listeners = [];
	var _connectionLost = false; //track if we lost connection to EventSource
	var warningTimeout = void 0;
	var unfiltered = false;
	var RETRY_DELAY_MS = 4000;

	function _reset() {
		if (_feed) {
			_feed.close();
		}
		_feed = null; // Resets _feed
		listeners = [];
	}

	function _resetIfFilteringChanged(unf) {
		if (unf !== unfiltered) {
			_reset();
		}
	}

	function reconnect() {
		$timeout(function () {
			if (_feed.readyState !== _feed.OPEN) {
				$rootScope.$broadcast('sseConnectionLost', _feed);
			}
		}, RETRY_DELAY_MS);
	}

	function _createEventSource(noFilter) {
		if (AuthService.sessionExpired() || !AuthService.isAuthenticated('user')) {
			return;
		}
		var feedUrl = UrlService.sseUrl() + '?jwt=' + AuthService.token() + (noFilter ? '&nofilter=true' : '');
		//TODO: consider if there's a better way to handle persisting the ward id. See login controller on web client
		var wardId = $window.localStorage.getItem('medtasker.wardId');
		if (wardId) {
			feedUrl = feedUrl + '&wardid=' + wardId;
		}
		_feed = new EventSource(feedUrl, { withCredentials: true });
		_feed.filtered = !noFilter;
		warningTimeout = $timeout(function () {
			if (_feed.readyState !== _feed.OPEN) {
				ResourceService.connectionStatus.connecting = true;
				ResourceService.connectionStatus.serverUnavailable = false;
			}
		}, 5000);
		_feed.onerror = function () {
			log.error('Error in EventSource');
			_connectionLost = true;
			if (_feed.readyState === _feed.CLOSED) {
				$http.get(feedUrl).then(null, function (res) {
					//ResourceService.connectionStatus.connecting = false;
					if (res.status === 401) {
						$rootScope.$broadcast('sseUnauthorized');
					} else {
						reconnect();
					}
				}, function () {
					reconnect();
				});
			} else if (_feed.readyState === _feed.CONNECTING) {
				reconnect();
			}
		};
		_feed.onopen = function () {
			log.info('EventSource connection established');
			$timeout.cancel(warningTimeout);
			ResourceService.connectionStatus.serverUnavailable = false;
			ResourceService.connectionStatus.connecting = false;
			$rootScope.$broadcast('sseConnectionOpen');
			_connectionLost = false;
		};
	}

	function _addListeners(resNames, callbackHandler, noFilter) {
		if (!_feed) {
			_createEventSource(noFilter);
		}
		if (!_feed) {
			//if _createEventSource did not return an event source, it means our token expired, so ignore ignore _addListeners
			return;
		}

		var _loop = function _loop(i) {
			if (!_.some(listeners, function (r) {
				return (//don't add more than once
					r.resName === resNames[i] && r.handler === callbackHandler
				);
			})) {
				listeners.push({ resName: resNames[i], handler: callbackHandler });
				_feed.addEventListener(resNames[i], function (event) {
					callbackHandler(event);
				}, false);
				_feed.listeners = listeners;
			}
		};

		for (var i = 0; i < resNames.length; i++) {
			_loop(i);
		}
	}

	function _recreateEventSource(es) {
		_createEventSource(!es.filtered);
		_.forEach(es.listeners, function (l) {
			_feed.addEventListener(l.resName, function (event) {
				l.handler(event);
			}, false);
		});
		listeners = es.listeners;
	}

	//If handler is undefined, removes all listeners for given resource.
	//If no arguments passed, removes all listeners
	function _removeListener(resName, handler) {
		if (!resName || !handler) {
			throw new Error('resName and handler required by _removeListener');
		}
		var ls = _.filter(listeners, function (listener) {
			return listener.resName === resName && listener.handler === handler;
		});
		_.pullAll(listeners, ls);
		if (_feed) {
			ls.forEach(function (l) {
				_feed.removeEventListener(l.resName, l.handler);
			});
		}
	}

	function _removeAllListeners() {
		if (_feed) {
			if (listeners) {
				listeners.forEach(function (l) {
					_feed.removeEventListener(l.resName, l.handler);
				});
				listeners.length = 0;
			}
		}
	}

	var _standardListeners = [{ name: 'MtTask', handler: AppService.taskSseHandler }, { name: 'MtRoleAssignment', handler: AppService.roleAssignmentSseHandler }, { name: 'MtRole', handler: AppService.roleSseHandler }, { name: 'Communication', handler: AppService.communicationSseHandler }, { name: 'MtOverrideRequest', handler: AppService.overrideRequestSseHandler }, { name: 'MtTeamAssignment', handler: AppService.teamAssignmentSseHandler }, { name: 'Encounter', handler: AppService.encounterSseHandler }, { name: 'MtClinicalPhotograph', handler: AppService.clinicalPhotographSseHandler }];

	function _addStandardListeners(nofilter) {
		_standardListeners.forEach(function (l) {
			return _addListeners([l.name], l.handler, nofilter);
		});
	}

	function _removeStandardListeners() {
		_standardListeners.forEach(function (l) {
			return _removeListener(l.name, l.handler);
		});
	}

	return {
		addListeners: _addListeners,
		createEventSource: _createEventSource,
		recreateEventSource: _recreateEventSource,
		removeListener: _removeListener,
		removeAllListeners: _removeAllListeners,
		reset: _reset,
		resetIfFilteringChanged: _resetIfFilteringChanged, //only reset the event source if filtering state is changing
		addStandardListeners: _addStandardListeners,
		removeStandardListeners: _removeStandardListeners,
		//return true if reconnecting after a lost connection
		reconnected: function reconnected() {
			return _connectionLost;
		}
	};
}]);