'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

angular.module('medtasker').controller('InboxCtrl', ['ctx', 'AppService', 'ResourceService', 'AuthService', 'ComposeService', 'InboxService', 'TaskService', 'DialogService', 'AudioService', 'LogService', 'moment', 'config', '$modal', '$timeout', '_', '$log', 'viewName', 'mode', '$sce', '$state', '$stateParams', '$scope', '$location', '$anchorScroll', '$rootScope', function (ctx, AppService, ResourceService, AuthService, ComposeService, InboxService, TaskService, DialogService, AudioService, LogService, moment, config, $modal, $timeout, _, $log, viewName, mode, $sce, $state, $stateParams, $scope, $location, $anchorScroll, $rootScope) {

	var _this = this;
	_this.viewName = viewName;
	var timeouts = []; //keep track of any to dispose

	_this.tasks = ctx.tasks;
	_this.filtered = [];
	_this.clown = [];

	_this.selectedTask = null;
	_this.selectedTaskObsHash = {};
	_this.selectedTaskSenderContactDetailsHash = {};
	_this.selectedTaskCurrentStatus = '';
	_this.selectedTaskNextStatus = '';
	_this.showCc = {};
	_this.mode = mode;

	_this.selectTask = function (task, scroll) {
		_this.selectedTaskObsHash = {};
		_this.selectedTask = task;
		_this.annotationsAvailable = InboxService.availableAnnotations(task).length > 0 && (task.$metadata.recipient || AuthService.hasAdminPriv());
		_this.selectedTaskObsHash = _.keyBy(_this.selectedTask.supportingInformation, 'name');
		_this.senderContact = _.keyBy(_this.selectedTask.overrideSenderContact, 'system');
		_this.taskStatus = AppService.displayFromCode('taskStatus', _this.selectedTask.status);
		_this.taskNextStatus = AppService.configMap('taskStatus', 'code', _this.selectedTask.status, 'nextStatusDisplay');
		InboxService.setTaskAsViewed(task);
		if (scroll) {
			try {
				$location.hash(task.id);
				$anchorScroll();
			} catch (ex) {
				$log.debug('Error trying to scroll to task: ' + ex);
			}
		}
	};

	function ccDeclined(task) {
		return InboxService.myCcStatus(task, 'declined');
	}

	//return breakdown of tasks into new and incomplete, according to filters
	function taskBreakdown(tasks, hideArchived) {
		var result = {};
		var incomplete = _.filter(tasks, function (t) {
			return t.$metadata.active;
		});
		var notArchived = _.filter(incomplete, function (t) {
			return _this.mode === 'myinbox' || _this.mode === 'systemtasks' ? !t.$metadata.archived : !t.$metadata.completedOrCancelled;
		});
		result.new = _.filter(hideArchived ? notArchived : incomplete, function (t) {
			return t.status === 'sent';
		}).length;
		result.incomplete = hideArchived ? notArchived.length : incomplete.length;
		result.archived = tasks.length - notArchived.length;
		result.total = tasks.length;
		return result;
	}

	function _matchesFields(t, searchStr) {
		var fields = (t.description + ' ' + t.priority.coding[0].display + ' ' + AppService.displayFromCode('taskStatus', t.status) + ' ' + _this.team(t) + ' ' + _this.location(t)).split(' ');
		if (t.patient) {
			// Get all given and family strings
			var names = _.flattenDeep(t.patient.name);
			fields = fields.concat(_.flatten(_.map(names, 'given')));
			fields = fields.concat(_.flatten(_.map(names, 'family')));
			var descFields = t.serviceRequested[0].coding[0].display.split(' ');
			fields = fields.concat(descFields);
		}

		var terms = searchStr.toLowerCase().split(' ');

		return _.every(terms, function (term) {
			return _.some(fields, function (field) {
				return _.startsWith(field.toLowerCase(), term);
			});
		});
	}

	function shortRoleNameForRoleId(roleId) {
		return AppService.roleShortName(_.find(AppService.ctx.roles, { 'id': roleId }));
	}

	(function init() {
		function showTask(task) {
			if (task.$metadata.archived) {
				_this.hideArchivedTasks = false;
			}
			_this.selectTask(task, true);
		}
		if (mode === 'senttasks' || mode === 'wardtasks' || mode === 'monitortasks' || mode === 'task' || mode === 'tasksearch') {
			_this.hideArchivedTasks = false;
		} else {
			_this.hideArchivedTasks = true;
		}
		if ($stateParams.taskId) {
			var task = _.find(_this.tasks, { 'id': $stateParams.taskId });
			if (task) {
				showTask(task);
			} else {
				AppService.get('MtTask', $stateParams.taskId).then(function (t) {
					showTask(t);
					_this.sortedTasks = _this.getFilteredAndSortedTasks();
				}, function () {
					$log.error('Task not found:' + $stateParams.taskId, 1);
					_this.tasks = [];
				});
			}
		}
	})();

	var unbind = [];

	//If an RA that affects me is added while live, user data will be reloaded so we need to fetch tasks again.
	unbind.push($scope.$on('ReloadUserData', function () {
		_this.sortedTasks = _this.getFilteredAndSortedTasks();
	}));

	unbind.push($scope.$on('sse.task', function (ev, task) {
		if (_this.selectedTask && task.id === _this.selectedTask.id) {
			_this.selectTask(task);
		}
		_this.sortedTasks = _this.getFilteredAndSortedTasks();
	}));

	unbind.push($scope.$on('refreshFilteredTasks', function (ev, desc) {
		if (desc) {
			_this.viewName = desc;
		}
		_this.sortedTasks = _this.getFilteredAndSortedTasks();
		$timeout(function () {});
	}));

	unbind.push($scope.$on('sse.appalert', function (ev, alert) {
		var task = _.find(_this.tasks, function (t) {
			return t.id === alert.payload[0].contentReference.id;
		});
		if (task) {
			task.alerts = InboxService.taskAlerts(task);
			if (_this.selectedTask && task.id === _this.selectedTask.id) {
				InboxService.setTaskAsViewed(_this.selectedTask);
			}
		}
	}));

	unbind.push($scope.$on('teamDataLoaded', function () {
		_this.getFilteredAndSortedTasks();
	}));

	$scope.$on('$destroy', function () {
		_.forEach(unbind, function (remove) {
			remove();
		});
		_.forEach(timeouts, function (t) {
			$timeout.cancel(t.promise);
		});
	});

	_this.sortConfig = {
		status: {
			func: function func(t) {
				return _.find(config.taskStatus, { 'code': t.status }).index;
			},
			dir: 'asc'
		},
		urgency: {
			func: function func(t) {
				return _.find(config.urgencies, { 'code': t.priority.coding[0].code }).index;
			},
			dir: 'asc'
		},
		ward: {
			func: function func(t) {
				if (t.overrideWard) {
					return t.overrideWard.identifier[0].value;
				}
				return t.encounter ? t.encounter.location[t.encounter.location.length - 1].location.partOf.identifier[0].value : '';
			},
			dir: 'asc'
		},
		bed: {
			func: function func(t) {
				var s = void 0;
				if (t.overrideBed) {
					s = t.overrideBed;
				} else {
					if (config.bedSort === 'byLastNumber' && t.encounter) {
						return AppService.lastNumber(t.encounter.location[0].location.identifier[0].value);
					}
					s = t.encounter ? t.encounter.location[t.encounter.location.length - 1].location : '';
					if (s !== '') {
						s = config.bedDisplayField === 'name' ? s.name : s.identifier[0].value;
					}
				}
				return s;
			},
			dir: 'asc'
		},
		time: {
			func: function func(t) {
				return t.dateSent;
			},
			dir: 'desc'
		}
	};

	_this.statusSort = ['status', 'urgency', 'time', 'ward', 'bed'];
	_this.timeSort = ['time'];
	_this.locationSort = ['ward', 'bed'];
	_this.urgencySort = ['urgency', 'time', 'ward', 'bed'];
	_this.sortOrder = _this.statusSort;
	_this.searchStr = '';
	_this.ccStatusCount = function (cc, status) {
		return _.filter(cc, function (c) {
			return c.status === status;
		}).length;
	};

	_this.myCc = InboxService.myCc;

	_this.myCcStatus = InboxService.myCcStatus;

	_this.acknowledgeCc = function (task) {
		return InboxService.updateCc(task, true);
	};

	_this.declineCc = function (task) {
		return InboxService.updateCc(task, false);
	};

	_this.userTeams = function () {
		return _.union(_.map(AppService.userRAs, 'role.organization'), _.map(AppService.userTAs, 'organization'));
	};

	_this.archived = function (t) {
		return mode === 'myinbox' || mode === 'systemtasks' ? t.$metadata.archived : t.$metadata.completedOrCancelled;
	};

	function setNoTasksText(roleId) {
		switch (mode) {
			case 'myinbox':
				_this.noTasksMessage = 'Your inbox is empty!';
				if (roleId) {
					_this.noTasksMessage = shortRoleNameForRoleId(roleId) + '\'s inbox is empty!';
				}
				break;
			case 'teaminbox':
				if (_this.userTeams().length === 0) {
					_this.noTasksMessage = 'You are not assigned to any teams';
				} else if (roleId) {
					_this.noTasksMessage = shortRoleNameForRoleId(roleId) + '\'s inbox is empty!';
				} else {
					_this.noTasksMessage = 'Your team\'s inbox is empty';
				}
				break;
			case 'senttasks':
				_this.noTasksMessage = 'You have no uncompleted sent tasks';
				break;
			case 'patientstasks':
				if (_this.userTeams().length === 0) {
					_this.noTasksMessage = 'You do not have any patients';
				} else {
					_this.noTasksMessage = 'There are no uncompleted tasks for patients in your team(s)';
				}
				break;
			case 'systemtasks':
				_this.noTasksMessage = 'You have no uncompleted generated tasks';
				break;
			case 'tasksforpatient':
				_this.noTasksMessage = AppService.patientName(_.result(_.find(AppService.ctx.encounters, { 'id': $state.params.encounterId }), 'patient')) + ' has no outstanding tasks';
				break;
			case 'priortasks':
				_this.noTasksMessage = 'You have no uncompleted tasks from past roles';
				break;
			case 'alltasks':
				_this.noTasksMessage = 'There are no uncompleted tasks in the system';
				break;
			case 'task':
				_this.noTasksMessage = 'Nothing to see here';
				break;
		}
	}

	setNoTasksText();

	//_this.currentPage = 0;
	//_this.pageSize = config.defaultPageSize || 10;

	//Why aren't we using a filter for sorting I hear you ask?
	//Because a filter will take a single task object as a parameter
	//and that will make multi-sorting on various fields difficult.
	//The below method lets us leverage the powerful sorting functions
	//in lodash.
	_this.getTasks = function () {
		var tasks = void 0;
		switch (mode) {
			case 'myinbox':
				tasks = AppService.inboxTasks();
				_this.prior = taskBreakdown(AppService.priorTasks(), _this.hideArchivedTasks);
				_this.priorCount = _this.prior.total;
				break;
			case 'teaminbox':
				tasks = AppService.teamInboxTasks();
				break;
			case 'senttasks':
				tasks = AppService.sentTasks();
				break;
			case 'patientstasks':
				var encounters = AppService.getUsersEncounters();
				_this.numPatients = encounters.length;
				tasks = AppService.patientsTasks();
				break;
			case 'tasksforpatient':
				tasks = AppService.encounterTasks($state.params.encounterId);
				break;
			case 'priortasks':
				tasks = AppService.priorTasks();
				_this.current = taskBreakdown(AppService.inboxTasks(), _this.hideArchivedTasks);
				break;
			case 'wardtasks':
				tasks = AppService.wardTasks();
				break;
			case 'systemtasks':
				tasks = AppService.systemTasks();
				break;
			case 'alltasks':
				tasks = AppService.ctx.tasks;
				break;
			case 'insighttasks':case 'monitortasks':case 'orphanedtasks':case 'tasksearch':
				if (mode === 'tasksearch') {
					_this.pagerInfo = AppService.ctx.pagerInfo;
				}
				tasks = AppService.ctx.filteredTasks || [];
				break;
			case 'task':
				if (_this.selectedTask) {
					tasks = [_this.selectedTask];
				} else {
					tasks = [];
				}
				break;
		}
		_this.summary = taskBreakdown(tasks, _this.hideArchivedTasks);
		_this.allTasks = tasks;
		return _this.allTasks;
	};

	_this.filterTasks = function (tasks) {
		if (mode === 'tasksearch') {
			return tasks;
		}
		if (_this.selectedRole) {
			tasks = _.filter(tasks, function (t) {
				return t.recipientRole[0].id === _this.selectedRole.id;
			});
			setNoTasksText(_this.selectedRole.id);
		}

		if (_this.selectedTeam) {
			if (mode === 'teaminbox' || mode === 'myinbox') {
				tasks = _.filter(tasks, function (t) {
					return t.recipientRole[0].organization.id === _this.selectedTeam.id;
				});
			} else if (mode === 'patientstasks') {
				tasks = _.filter(tasks, function (t) {
					return t.encounter && t.encounter.serviceProvider.id === _this.selectedTeam.id;
				});
			}
		}

		if (_this.searchStr) {
			tasks = _.filter(tasks, function (t) {
				return _matchesFields(t, _this.searchStr);
			});
		}

		if (_this.hideArchivedTasks) {
			tasks = _.filter(tasks, function (t) {
				return t.status !== 'completed';
			});
		}
		_this.numTasks = tasks.length;
		_this.filteredTasks = tasks;
		return _this.filteredTasks;
	};

	_this.sortTasks = function (tasks) {
		if (mode === 'tasksearch') {
			//in tasksearch view, tasks are ordered by the server
			_this.sortedTasks = tasks;
			return tasks;
		}
		tasks = _.orderBy(tasks,
		//return array of sort functions to apply
		_.transform(_this.sortOrder, function (funcs, sort) {
			funcs.push(_this.sortConfig[sort].func);
		}),
		//return array of sort directions
		_.transform(_this.sortOrder, function (dirs, sort) {
			dirs.push(_this.sortConfig[sort].dir);
		}));
		_this.sortedTasks = tasks;
		return _this.sortedTasks;
	};

	//get, filter, and sort tasks
	_this.getFilteredAndSortedTasks = function () {
		return _this.sortTasks(_this.filterTasks(_this.getTasks()));
	};

	//re-filter and sort the tasks list
	_this.filterAndSortTasks = function () {
		//_this.currentPage = 0; //reset current page when changing filters
		_this.sortedTasks = _this.sortTasks(_this.filterTasks(_this.allTasks));
	};

	_this.sortedTasks = _this.getFilteredAndSortedTasks();

	function reverseSortDirection(d) {
		_this.sortConfig[d].dir = _this.sortConfig[d].dir === 'asc' ? 'desc' : 'asc';
	}

	_this.showAuditTrail = function (task) {
		task.showActions = !task.showActions;
		// if (task.action === angular.undefined) {
		// 	task.action = [];
		// }
		if (task.showActions) {
			var events = [];
			var i = 0;
			events = _.transform(task.action, function (accum, a) {
				accum.push({
					index: i * -1,
					code: a.action.code,
					description: a.action.display,
					practitioner: a.practitioner ? AppService.displayName(a.practitioner) : null,
					target: a.targetPractitioner ? AppService.displayName(a.targetPractitioner) : null,
					targetRole: a.targetRole ? AppService.roleShortName(a.targetRole) : null,
					reason: a.reason,
					time: a.actionedAt,
					timestamp: -a.actionedAt,
					type: _.find(config.taskActions, function (ta) {
						return ta.code === a.action.code;
					}).type
				});
				i++;
			}, events);
			_.transform(task.response, function (accum, r) {
				accum.push({
					index: i * -1,
					code: 'replied',
					description: 'Reply from',
					practitioner: AppService.displayName(r.sender),
					time: r.dateSent,
					timestamp: -r.dateSent
				});
				i++;
			}, events);
			task.auditTrail = _.sortBy(events, ['timestamp', 'index']);
		}
	};

	_this.hideShowTasks = function () {
		_this.hideArchivedTasks = !_this.hideArchivedTasks;
		_this.getFilteredAndSortedTasks();
		//_this.filterAndSortTasks();
	};

	_this.newStatusDisplay = '';

	_this.status = {
		isopen: false
	};

	_this.timeAgoFormat = AppService.timeAgoFormat;

	_this.clearSearch = function () {
		_this.selectedRole = null;
		_this.selectedTeam = null;
		_this.searchStr = '';
	};

	_this.toggleTask = function (task) {
		//$log.debug('Toggling task');
		if (_this.selectedTask && _this.selectedTask.id === task.id) {
			_this.showCc[_this.selectedTask.id] = false;
			_this.selectedTask = null;
		} else {
			_this.selectTask(task);
		}
	};

	_this.unselectTask = function () {
		if (_this.selectedTask) {
			_this.selectedTask = null;
		}
	};

	_this.taskWard = function (task) {
		if (!task.encounter) {
			return void 0;
		}
		return task.overrideWard ? AppService.displayLocation('ward', task.overrideWard) : AppService.displayLocation('ward', task.encounter.location[task.encounter.location.length - 1].location.partOf);
	};

	_this.taskBed = function (task) {
		if (!task.encounter) {
			return void 0;
		}
		return task.overrideBed || AppService.displayLocation('bed', task.encounter.location[task.encounter.location.length - 1].location);
	};

	_this.filteredRoles = function () {
		var roles = [];
		switch (_this.mode) {
			case 'myinbox':case 'patientstasks':
				roles = _.sortBy(_.map(AppService.userRAs, 'role'), 'role.organization.identifier[0].value');
				break;
			case 'teaminbox':
				roles = _.sortBy(AppService.getRolesForUsersTeams(), 'organization.identifier[0].value');
				break;
		}
		return roles;
	};

	_this.roleShortName = AppService.roleShortName;

	_this.info = function () {
		if (_this.selectedTeam || _this.selectedRole) {
			_this.showInfo = false;
			return null;
		}
		var info = void 0;
		if (_this.mode === 'patientstasks' || _this.mode === 'teaminbox') {
			var t = _.transform(_this.userTeams(), function (accum, team) {
				accum.push(_this.formattedTeam(team));
			}).join(', ');
			if (t.length > 0) {
				if (_this.mode === 'patientstasks') {
					info = 'Tasks for patients in: ' + t;
				}
				if (_this.mode === 'teaminbox') {
					info = 'Tasks for: ' + t;
				}
			}
			return info;
		}
		return null;
	};

	_this.viewLabel = function () {
		if (_this.mode === 'myinbox' && _this.priorCount > 0) {
			return 'Current';
		}
		if (_this.mode === 'myinbox' && _this.priorCount === 0) {
			return 'Inbox';
		}
		if (_this.mode === 'wardtasks') {
			return AppService.getCurrentWard();
		}
		if (_this.mode === 'patientstasks') {
			return 'Tasks';
		}
		return _this.viewName;
	};

	_this.formatDescription = AppService.formatDescription;

	_this.formattedTeam = function (team) {
		return AppService.campusDisplayCode(team.partOf.location[0].id) + team.name;
	};

	_this.obsValue = function (obsType, displayCode) {
		return AppService.observationValue(_this.selectedTask, obsType, displayCode);
	};

	_this.obsTakenAt = function () {
		return moment(AppService.obsTakenAt(_this.selectedTask)).fromNow();
	};

	_this.loggedInUserSentTask = function () {
		if (!_this.selectedTask || !AppService.user) {
			return false;
		}
		return _this.selectedTask.$metadata.sender;
	};

	_this.bestContactNumber = function (contactPointArray) {
		if (!contactPointArray) {
			return null;
		}
		return AppService.formatTelecom(AppService.bestTelecom(contactPointArray));
	};

	_this.contactNumber = function (contact) {
		return AppService.formatTelecom(contact, true);
	};

	_this.addReply = function (task) {
		//task.showReply = false;
		var r = {
			message: task.newReply
		};
		TaskService.respond(task, r).then(function () {
			//_this.selectedTask.newReply = '';
		}, function (err) {
			DialogService.errorMessage('An error occurred trying to reply to this task. The task has <strong>not</strong> been updated. Please try again. If this error recurs, contact support.', err);
		});
	};

	_this.transitionLabel = function (task) {
		switch (task.status) {
			case 'sent':
				// Add 'for me' suffix for multi-recipient tasks
				if (task.recipient && task.recipient.length > 1) {
					return 'Accept task for me';
				}
				return 'Accept task';
			case 'accepted':
				if (InboxService.startRequired(task)) {
					return 'Start task';
				}
				return 'Complete task';
			case 'started':
				return 'Complete task';
			case 'completed':
				return 'Undo completion';
			default:
				LogService.error('Task was not in any of the sent, accepted, started or completed states, but attempted transition', 2, 'UnknownTaskStateError');
				return '';
		}
	};

	_this.checkboxDisabled = function (task) {
		return task.status === 'cancelled' || task.status === 'sent' || !_this.transitionEnabled(task) || task.status === 'completed' && !_this.canUndoCompletion(task);
	};

	_this.canUndoCompletion = function (task) {
		return task.$metadata.recipient || AuthService.hasAdminPriv();
	};

	_this.transitionTask = function (task) {
		// Only find and pass roleId for multi-recipient tasks
		var roleId = null;
		if (task.status === 'sent' && task.recipient && task.recipient.length > 1) {
			var _loop = function _loop(i) {
				var recipientRole = task.recipientRole[i];
				if (_.some(AppService.userRAs, function (userRA) {
					return userRA.role.id === recipientRole.id;
				})) {
					roleId = recipientRole.id;
					return 'break';
				}
			};

			// Find the matching role ID for the current user
			for (var i = 0; i < task.recipient.length; i++) {
				var _ret = _loop(i);

				if (_ret === 'break') break;
			}
		}

		// roleId will be null for single recipient tasks
		InboxService.transitionTask(task, roleId).then(function (t) {
			if (t.status === 'accepted') {
				AudioService.playSendSound();
			} else if (t.status === 'completed') {
				AudioService.playCompleteSound();
			}
			_this.selectedTask = null;
		}, function (err) {
			var msg = err.status === 409 ? '' : 'An error occurred trying to transition this task. The task has <strong>not</strong> been updated. Please try again. If this error recurs, contact support.';
			DialogService.errorMessage(msg, err);
		});
	};

	_this.acceptAndCompleteTask = function (task) {
		InboxService.acceptAndCompleteTask(task).then(function () {
			AudioService.playCompleteSound();
			_this.selectedTask = null;
		}, function (err) {
			var msg = err.status === 409 ? '' : 'An error occurred trying to transition this task. The task has <strong>not</strong> been updated. Please try again. If this error recurs, contact support.';
			DialogService.errorMessage(msg, err);
		});
	};

	_this.completeTaskForMe = function (task) {
		// Find the matching role ID for the current user
		var roleId = null;

		var _loop2 = function _loop2(i) {
			var recipientRole = task.recipientRole[i];
			if (_.some(AppService.userRAs, function (userRA) {
				return userRA.role.id === recipientRole.id;
			})) {
				roleId = recipientRole.id;
				return 'break';
			}
		};

		for (var i = 0; i < task.recipient.length; i++) {
			var _ret2 = _loop2(i);

			if (_ret2 === 'break') break;
		}

		if (roleId) {
			InboxService.completeTaskForMe(task, roleId).then(function () {
				AudioService.playCompleteSound();
				_this.selectedTask = null;
			}, function (err) {
				var msg = err.status === 409 ? '' : 'An error occurred trying to complete this task. The task has <strong>not</strong> been updated. Please try again. If this error recurs, contact support.';
				DialogService.errorMessage(msg, err);
			});
		}
	};

	_this.canCompleteTaskForMe = function (task) {
		var _loop3 = function _loop3(i) {
			var recipientRole = task.recipientRole[i];
			if (_.some(AppService.userRAs, function (userRA) {
				return userRA.role.id === recipientRole.id;
			})) {
				// Check recipient status
				if (task.recipientStatuses && task.recipientStatuses[i]) {
					var status = task.recipientStatuses[i].status;
					return {
						v: status === 'sent' || status === 'escalated' || status === 'accepted'
					};
				}
				return {
					v: true
				}; // If no status, default to showing button
			}
		};

		// Find the matching role ID for the current user
		for (var i = 0; i < task.recipient.length; i++) {
			var _ret3 = _loop3(i);

			if ((typeof _ret3 === 'undefined' ? 'undefined' : _typeof(_ret3)) === "object") return _ret3.v;
		}
		return false;
	};

	_this.displayName = AppService.displayName;

	_this.campusCode = AppService.campusDisplayCode;

	_this.taskAlerts = InboxService.taskAlerts;

	_this.unviewedAlert = InboxService.unviewedAlert;

	// if task is a multi recipient task 
	// return number of recipients - 1 (as the current user is already counted)
	// for a single recipient task return an empty string
	_this.recipientCount = function (task) {
		// $log.info('recipientCount'+ (task.recipient.length).toString());
		if (task.recipient.length > 1) {
			// convert to string to display in the UI
			return '+' + (task.recipient.length - 1).toString();
		}
		return '';
	};
	_this.addAnnotation = function () {
		var options = {
			templateUrl: 'app/inbox/add-annotation.html',
			backdrop: true,
			size: 'lg',
			scope: $scope,
			controller: 'AddAnnotationCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						annotations: InboxService.availableAnnotations(_this.selectedTask)
					};
				}
			}
		};
		_this.addAnnotationModal = $modal.open(options);
	};

	_this.cancelAddAnnotation = function () {
		_this.addAnnotationModal.dismiss();
	};

	_this.saveAnnotation = function (annotation, reason) {
		_this.addAnnotationModal.close();
		var msg = DialogService.openMessageDialog('Adding event...', '', null, { disallowClose: true });
		//Generate a 409...
		//_this.selectedTask.meta.versionId = 1;
		InboxService.annotateTask(_this.selectedTask, annotation.code, reason).then(function () {
			msg.close();
			DialogService.openMessageDialog('Event added', '', 'annotation', { duration: 1500 });
		}, function (err) {
			msg.close();
			DialogService.errorMessage('An error occurred adding the event. The task has <strong>not</strong> been updated. Please try again. If this error recurs, contact support.', err);
		});
	};

	_this.forwardTask = function () {
		var options = {
			templateUrl: 'app/inbox/forwardtask.html',
			backdrop: true,
			size: 'lg',
			scope: $scope,
			controller: 'ForwardTaskCtrl',
			controllerAs: 'fwd',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						reasons: _.filter(config.forwardReasons, function (r) {
							return !r.userTypes || _.some(r.userTypes, function (t) {
								return t === _this.selectedTask.recipientRole[0].type.code;
							});
						}),
						reasonRequired: config.forwardReasonRequired
					};
				}
			}
		};
		_this.forwardModal = $modal.open(options);
	};

	_this.cancelForward = function () {
		_this.forwardModal.dismiss();
	};

	_this.forwards = function (actions) {
		return _.filter(actions, function (a) {
			return a.action.code === 'forwarded' || a.action.code === 'reassigned';
		});
	};

	_this.forward = function (ra, reason, fromRoleId) {
		_this.forwardModal.close();
		var forwardingTaskMessage = DialogService.openMessageDialog('Forwarding task...', '', null, { disallowClose: true });
		//Generate a 409...
		//_this.selectedTask.meta.versionId = 1;
		ComposeService.forwardTask(_this.selectedTask, ra, reason, fromRoleId).then(function () {
			_.remove(ctx.tasks, function (t) {
				return t.id === _this.selectedTask.id && t.meta.versionId === _this.selectedTask.versionId;
			});
			_this.selectedTask = null;
			forwardingTaskMessage.close();
			AudioService.playSendSound();
			DialogService.openMessageDialog('Task forwarded', '', 'fwd', { duration: 1500 });
		}, function (err) {
			DialogService.errorMessage('An error occurred forwarding the task. The task has <strong>not</strong> been updated. Please try again. If this error recurs, contact support.', err);
			forwardingTaskMessage.close();
		});
	};

	_this.cancelTask = function () {
		var p = void 0;
		var options = {
			templateUrl: 'app/inbox/reason-modal.dialog.html',
			// size: 'lg',
			backdrop: true,
			controller: 'ReasonModalCtrl',
			controllerAs: 'ctrl',
			resolve: {
				modalArgs: function modalArgs() {
					return {
						reasons: _.filter(config.cancellationReasons, function (r) {
							return !r.userTypes || _.some(r.userTypes, function (t) {
								return t === _this.selectedTask.recipientRole[0].type.code;
							});
						}),
						message: 'You must provide a reason when cancelling a task',
						title: 'Cancellation Reason'
					};
				}
			}
		};

		if (config.cancellationReasonRequired) {
			p = $modal.open(options).result;
		} else {
			p = AppService.resolve();
		}
		p.then(function (reason) {
			if (!reason) {
				DialogService.openMessageDialog('Required', 'You must provide a reason when cancelling a task');
				return;
			}
			var cancelTaskModal = DialogService.openMessageDialog('Cancelling task...', '', null, { disallowClose: true });
			ComposeService.cancelTask(_this.selectedTask, reason).then(function () {
				//remove as we'll get it again via SSE
				_.remove(AppService.ctx.tasks, { 'id': _this.selectedTask.id });
				cancelTaskModal.close();
				_this.selectedTask = null;
				DialogService.openMessageDialog('Task cancelled', '', 'cancel', { duration: 1500 });
			}, function (err) {
				cancelTaskModal.close();
				DialogService.errorMessage('An error occurred cancelling the task. The task has <strong>not</strong> been updated. Please try again. If this error recurs, contact support.', err);
			});
		});
	};

	_this.hasAdminPriv = AuthService.hasAdminPriv();

	_this.hasManagerPriv = AuthService.hasManagerPriv();

	_this.hasInsightsPriv = AuthService.belongsTo('insights');

	_this.showStatus = function (s) {
		return _.find(config.taskStatus, function (ts) {
			return ts.code === s;
		}).display.toUpperCase();
	};

	_this.showDescription = function (task) {
		// cases:
		// Non patient related: code = other && patient null     - show description only
		// Other task type: code = other && patient not null     - show Other: description
		// Code task, no patient: code = !other && patient null  - show code only
		// Normal task: code != other && patient not null		 - show code only
		if (!task.serviceRequested || task.serviceRequested[0].coding[0].code === 'non-patient-related' || !task.patient && task.serviceRequested[0].coding[0].code === 'other') {
			return _this.firstChars(task.description, 200);
		}

		if (_this.clinicalTaskNoPatient(task)) {
			return task.serviceRequested[0].coding[0].display;
		}
		if (task.serviceRequested && task.serviceRequested[0].coding[0].code === 'other' && task.patient) {
			return 'Other: ' + _this.firstChars(task.description, 192);
		}
		return task.serviceRequested[0].coding[0].display;
	};

	_this.firstChars = function (str, n) {
		if (!str) return void 0; // eslint-disable-line
		// TODO: fix at task description level instead
		str = str.replace(/(\r\n|\n|\r|\&\#10;)/gm, ' ').replace(/\&gt;/g, '>').replace(/\&lt;/g, '<').replace(/\&eq;/g, '=');
		str = str.replace(/\[i]/g, '').replace(/\[\/i]/g, ''); // remove italics bbcode markup from string

		if (str.length <= n) {
			return str;
		}
		return str.slice(0, n) + '...';
	};

	_this.location = function (task) {
		var location = '';
		if (task && task.encounter) {
			var bedLoc = task.encounter.location[task.encounter.location.length - 1].location;
			var pasWard = AppService.displayLocation('ward', bedLoc.partOf);
			var pasBed = AppService.displayLocation('bed', bedLoc);
			var overrideWard = task.overrideWard ? task.overrideWard.identifier[0].value : pasWard;
			var overrideBed = task.overrideBed ? task.overrideBed : pasBed;
			location = (pasWard === overrideWard ? pasWard : overrideWard) + '-' + (pasBed === overrideBed ? pasBed : overrideBed);
		}
		return location;
	};

	_this.team = function (task) {
		var team = '';
		if (task && task.encounter) {
			if (task.overrideTeam) {
				team = AppService.campusDisplayCode(task.overrideTeam.partOf.location[0].id) + task.overrideTeam.name;
			} else {
				team = AppService.campusDisplayCode(task.encounter.serviceProvider.partOf.location[0].id) + task.encounter.serviceProvider.name;
			}
		}
		return team;
	};

	_this.isTaskRecipient = AppService.isTaskRecipient;

	_this.isTaskStateModifiable = function (task) {
		// $log.info('isTaskStateModifiable '+ task.id.toString()+' '+(task.recipient.length).toString() + ' , ', (task.recipient.length == 1).toString());
		// for now we only allow single recipient tasks to be modifiable .
		// for multi recipient task , users can only view them at this moment in time

		// enable task state modifiation for multi recipient tasks
		// only if config field 'multiRecipientTaskStateModifiable' is set to true
		if (task.recipient.length > 1) {
			return config.featureFlags.legacyWeb.multiRecipientTaskStateModifiable;
		}
		return true;
	};

	_this.showAllRecipients = function (task) {
		// $log.info('isTaskStateModifiable '+ task.id.toString()+' '+(task.recipient.length).toString() + ' , ', (task.recipient.length == 1).toString());
		// for now we only allow single recipient tasks to be modifiable .
		// for multi recipient task , users can only view them at this moment in time

		// enable task state modifiation for multi recipient tasks
		// only if config field 'multiRecipientTaskStateModifiable' is set to true
		if (task.recipient.length > 1) {
			return config.featureFlags.legacyWeb.multiRecipientShowAllRecipients;
		}
		return false;
	};

	_this.isPrimaryRecipient = function (task) {
		// Check if any of the user's roles match a primary recipient role in the task
		return _.some(task.recipient, function (recipient, index) {
			// Get the corresponding role for this recipient
			var recipientRole = task.recipientRole[index];
			// Check if user has this role
			return _.some(AppService.userRAs, function (userRA) {
				return userRA.role.id === recipientRole.id;
			});
		});
	};

	_this.transitionEnabled = function (task) {
		return task.$metadata.recipient && task.$metadata.active || ['tasksearch', 'insighttasks'].indexOf(_this.mode) > -1;
	}; //transition enabled in insights and all tasks view as well as if you're the recipient and the task is active (sent,accepted,started)

	_this.forwardEnabled = function (task) {
		return _this.transitionEnabled(task) || _this.mode === 'senttasks' || _this.mode === 'wardtasks' || !_this.myCcStatus(task, ['declined', 'sent']);
	};

	_this.showable = function (task) {
		return (task.status === 'sent' || task.status === 'accepted') && !ccDeclined(task);
	};

	_this.sortByStatus = function () {
		if (_this.sortOrder === _this.statusSort) {
			reverseSortDirection('status');
		}
		_this.sortOrder = _this.statusSort;
		//_this.currentPage = 0;
		_this.sortTasks(_this.filteredTasks);
	};

	_this.sortByUrgency = function () {
		if (_this.sortOrder === _this.urgencySort) {
			reverseSortDirection('urgency');
		}
		_this.sortOrder = _this.urgencySort;
		//_this.currentPage = 0;
		_this.sortTasks(_this.filteredTasks);
	};

	_this.sortByTime = function () {
		if (_this.sortOrder === _this.timeSort) {
			reverseSortDirection('time');
		}
		_this.sortOrder = _this.timeSort;
		//_this.currentPage = 0;
		_this.sortTasks(_this.filteredTasks);
	};

	_this.sortByLocation = function () {
		if (_this.sortOrder === _this.locationSort) {
			reverseSortDirection('ward');
			reverseSortDirection('bed');
		}
		_this.sortOrder = _this.locationSort;
		//_this.currentPage = 0;
		_this.sortTasks(_this.filteredTasks);
	};

	_this.taskFilter = function (t) {
		if (_this.searchStr) {
			return _matchesFields(t, _this.searchStr);
		}
		return true;
	};

	_this.teamFilter = function (t) {
		if (_this.selectedRole) {
			return t.id === _this.selectedRole.organization.id;
		}
		return true;
	};

	_this.roleFilter = function (r) {
		if (r && r.organization && _this.selectedTeam) {
			return r.organization.id === _this.selectedTeam.id;
		}
		return true;
	};

	_this.isNonAdmitted = function (t) {
		if (t && t.encounter && t.encounter.class) {
			return t.encounter.class === 'mt_non_admitted';
		}
		return false;
	};

	_this.nonAdmitted = function (t) {
		if (config.napDisplayString) {
			return config.napDisplayString;
		}
		return _this.taskWard(t);
	};

	//We're not using this function any more - a selected task is always displayed even if the
	//filter or sort changes
	_this.taskDisplayed = function (task, tasks) {
		return _.some(tasks, function (t) {
			return t.id === task.id && (_this.hideArchivedTasks ? t.status !== 'completed' : true);
		});
	};

	_this.customFieldLabel = function (name) {
		return _.find(config.customTaskFields, function (f) {
			return f.name === name;
		}).label;
	};

	_this.customFieldIsType = function (name, types) {
		var cf = _.find(config.customTaskFields, function (f) {
			return f.name === name;
		});
		return types.indexOf(cf.inputType) > -1;
	};

	_this.imageUrl = AppService.imageUrl;

	_this.showPhoto = function (photo) {
		var scope = $rootScope.$new();
		scope.imageUrl = config.baseUrl + photo.url;

		var modalOptions = {
			templateUrl: 'app/common/photo.modal.html',
			scope: scope,
			size: 'lg',
			/*eslint-disable */
			controller: ['$modalInstance', '$scope', function ($modalInstance, $scope) {
				$scope.close = function () {
					$modalInstance.close();
				};
				/*eslint-enable */
			}]
		};
		$modal.open(modalOptions);
	};

	_this.actionTime = function (t) {
		if (t.isAfter(moment().startOf('day'))) {
			return 'at ' + t.format('HH:mm');
		} else if (t.isAfter(moment().subtract(24, 'hours').startOf('day'))) {
			return 'yesterday';
		}
		return t.format('DD-MMM');
	};

	_this.timeFormat = function (t) {
		if (t.format('YYYY') !== moment().format('YYYY')) {
			return t.format('h:mm  A ddd, MMM DD YYYY');
		}
		return t.format('h:mm  A ddd, MMM DD');
	};
	_this.searchSorts = [{ display: 'Sent time', field: 'sent', order: 'desc' }, { display: 'Patient name', field: 'patient', order: 'asc' }, { display: 'Urgency', field: 'urgency', order: 'desc' }, { display: 'Status', field: 'status', order: 'asc' }, { display: 'Patient team', field: 'team', order: 'asc' }, { display: 'Type', field: 'type', order: 'asc' }, { display: 'Location', field: 'location', order: 'asc' }];

	_this.searchSort = _this.searchSorts[0];

	_this.pageUp = function () {
		$rootScope.$broadcast('TaskPageUp');
	};

	_this.pageDown = function () {
		$rootScope.$broadcast('TaskPageDown');
	};

	_this.pageFirst = function () {
		$rootScope.$broadcast('TaskPageFirst');
	};

	_this.pageLast = function () {
		$rootScope.$broadcast('TaskPageLast');
	};

	_this.setPageSize = function (pages) {
		AppService.ctx.pagerInfo.pageSize = pages;
		$rootScope.$broadcast('TaskRefresh');
	};

	_this.clinicalTaskNoPatient = function (t) {
		return !t.patient && t.serviceRequested[0].coding[0].code !== 'other' && t.serviceRequested[0].coding[0].code !== 'non-patient-related';
	};

	_this.otherTaskType = function (t) {
		return !t.serviceRequested || t.serviceRequested[0].coding[0].code === 'other' || t.serviceRequested[0].coding[0].code === 'non-patient-related';
	};

	_this.selectSearchSort = function (s) {
		if (_this.searchSort === s) {
			if (s.order === 'desc') {
				s.order = 'asc';
			} else {
				s.order = 'desc';
			}
		}
		_this.searchSort = AppService.ctx.pagerInfo.sort = s;
		$rootScope.$broadcast('TaskSort');
	};

	_this.pageRange = function () {
		if (!_this.pagerInfo) {
			//should not happen, but seen as an error in IE 11 (MT-2247)
			return '';
		}
		var f = (_this.pagerInfo.page - 1) * _this.pagerInfo.pageSize + 1;
		var l = f + _this.pagerInfo.pageSize - 1;
		if (l > _this.pagerInfo.total) {
			l = _this.pagerInfo.total;
		}
		if (f === l) {
			return f;
		}
		return '' + f + ' - ' + l;
	};
	_this.getNPRTLabel = function () {
		return InboxService.getNPRTLabel();
	};
}]);

angular.module('medtasker').filter(['showFirst20Chars', function () {
	return function (input) {
		if (!input) {
			return void 0;
		}
		if (input.length <= 20) {
			return input;
		}
		return input.slice(0, 20) + '...';
	};
}]);