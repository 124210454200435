'use strict';

/* eslint angular/no-private-call:0 */
/* eslint-disable no-use-before-define */
/* global angular */
angular.module('medtasker.service').factory('AppService', ['LogService', 'ResourceService', 'AuthService', 'UrlService', 'DecoratorService', 'config', '_', '$rootScope', '$timeout', '$q', 'moment', '$window', '$interval', '$sanitize', '$sce', function (log, ResourceService, AuthService, UrlService, DecoratorService, config, _, $rootScope, $timeout, $q, moment, $window, $interval, $sanitize, $sce) {
	'use strict';

	var _ctx = {
		roleAssignments: [],
		roles: [],
		locations: [],
		organizations: [],
		encounters: [],
		aggregates: [],
		shifts: [],
		patients: [],
		practitioners: [],
		tasks: [],
		claims: []
	};
	var raTimeout = void 0; //handle for timeout to change over role assignments
	var raEndWarn = void 0; //handle for timeout to warn about expiring shift
	var _client = {
		isMobile: false
	};
	var _sessionExpiry = void 0;
	var taskIncludes = '&_include=MtTask:patient\n\t&_include=MtTask:requester\n\t&_include=MtTask:recipient\n\t&_include=MtTask:recipientRole\n\t&_include=MtTask:encounter\n\t&_include=Encounter:patient\n\t&_include=Encounter:serviceProvider\n\t&_include=Encounter:episodeOfCare\n\t&_include=Encounter:location.location\n\t&_include=MtTask:action.practitioner\n\t&_include=MtTask:action.targetPractitioner\n\t&_include=MtTask:response.sender\n\t&_include=MtTask:response.photo.metaData\n\t&_include=MtTask:photo.metaData\n\t&_include=MtClinicalPhotograph:encounter\n\t&_include=MtTask:cc.practitioner\n\t&_include=MtTask:cc.role\n\t&_include=MtTask:supportingInformation';

	//return a promise immediately resolved to the provided value
	function _resolve(p) {
		var d = $q.defer();
		d.resolve(p);
		return d.promise;
	}
	//return a rejected promise with error set to provided value
	function _reject(p) {
		var d = $q.defer();
		d.reject(p);
		return d.promise;
	}

	function _formatDate(d) {
		return d.utc().format('YYYY-MM-DDTHH:mm:ss');
	}

	function _setSessionExpiry() {
		_sessionExpiry = moment().add(config.mobileMinimumSessionLengthMinutes, 'minutes');
		$window.localStorage.setItem('sessionExpiry', _sessionExpiry.format());
		log.info('Setting session expiry to ' + _sessionExpiry.format('HH:mm:ss'));
	}

	// Return the pager, or phone as code if found
	// Eg: {system: 'pager', value: '3993'}
	function _bestTelecom(telecoms, defaultSwitch, source) {
		var swtch = { system: 'switch', value: '', byDefault: true };
		swtch.value = defaultSwitch;
		if (!telecoms || telecoms.length === 0) {
			return swtch;
		}
		// Check for switch first (for when aggregate is set to 'via switch')
		for (var i = 0; i < config.preferredTelecoms.length; i++) {
			var t = _.find(telecoms, { 'system': config.preferredTelecoms[i] });
			if (t) {
				t.source = source;

				return t;
			}
		}
		return swtch;
	}

	function parseFeedData(data) {
		var d = angular.fromJson(data.data);
		return {
			id: d.id,
			model: d.model,
			resourceType: d.resource,
			deleted: !!d.deleted
		};
	}

	function _batchRequests(requests, batchSize) {
		function doBatch(batches, batchIndex, numBatches, deferred) {
			log.debug('Doing batch ' + batchIndex);
			var p = [];
			var batch = batches[batchIndex];
			_.forEach(batch, function (f) {
				if (f.name) {
					log.debug('Calling ' + f.name);
				}
				p.push(f());
			});
			$q.all(p).then(function () {
				if (batchIndex === numBatches - 1) {
					deferred.resolve();
					return;
				}
				doBatch(batches, ++batchIndex, numBatches, deferred);
			}, function (err) {
				deferred.reject(err);
			});
		}
		var deferred = $q.defer();
		var b = batchSize || 3;
		var batches = _.chunk(requests, b);
		doBatch(batches, 0, batches.length, deferred);
		return deferred.promise;
	}

	var o = {
		timezone: {},
		stateTimer: null,
		user: null,
		userRAs: [],
		userFutureRAs: [],
		userTAs: [],
		userFutureTAs: [],
		ctx: _ctx,
		client: _client,
		ready: $q.defer(),
		baseResourcesLoaded: $q.defer(),
		initInProgress: false,
		rolesLoaded: $q.defer(),
		userDataLoaded: $q.defer(),
		userDataStatus: {
			loading: false
		},
		batchRequests: _batchRequests,
		tasksStatus: {
			loaded: false
		},
		reinitCount: {
			reinitCounter: 0,
			resetCounter: 0
		},
		appStatus: {}, //all-purpose bucket for storing the status of the app
		resolve: _resolve,
		reject: _reject,
		resetReady: function resetReady() {
			o.ready = $q.defer();
		},

		// Return the initialized ctx object
		init: function init(force) {
			function handleError(msg, err, promise) {
				o.initInProgress = false;
				log.error(msg + ': ' + err, 2, 'AppServiceInitError');
				$rootScope.$broadcast('appLoadingPop', { source: 'AppInit' });
				promise.reject(err);
			}
			log.info('AppService.init called');
			var deferred = $q.defer();
			if (o.initInProgress) {
				log.info('AppService.init already in progress');
				o.ready.promise.then(function () {
					deferred.resolve(_ctx);
				}, function (err) {
					deferred.reject(err);
				});
				return deferred.promise;
			}
			if (o.ready.promise.$$state.status === 1 && !force) {
				log.info('AppService already initialized - resolving.');
				deferred.resolve(_ctx);
			} else {
				o.initInProgress = true;
				moment.updateLocale('en', {
					calendar: {
						lastDay: '[Yesterday at] LT',
						sameDay: '[Today at] LT',
						nextDay: '[Tomorrow at] LT',
						lastWeek: '[last] dddd [at] LT',
						nextWeek: 'dddd [at] LT',
						sameElse: 'DD/MM/YYYY'
					}
				});
				$rootScope.$broadcast('appLoadingPush', { source: 'AppInit' });
				o.loadBaseResources().then(function () {
					ResourceService.medtaskerProc('timezone').then(function (res) {
						o.timezone = res.data;
						moment.tz.setDefault(o.timezone.location);
					}, function (err) {
						handleError('Error getting server timezone', err.data, deferred);
					});
					if (AuthService.isAuthenticated('user')) {
						var ldapId = AuthService.userId();
						var u = o.user;
						o.resetUserRes();
						if (u) {
							o.user = { id: u.id }; // retain id as used in some scopes (inbox)
						}
						o.loadUserResources(ldapId).then(function () {
							o.initInProgress = false;
							o.userDataStatus.loading = false;
							o.tasksStatus.loaded = true;
							o.ready.resolve('finished AppService init');
							$rootScope.$broadcast('appLoadingPop', { source: 'AppInit' });
							$rootScope.$broadcast('appInitComplete');
							deferred.resolve(_ctx);
						}, function (err) {
							handleError('Error loading user resources', err.data, deferred);
						});
					} else if (AuthService.isAuthenticated('ward')) {
						o.initInProgress = false;
						$rootScope.$broadcast('appLoadingPop', { source: 'AppInit' });
						deferred.resolve(_ctx); // return the ctx
					} else {
						handleError('User not authenticated during AppService.init() ', '', deferred);
					}
				}, function (err) {
					handleError('Error loading base resources during AppService.init() ', err.data, deferred);
				});
			}
			return deferred.promise;
		},
		// Clear all resources except for orgs, locations and roles.
		// Those are read on app init and kept.
		clearContext: function clearContext() {
			var tempCtx = _ctx;
			o.ctx = _ctx = {
				roleAssignments: [],
				roles: [],
				locations: [],
				organizations: [],
				encounters: [],
				aggregates: [],
				shifts: [],
				patients: [],
				practitioners: [],
				tasks: []
			};
			_ctx.organizations = tempCtx.organizations;
			_ctx.locations = tempCtx.locations;
			_ctx.roles = tempCtx.roles;
			_ctx.aggregates = tempCtx.aggregates;
			_ctx.shifts = tempCtx.shifts;
			ResourceService.clearCache();
			o.tasksStatus.loaded = false;
		},

		resetUserRes: function resetUserRes() {
			o.user = null;
			o.userRAs.length = 0;
			o.userFutureRAs.length = 0;
			o.userTAs.length = 0;
			o.userFutureTAs.length = 0;
		},

		login: function login(credentials, ward) {
			var deferred = $q.defer();
			AuthService.login(credentials, ward, _client.isMobile).then(function () {
				if (_client.isMobile) {
					//set the  minimum session length if using a mobile device
					_setSessionExpiry();
				}
				deferred.resolve();
			}, function (err) {
				deferred.reject(err);
			});
			return deferred.promise;
		},

		logout: function logout(type) {
			//clear context data before AuthService.logout so that context has been cleared before we broadcast the logout
			o.resetUserRes();
			o.clearContext();
			if (o.appStatus.initTimeout) {
				//on mobile cancel any app init retries
				$timeout.cancel(o.appStatus.initTimeout);
			}
			if (raTimeout) {
				$window.clearTimeout(raTimeout);
			}
			if (raEndWarn) {
				$window.clearTimeout(raEndWarn);
			}
			AuthService.logout(type);
		},

		getUser: function getUser() {
			// Sanity check
			if (o.user && !AuthService.isAuthenticated('user')) {
				var errorMsg = 'A user is stored but not authenticated';
				log.error(errorMsg, 2, 'GetUserError');
				return null;
			}
			return o.user;
		},

		get: function get(resName, id, version) {
			return ResourceService.get(_ctx, resName, id, version);
		},

		search: function search(resName, criteria, noIndex) {
			var deferred = $q.defer();
			ResourceService.search(_ctx, resName, criteria, noIndex).then(function (data) {
				deferred.resolve(data);
			}, function (err) {
				return deferred.reject(err);
			});
			return deferred.promise;
		},

		create: function create(res) {
			var deferred = $q.defer();
			ResourceService.create(_ctx, res).then(function () {
				res.$deserialized = true;
				ResourceService.addToContext(_ctx, res).then(function () {
					deferred.resolve(res);
				}, function (err) {
					return deferred.reject(err);
				});
			}, function (err) {
				deferred.reject(err);
			});
			return deferred.promise;
		},

		//If retryCondition returns true or is falsey, and retryFunction is supplied, call retryFunction on a 409 (conflict). retryFunction must return a promise
		update: function update(res, retryCondition, retryFunction) {
			var retries = 0;
			var MAX_RETRIES = 3;
			var deferred = $q.defer();
			ResourceService.update(_ctx, res).then(function () {
				deferred.resolve(res);
			}, function (err) {
				if (err.status === 409 && retryFunction) {
					log.error('409 conflict! We\'ll see if we can retry...');
					o.get(res.resourceType, res.id).then(function (r) {
						if ((!retryCondition || retryCondition(r, res)) && retries <= MAX_RETRIES) {
							log.info('Retry condition was met');
							retries++;
							retryFunction(r).then(function (r2) {
								log.info('Conflict resolved');
								deferred.resolve(r2);
							}, function () {
								log.info('Failed to resolve conflict');
								deferred.reject(err);
							});
						} else {
							log.info('Retry condition not met. Returning error');
							deferred.reject(err);
						}
					}, function (err2) {
						deferred.reject(err2);
					});
				} else {
					deferred.reject(err);
				}
			});
			return deferred.promise;
		},

		delete: function _delete(res) {
			return ResourceService.delete(_ctx, res);
		},

		imageUrl: function imageUrl(i) {
			if (i && i.url) {
				return UrlService.baseUrl() + i.url;
			}
			return null;
		},

		loadBaseResources: function loadBaseResources() {
			log.info('Loading base resources');
			if (o.baseResourcesLoaded.promise.$$state.status === 1) {
				//log.debug('loadBaseResources returning already loaded resources');
				return o.baseResourcesLoaded.promise;
			}
			return o.loadLoginLocations().then(function () {
				return ResourceService.search(_ctx, 'Organization', 'active=true').then(function () {
					o.baseResourcesLoaded.resolve();
				});
			});
		},

		loadRoles: function loadRoles() {
			log.info('Loading MtRoles');
			if (o.rolesLoaded.promise.$$state.status === 1) {
				return o.rolesLoaded.promise;
			}
			return ResourceService.search(_ctx, 'MtRole').then(function () {
				o.rolesLoaded.resolve('MtRoles loaded');
			});
		},

		loadOrgsAndRoles: function loadOrgsAndRoles() {
			log.info('Loading orgs and roles');
			return ResourceService.search(_ctx, 'Organization', 'active=true').then(function () {
				return ResourceService.search(_ctx, 'MtRole');
			});
		},

		formatDate: _formatDate,

		getOrgsByName: function getOrgsByName(orgName) {
			var criteria = 'name=' + orgName;
			return ResourceService.search(_ctx, 'Organization', criteria);
		},

		parseFeedData: parseFeedData,

		//Set time outs to reload the role assignments from the server when they expire or commence
		setRoleAssignmentTimerEvents: function setRoleAssignmentTimerEvents(ras) {
			if (ras.length === 0) {
				return;
			}
			log.info('Setting role assignments timer');
			var changeoverTimes = [];
			//cancel any previous existing timeout
			if (raTimeout) {
				$window.clearTimeout(raTimeout);
				$window.clearTimeout(raEndWarn);
			}

			var now = moment();
			_.forEach(ras, function (ra) {
				var t = {};
				if (ra.period.start.isAfter(now)) {
					t.time = ra.period.start;
					t.type = 'start';
				} else if (ra.period.end.isAfter(now)) {
					t.time = ra.period.end;
					t.type = 'end';
				}
				if (t.time) {
					t.delay = t.time.diff(now); //time between now and shift start or end in milliseconds
					t.ra = ra;
					//time field only included for easy debugging. TODO: remove when stable
					changeoverTimes.push(t);
				}
			});
			var firstTime = _.minBy(changeoverTimes, function (t) {
				return t.delay;
			});
			//Warn 30 minutes before end of my shift
			var nextExpiry = void 0;
			if (o.user) {
				nextExpiry = _.minBy(_.filter(changeoverTimes, function (t) {
					return t.type === 'end' && t.ra.practitioner.id === o.user.id;
				}), function (t) {
					return t.delay;
				});
			}

			if (firstTime) {
				raTimeout = $window.setTimeout(function () {
					var verb = firstTime.type === 'start' ? 'started' : 'finished';
					log.info('A role assignment ' + verb + '. Reloading...');
					$rootScope.$broadcast('roleAssignmentChangeOver', firstTime.ra);
					$rootScope.$emit('ReloadUserData', 'A shift ' + verb + '. Getting new role data...');
				}, firstTime.delay + Math.random() * 20000, 1); //add a random offset up to 20 seconds to prevent all clients hammering the server at the same time
			}
			if (nextExpiry && nextExpiry.time.isAfter(moment().add(30, 'minutes'))) {
				raEndWarn = $window.setTimeout(function () {
					log.info('User\s role assignment will expire in 30 mins.');
					$rootScope.$broadcast('roleAssignmentExpiryWarning', nextExpiry.ra);
				}, nextExpiry.delay - 30 * 60 * 1000, 1); //subtract 30 minutes from time shift ends
			}
		},

		loadAllTasks: function loadAllTasks() {
			log.debug('loadAllTasks', false, false, true);
			var criteria = taskIncludes;

			//return ResourceService.search(_ctx, 'MtTask', criteria);
			var deferred = $q.defer();
			ResourceService.search(_ctx, 'MtTask', criteria).then(function (d) {
				deferred.resolve(d);
				log.debug('loadAllTasks success', false, false, true);
			}, function (e) {
				log.error('loadAllTasks error: ' + e);
				deferred.reject(e);
			});
			return deferred.promise;
		},

		loadIncompleteTasks: function loadIncompleteTasks() {
			log.debug('loadIncompleteTasks', false, false, true);
			var deferred = $q.defer();
			o.ready.promise.then(function () {
				var criteria = 'status=sent%2Caccepted%2Cstarted' + taskIncludes;

				// Res
				ResourceService.search(_ctx, 'MtTask', criteria).then(function (d) {
					deferred.resolve(d);
					log.debug('loadIncompleteTasks success', false, false, true);
				}, function (e) {
					log.error('loadIncompleteTasks error: ' + e);
					deferred.reject(e);
				});
			});
			return deferred.promise;
		},

		loadUserResources: function loadUserResources(ldapId) {
			log.debug('loadUserResources', false, false, true);
			var params = {
				wardid: $window.localStorage.getItem('medtasker.wardId')
			};
			return ResourceService.medtaskerProc('load-user-resources', params, true, true).then(function (r) {
				return ResourceService.registerArray(_ctx, r.data).then(function () {
					o.setUserData(ldapId);
					o.setRoleAssignmentTimerEvents(_ctx.roleAssignments);
					$rootScope.$broadcast('teamDataLoading');
					ResourceService.medtaskerProc('load-team-resources', null, true, true).then(function (tr) {
						ResourceService.registerArray(_ctx, tr.data).then(function () {
							o.setUserTeamData();
						});
					});
				});
			});
		},

		setUserData: function setUserData(ldapId) {
			o.user = _.find(_ctx.practitioners, function (p) {
				return _.some(p.identifier, function (id) {
					return id.value.toLowerCase() === ldapId.toLowerCase();
				});
			});
			o.userRAs.length = 0;
			o.userFutureRAs.length = 0;
			_.forEach(_ctx.roleAssignments, function (ra) {
				if (ra.practitioner.id === o.user.id && ra.period.start.isBefore(moment()) && ra.period.end.isAfter(moment())) {
					//ignore included overridden RAs
					o.userRAs.push(ra);
				} else if (ra.practitioner.id === o.user.id && ra.period.start.isAfter(moment())) {
					o.userFutureRAs.push(ra);
				}
			});
			o.userTAs.length = 0;
			_.forEach(_ctx.teamAssignments, function (ta) {
				if (ta.practitioner.id === o.user.id && ta.period.start.isBefore(moment()) && ta.period.end.isAfter(moment())) {
					//ignore included overridden RAs
					o.userTAs.push(ta);
				} else if (ta.practitioner.id === o.user.id && ta.period.start.isAfter(moment())) {
					o.userFutureRAs.push(ta);
				}
			});
			_ctx.$userData = {
				userId: o.user.id,
				userRoleIds: _.map(o.userRAs, 'role.id'),
				userTeamIds: o.getUserTeamIds(),
				userEncounterIds: o.getUsersEncounterIds(),
				ward: $window.localStorage.getItem('medtasker.wardId')
			};
			DecoratorService.decorateTasks(_ctx.tasks, _ctx); //necessary because on initial load, we do not have this info at deserialization time.
			$rootScope.$broadcast('userLoaded');
		},

		setUserTeamData: function setUserTeamData() {
			// a bit inefficient to redecorate all the tasks, but probably not significant. TODO: optimise to only decorate the team tasks?
			_ctx.$userData.userEncounterIds = o.getUsersEncounterIds();
			DecoratorService.decorateTasks(_ctx.tasks, _ctx);
			$rootScope.$broadcast('teamDataLoaded');
		},

		loadAllPreMetOrHigherTasksSince: function loadAllPreMetOrHigherTasksSince(time) {
			var timeParam = _formatDate(time);
			var criteria = 'priority=premet,met,codeblue\n\t\t\t&_lastUpdated=>=' + timeParam + '\n\t\t\t' + taskIncludes;
			return ResourceService.search(_ctx, 'MtTask', criteria);
		},

		loadRoleAssignmentsForViewPeriod: function loadRoleAssignmentsForViewPeriod(vpStart, vpEnd, teamName, cache) {
			// Query 1: y = GET all roles for team x
			// Query 2: GET all RAs where roles == y for viewPeriod
			// if RAs have aggregate, add to array z
			// Query 3: GET all RAs where aggregate == z for viewPeriod
			var deferred = $q.defer();
			var team = void 0;
			var rids = void 0;
			if (teamName) {
				team = _.find(_ctx.organizations, function (t) {
					return t.identifier[0].value === teamName;
				});
				//Q1
				var roles = _.filter(_ctx.roles, function (role) {
					return role.organization.id === team.id;
				});
				rids = _.map(roles, 'id').join(',');
			}

			//Q2
			var includeClause = '&_include=MtRoleAssignment:practitioner\n\t\t\t&_include=MtRoleAssignment:role\n\t\t\t&_include=MtRoleAssignment:override\n\t\t\t&_include=MtRoleAssignment:shift\n\t\t\t&_include=MtRoleAssignment:aggregate\n\t\t\t&_include=MtRole:organization\n\t\t\t&_include=MtShift:aggregate';
			// We need all the RA's covering the one day before and after the view period
			// to cover the case where some rshifts partially overlap with the ViewPeriod
			var start = _formatDate(moment(vpStart).utc().subtract(1, 'day'));
			var end = _formatDate(moment(vpEnd).utc().add(1, 'day'));
			var timeClause = 'period=>' + start + '&period=<' + end;
			var criteria = (rids ? 'role:MtRole=' + rids + '&' : '') + timeClause + includeClause;
			ResourceService.search(_ctx, 'MtRoleAssignment', criteria, false, cache).then(function (ras) {
				var aggs = _.transform(ras, function (accum, ra) {
					if (ra.aggregate) {
						accum.push(ra.aggregate);
					}
				});
				if (aggs.length > 0) {
					var aggRaids = _.map(aggs, 'id').join();
					//Q3
					criteria = 'aggregate:MtAggregate=' + aggRaids + '&' + timeClause + includeClause;
					ResourceService.search(_ctx, 'MtRoleAssignment', criteria, false, cache).then(function (aggRas) {
						_.forEach(aggRas, function (ara) {
							if (!_.some(ras, function (ra) {
								return ra.id === ara.id;
							})) {
								ras.push(ara);
							}
						});
						deferred.resolve(ras);
					});
				} else {
					deferred.resolve(ras);
				}
			}, function (err) {
				log.error('Error retrieving role assignments for view period: ' + angular.toJson(err), 2, 'LoadRoleAssignmentsForViewPeriodError');
				deferred.reject(err);
			});
			return deferred.promise;
		},

		loadTeamAssignmentsForViewPeriod: function loadTeamAssignmentsForViewPeriod(vpStart, vpEnd) {
			// We need all the TA's covering the one day before and after the view period
			// to cover the case where some rshifts partially overlap with the ViewPeriod
			var start = _formatDate(moment(vpStart).utc().subtract(1, 'day'));
			var end = _formatDate(moment(vpEnd).utc().add(1, 'day'));
			var criteria = '_include=MtTeamAssignment:practitioner' + '&period=>' + start + '&period=<' + end;
			return ResourceService.search(_ctx, 'MtTeamAssignment', criteria);
		},

		loadAllPractitioners: function loadAllPractitioners() {
			return ResourceService.search(_ctx, 'Practitioner');
		},

		loadAllLocations: function loadAllLocations() {
			return ResourceService.search(_ctx, 'Location');
		},

		loadLoginLocations: function loadLoginLocations() {
			var criteria = 'physicalType.coding=wi,bu&_include=Location:partOf';
			return ResourceService.search(_ctx, 'Location', criteria);
		},

		loadRoleGroups: function loadRoleGroups() {
			return ResourceService.search(_ctx, 'MtRoleGroup');
		},

		loadShifts: function loadShifts(schedules, teams, start, end, cache) {
			if (!teams) {
				return _resolve([]);
			}
			if (!angular.isArray(teams)) {
				teams = [teams];
			}
			var q = ['_medtasker_mode=without_index'];
			var bq = [];
			if (start || end) {
				var st = _formatDate(start);
				if (!end) {
					start = end;
				}
				var et = _formatDate(end);
				bq.push('validPeriod=>' + st);
				bq.push('validPeriod=<' + et);
			}
			if (schedules) {
				bq.push('schedule=' + schedules.join());
			}
			var rids = o.getRoleIdsForTeams(_.map(teams, 'id'));
			q.push('_include=MtShift:aggregate');
			if (rids.length > 0) {
				q.push('role=' + rids.join());
			} else {
				return _resolve([]);
			}

			return ResourceService.search(_ctx, 'MtShift', bq.concat(q).join('&'), true, cache).then(function (shifts) {
				var aggs = _.transform(shifts, function (accum, s) {
					if (s.aggregate) {
						accum.push(s.aggregate.id);
					}
				}, []);
				if (aggs.length > 0) {
					bq.push('aggregate=' + aggs.join());
					return ResourceService.search(_ctx, 'MtShift', bq.join('&'), true, cache);
				}
				return o.resolve();
			});
		},

		loadAllShifts: function loadAllShifts(schedules, cache) {
			var today = _formatDate(moment().startOf('day').add(1, 'seconds'));
			var criteria = 'validPeriod=>' + today + '&validPeriod=<' + today;
			if (schedules && schedules.length > 0) {
				criteria = criteria + '&schedule=' + schedules.join(',');
			} else if (!cache) {
				_ctx.shifts = [];
			}
			criteria = criteria + '&_include=MtShift:aggregate';
			return ResourceService.search(_ctx, 'MtShift', criteria, true, cache);
		},

		loadShiftsForAggregate: function loadShiftsForAggregate(aggregateId, cache) {
			return ResourceService.search(_ctx, 'MtShift', 'aggregate=' + aggregateId, true, cache);
		},

		loadAllShiftAggregates: function loadAllShiftAggregates(schedules, cache) {
			var criteria = 'type=mtShiftAggregate';
			if (schedules && schedules.length > 0) {
				criteria = criteria + '&schedule=' + schedules.join(',');
			} else if (!cache) {
				_ctx.aggregates = [];
			}
			return ResourceService.search(_ctx, 'MtAggregate', criteria, false, cache);
		},

		loadCompletedTasksSinceYesterday: function loadCompletedTasksSinceYesterday() {
			// Return all MtTasks for the user in the last 24hrs
			log.debug('loadCompletedTasksSinceYesterday', false, false, true);
			var yesterday = _formatDate(moment().startOf('day').subtract(1, 'day'));

			var criteria = 'status=completed%2Ccancelled&dateSent=>=' + yesterday + taskIncludes;

			var deferred = $q.defer();
			ResourceService.search(_ctx, 'MtTask', criteria).then(function (d) {
				deferred.resolve(d);
				log.debug('loadCompletedTasksSinceYesterday success', false, false, true);
			}, function (e) {
				log.error('loadCompletedTasksSinceYesterday error: ' + e);
				deferred.reject(e);
			});
			return deferred.promise;
		},

		// HACK: to switch to o._loadTeamAssignmentsForViewPeriod
		loadAllTeamAssignments: function loadAllTeamAssignments() {
			return ResourceService.search(_ctx, 'MtTeamAssignment', '_include=MtTeamAssignment:practitioner');
		},

		loadAllRotations: function loadAllRotations() {
			return ResourceService.search(_ctx, 'MtRotation');
		},

		fetchPractitioners: function fetchPractitioners(query) {
			// normalize for the server search
			query = query.toLowerCase();
			query = 'name=' + query.split(' ').join('&name=') + '&_count=20';

			var deferred = $q.defer();
			o.search('Practitioner', query).then(function (resArray) {
				_ctx.filteredPractitioners = resArray;
				deferred.resolve(resArray);
			}, function (err) {
				deferred.reject(err);
			});
			return deferred.promise;
		},

		fetchWards: function fetchWards(query) {
			// normalize for the server search
			query = query.toLowerCase();
			query = 'name=' + query.split(' ').join('&name=') + '&physicalType=wi';

			var deferred = $q.defer();
			o.search('Location', query).then(function (resArray) {
				_ctx.filteredWards = resArray;
				deferred.resolve(resArray);
			}, function (err) {
				deferred.reject(err);
			});
			return deferred.promise;
		},

		noObservation: function noObservation(obs) {
			if (!obs) {
				return true;
			}
			var keys = _.keys(obs);

			for (var i = 0; i < keys.length; i++) {
				if (obs[keys[i]] !== null) {
					return false;
				}
			}
			return true;
		},

		requestCover: function requestCover(practitioner, period, roleAssignments, note) {
			var req = ResourceService.newOverrideRequest(o.user, practitioner, period, roleAssignments, note);
			return o.create(req);
		},

		filterTasksByRoleId: function filterTasksByRoleId(tasks, roleId) {
			return _.filter(tasks, function (t) {
				// Check if any recipient role matches the provided roleId
				return _.some(t.recipientRole, function (role) {
					return role.id === roleId;
				});
			});
		},

		filterEncountersByTeamId: function filterEncountersByTeamId(encs, teamId) {
			return _.filter(encs, function (e) {
				return e.serviceProvider.id === teamId;
			});
		},

		escalationProfileForTaskType: function escalationProfileForTaskType(c) {
			var pn = c.escalationProfileName;
			return _.find(config.escalationProfiles, function (p) {
				return p.name === pn;
			});
		},

		addTaskAction: function addTaskAction(task, actionCode, target, targetRole, reason) {
			var a = _.find(config.taskActions, { 'code': actionCode });
			if (!a) {
				throw new Error('unknown action code');
			}
			task.action = task.action || [];
			var action = {
				action: a,
				practitioner: o.user,
				targetPractitioner: target,
				targetRole: targetRole,
				actionedAt: moment()
			};
			if (reason) {
				action.reason = reason;
			}
			task.action.push(action);
		},

		reloadUserData: function reloadUserData(ldapId) {
			//if there's an outstanding user data reload request, return the existing promise
			if (o.userDataStatus.loading) {
				log.debug('Already reloading user data, returning existing promise');
				return o.userDataLoaded.promise;
			}
			o.userDataStatus.loading = true;
			o.tasksStatus.loaded = false; //legacy of previous implementation where there were more promises in the chain. TODO: check if we can refactor this away.
			o.userDataLoaded = $q.defer();
			o.clearContext();
			o.loadUserResources(ldapId).then(function () {
				o.tasksStatus.loaded = true;
				o.userDataStatus.loading = false;
				$rootScope.$broadcast('userDataLoaded');
				//TODO: review the use of these flags (ready, userDataLoaded etc). It's messy and unclear where and how they're being used.
				//o.ready.resolve();
				o.userDataLoaded.resolve();
			}, function () {
				o.userDataLoaded.reject();
			});
			return o.userDataLoaded.promise;
		},

		//When user session restored in mobile app, we need to get some resources...
		restoreUserResources: function restoreUserResources() {
			config.preinit(); //restore base Url and any other settings we have persisted locally
			return config.init().then(function () {
				return o.init('force');
			});
		},

		loadUsersEncounters: function loadUsersEncounters() {
			var tids = o.getUserTeamIds();
			if (tids.length > 0) {
				return o.search('Encounter', 'serviceProvider=' + tids.join() + '&status=in-progress&_include=Encounter:patient&_include=Encounter:location.location&_include=Encounter:episodeOfCare');
			}
			var deferred = $q.defer();
			deferred.resolve([]);
			return deferred.promise;
		},

		roleAssignmentsForShift: function roleAssignmentsForShift(shiftId) {
			var criteria = 'shift:MtShift=' + shiftId + '\n\t\t\t&_include=MtRoleAssignment:practitioner\n\t\t\t&_include=MtRoleAssignment:role\n\t\t\t&_include=MtRoleAssignment:override\n\t\t\t&_include=MtRoleAssignment:shift\n\t\t\t&_include=MtRoleAssignment:aggregate';
			return ResourceService.search(_ctx, 'MtRoleAssignment', criteria);
		},

		checkConnect: function checkConnect() {
			return ResourceService.ping();
		},

		configMap: function configMap(configArrayName, valueField, value, mapField) {
			if (!value) {
				return null;
			}
			var item = _.find(config[configArrayName], function (i) {
				return i[valueField] === value;
			});
			if (item) {
				return item[mapField];
			}
			var errorMsg = 'Error finding item on ' + configArrayName + ' with ' + valueField + '=' + value;
			log.error(errorMsg, 2, 'ConfigMapError');
			return 'error';
		},

		switchForCampusFromCampusID: function switchForCampusFromCampusID(teamCampusUUID) {
			var campusId = o.campusCode(teamCampusUUID);
			if (typeof config.tapToDialSwitchBoardNumber === 'undefined') {
				return '';
			}
			if (campusId in config.tapToDialSwitchBoardNumber) {
				var switchForTheCampus = config.tapToDialSwitchBoardNumber[campusId];
				if (typeof switchForTheCampus === 'undefined') {
					return '';
				}
				if (switchForTheCampus.length === 0) {
					return '';
				}
				if (typeof switchForTheCampus[0].switchnumber === 'undefined') {
					return '';
				}
				return switchForTheCampus[0].switchnumber;
			}
			return '';
		},

		// Return the correct pager or phone number telecom for a role assignment
		// Eg: telecom = {system: 'pager', value: '1239'}
		telecomForRoleAssignment: function telecomForRoleAssignment(ra, aggRShifts) {
			//console.log("telecomForRoleAssignment", ra)
			//const campusId = o.campusCode(ra.role.organization.partOf.location[0].id)
			//let switchForTheCampus = config.tapToDialSwitchBoardNumber[campusId]
			//const switchForTheCampus = o.switchForCampusFromRole(ra.role)
			var switchForTheCampus = o.switchForCampusFromCampusID(ra.role.organization.partOf.location[0].id);
			//console.log("telecomForRoleAssignment ",switchForTheCampus)
			// return an array of telecoms. If more than one, it means there's a conflict and the algorithm can't decide
			// which one is best.
			var telecoms = [];
			// telecoms.push({system: 'switch', value: switchForTheCampus[0].switchnumber, byDefault: true})
			if (ra.aggregate) {
				// Return the contact from the aggregate if one exists
				if (ra.aggregate.telecom && ra.aggregate.telecom.length > 0) {
					telecoms.push(_bestTelecom(ra.aggregate.telecom, switchForTheCampus));
				} else {
					_.forEach(aggRShifts, function (rs) {
						telecoms.push(_bestTelecom(rs.shift.role.telecom, switchForTheCampus, o.roleShortName(rs.shift.role)));
					});
					telecoms.push(_bestTelecom(ra.role.telecom, switchForTheCampus, o.roleShortName(ra.role)));
					telecoms = _.uniqBy(telecoms, function (t) {
						return t.system + t.value;
					});
				}
			} else if (ra.role.telecom && ra.role.telecom.length > 0) {
				telecoms.push(_bestTelecom(ra.role.telecom, switchForTheCampus, o.roleShortName(ra.role)));
			} else {
				telecoms.push(_bestTelecom(ra.practitioner.telecom, switchForTheCampus, o.displayName(ra.practitioner)));
			}
			return telecoms;
		},

		isContactLink: function isContactLink(t) {
			t = t.replace(/\s+|x/g, '');
			var found = config.tapToDialPrefixes.find(function (element) {
				return element.digits === t.length;
			});
			if (t.startsWith('+')) {
				return true;
			}
			return !t.startsWith('#') && typeof found !== 'undefined';
		},

		contactLink: function contactLink(t) {
			if (!t.startsWith('#') && t.length >= 1) {
				//ignore the x value prefixed by other parts of the code
				//TODO need to have a discuss with team on how to solve this issue in proper way
				t = t.replace(/\s+|x/g, '');
				if (typeof config.tapToDialPrefixes !== 'undefined') {
					var found = config.tapToDialPrefixes.find(function (element) {
						return element.digits === t.length;
					});
					if (typeof found !== 'undefined') {
						switch (found.transformType) {
							case 'removeZeroAddPrefix':
								if (t[0] === '0') {
									t = found.transformation + t.slice(1);
								}
								break;
							case 'addPrefix':
								t = found.transformation + t;
								break;
						}
					}
				}
				return 'tel:' + t;
			}
			return '';
		},

		formatTelecom: function formatTelecom(tel, includeSystemName) {
			if (!tel) {
				return '';
			}
			var ret = includeSystemName ? o.capitalizeFirstLetter(tel.system) + ' ' : '';
			switch (tel.system) {
				case 'pager':
					ret = ret + '#' + tel.value;
					break;
				case 'phone':
				case 'switch':
					// if (tel.system == 'switch' && tel.value == undefined) {
					// 	return '(via switch)'
					// }
					if (tel.value.length === 5) {
						ret = ret + 'x' + tel.value; // extension
					} else if (tel.value.length === 10) {
						ret = ret + tel.value.substring(0, 4) + ' ' + tel.value.substring(4, 7) + ' ' + tel.value.substring(7, 10);
					} else {
						ret = ret + tel.value;
					}
					break;
			}
			return ret;
		},

		formatDateForQuery: _formatDate,

		timeAgoFormat: function timeAgoFormat(t) {
			if (!t) {
				return '';
			}
			var ago = moment.duration(moment().diff(t));
			switch (true) {
				case ago.asMinutes() <= 60:
					return t.fromNow();
				case t.isSame(moment(), 'day'):
					return 'Today at ' + t.format('h:mm A');
				case ago.asDays() === 1:
					return 'Yesterday at ' + t.format('h:mm A');
				default:
					return t.format('ddd, DD MMM') + ' at ' + t.format('h:mm A');
			}
		},

		bestTelecom: _bestTelecom,

		formatDescription: function formatDescription(d) {
			if (!d) {
				return '';
			}
			d = $sanitize(d);
			d = d.replace(/&#10;/g, '<br>'); //newline becomes &#10; after sanitization
			d = d.replace(/\[i\]/, '<i>');
			d = d.replace(/\[\/i\]/, '</i>');
			return $sce.trustAsHtml(d);
		},

		capitalizeFirstLetter: function capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},

		sessionExpired: function sessionExpired() {
			if (!_sessionExpiry) {
				var s = $window.localStorage.getItem('sessionExpiry');
				if (s) {
					_sessionExpiry = moment(s);
				} else {
					return true;
				}
			}
			return _sessionExpiry.isBefore(moment());
		},

		formatDuration: function formatDuration(duration) {
			var hours = duration.days() * 24 + duration.hours();
			return sprintf('%02d:%02d', hours, duration.minutes()); // eslint-disable-line
		},

		wrapDuration: function wrapDuration(d) {
			if (d.hours() === 24) {
				d.add(1, 'days').subtract(24, 'hours');
			}
			return d.days() !== 0 ? moment.duration(d).subtract(d.days(), 'days') : d.hours() < 0 ? moment.duration(d).add(1, 'day') : d;
		},

		displayFromCode: function displayFromCode(configArrayName, code) {
			return this.configMap(configArrayName, 'code', code, 'display');
		},

		roleFullName: function roleFullName(role) {
			return (config.multiCampus ? o.campusDisplayCode(role.organization.partOf.location[0].id) : '') + role.organization.name + ' ' + role.responsibility.display + ' ' + (role.level.display !== role.responsibility.display ? '(' + role.level.display + ')' : '');
		},

		roleShortName: function roleShortName(role) {
			if (!role) {
				return '';
			}
			return (config.multiCampus ? o.campusDisplayCode(role.organization.partOf.location[0].id) : '') + role.organization.name + ' ' + role.responsibility.display + ' ' + (role.level.display !== role.responsibility.display ? role.level.display : '');
		},

		patientName: function patientName(patient) {
			return patient.name[0].given[0] + ' ' + patient.name[0].family[0];
		},

		isTouchDevice: function isTouchDevice() {
			var touch = 'ontouchstart' in $window || navigator.msMaxTouchPoints; // eslint-disable-line
			return touch;
		},

		getCurrentWard: function getCurrentWard() {
			return AuthService.getCurrentWard();
		},

		getUserTeamIds: function getUserTeamIds() {
			return _.union(_.map(o.userRAs, 'role.organization.id'), _.map(o.userTAs, 'organization.id'));
		},

		//Get all encounterIds for patients who are cared for by any team the user is assigned to
		getUsersEncounterIds: function getUsersEncounterIds() {
			var encounters = o.getUsersEncounters();
			return _.map(encounters, 'id');
		},

		getPrivateEncounters: function getPrivateEncounters() {
			var encounters = _.filter(o.getUsersEncounters(), function (e) {
				return e.type && e.type.code !== 'MP';
			});
			return encounters;
		},

		getUsersEncounters: function getUsersEncounters() {
			var tids = o.getUserTeamIds();
			var encounters = _.filter(_ctx.encounters, function (e) {
				return _.some(tids, function (tid) {
					return e.status === 'in-progress' && e.serviceProvider.id === tid;
				});
			});
			return encounters;
		},

		getRoleIdsForTeams: function getRoleIdsForTeams(tids) {
			return _.transform(_ctx.roles, function (rids, role) {
				if (_.some(tids, function (tid) {
					return role.organization.id === tid;
				})) {
					rids.push(role.id);
				}
			}, []);
		},

		getRolesForUsersTeams: function getRolesForUsersTeams() {
			var tids = o.getUserTeamIds();
			return _.transform(_ctx.roles, function (roles, role) {
				if (_.some(tids, function (tid) {
					return role.organization.id === tid;
				})) {
					roles.push(role);
				}
			}, []);
		},

		getTask: function getTask(id, noIndex) {
			var criteria = '_id=' + id + taskIncludes;
			return ResourceService.search(_ctx, 'MtTask', criteria, noIndex);
		},

		inboxTasks: function inboxTasks() {
			return _.filter(_ctx.tasks, function (t) {
				return t.$metadata && t.$metadata.inbox;
			});
		},

		teamInboxTasks: function teamInboxTasks() {
			return _.filter(_ctx.tasks, function (t) {
				return t.$metadata && t.$metadata.teamInbox;
			});
		},

		sentTasks: function sentTasks() {
			return _.filter(_ctx.tasks, function (t) {
				return t.$metadata && t.$metadata.sentItems;
			});
		},

		patientsTasks: function patientsTasks() {
			return _.filter(_ctx.tasks, function (t) {
				return t.$metadata && t.$metadata.patientsTasks;
			});
		},

		wardTasks: function wardTasks() {
			return _.filter(_ctx.tasks, function (t) {
				return t.$metadata && t.$metadata.wardTasks;
			});
		},

		systemTasks: function systemTasks() {
			return _.filter(_ctx.tasks, function (t) {
				return t.$metadata && t.$metadata.systemTasks;
			});
		},

		isTaskRecipient: function isTaskRecipient(t) {
			return t.$metadata && t.$metadata.recipient;
		},

		//Get all tasks sent to the logged in practitioner which are currently outstanding or completed/cancelled in the past 24 hours
		priorTasks: function priorTasks() {
			return _.filter(_ctx.tasks, function (t) {
				return t.$metadata && t.$metadata.priorTasks;
			}); //but not any of the user's current roles
		},

		encounterTasks: function encounterTasks(encounterId) {
			return _.filter(_ctx.tasks, function (t) {
				return t.encounter && t.encounter.id === encounterId;
			});
		},

		observationValue: function observationValue(task, obsType, displayCode) {
			var index = _.findIndex(task.supportingInformation, { 'name': obsType });

			if (index !== -1) {
				if (displayCode) {
					if (task.supportingInformation[index].valueCodeableConcept.coding[0].code) {
						return task.supportingInformation[index].valueCodeableConcept.coding[0].code;
					}
				}
				if (task.supportingInformation[index].valueCodeableConcept.coding[0].display) {
					return task.supportingInformation[index].valueCodeableConcept.coding[0].display;
				} else if (task.supportingInformation[index].valueQuantity.value) {
					return task.supportingInformation[index].valueQuantity.value;
				}
			}
			return null;
		},

		obsTakenAt: function obsTakenAt(task) {
			if (task.supportingInformation && task.supportingInformation[0]) {
				return task.supportingInformation[0].appliesPeriod.start;
			}
			return null;
		},

		displayLocation: function displayLocation(locType, loc) {
			// Displays ward and bed depending on what is specified in config.js
			// under 'bedDisplayField' or 'wardDisplayField' (options: name || identiferValue)
			var d = config[locType + 'DisplayField'];
			if (d === 'name') {
				return loc.name;
			}
			//sanity check - some errors thrown here. TODO: investigate why loc.identifier is sometimes undefined
			if (loc && loc.identifier) {
				return loc.identifier[0].value;
			}
			return '';
		},

		defaultCampus: function defaultCampus() {
			var campus = void 0;
			if (config.defaultCampusLoc && config.defaultCampusLoc.id) {
				campus = _.find(o.campuses(), { 'id': config.defaultCampusLoc.id });
			}
			if (!campus) {
				campus = o.campuses()[0];
			}
			return campus;
		},

		// return the last number found in a string
		lastNumber: function lastNumber(s) {
			var foundFirstNum = false;
			var ns = '';
			for (var i = s.length - 1; i > -1; i--) {
				if (!isNaN(parseInt(s[i]))) {
					ns = s[i] + ns;
					foundFirstNum = true;
				} else if (foundFirstNum) {
					return parseInt(ns);
				}
			}
			return -1;
		},

		firstName: function firstName(p) {
			if (!p || !p.name) {
				return '';
			}
			var name = void 0;
			if (angular.isArray(p.name)) {
				name = p.name[0];
			} else {
				name = p.name;
			}
			if (name.given && name.given.length > 0) {
				return name.given[0];
			}
			return '';
		},

		lastName: function lastName(p) {
			if (!p || !p.name) {
				return '';
			}
			var name = void 0;
			if (angular.isArray(p.name)) {
				name = p.name[0];
			} else {
				name = p.name;
			}
			if (name.family && name.family.length > 0) {
				return name.family[0];
			}
			return '';
		},

		displayName: function displayName(p, lastNameFirst) {
			if (!p) {
				return '';
			}
			var names = [];
			var f = o.firstName(p);
			var s = o.lastName(p);
			if (f) {
				names.push(f);
			}
			if (s) {
				names.push(s);
			}
			if (lastNameFirst) {
				return names.reverse().join(', ');
			}
			return names.join(' ');
		},

		displayNameAbbrev: function displayNameAbbrev(p) {
			var lastName = o.lastName(p);
			var firstName = o.firstName(p);
			var name = void 0;
			if (lastName) {
				lastName = lastName > 8 ? lastName.substring(0, 8) + '...' : lastName;
			} else {
				lastName = '';
			}
			if (firstName) {
				name = firstName.substring(0, 1) + '. ' + lastName;
			} else {
				name = lastName;
			}
			return name;
		},

		displayNameInitials: function displayNameInitials(p) {
			if (!p) {
				return '';
			}
			var names = [];
			var f = o.firstName(p);
			f = f ? f.substring(0, 1) + '.' : '';
			if (f) {
				names.push(f);
			}
			var s = o.lastName(p);
			s = s ? s.substring(0, 1) + '.' : '';
			if (s) {
				names.push(s);
			}
			return names.join('');
		},

		toTitleCase: function toTitleCase(str) {
			if (str) {
				return str.replace(/\w\S*/g, function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				});
			}
			return '';
		},

		// Return 'higher', 'lower' or 'equal' depending on a comparison of two dot-separated versions
		// Version examples: 1.0, 2.13.5, 3.1.1.5
		// e.g. if v1 = 2.0 and v2 = 2.0.1 returns 'lower'
		compareVersion: function compareVersion(v1, v2) {
			var a1 = v1.split('.');
			var a2 = v2.split('.');
			var i = 0;
			for (;;) {
				if (a1.length === i) {
					if (a2.length > i) {
						return 'lower';
					} else if (a2.length === i) {
						return 'equal';
					}
				} else if (a2.length === i) {
					return 'higher';
				}
				if (a1[i] > a2[i]) {
					return 'higher';
				} else if (a1[i] < a2[i]) {
					return 'lower';
				}
				i++;
			}
		},

		highlight: function highlight(text, terms, spanClass) {
			var t = terms.toLowerCase().split(' ');
			var searchText = text.toLowerCase();

			function getMatchingSegments(s, term, matches) {
				var rx = new RegExp('\\b' + term, 'ig');
				var match = void 0;
				while ((match = rx.exec(s)) !== null) {
					var p = match.index;
					var e = p + term.length;
					matches.push({ start: p, end: e });
				}
			}

			//recursive function to find end of contiguous block of matches
			function endOfBlock(matches, index) {
				var end = { end: matches[index].end, nextIndex: index + 1 };
				if (matches.length > index + 1) {
					if (matches[index + 1].start <= matches[index].end) {
						return endOfBlock(matches, index + 1);
					}
				}
				return end;
			}

			//take an array of start and end indices and consolidate into an array which
			//merges contiguous blocks
			function consolidateContiguousSegments(matches) {
				var i = 0;
				var consolidated = [];
				while (matches.length > i) {
					var r = endOfBlock(matches, i);
					consolidated.push({ start: matches[i].start, end: r.end });
					i = r.nextIndex;
				}
				return consolidated;
			}
			//posOffset is an offset determined by the number of subsitutions we have already made into the string - the inserted tags affect the total length, so must be compensated for
			function wrapSegmentInTag(str, pos, tagStart, tagEnd, posOffset) {
				var start = pos.start + posOffset;
				var end = pos.end + posOffset;
				return str.substring(0, start) + tagStart + str.substring(start, end) + tagEnd + str.substring(end);
			}

			var segments = []; //this will be an array of segments (starting and ending indexes) that need to be highlighted in text
			for (var i = 0; i < t.length; i++) {
				getMatchingSegments(searchText, t[i], segments);
			}

			if (segments.length > 0) {
				segments = consolidateContiguousSegments(_.sortBy(segments, 'start'));
				var s1 = '<span class= "' + spanClass + '">';
				var s2 = '</span>';
				var l = s1.length + s2.length;
				for (var _i = 0; _i < segments.length; _i++) {
					//jshint ignore:line
					text = wrapSegmentInTag(text, segments[_i], s1, s2, _i * l);
				}
			}
			return text;
		},

		searchEncounters: function searchEncounters(criteria, fhir, addToContext, ctx) {
			var api = 'search-encounters' + (fhir ? '-fhir' : '');
			ctx = ctx || _ctx;
			var deferred = $q.defer();
			criteria = _.omitBy(criteria, function (i) {
				return !i;
			}); //remove empty keys
			ResourceService.medtaskerProc(api, criteria, true, true).then(function (res) {
				if (res.status === 200) {
					if (fhir && addToContext) {
						ResourceService.registerBundle(ctx, res.data).then(function () {
							deferred.resolve(res.data);
						});
					} else {
						deferred.resolve(res.data);
					}
				} else {
					deferred.reject('Error searching encounters');
				}
			}, function (e) {
				deferred.reject(e);
			});
			return deferred.promise;
		},

		searchTasks: function searchTasks(criteria, addToContext, ctx) {
			var deferred = $q.defer();
			ctx = ctx || _ctx;
			criteria = _.omitBy(criteria, function (i) {
				return !i;
			}); //remove empty keys
			ResourceService.medtaskerProc('search-tasks-fhir', criteria, true, true).then(function (res) {
				if (res.status === 200) {
					if (addToContext) {
						ResourceService.registerBundle(ctx, res.data).then(function () {
							deferred.resolve(res.data);
						});
					} else {
						deferred.resolve(res.data);
					}
				} else {
					deferred.reject('Error searching encounters');
				}
			}, function (e) {
				deferred.reject(e);
			});
			return deferred.promise;
		},

		searchRoleAssignments: function searchRoleAssignments(criteria) {
			var deferred = $q.defer();
			criteria = _.omitBy(criteria, function (i) {
				return !i;
			}); //remove empty keys
			ResourceService.medtaskerProc('search-role-assignments', criteria, true, true).then(function (res) {
				if (res.status === 200) {
					deferred.resolve(res.data);
				} else {
					deferred.reject('Error searching role assignments');
				}
			}, function (e) {
				deferred.reject(e);
			});
			return deferred.promise;
		},

		searchRoleGroups: function searchRoleGroups(criteria) {
			var deferred = $q.defer();
			criteria = _.omitBy(criteria, function (i) {
				return !i;
			}); //remove empty keys
			ResourceService.medtaskerProc('search-role-groups', criteria, true, true).then(function (res) {
				if (res.status === 200) {
					deferred.resolve(res.data);
				} else {
					deferred.reject('Error searching role assignments');
				}
			}, function (e) {
				deferred.reject(e);
			});
			return deferred.promise;
		},

		newAlerts: function newAlerts() {
			if (!_ctx.communications) {
				return [];
			}
			var alerts = _.filter(_ctx.communications, function (c) {
				return c.status === 'in-progress' && c.payload[0].contentReference && c.medium[0].text === 'appAlert' && !(c.payload[0].contentReference.status === 'completed' && moment().diff(c.payload[0].contentReference.meta.lastUpdated, 'hours') > 24);
			});
			alerts = _.sortBy(alerts, 'sent').reverse();
			return alerts;
		},

		campuses: function campuses() {
			if (o._campuses) {
				return o._campuses;
			}
			o._campuses = _.sortBy(_.filter(o.ctx.locations, function (l) {
				return l.physicalType.coding[0].code === 'bu' && l.identifier[0].value !== 'TEMP_CAMPUS';
			}), function (l) {
				return l.name;
			});
			o._campuses.unshift({
				order: -1,
				name: 'All'
			});
			return o._campuses;
		},

		campusCode: function campusCode(id) {
			if (!config.multiCampus) {
				return '';
			}
			var c = _.find(o.campuses(), { 'id': id });
			if (c) {
				return c.identifier[0].value;
			}
			return '';
		},

		campusDisplayCode: function campusDisplayCode(id) {
			if (!config.multiCampus) {
				return '';
			}
			var c = _.find(o.campuses(), { 'id': id });
			if (c) {
				return '(' + c.identifier[0].value + ') ';
			}
			return '';
		},

		formatCampus: function formatCampus(c) {
			if (!c) {
				return '';
			}
			if (!c.identifier) {
				return c.name;
			}
			return '(' + c.identifier[0].value + ') ' + c.name;
		},

		//processCondition is a function which is passed the model and returns true if the resource should be processed and added to context
		//If not supplied, the resource is always processed
		genericSseHandler: function genericSseHandler(resourceType, data, processCondition) {
			if (!o.user) {
				return o.resolve();
			}
			var deferred = $q.defer();
			var res = parseFeedData(data);
			if (res.resourceType === resourceType) {
				log.info('Received ' + resourceType + ' SSE');
				if (!res.deleted) {
					if (!processCondition || processCondition(res.model)) {
						ResourceService.addToContext(o.ctx, res.model).then(function () {
							deferred.resolve(res.model); //resolve first, so any additional actions are run before we broadcast the entity
							$rootScope.$broadcast('sse.' + ResourceService.toFriendlyName(resourceType), res.model);
						});
					} else {
						deferred.resolve();
					}
				} else {
					var collectionName = ResourceService.pluralize(resourceType);
					var entity = _.find(_ctx[collectionName], { 'id': res.id });
					if (entity) {
						ResourceService.unregister(_ctx, entity);
					}
					$rootScope.$broadcast('sse.' + ResourceService.toFriendlyName(resourceType) + '.deleted', { id: res.id });
					deferred.resolve();
				}
			} else {
				deferred.resolve();
			}
			return deferred.promise;
		},

		taskSseHandler: function taskSseHandler(data) {
			o.genericSseHandler('MtTask', data);
		},

		roleAssignmentSseHandler: function roleAssignmentSseHandler(data) {
			var res = parseFeedData(data);
			if (res.resourceType !== 'MtRoleAssignment') {
				return;
			}
			log.info('Received MtRoleAssignment SSE');
			if (o.user && o.user.id) {
				//ignore if user not logged in
				if (!res.deleted) {
					var period = {
						start: moment(res.model.period.start),
						end: moment(res.model.period.end)
					};
					var overridesMyCurrent = false;
					if (res.model.override) {
						var or = res.model.override.reference.split('/')[1];
						overridesMyCurrent = _.some(o.userRAs, function (ra) {
							return or === ra.id && ra.period.start.isBefore(moment()) && ra.period.end.isAfter(moment()) && ra.practitioner.id === o.user.id;
						});
					}

					var current = period.start.isBefore(moment()) && period.end.isAfter(moment());

					var practitionerIsMe = res.model.practitioner.reference.indexOf(o.user.id) > -1;

					var endingOverride = res.model.override && practitionerIsMe && period.end.isBefore(moment());

					ResourceService.addToContext(o.ctx, res.model).then(function () {
						if (current && practitionerIsMe || overridesMyCurrent || endingOverride) {
							//if the RA applies to now and is mine, or if it overrides any current RA of mine, reload user's data
							//the Elasticsearch indexing delay appears to be necessary still
							$timeout(function () {
								$rootScope.$emit('ReloadUserData');
							}, 1500);
						} else if (practitionerIsMe && period.start.isAfter(moment()) || overridesMyCurrent) {
							//if RA is in the future and is either mine or overrides an RA of mine:
							if (practitionerIsMe) {
								//add to my future RAs if it's mine
								o.userFutureRAs.push(res.model);
							}
							o.setRoleAssignmentTimerEvents(_ctx.roleAssignments); //reset the RA timer event
						}
						$rootScope.$broadcast('sse.roleAssignment', res.model);
					});
				} else {
					log.debug('SSE RA was deleted');
					// If RA deleted, unregister it from ctx
					ResourceService.unregister(_ctx, _.find(_ctx.roleAssignments, { 'id': res.id }));
					// If deleted RA involves logged in user reinitialize AppService
					if (_.find(o.userFutureRAs, { 'id': res.id })) {
						_.remove(o.userFutureRAs, function (ra) {
							return ra.id === res.id;
						});
					}
					if (o.ready.promise.$$state.status === 1 && _.find(o.userRAs, { 'id': res.id })) {
						$timeout(function () {
							$rootScope.$emit('ReloadUserData');
						}, 1500);
					}
				}
			}
		},

		allRoleAssignmentsSseHandler: function allRoleAssignmentsSseHandler(data) {
			o.genericSseHandler('MtRoleAssignment', data);
		},

		encounterSseHandler: function encounterSseHandler(data) {
			o.genericSseHandler('Encounter', data);
		},

		aggregateSseHandler: function aggregateSseHandler(data) {
			o.genericSseHandler('MtAggregate', data);
		},

		shiftSseHandler: function shiftSseHandler(data) {
			o.genericSseHandler('MtShift', data);
		},

		communicationSseHandler: function communicationSseHandler(data) {
			if (!o.user) {
				return;
			}
			var res = parseFeedData(data);

			if (res.resourceType === 'Communication' && res.model.recipient[0].reference.indexOf(o.user.id) > -1) {
				//IMPORTANT: Only communications in the appAlert medium will be persisted to the context. Push notifications
				//are *handled* (an alert may or may not be displayed), but not saved.
				if (res.model.medium[0].text === 'appAlert') {
					ResourceService.addToContext(_ctx, res.model).then(function () {
						$rootScope.$broadcast('sse.appAlert', res.model);
						log.debug('Added communication to ctx');
					});
				} else if (res.model.category.text === 'push notification') {
					ResourceService.deserialize(_ctx, res.model).then(function () {
						//don't add to context, just deserialize it
						$rootScope.$broadcast('sse.pushNotification', res.model);
					});
				}
			}
		},

		overrideRequestSseHandler: function overrideRequestSseHandler(data) {
			if (!o.user) {
				return;
			}
			var res = parseFeedData(data);
			if (res.resourceType === 'MtOverrideRequest' && (res.model.recipient.reference.indexOf(o.user.id) > -1 || res.model.requester.reference.indexOf(o.user.id) > -1)) {
				ResourceService.addToContext(_ctx, res.model).then(function () {
					$rootScope.$broadcast('sse.overrideRequest', res.model);
					log.debug('Added OverrideRequest to ctx');
				});
			}
		},

		locationSseHandler: function locationSseHandler(data) {
			return o.genericSseHandler('Location', data);
		},

		organizationSseHandler: function organizationSseHandler(data) {
			return o.genericSseHandler('Organization', data);
		},

		clinicalPhotographSseHandler: function clinicalPhotographSseHandler(data) {
			return o.genericSseHandler('MtClinicalPhotograph', data);
		},

		roleSseHandler: function roleSseHandler(data) {
			return o.genericSseHandler('MtRole', data);
		},

		teamAssignmentSseHandler: function teamAssignmentSseHandler(data) {
			var res = parseFeedData(data);

			var now = moment();

			// Only cache MtTeamAssignments if it relates to loggedInPractitioner and it is current (period intersects now)
			if (res.resourceType === 'MtTeamAssignment') {
				if (!res.deleted) {
					if (res.model.practitioner.reference.indexOf(o.user.id) > -1) {
						ResourceService.get(_ctx, 'MtTeamAssignment', res.model.id).then(function (ta) {
							if (+ta.period.start > +now) {
								o.userFutureTAs.push(ta);
							} else if (+ta.period.end > +now) {
								//HACK: delay for ES indexing
								$timeout(function () {
									$rootScope.$emit('ReloadUserData');
								}, 1000);
							}
							$rootScope.$broadcast('sse.teamAssignment', ta);
						});
					}
				} else {
					// If TA deleted, and it exists in the ctx, unregister it
					var ta = _.find(_ctx.teamAssignments, { 'id': res.id });
					if (ta) {
						ResourceService.unregister(_ctx, ta);
						$rootScope.$broadcast('sse.teamAssignment');
						if (ta.practitioner.id === o.user.id) {
							if (+ta.period.start < +now && +ta.period.end > +now) {
								_.remove(o.userTAs, function (t) {
									return t.id === ta.id;
								});
							}
							if (+ta.period.start > +now) {
								_.remove(o.userFutureTAs, function (t) {
									return t.id === ta.id;
								});
							}
							$rootScope.$emit('ReloadUserData');
						}
					}
				}
			}
		}
	};

	return o;
}]);