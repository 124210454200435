'use strict';
/*jshint latedef: nofunc */

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

angular.module('medtasker').controller('ComposeCtrl', ['ctx', 'AppService', 'ResourceService', 'ComposeService', 'DialogService', 'AudioService', 'config', 'codeCompose', 'wards', 'LogService', '$modal', '$timeout', '$state', '$q', '$sce', '$scope', '$rootScope', 'moment', '$stateParams', '_', function (ctx, AppService, ResourceService, ComposeService, DialogService, AudioService, config, codeCompose, wards, LogService, $modal, $timeout, $state, $q, $sce, $scope, $rootScope, moment, $stateParams, _) {

	var _this = this;
	AppService.ctx.practitioners.length = 0;

	_this.setSubCatgUrgency = function (subcat) {
		_this.task.urgency = _.find(config.urgencies, function (u) {
			return u.code === (subcat.urgency || 'routine');
		});
		var profile = AppService.escalationProfileForTaskType(subcat);
		_this.disableDueTime = profile && profile.dueWithinMins;
	};

	_this.leftPanel = function () {
		_this.taskPanel = 0;
		_this.subcategoryIndex = -1;
		_this.task.subCategory = null;
	};

	_this.formatCampus = function (c) {
		if (!c.id) {
			return '--' + c.name + '--';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	_this.rightPanel = function () {
		if (_this.taskPanel === 0 && _this.subCategories.length > 0) {
			_this.taskPanel = 1;
			_this.subcategoryIndex = 0;
			_this.task.subCategory = _this.subCategories[_this.subcategoryIndex];
		}
	};

	function _clearCustom() {
		_this.custom = {
			values: {},
			multivalues: {}
		};
	}

	function getRequiredResources(ccs) {
		var fetchPrac = [];
		var fetchRole = [];
		var promises = [];
		_.forEach(ccs, function (cc) {
			if (!_.find(ctx.practitioners, function (p) {
				return p.id === cc.practitionerId;
			})) {
				fetchPrac.push(cc.practitionerId);
			}
			if (!_.find(ctx.roles, function (r) {
				return r.id === cc.roleId;
			})) {
				fetchRole.push(cc.roleId);
			}
		});
		if (fetchPrac.length > 0) {
			promises.push(AppService.search('Practitioner', '_id=' + fetchPrac.join()));
		}
		if (fetchRole.length > 0) {
			promises.push(AppService.search('MtRole', '_id=' + fetchRole.join()));
		}
		if (promises.length > 0) {
			return $q.all(promises);
		}
		return AppService.resolve();
	}

	function _makeDefaultRoles() {
		var dr = void 0;
		if (AppService.userRAs.length > 0) {
			dr = _.transform(AppService.userRAs, function (accum, ra) {
				if (ra.role) {
					accum.push({
						name: AppService.roleShortName(ra.role),
						ra: ra
					});
				}
			});
		} else {
			dr = config.defaultRoles;
			if (!_.find(dr, function (r) {
				return r.name === 'Other';
			})) {
				dr.push({
					name: 'Other'
				});
			}
		}
		return dr;
	}
	_this.defaultRoles = _makeDefaultRoles();
	//return a atring of all chars in s1 that aren't in s2
	function stringDiff(s1, s2) {
		var r = [];
		var s2index = 0;
		for (var i = 0; i < s1.length; i++) {
			if (s1[i] !== s2[s2index]) {
				r.push(s1[i]);
				i++;
			}
			s2index++;
		}
		return r.join('');
	}

	function _setSenderRole() {
		if (AppService.userRAs.length === 0 && AppService.user.defaultRole) {
			var role = _.find(_this.defaultRoles, function (r) {
				return r.name === AppService.user.defaultRole;
			});
			if (role) {
				_this.role = role;
				_this.setRole(role);
			} else {
				_this.role = { name: 'Other' };
				_this.task.senderRole = AppService.user.defaultRole;
			}
		}
		if (AppService.userRAs.length === 1) {
			_this.role = _this.defaultRoles[0];
			ComposeService.setSenderContactsForRoleAssignment(_this.task, _this.role.ra);
		}
	}

	var customField = function customField(name) {
		_classCallCheck(this, customField);

		this.name = name;
		this.value = [];
	};

	// marshal custom fields into an array of customField objects to be assigned to the task's customData field


	function _marshalCustomData() {
		if (!_this.customFields || _this.customFields.length === 0) {
			return null;
		}
		var customData = [];
		_.forEach(_this.customFields, function (field) {
			switch (field.inputType) {
				case 'select':case 'select-typeahead':
					if (_this.custom.values[field.name]) {
						var cd = new customField(field.name);
						var val = {
							dataType: 'coding', // ignore the dataType field - it has to be a coding
							codingValue: _this.custom.values[field.name]
						};
						cd.value.push(val);
						customData.push(cd);
					}
					break;
				case 'multi-select':case 'multi-check':
					if (_this.custom.multivalues[field.name] && _this.custom.multivalues[field.name].length > 0) {
						var _cd = new customField(field.name);
						_.forEach(_this.custom.multivalues[field.name], function (v) {
							var val = {
								dataType: 'coding', // ignore the dataType field - it has to be a coding
								codingValue: v
							};
							_cd.value.push(val);
						});
						customData.push(_cd);
					}
					break;
				case 'text':case 'textarea':
					if (_this.custom.values[field.name]) {
						var _cd2 = new customField(field.name);
						var _val = {
							dataType: 'string',
							value: _this.custom.values[field.name]
						};
						_cd2.value.push(_val);
						customData.push(_cd2);
					}
					break;
				case 'number':
					if (angular.isDefined(_this.custom.values[field.name])) {
						//need isDefined here since 0 is falsey
						var _cd3 = new customField(field.name);
						var _val2 = {
							dataType: field.dataType,
							value: _this.custom.values[field.name].toString()
						};
						_cd3.value.push(_val2);
						customData.push(_cd3);
					}
					break;
			}
		});
		return customData;
	}

	function _setEncounter(e) {
		_this.task.patientRelated = true;
		_this.selectedEncounter = e;
		_this.hasSelectedPatient = true;
		_this.encounterResults.length = 0;
		_this.patientInput = AppService.displayName(e.patient);
		_this.confirmTeam = _this.selectedEncounter.serviceProvider;
		if (!codeCompose) {
			// do not update categories if we are in codeCompose mode
			// as the categories are intialised when on page load
			_this.categories = _.filter(config.categories, _this.catgFilter);
			_this.removeIfOtherIsOnlyCategory();
		}

		//LogService.debug('_setEncounter ' + JSON.stringify(_this.categories, null, 2));

		// Parse location
		// Pick the last location listed in the encounter
		var bedLoc = _this.selectedEncounter.location[_this.selectedEncounter.location.length - 1].location;
		_this.ward = AppService.displayLocation('ward', bedLoc.partOf);
		_this.bed = AppService.displayLocation('bed', bedLoc);
		_this.tempCampus = _this.editPatientCampus = _.find(_this.campuses, function (c) {
			return _this.selectedEncounter.serviceProvider.partOf.location[0].id === c.id;
		});
		_this.focus = 'patientSelected';
	}

	function _resetTask() {
		_this.roleAssignment = null;
		_this.contactForRA = null;
		_this.resetRecipientFilters();
		_this.recipientInput = '';
		_this.resetPatientFilters();

		_this.recipientResults.length = 0;
		_this.encounterResults.length = 0;
		_this.roleAssignmentIndex = 0;
		_this.highlightedResultIndex = 0;
		_this.highlightedResult = null;
		_this.selectedEncounter = null;
		_this.showDue = false;
		_this.taskSelectWasFocussed = false;

		_this.notPatientRelatedOptionVisible = false;
		_this.editPatientInfo = false;
		_this.ccs = [];

		_this.confirmTeam = null;
		_this.ward = null;
		_this.bed = null;
		_this.includeObservations = false;

		_this.categoryIndex = -1;
		_this.subcategoryIndex = -1;
		_this.role = null;

		_this.task = ComposeService.newTask();
		_this.task.senderPhone = null;
		_this.task.senderPager = null;
		_setSenderRole();
		_clearCustom();
		if (codeCompose) {
			_this.setTaskCategory(_this.categories[0]);
		} else {
			_this.subCategories = [];
		}

		_this.patientInput = '';
		if (codeCompose) {
			if (angular.isDefined(_this.selectedCampus)) {
				var campusId = _this.selectedCampus.identifier[0].value;
				if (config.codesConfig) {
					var codesConfig = config.codesConfig[campusId];
					if (codesConfig) {
						_this.role = codesConfig.senderRole;
						_this.task.from = codesConfig.senderName;
						_this.task.senderPhone = codesConfig.senderPhone;
					}
				}
				_this.setTaskCategory(_this.categories[0]);
				_this.taskPanel = 1;
			}
			//_this.rightPanel();
		} else {
			_this.task.from = AppService.displayName(AppService.user);
			ComposeService.setTaskSenderDetails(_this.task);
		}
	}

	// Returns false only if a category doesn't have sub-categories (eg: 'Other')
	_this.setTaskCategory = function (cat) {
		_this.focus = 'selectTask';
		_this.task.category = cat;
		_this.task.subCategory = null;
		if (_this.task.category.display !== 'Other') {
			// Assumes category name is unique amongst all the categories
			_this.subCategories = _.filter(_.find(_this.categories, { display: cat.display }).subCategories, _this.catgFilter) || [];
		} else {
			_this.subCategories = [];
		}
	};

	function _sendTask(form) {
		_this.task.cc = _.transform(_this.ccs, function (accum, cc) {
			accum.push({
				role: {
					resourceType: 'MtRole',
					id: cc.roleId
				},
				practitioner: {
					resourceType: 'Practitioner',
					id: cc.practitionerId
				},
				status: 'sent'
			});
		}, []);
		var cd = _marshalCustomData();
		if (cd && cd.length > 0) {
			_this.task.customData = cd;
		}
		var sendingTaskModal = DialogService.openMessageDialog('Sending task...', '', 'info', { disallowClose: true });
		ComposeService.createTask(_this.task, _this.selectedEncounter, _this.roleAssignment).then(function () {
			sendingTaskModal.close();
			AudioService.playSendSound();
			DialogService.openMessageDialog('Your task has been sent!', '', 'sent', { duration: 1500 });
			if (AppService.userRAs.length === 0 && AppService.user.defaultRole !== _this.task.senderRole) {
				AppService.user.defaultRole = _this.task.senderRole;
				AppService.update(AppService.user); //This is a minor function, so we'll ignore any error here
			}
			$timeout(function () {
				_resetTask();
				form.$setPristine();
				form.section1Form.$setPristine();
				form.$setUntouched();
				if (!codeCompose) {
					_this.taskSectionVisible = false;
				}
				_this.focus = 'recipient';
			}, 1500);
		}, function (err) {
			var msg = 'An error occurred sending the task. Please try again. If this error recurs, contact support.';
			if (err.data.indexOf('Role assignment for recipient') > -1) {
				msg = 'The role of ' + AppService.roleShortName(_this.roleAssignment.role) + ' may have finished or been diverted since you started composing this task. Please re-send to the staff member who is now covering the role (delete "to" field and search the role name again).';
			}
			sendingTaskModal.close();
			DialogService.errorMessage(msg, err);
			LogService.error('Could not create task: ' + err);
		});
		LogService.debug('task done!');
	}

	_this.selectAllInRoleGroup = function (group) {
		if (group.roleAssignments.length === 0) {
			if (codeCompose) {
				var msg = 'There are no staff assigned to any of the roles in the recipient group for this code';
				DialogService.openMessageDialog('No recipients available', msg, 'warning');
			}
			return;
		}
		var recipients = {
			primary: group.roleAssignments[0],
			cc: group.roleAssignments.slice(1)
		};
		_this.selectRecipients(recipients);
	};

	_this.selectCcs = function (group, cc) {
		if (group.roleAssignments.length === 0) {
			var msg = 'There are no staff currently assigned to any of the roles in this list';
			DialogService.openMessageDialog('No recipients available', msg, 'warning');
		}
		if (cc) {
			_this.ccInput = '';
		}
		var options = {
			templateUrl: 'app/compose/cc-dialog.html',
			// size: 'lg',
			backdrop: true,
			controller: 'CcCtrl',
			controllerAs: 'ctrl',
			resolve: {
				emptyRoles: function emptyRoles() {
					if (group.emptyRoles && group.emptyRoles.length === 0) {
						return AppService.resolve([]);
					}
					return AppService.search('MtRole', '_id=' + group.emptyRoles.join());
				},
				modalArgs: function modalArgs() {
					return {
						group: group,
						compose: _this
					};
				}
			}
		};
		var instance = $modal.open(options);
		instance.result.then(function (recipients) {
			if (recipients) {
				_this.selectRecipients(recipients);
			}
		});
	};

	var encounterSearchPromises = [];
	var raSearchPromises = [];
	var grpSearchPromises = [];

	_this.config = config;
	_this.patientInput = '';
	_this.ward = null;
	_this.bed = null;

	_this.task = null;
	_this.neededResources = AppService.resolve(); //promise to track fetching of practitioners and roles required when a group role is selected
	_this.taskSectionVisible = false;
	_this.selectedEncounter = null;
	_this.encounters = ctx.encounters;
	_this.highlightedResult = null;
	_this.contactForRA = null;
	_this.roleAssignment = null; // Current RA for the screen
	_this.roleAssignmentIndex = null; // Used in drop down lists
	_this.roleGroupIndex = null;
	_this.organizations = ctx.organizations;
	_this.locations = ctx.locations;
	_this.selectedUrgency = 'routine';
	_this.recipientFilter = {};
	_this.encounterFilter = {};
	_this.focus = 'recipient';
	_this.taskSelectWasFocussed = false;
	_this.editPatientInfo = false;
	_this.tempObservations = {};
	_this.pagerPattern = config.pagerPattern;
	_this.phonePattern = config.phonePattern;
	_this.validPhoneDescription = config.validPhoneDescription;
	_this.validPagerDescription = config.validPagerDescription;
	_this.categoryIndex = -1;
	_this.subcategoryIndex = -1;
	_this.taskPanel = 0; //The selected panel for keyboard selection of task category/subcategory
	_this.displayLocation = AppService.displayLocation;
	_this.urgencies = _.filter(config.urgencies, { enabled: true });
	_this.wards = _.transform(wards, function (accum, w) {
		w.display = AppService.displayLocation('ward', w);
		accum.push(w);
	});
	_this.roleFullName = AppService.roleFullName;

	_this.recipientResults = [];
	_this.encounterResults = [];
	_this.roleGroupsResults = [];
	if (codeCompose) {
		_this.campuses = _.filter(AppService.campuses(), function (loc) {
			return loc.name.toLowerCase() !== 'all';
		});
	} else {
		_this.campuses = AppService.campuses();
	}
	_this.campusCode = AppService.campusDisplayCode;

	//turn Smith, John to John Smith
	_this.showCcName = function (name) {
		var n = name.split(',');
		return [n[1].trim(), n[0].trim()].join(' ');
	};

	var encounterSearchFields = ['patientName', 'patientIdentifier', 'bedName', 'patientDOB'];
	if (config.teamDisplayField === 'identifierValue') {
		encounterSearchFields.push('teamIdentifier');
	} else {
		encounterSearchFields.push('teamName');
	}
	if (config.wardDisplayField === 'identifierValue') {
		encounterSearchFields.push('wardIdentifier');
	} else {
		encounterSearchFields.push('wardName');
	}

	_this.onCampusChange = function () {
		$state.go($state.current, { campus: _this.selectedCampus.id });
	};

	if (config.defaultCampusLoc) {
		_this.recipientFilter.byCampus = _this.encounterFilter.byCampus = _.find(_this.campuses, { 'id': config.defaultCampusLoc.id });
	} else {
		_this.recipientFilter.byCampus = _this.encounterFilter.byCampus = _this.campuses[0];
	}

	if (codeCompose) {
		_this.selectedCampus = _this.encounterFilter.byCampus;
		if ($stateParams.campus) {
			_this.selectedCampus = _.find(_this.campuses, { 'id': $stateParams.campus });
			_this.recipientFilter.byCampus = _this.encounterFilter.byCampus = _this.selectedCampus;
		}
	}

	_this.displayCampus = function (c) {
		if (!c || !c.id) {
			return 'All';
		}
		return '(' + c.identifier[0].value + ') ' + c.name;
	};

	_this.excludeAll = function (c) {
		return c && c.id;
	};

	_this.formatTelecom = AppService.formatTelecom;

	_this.showCampus = config.multiCampus;

	_this.bestTelecom = function (contacts) {
		return _.find(contacts, { 'resource': 'MtAggregate' }) || _.find(contacts, { 'resource': 'MtRole' }) || _.find(contacts, { 'resource': 'Practitioner' }) || { system: '', value: '?' };
	};

	_this.campusLocIdentifier = function (id) {
		return _.find(_this.campuses, { 'id': id }).identifier[0].value;
	};

	_this.catgFilter = function (c) {
		if (codeCompose) {
			// if no campus value is present 
			// this subcategory is available for all the campus
			if (angular.isUndefined(c.campus)) {
				return true;
			}
			if (angular.isUndefined(_this.selectedCampus)) {
				return true;
			}
			// filter by campus
			if (_.some(c.campus, function (t) {
				return t === _this.selectedCampus.identifier[0].value;
			})) {
				return true;
			}
			return false;
		}
		if (!_this.roleAssignment) {
			return false;
		}
		if (c.disabled) {
			return false;
		}
		// LogService.debug('catgFilter '+ JSON.stringify(c, null, 2) + ' task '+ _this.task.patientRelated);
		if (!_this.task.patientRelated) {
			// if no patient is not selected  check if
			// notPatientRelated flag is set return true based on that flag.
			// if notPatientRelated is not defined we assume that this task should  be shown
			// only for patient related tasks

			if (angular.isDefined(c.subCategories)) {
				// logic we use in this function is if this is category task check if
				// there is any sub categories which has not patient related task
				var subCate = _.filter(c.subCategories, function (subc) {
					return subc.notPatientRelated === true && _.some(subc.userTypes, function (t) {
						return t === _this.roleAssignment.role.type.code;
					});
				});
				//LogService.debug('sub category ' + angular.toJson(subCate));
				return subCate.length > 0;
			} else if (angular.isDefined(c.notPatientRelated) && c.notPatientRelated) {
				// if this is a subcategory check if task usertype matches with the destination user
				return _.some(c.userTypes, function (t) {
					return t === _this.roleAssignment.role.type.code;
				});
			}
			return false;
		}
		if (angular.isDefined(c.subCategories)) {
			// logic we use in this function is if this is category task check if
			// there is any sub categories has any  patient related task
			// a task is a patient related task
			// 1) if patientRelated field is set to true  or
			// 2) i patientRelated field is not defined. By default we assume all tasks are patient related unless you
			//	   override it with patientRelated field
			var _subCate = _.filter(c.subCategories, function (subc) {
				return (angular.isUndefined(subc.patientRelated) || //if patientRelated field is not defined we assume this task is patient related
				angular.isDefined(subc.patientRelated) && subc.patientRelated) && //if patientRelated field is defined and set to true
				_.some(subc.userTypes, function (t) {
					return t === _this.roleAssignment.role.type.code;
				});
			});
			//LogService.debug('sub category ' + angular.toJson(subCate));
			return _subCate.length > 0;
		} else if (angular.isUndefined(c.patientRelated) || angular.isDefined(c.patientRelated) && c.patientRelated) {
			// if this is a subcategory check if task usertype matches with the destination user
			return _.some(c.userTypes, function (t) {
				return t === _this.roleAssignment.role.type.code;
			});
		}
		return false;
	};

	_this.pagerPreview = function () {
		if (_this.task && _this.selectedEncounter) {
			_this.pagerMsg = ComposeService.pagerMessage(_this.task, _this.selectedEncounter);
			return _this.pagerMsg;
		}
		_this.pagerMsg = ComposeService.pagerMessage(_this.task, null);
		return _this.pagerMsg;
	};

	_this.formattedPagerPreview = function () {
		var m = _this.pagerMsg;
		if (!m) {
			return '';
		}
		if (m.length < 216) {
			return m;
		}
		var s = m.substring(0, 215) + '<span class="extra-chars">' + m.substring(215) + '</span>';
		return $sce.trustAsHtml(s);
	};

	_this.setRole = function (r) {
		if (!r) {
			_this.task.senderPhone = '';
			_this.task.senderPager = '';
			return;
		}
		if (r.name !== 'Other') {
			_this.task.senderRole = r.name;
		} else {
			_this.task.senderRole = '';
			_this.focus = 'otherrole';
		}
		if (r.ra) {
			ComposeService.setSenderContactsForRoleAssignment(_this.task, r.ra);
		}
	};

	_this.displayName = AppService.displayName;

	_this.roleShortName = AppService.roleShortName;

	_this.roleTeam = function (r) {
		return (config.multiCampus ? _this.campusCode(r.organization.partOf.location[0].id) : '') + r.organization.name;
	};

	_this.dueDayOptions = [{ text: 'Today', days: 0 }, { text: 'Tomorrow', days: 1 }, { text: 'Day after tomorrow', days: 2 }];
	_this.dueByDay = _this.dueDayOptions[0];

	_this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789'.split('');

	if (codeCompose) {
		var emergencyResponseCategoryName = config.emergencyResponseCategoryDisplay ? config.emergencyResponseCategoryDisplay : 'CODE';
		_this.categories = _.filter(config.categories, function (c) {
			return c.display === emergencyResponseCategoryName;
		});
	}

	_this.removeIfOtherIsOnlyCategory = function () {
		if (_this.categories.length === 1 && _this.categories[0].display === 'Other') {
			_this.categories = [];
		}
	};
	// Functions
	_this.selectRoleAssignment = function (id) {
		if (id) {
			ctx.roleAssignments[id] = void 0; // force refetch of practitioner and role if missing
			AppService.get('MtRoleAssignment', id).then(function (ra) {
				_this.roleAssignment = ra;
				_this.recipientResults.length = 0;
				_this.contactForRA = AppService.formatTelecom(AppService.telecomForRoleAssignment(ra)[0]);
				_this.task.to = _this.recipientInput = AppService.displayName(ra.practitioner);
				_this.resetRecipientFilters();
				_this.searchGroups = false;
				if (!codeCompose) {
					_this.categories = _.filter(config.categories, _this.catgFilter);
					_this.removeIfOtherIsOnlyCategory();
					//LogService.debug('select role assignement ' + JSON.stringify(_this.categories, null, 2));
					_this.task.category = null;
					_this.task.subCategory = null;
					//_this.focus = 'taskDesc';
				}
				_this.focus = 'patient';
			});
		}
	};
	_this.selectRoleAssignmentAsCc = function (id) {
		if (id) {
			ctx.roleAssignments[id] = void 0; // force refetch of practitioner and role if missing
			AppService.get('MtRoleAssignment', id).then(function (ra) {
				_this.recipientResults.length = 0;
				_this.resetRecipientFilters();
				_this.ccs.push({
					practitionerId: ra.practitioner.id,
					roleId: ra.role.id,
					roleName: _this.roleFullName(ra.role),
					name: AppService.displayName(ra.practitioner, true),
					contact: AppService.formatTelecom(AppService.telecomForRoleAssignment(ra)[0])
				});
				_this.focus = 'patient';
			});
		}
		_this.ccInput = '';
		_this.searchGroups = false;
		_this.recipientResults.length = 0;
	};
	_this.selectRecipients = function (recipients) {
		if (recipients.primary) {
			_this.selectRoleAssignment(recipients.primary.roleAssignmentId);
		}

		recipients.cc.forEach(function (ra) {
			_this.ccs.push({
				roleId: ra.roleId,
				practitionerId: ra.practitionerId,
				name: ra.practitionerName,
				roleName: _this.campusCode(ra.teamCampusId) + ' ' + ra.teamName + ' ' + ra.roleName,
				contact: AppService.formatTelecom(ra.contacts[0]) });
		});

		_this.roleGroupsResults.length = 0;
		_this.searchGroups = false;
	};

	_this.unique = ComposeService.uniqueRas;
	_this.roleAssignments = AppService.ctx.roleAssignments;

	_this.resetRecipientFilters = function () {
		_this.recipientFilter.byTeam = null;
		_this.recipientFilter.byLevel = null;
		_this.recipientFilter.byResponsibility = null;
		_this.recipientFilter.byPager = null;
	};

	_this.resetPatientFilters = function () {
		_this.encounterFilter.byTeam = null;
		_this.encounterFilter.byWard = null;
		_this.encounterFilter.byBed = null;
	};

	_this.searchGroups = false;

	(function init() {
		_resetTask();
		_this.task.urgency = _.find(_this.urgencies, { code: 'routine' });
		if ($state.params.roleAssignmentId) {
			_this.selectRoleAssignment($state.params.roleAssignmentId);
			_this.focus = 'patient';
		} else if (codeCompose) {
			_this.focus = 'selectTask';
		} else {
			_this.focus = 'recipient';
		}
		if ($state.params.urn) {
			var urn = $state.params.urn;
			var enc = _.find(ctx.encounters, function (e) {
				return e.patient.identifier[0].value === urn && e.status === 'in-progress';
			});
			if (enc) {
				_setEncounter(enc);
			} else {
				AppService.search('Patient', 'identifier=' + urn).then(function (p) {
					if (p && p.length > 0) {
						AppService.search('Encounter', 'patient=' + p[0].id + '&status=in-progress').then(function (e) {
							if (e && e.length === 1) {
								_setEncounter(e[0]);
							}
						});
					}
				});
			}
		}
	})();

	_this.resetPatient = function () {
		if (!_this.selectedEncounter && _this.task.patientRelated !== false) {
			_this.patientInput = ''; // cancel whatever was typed in
		}
		_this.encounterResults.length = 0;
	};

	_this.resetRecipient = function () {
		if (!_this.roleAssignment) {
			_this.recipientInput = _this.task.to = '';
			//_this.recipientResults.length = 0;
		}
	};

	_this.removeCc = function (cc) {
		_.remove(_this.ccs, cc);
	};

	_this.resetCc = function () {
		_this.ccInput = '';
		//_this.roleGroupsResults.length = 0;
	};

	_this.onPatientInputChanged = function (newVal, oldVal) {
		var newchars = void 0;
		if (!_this.task.patientRelated) {
			_this.task.patientRelated = true;
			if (!codeCompose) {
				// do not update categories if we are in codeCompose mode
				_this.categories = _.filter(config.categories, _this.catgFilter);
				_this.removeIfOtherIsOnlyCategory();
			}
			//LogService.debug('onPatient Input change ' + JSON.stringify(_this.categories, null, 2));
			newchars = stringDiff(newVal, oldVal);
			_this.patientInput = newchars;
			return;
		}
		if (_this.selectedEncounter) {
			_this.selectedEncounter = null;
			newchars = stringDiff(newVal, oldVal);
			_this.patientInput = newchars;
		}
	};

	_this.recipientFilterApplied = function () {
		return _this.recipientFilter.byTeam || _this.recipientFilter.byLevel || _this.recipientFilter.byPager;
	};

	_this.toggleObservations = function () {
		_this.includeObservations = !_this.includeObservations;
		if (!_this.includeObservations) {
			_this.task.observation = {};
		}
	};

	//clear recipientFilter if search string is cleared
	$scope.$watch('compose.patientInput', function () {
		if (!_this.patientInput || _this.patientInput.length === 0) {
			_this.highlightedResult = null;
			_this.resetPatientFilters();
		}
	});

	$scope.$watch('compose.recipientInput', function () {
		if (!_this.recipientInput || _this.recipientInput.length === 0) {
			_this.highlightedRoleAssignment = null; //prevent selection on tab
			_this.resetRecipientFilters();
		}
	});

	_this.showTaskSection = function (valid) {
		if (valid) {
			_this.taskSectionVisible = true;
		}
		return valid || _this.taskSectionVisible && (_this.selectedEncounter || !_this.task.patientRelated);
	};

	_this.processKey = function (section, key) {
		// Only process enter, tab, shift-tab
		if (key !== 'ENTER' && key !== 'TAB' && key !== 'SHIFT-TAB') {
			return;
		}
		switch (section) {
			case 'recipient':
				if (!_this.searchGroups && _this.highlightedRoleAssignment) {
					_this.selectRoleAssignment(_this.highlightedRoleAssignment.roleAssignmentId);
				}
				if (_this.searchGroups && _this.highlightedRoleGroup) {
					_this.selectCcs(_this.highlightedRoleGroup);
				}
				if (_this.roleAssignment && key === 'TAB' || key === 'ENTER') {
					_this.focus = 'patient';
				} else {
					_this.focus = 'recipient';
				}
				break;

			case 'cc':
				if (!_this.searchGroups && _this.highlightedRoleAssignment) {
					_this.selectRoleAssignmentAsCc(_this.highlightedRoleAssignment.roleAssignmentId);
				}
				if (_this.searchGroups && _this.highlightedRoleGroup) {
					_this.selectCcs(_this.highlightedRoleGroup, 'cc');
				}
				if (_this.roleAssignment && key === 'TAB' || key === 'ENTER') {
					_this.focus = 'patient';
				} else {
					_this.focus = 'cc';
				}
				break;

			case 'patient':
				if (key === 'SHIFT-TAB') {
					_this.resetPatient();
					_this.focus = 'recipient';
					break;
				}

				if (key === 'TAB' || key === 'ENTER') {
					if (_this.highlightedResult && _this.patientInput.length > 0) {
						_this.selectEncounter(_this.highlightedResult.encounterId);
						_this.highlightedResult = null;
						_this.focus = 'sender';
					} else if (key === 'TAB') {
						if (_this.task.patientRelated !== false && !_this.selectedEncounter) {
							_this.focus = 'notPatientRelated';
						} else {
							_this.focus = 'sender';
						}
					}
				}
				break;

			case 'sender':
				if (key === 'SHIFT-TAB') {
					_this.focus = 'patient';
				}
				break;
		}
	};

	_this.downTask = function () {
		//sanity check - panel *should* be 0 if no category selected
		if (_this.taskPanel === 1 && !_this.task.category) {
			_this.taskPanel = 0;
		}
		if (_this.taskPanel === 0) {
			_this.categoryIndex++;
			if (_this.categoryIndex > _this.categories.length - 1) {
				_this.categoryIndex = 0;
			}
			_this.setTaskCategory(_this.categories[_this.categoryIndex]);
		} else {
			_this.subcategoryIndex++;
			if (_this.subcategoryIndex > _this.subCategories.length - 1) {
				_this.subcategoryIndex = 0;
			}
			_this.task.subCategory = _this.subCategories[_this.subcategoryIndex];
			if (codeCompose) {
				_this.setSubCatgUrgency(_this.task.subCategory);
			}
		}
	};

	_this.upTask = function () {
		if (_this.taskPanel === 0) {
			_this.categoryIndex--;
			if (_this.categoryIndex < 0) {
				_this.categoryIndex = _this.categories.length - 1;
			}
			_this.setTaskCategory(_this.categories[_this.categoryIndex]);
		} else {
			_this.subcategoryIndex--;
			if (_this.subcategoryIndex < 0) {
				_this.subcategoryIndex = _this.subCategories.length - 1;
			}
			_this.task.subCategory = _this.subCategories[_this.subcategoryIndex];
			if (codeCompose) {
				_this.setSubCatgUrgency(_this.task.subCategory);
			}
		}
	};

	_this.taskSelectFocussed = function () {
		_this.focus = '';
		//HACK: don't look Bernard! This is the only way we've found to prevent the click event from undoing the accordion opening when focus occurs by mouse click
		_this.disableClickClose = true;
		$timeout(function () {
			_this.disableClickClose = false;
		}, 200);
		if (!_this.task.category) {
			_this.downTask();
		}
	};

	_this.taskSelectClicked = function () {
		LogService.debug('select task clicked');
		if (!_this.disableClickClose) {
			_this.showTaskPanel = !_this.showTaskPanel;
		}
	};

	_clearCustom();

	_this.setTaskSubCategory = function (subcat) {
		if (subcat) {
			_this.task.subCategory = subcat;
			_this.setSubCatgUrgency(subcat);
			if (subcat.requireObs || subcat.urgency && (_this.task.urgency.code === 'premet' && config.requirePreMetObs || _this.task.urgency.code === 'met' && config.requireMetObs || _this.task.urgency.code === 'codeblue' && config.requireCodeBlueObs)) {
				_this.requireObs = true;
				_this.includeObservations = true;
			} else {
				_this.requireObs = false;
			}

			var cf = void 0;
			if (_this.customFields) {
				cf = angular.copy(_this.customFields);
			}
			_this.customFields = ComposeService.customFieldsByTaskType(subcat);
			if (cf) {
				if (cf.length !== _this.customFields.length) {
					_clearCustom();
				} else {
					for (var i = 0; i < cf.length; i++) {
						if (cf[i].name !== _this.customFields[i].name) {
							_clearCustom();
						}
					}
				}
			}

			if (codeCompose) {
				if (subcat.recipientRoleGroup) {
					var c = {
						name: subcat.recipientRoleGroup,
						size: 1
					};
					AppService.searchRoleGroups(c).then(function (e) {
						if (e.totalHits > 0) {
							var rg = _.find(e.results, function (r) {
								return r.groupName === subcat.recipientRoleGroup;
							});
							if (rg) {
								// replace recipients
								_this.ccs.length = 0;
								_this.roleAssignment = null;
								_this.selectAllInRoleGroup(rg);
							}
						} else {
							LogService.warn('Configured recipient role group (' + subcat.recipientRoleGroup + ') for code not found.');
						}
					});
				} //else if (subcat.recipientRole) {
				// }
				_this.focus = 'patient';
			} else if (_this.customFields && _this.customFields.length > 0) {
				_this.focus = 'custom-' + _this.customFields[0].name;
			} else {
				_this.focus = 'taskDesc';
			}
		}
	};

	_this.selectOptionFromKey = function (e, section) {
		if (section === 'task') {
			var k = e.which;
			if (k > 96) {
				//keyboards treating letters as numpad keys (looking at you, Mac!)
				k -= 32;
			}

			if (k > 64 && k < 91) {
				//alpha keys
				var item = k - 65; //convert to letter index in alphabet

				var cats = codeCompose ? 0 : _this.categories.length;
				if (item < cats) {
					//category
					_this.setTaskCategory(_this.categories[item]);
					if (_this.task.category.display === 'Other') {
						_this.showTaskPanel = false;
						_this.focus = 'taskDesc';
					}
				} else if (_this.task.category) {
					if (item - cats < _this.subCategories.length) {
						_this.setTaskType(_this.subCategories[item - cats]);
					}
				}
			}
		}
	};

	_this.replaceSpaces = function (s) {
		return s.replace(' ', '-');
	};

	_this.roleName = function (role) {
		return role.responsibility.display + ' ' + (role.level.display !== role.responsibility.display ? role.level.display : '');
	};

	_this.formattedPatientName = function (name) {
		var prefix = name.use === 'official' ? '' : '(' + name.use + ') ';
		return prefix + name.given.join(' ') + ' ' + name.family.join(' ');
	};

	_this.showNotPatientRelatedOption = function () {
		_this.notPatientRelatedOptionVisible = true;
	};

	_this.hideNotPatientRelatedOption = function () {
		$timeout(function () {
			_this.notPatientRelatedOptionVisible = false;
		}, 250);
	};

	_this.onRecipientInputChanged = function (newVal, oldVal) {
		if (_this.roleAssignment) {
			_this.roleAssignment = null;
			var newchars = stringDiff(newVal, oldVal);
			_this.recipientInput = _this.task.to = newchars;
		}
		_this.task.to = _this.recipientInput;
		_this.roleAssignment = null;
		_this.searchRoleAssignments(newVal);
		_this.searchRoleGroups(newVal);
	};

	_this.onCcInputChanged = function (s) {
		_this.searchRoleAssignments(s);
		_this.searchRoleGroups(s);
	};

	_this.deleteSelectionIfAssigned = function () {
		if (_this.roleAssignment) {
			_this.roleAssignment = null;
			_this.recipientInput = '';
		}
	};

	_this.clearPatient = function () {
		if (_this.hasSelectedPatient || !_this.task.patientRelated) {
			_this.selectedEncounter = null;
			_this.hasSelectedPatient = false;
			_this.highlightedResult = null;
			_this.patientInput = '';
			//			_this.focus = 'patient'; //needed in order to retrigger focus
		}
		if (!_this.task.patientRelated) {
			_this.task.patientRelated = true;
			if (!codeCompose) {
				// do not update categories if we are in codeCompose mode
				// as the categories are intialised when on page load
				_this.categories = _.filter(config.categories, _this.catgFilter);
				_this.removeIfOtherIsOnlyCategory();
			}
			//LogService.debug('clearPatient ' + JSON.stringify(_this.categories, null, 2));
		}
	};

	_this.setTeam = function () {
		if (_this.confirmTeam) {
			if (_this.confirmTeam !== _this.selectedEncounter.serviceProvider) {
				_this.task.overrideTeam = _this.confirmTeam;
			} else {
				_this.task.overrideTeam = null;
			}
		}
	};

	_this.isNonAdmittedEncounter = function (enc) {
		if (enc && enc.class) {
			return enc.class === 'mt_non_admitted';
		}
		return false;
	};

	_this.nonAdmittedDisplayString = function (teamName) {
		if (config.napDisplayString) {
			return config.napDisplayString;
		}
		return teamName;
	};

	_this.nonAdmittedBedName = function () {
		return 'NON ADMITTED';
	};

	_this.isNonAdmittedPatient = function (enc) {
		if (enc && enc.bedName) {
			return enc.bedName === 'NON ADMITTED';
		}
		return false;
	};

	_this.setWard = function () {
		if (_this.ward) {
			// NOTE: OverrideWard is stored as reference to a location resource
			if (_this.ward !== _this.selectedEncounter.location[_this.selectedEncounter.location.length - 1].location.partOf) {
				_this.task.overrideWard = _.find(_this.wards, function (w) {
					return config.wardDisplayField === 'name' ? w.name === _this.ward : w.identifier[0].value === _this.ward;
				});
			} else {
				_this.task.overrideWard = null;
			}
		}
	};

	_this.setBed = function () {
		if (_this.bed) {
			if (_this.bed !== AppService.displayLocation('bed', _this.selectedEncounter.location[_this.selectedEncounter.location.length - 1].location)) {
				_this.task.overrideBed = _this.bed;
			} else {
				_this.task.overrideBed = null;
			}
		}
	};

	_this.cancelEditPatient = function () {
		var bedLoc = _this.selectedEncounter.location[_this.selectedEncounter.location.length - 1].location;
		if (_this.task.overrideWard) {
			_this.ward = AppService.displayLocation('ward', _this.task.overrideWard);
		} else {
			_this.ward = AppService.displayLocation('ward', bedLoc.partOf);
		}
		_this.bed = _this.task.overrideBed || AppService.displayLocation('bed', bedLoc);
		_this.confirmTeam = _this.task.overrideTeam || _this.selectedEncounter.serviceProvider;
		_this.editPatientInfo = false;
	};

	_this.setOverrideData = function () {
		_this.setBed();
		_this.setWard();
		_this.setTeam();
		_this.editPatientInfo = false;
	};

	_this.taskUnrelatedToPatient = function () {
		// fieldToSetPristine.$setPristine();
		_this.hasSelectedPatient = false;
		_this.task.orderliesTask = true;
		_this.task.patientRelated = false;
		if (!codeCompose) {
			// do not update categories if we are in codeCompose mode
			// as the categories are intialised when on page load
			_this.categories = _.filter(config.categories, _this.catgFilter);
			_this.removeIfOtherIsOnlyCategory();
		}

		//LogService.debug('task un related to patients ' + JSON.stringify(_this.categories, null, 2));
		// Remove previously selected patients and clear input field
		_this.selectedEncounter = null;
		_this.highlightedResult = null;
		_this.patientInput = 'Task is not patient related';
		// Remove previously selected task & task category
		_this.task.category = null;
		_this.task.subCategory = null;
		// Clear search results
		_this.encounterResults.length = 0;
		_this.encounterHits = 0;
	};

	_this.unknownPatient = function () {
		// fieldToSetPristine.$setPristine();
		_this.hasSelectedPatient = false;
		_this.task.patientRelated = false;
		if (!codeCompose) {
			// do not update categories if we are in codeCompose mode
			// as the categories are intialised when on page load
			_this.categories = _.filter(config.categories, _this.catgFilter);
		}
		//LogService.debug('unkown patients' + JSON.stringify(_this.categories, null, 2));
		_this.task.patientUnknown = true;
		// Remove previously selected patients and clear input field
		_this.selectedEncounter = null;
		_this.highlightedResult = null;
		_this.patientInput = 'Unknown/other patient';
		// Clear search results
		_this.encounterResults.length = 0;
		_this.encounterHits = 0;
	};

	_this.clickTaskCategory = function (c) {
		_this.categoryIndex = _.indexOf(_this.categories, c);
		_this.setTaskCategory(c);
		if (_this.task.category.display === 'Other') {
			_this.focus = 'taskDesc';
			return false;
		}
		return true;
	};

	_this.taskEnterOrTab = function () {
		if (!_this.task.category) {
			_this.downTask();
			return;
		}
		if (_this.task.subCategory || _this.task.category.display === 'Other') {
			_this.focus = 'taskDesc';
			_this.showTaskPanel = false;
			_this.setTaskSubCategory(_this.task.subCategory);
		} else {
			_this.rightPanel();
		}
	};

	_this.otherButNoDescription = function () {
		var o = _this.task.category && _this.task.category.display === 'Other' && (!_this.task.description || _this.task.description.length === 0);
		return o;
	};

	// recipientFilter
	_this.teamsOnly = function (org) {
		return org.type === 'team' || org.type === 'subteam';
	};

	_this.teamsByPractitionerFilter = function (team) {
		return _.some(_this.textFilteredRas, function (ra) {
			return ra.role.organization.id === team.id;
		});
	};

	_this.teamsByRecipientCampus = function (team) {
		return team.partOf.location && team.partOf.location[0].id === _this.recipientFilter.byCampus.id;
	};

	_this.teamsByPatientCampus = function (team) {
		return team.partOf.location && team.partOf.location[0].id === _this.encounterFilter.byCampus.id;
	};

	_this.teamsByEditPatientCampus = function (team) {
		return team.partOf.location && team.partOf.location[0].id === _this.editPatientCampus.id;
	};

	_this.wardsByEditPatientCampus = function (ward) {
		return ward.partOf.id === _this.editPatientCampus.id;
	};

	_this.wardsOnly = function (loc) {
		return _.some(loc.physicalType.coding, { 'code': 'wi' });
	};

	_this.wardsByCampusFilter = function (loc) {
		return loc.partOf.id === _this.encounterFilter.byCampus.id;
	};

	_this.levelsByPractitionerFilter = function (r) {
		return _.some(_this.textFilteredRas, function (ra) {
			return ra.role.level.code === r.code;
		});
	};

	_this.bedsOnly = function (loc) {
		return _.some(loc.physicalType.coding, { 'code': 'ro' });
	};

	_this.filterWard = function (text) {
		return function (w) {
			return w.display.toLowerCase().startsWith(text.toLowerCase());
		};
	};

	_this.wardField = function () {
		return config.wardDisplayField === 'name' ? 'name' : 'identifier[0].value';
	};

	_this.filterUrgencies = function (u) {
		//eslint-disable-line
		if (codeCompose || _this.task.subCategory && _this.task.subCategory.urgency && _this.task.subCategory.urgency !== 'routine') {
			return true;
		}
		if (_this.task.patientRelated) {
			return u.userSelectable;
		}
		return u.index > 3 && u.userSelectable;
	};

	_this.toggleDue = function () {
		_this.showDue = !_this.showDue;
		if (_this.showDue && !_this.task.fulfillmentTime) {
			_this.dueByTime = moment().subtract(moment().minutes(), 'minutes').add(1, 'hours');
		}
	};

	_this.setFulfillmentTime = function () {
		if (_this.mainScope.section2Form.dueTime.$valid) {
			_this.task.fulfillmentTime = moment(_this.dueByTime).add(_this.dueByDay.days, 'days');
		}
		_this.showDue = false;
		_this.focus = 'timeframe';
	};

	_this.validateTime = function () {
		_this.mainScope.section2Form.dueTime.$setValidity('invalidTime', moment(_this.dueByTime).add(_this.dueByDay.days, 'days').isAfter(moment()));
	};

	_this.clearTime = function () {
		_this.task.fulfillmentTime = null;
		_this.dueByTime = moment().subtract(moment().minutes(), 'minutes').add(1, 'hours');
		_this.dueByDay = _this.dueDayOptions[0];
		_this.validateTime();
	};

	_this.open = function ($event) {
		$event.preventDefault();
		$event.stopPropagation();
		_this.opened = true;
	};

	//highlight occurrences of term within s by wrapping with <span class="hilight">term</span>
	_this.highlight = function (text, terms, className) {
		className = className || 'hilight';
		return $sce.trustAsHtml(ComposeService.highlight(text, terms, className));
	};

	_this.timeframeTab = function () {
		if (_this.includeObservations) {
			_this.focus = 'airway-0';
		} else {
			_this.focus = 'submit-btn';
		}
	};

	_this.buttonObsUp = function (index, name) {
		if (index > 0) {
			index--;
			_this.focus = name + '-' + index;
		}
	};

	_this.buttonObsDown = function (index, name) {
		if (index < config[name].options.length - 1) {
			index++;
			_this.focus = name + '-' + index;
		}
	};

	_this.downRoleAssignment = function () {
		if (_this.recipientResults.length !== 0) {
			_this.roleAssignmentIndex++;
			if (_this.roleAssignmentIndex === _this.recipientResults.length) {
				_this.roleAssignmentIndex = 0;
			}
			_this.highlightedRoleAssignment = _this.recipientResults[_this.roleAssignmentIndex];
		}
	};

	_this.up = function () {
		return _this.searchGroups ? _this.upGroup() : _this.upRoleAssignment();
	};
	_this.down = function () {
		return _this.searchGroups ? _this.downGroup() : _this.downRoleAssignment();
	};

	_this.upRoleAssignment = function () {
		if (_this.recipientResults.length !== 0) {
			_this.roleAssignmentIndex--;
			if (_this.roleAssignmentIndex < 0) {
				_this.roleAssignmentIndex = _this.recipientResults.length - 1;
			}
			_this.highlightedRoleAssignment = _this.recipientResults[_this.roleAssignmentIndex];
		}
	};

	_this.downGroup = function () {
		if (_this.roleGroupsResults.length !== 0) {
			_this.roleGroupIndex++;
			if (_this.roleGroupIndex === _this.roleGroupsResults.length) {
				_this.roleGroupIndex = 0;
			}
			_this.highlightedRoleGroup = _this.roleGroupsResults[_this.roleGroupIndex];
		}
	};

	_this.upGroup = function () {
		if (_this.roleGroupsResults.length !== 0) {
			_this.roleGroupIndex--;
			if (_this.roleGroupIndex < 0) {
				_this.roleGroupIndex = _this.roleGroupsResults.length - 1;
			}
			_this.highlightedRoleGroup = _this.roleGroupsResults[_this.roleGroupIndex];
		}
	};

	_this.searchRoleGroups = function (text, page) {
		_this.groupHits = 0;
		if (!text) {
			_this.roleGroupsResults = [];
			return;
		}
		_this.roleGroupPage = page = page || 0;
		_this.searchingRoleGroups = true;
		var c = {
			name: text,
			campus: _this.recipientFilter.byCampus ? _this.recipientFilter.byCampus.id : null,
			//TODO: pager and level/responsibility
			size: 20,
			from: page * 20
		};
		var p = AppService.searchRoleGroups(c);
		grpSearchPromises.push(p);
		p.then(function (e) {
			if (p === grpSearchPromises[grpSearchPromises.length - 1]) {
				_this.searchingRoleGroups = false;
				if (e.results.length > 0) {
					if (page === 0) {
						_this.roleGroupsResults = e.results;
					} else {
						_this.roleGroupsResults = _this.roleGroupsResults.concat(e.results);
					}
					_this.highlightedRoleGroup = _this.roleGroupsResults[0];
					_this.roleGroupHits = e.totalHits;
					_this.roleGroupIndex = 0;
					for (var i = 0; i < e.results.length; i++) {
						var g = e.results[i];
						//g.role.unshift(g.primaryRole);
						g.groupedRoles = _.groupBy(g.roleAssignments, function (ra) {
							return _this.campusCode(ra.teamCampusId) + ' ' + ra.teamName;
						});
					}
					for (var _i = 0; _i < e.results.length; _i++) {
						if (_this.highlightedRoleGroup === e.results[_i]) {
							_this.highlightedRoleGroup = e.results[0];
							_this.roleGroupIndex = _i;
							break;
						}
					}
				} else {
					_this.highlightedRoleGroup = null;
					_this.roleGroupIndex = -1; // Should not be used
					_this.roleGroupsResults = [];
				}
				grpSearchPromises = [];
			}
		}, function () {
			_this.searchingRoleGroups = false;
		});
	};

	_this.searchRoleAssignments = function (text, page) {
		_this.recipientHits = 0;
		if (!text) {
			_this.recipientResults = [];
			return;
		}
		_this.recipientPage = page = page || 0;
		_this.searchingRecipients = true;
		var c = {
			terms: text.toLowerCase(),
			team: _this.recipientFilter.byTeam ? _this.recipientFilter.byTeam.id : null,
			role: _this.recipientFilter.byLevel,
			campus: _this.recipientFilter.byCampus ? _this.recipientFilter.byCampus.id : null,
			//TODO: pager and level/responsibility
			size: 20,
			from: page * 20
		};
		var p = AppService.searchRoleAssignments(c);
		raSearchPromises.push(p);
		p.then(function (e) {
			if (p === raSearchPromises[raSearchPromises.length - 1]) {
				_this.searchingRecipients = false;
				if (e.results.length > 0) {
					if (page === 0) {
						_this.recipientResults = e.results;
					} else {
						_this.recipientResults = _this.recipientResults.concat(e.results);
					}

					_this.recipientHits = e.totalHits;
					_this.highlightedRoleAssignment = _this.recipientResults[0];
					_this.roleAssignmentIndex = 0;
					for (var i = 0; i < e.results.length; i++) {
						if (_this.highlightedRoleAssignment === e.results[i]) {
							_this.highlightedRoleAssignment = e.results[0];
							_this.roleAssignmentIndex = i;
							break;
						}
					}
				} else {
					_this.highlightedRoleAssignment = null;
					_this.roleAssignmentIndex = -1; // Should not be used
					_this.recipientResults = [];
				}
				raSearchPromises = [];
			}
		}, function () {
			_this.searchingRecipients = false;
		});
	};

	// Encounter (Patient section)
	_this.searchEncounters = function (page) {
		_this.encounterHits = 0;
		if (!_this.patientInput) {
			_this.encounterResults = [];
			return;
		}
		_this.encounterPage = page = page || 0;
		_this.searchingEncounters = true;
		var c = {
			terms: _this.patientInput.toLowerCase().replace(/\//g, ' '),
			team: _this.encounterFilter.byTeam ? _this.encounterFilter.byTeam.id : null,
			ward: _this.encounterFilter.byWard ? _this.encounterFilter.byWard.id : null,
			campus: _this.encounterFilter.byCampus ? _this.encounterFilter.byCampus.id : null,
			size: 20,
			from: page * 20,
			fields: encounterSearchFields
		};
		var p = AppService.searchEncounters(c);
		encounterSearchPromises.push(p);
		p.then(function (e) {
			if (p === encounterSearchPromises[encounterSearchPromises.length - 1]) {
				_this.searchingEncounters = false;
				if (page === 0) {
					_this.encounterResults = e.results;
				} else {
					_this.encounterResults = _this.encounterResults.concat(e.results);
				}

				_this.encounterHits = e.totalHits;
				if (_this.encounterResults.length) {
					for (var i = 0; i < _this.encounterResults.length; i++) {
						if (_this.highlightedResult === _this.encounterResults[i]) {
							_this.highlightedResultIndex = i;
							return;
						}
						_this.highlightedResult = _this.encounterResults[0];
						_this.highlightedResultIndex = 0;
					}
				}
				encounterSearchPromises = [];
			}
		}, function () {
			_this.searchingEncounters = false;
		});
	};

	_this.showMoreEncounters = function () {
		_this.searchEncounters(_this.encounterPage + 1);
	};

	_this.showMoreRecipients = function (text) {
		_this.searchRoleAssignments(text, _this.recipientPage + 1);
	};

	_this.showMoreGroups = function (text) {
		_this.searchRoleGroups(text, _this.roleGroupPage + 1);
	};

	_this.downEncounter = function () {
		if (_this.encounterResults.length !== 0) {
			_this.highlightedResultIndex++;
			if (_this.highlightedResultIndex === _this.encounterResults.length) {
				_this.highlightedResultIndex = 0;
			}
			_this.highlightedResult = _this.encounterResults[_this.highlightedResultIndex];
		}
	};

	_this.upEncounter = function () {
		if (_this.encounterResults.length !== 0) {
			_this.highlightedResultIndex--;
			if (_this.highlightedResultIndex < 0) {
				_this.highlightedResultIndex = _this.encounterResults.length - 1;
			}
			_this.highlightedResult = _this.encounterResults[_this.highlightedResultIndex];
		}
	};

	_this.selectEncounter = function (id) {
		if (id) {
			AppService.get('Encounter', id).then(function (e) {
				AppService.get('Patient', e.patient.id).then(function () {
					_setEncounter(e);
				});
			});
		}
	};

	_this.selectEditPatientCampus = function (c) {
		if (c !== _this.editPatientCampus) {
			_this.confirmTeam = null;
			_this.ward = '';
			_this.bed = '';
		}
		_this.editPatientCampus = c;
	};

	/* eslint-disable  */
	_this.editPatientValid = function () {
		return _this.mainScope.section1Form.override_bed.$valid && _this.mainScope.section1Form.override_ward.$valid && _this.mainScope.section1Form.override_team.$valid && _this.confirmTeam && _this.ward;
	};
	/* eslint-enable */

	_this.confirmPatientDetails = function () {
		if (_this.editPatientValid) {
			_this.editPatientInfo = false;
		}
	};

	_this.validateTeam = function (team) {
		_this.mainScope.section1Form.$setValidity('invalidTeam', !!team);
	};

	_this.validateWard = function (ward) {
		_this.mainScope.section1Form.$setValidity('invalidWard', !!ward);
	};

	_this.firstName = AppService.firstName;
	_this.lastName = AppService.lastName;

	// Observations
	// ------------------------------------------

	_this.setUrgency = function (u) {
		_this.task.urgency = u;
		_this.selectedUrgency = u;
	};

	_this.setObservationTime = function (t) {
		_this.task.observation.observationTime = t;
	};

	_this.setTrend = function (t) {
		_this.task.observation.trend = t;
	};

	_this.goToCc = function () {
		$state.go('app.cc');
	};

	_this.backToCompose = function () {
		$state.go('app.compose');
	};

	// Task
	// ------------------------------------------

	_this.setTaskType = function (t) {
		_this.setTaskSubCategory(t);
		_this.showTaskPanel = false;
	};

	_this.sendPressed = function ($event, form) {
		LogService.debug('send pressed');
		if (form.$valid) {
			_sendTask(form);
		} else {
			//			$event.preventDefault();
		}
	};

	_this.escalate = ComposeService.escalate;

	_this.escalateByOption = ComposeService.escalateByOption;

	// FILTER
	_this.shiftRoleAssignments = function (ra) {
		return ra.type === 'shift';
	};

	// Custom fields
	_this.addCustomItem = function (field, item) {
		if (item) {
			_this.custom.multivalues[field.name] = _this.custom.multivalues[field.name] || [];
			_this.custom.multivalues[field.name].push(item);
		}
	};

	_this.removeCustomItem = function (field, item) {
		if (item) {
			_.remove(_this.custom.multivalues[field.name], function (i) {
				return i.code === item.code;
			});
		}
	};

	_this.filterSelected = function (options, selected) {
		return _.filter(options, function (o) {
			return !_.find(selected, function (s) {
				return s.code === o.code;
			});
		});
	};

	_this.toggleCustomItem = function (field, item) {
		if (_.find(_this.custom.multivalues[field.name], item)) {
			_this.removeCustomItem(field, item);
		} else {
			_this.addCustomItem(field, item);
		}
	};

	_this.isCustomItemSelected = function (field, item) {
		return _.find(_this.custom.multivalues[field.name], item);
	};
}]);